import {
  Checkbox,
  checkboxClasses,
  FormControlLabel,
  Typography,
} from "@mui/material";
import React from "react";
import { Stack } from "react-bootstrap";
import Done from "@mui/icons-material/Done";
import { useState } from "react";
import { useEffect } from "react";

const CheckBoxes = ({ data, title, state, setState, accessor }) => {
  console.log(state);
  // const [checked,setChecked] = useState([])
  // (state);

  const handleCheck = (e) => {
    let updateList = [...state];
    if (e.target.checked) {
      updateList = [...state, e.target.value];
    } else updateList.splice(state.indexOf(e.target.value), 1);
    setState(updateList);
  };
  return (
    <Stack>
      <Typography variant="h5" fontSize="18px" fontWeight="600" mb={1}>
        {" "}
        {title}{" "}
      </Typography>
      {data.map((val, ind) => {
        console.log(data);
        return (
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked={
                  state[0] ? (val?.title === state[0] ? true : false) : false
                }
              />
            }
            name="category"
            value={accessor ? val[accessor] : val}
            onChange={handleCheck}
            label={accessor ? val[accessor] : val}
            key={ind}
          />
        );
      })}
    </Stack>
  );
};

export default CheckBoxes;
