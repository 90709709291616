import React from "react";
import parse from "html-react-parser";

const refundText = `<p style='margin-top:0in;margin-right:0in;margin-bottom:10.0pt;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri","sans-serif";text-align:justify;'><span style="font-size:27px;line-height:115%;">Refund policy</span></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:10.0pt;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri","sans-serif";text-align:justify;'>In theory, you are able to cancel a session and get a full refund at any time. We ask our mentees to be mindful of their mentor&apos;s time as a cancellation. If you book sessions without the intention to attend them, this takes a spot away from someone else and wastes yours and your mentor&apos;s time.</p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:10.0pt;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri","sans-serif";text-align:justify;'>&nbsp;</p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:10.0pt;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri","sans-serif";text-align:justify;'>If you can&apos;t find a time</p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:10.0pt;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri","sans-serif";text-align:justify;'>If you haven&apos;t scheduled a session yet due to time constraints, message your mentor and see whether they can open up extra spots to overlap with your timezone.</p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:10.0pt;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri","sans-serif";text-align:justify;'>&nbsp;</p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:10.0pt;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri","sans-serif";text-align:justify;'>If that&apos;s not possible, you or your mentors are welcome to cancel the session. A full refund is processed after 24 hours.</p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:10.0pt;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri","sans-serif";text-align:justify;'>&nbsp;</p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:10.0pt;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri","sans-serif";text-align:justify;'>If you&apos;ve already scheduled a time</p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:10.0pt;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri","sans-serif";text-align:justify;'>Message your mentor if you need to cancel altogether. You have the right to cancel a session and get a full refund up until 48 hours before the date of your session.</p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:10.0pt;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri","sans-serif";text-align:justify;'>&nbsp;</p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:10.0pt;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri","sans-serif";text-align:justify;'>You are not entitled to a refund if you cancel within 48 hours of your scheduled meeting. Your mentor may still offer you one.</p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:10.0pt;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri","sans-serif";text-align:justify;'>&nbsp;</p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:10.0pt;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri","sans-serif";text-align:justify;'>If you missed your session</p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:10.0pt;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri","sans-serif";text-align:justify;'>We do not offer refunds if you&apos;ve missed or no-showed to your session. It&apos;s up to the individual mentor if they&apos;d like to offer you a replacement date.</p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:10.0pt;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri","sans-serif";text-align:justify;'>&nbsp;</p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:10.0pt;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri","sans-serif";text-align:justify;'>If you&apos;ve booked and scheduled a session, the mentor has put in the prep work and has been online during your scheduled appointment, we count the session as &apos;completed&apos;.</p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:10.0pt;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri","sans-serif";text-align:justify;'>&nbsp;</p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:10.0pt;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri","sans-serif";text-align:justify;'>What counts as no-shows:</p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:10.0pt;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri","sans-serif";text-align:justify;'>Non-participation at the scheduled date</p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:10.0pt;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri","sans-serif";text-align:justify;'>Cancelling the meeting less than an hour before the appointment</p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:10.0pt;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri","sans-serif";text-align:justify;'>If the session has already happened</p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:10.0pt;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri","sans-serif";text-align:justify;'>If you had a session with your mentor for the pre-defined time, the session counts as completed. We do not offer a money-back guarantee for sessions, in the case that the session has not met your expectations. Please do not try and cancel the session to get a refund.</p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:10.0pt;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri","sans-serif";text-align:justify;'>&nbsp;</p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:10.0pt;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri","sans-serif";text-align:justify;'>Of course, we&apos;re dedicated to help you reach your goal. So if a session has not been up to the standard you expect, get in touch and let&apos;s find a solution together.</p>`;
const RefundPolicy = () => {
  return (
    <div
      className="terms"
      style={{ background: "white", padding: "1rem 2rem", color: "black" }}
    >
      {parse(refundText)}
    </div>
  );
};

export default RefundPolicy;
