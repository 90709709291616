import {
  Alert,
  Container,
  Divider,
  Grid,
  Snackbar,
  Stack,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { allImages } from "../../../images";
import BlueButton from "../../privateRoute/userRoute/BlueButton";
import NavbarL from "../LandingPage/NavbarL";
import APIRequest from "../../../utils/APIRequest";
import ConfigAPIURL from "../../../config/ConfigAPIURL";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobileNo: "",
    message: "",
  });
  const [snakbarValues, setSnakbarValues] = useState({
    status: false,
    severity: "",
    message: "",
  });

  const notificationClose = (event) => {
    setSnakbarValues({ status: false, severity: "", message: "" });
  };
  const [errors, setErrors] = useState({
    email: false,
    mobileNo: false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleChangeData = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // Validation logic
    if (name === "email") {
      const emailIsValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value); // Simple email validation
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: emailIsValid ? "" : "Please enter a valid email address",
      }));
    } else if (name === "mobileNo") {
      const mobileNoIsValid = /^\d{10}$/.test(value); // 10-digit number validation
      setErrors((prevErrors) => ({
        ...prevErrors,
        mobileNo: mobileNoIsValid
          ? ""
          : "Please enter a 10-digit mobile number",
      }));
    }
  };

  const[validation, setValidation] = useState(false)

  const handleSubmit = async (e) => {


    try {
      if(validation === true){
        return setSnakbarValues({
          status: true,
          severity: "error",
          message: "Please Wait for current query submission",
        });
      }
      if (formData.email === "") {
        return setSnakbarValues({
          status: true,
          severity: "error",
          message: "Please enter the Email",
        });
      }
      if (formData.mobileNo === "") {
        return setSnakbarValues({
          status: true,
          severity: "error",
          message: "Please enter the Mobile Number",
        });
      }
      if (formData.firstName === "") {
        return setSnakbarValues({
          status: true,
          severity: "error",
          message: "Please enter the Name",
        });
      }
      if (formData.message === "") {
        return setSnakbarValues({
          status: true,
          severity: "error",
          message: "Please enter Message",
        });
      }
      const requestBody = JSON.stringify({
        ...formData,
      });

      setValidation(true)

      const response = await APIRequest.request(
        "POST",
        ConfigAPIURL.submitContactUs,
        requestBody,
        {
          "Content-Type": "application/json",
        }
      );

      if (response?.data?.responseCode === 109) {
        setFormData({
          email: "",
          mobileNo: "",
          firstName: "",
          message: "",
          lastName: "",
        });
        setValidation(false)
        return setSnakbarValues({
          status: true,
          severity: "success",
          message: "Form submitted successfully !",
        });
      }
      // Handle successful form submission (e.g., show a success message)
    } catch (error) {
      console.error("Error submitting form:", error);
      // Handle form submission error (e.g., show an error message)
    }
  };

  return (
    <Stack sx={{ background: "#EFF6FFED", minHeight: "100vh" }}>
      <NavbarL />
      <Container maxWidth="lg" sx={{ mt: 0 }}>
        <Grid
          container
          sx={{ alignItems: "center", marginTop: "28px", marginBottom: "28px" }}
          spacing={5}
        >
          <Grid item xs={12} md={6}>
            {/* <img src={allImages.contactUs} /> */}
            <Stack
              sx={{
                gap: 4,
              }}
            >
              <Stack>
                <Typography
                  fontSize={"48px"}
                  fontWeight={"700"}
                  color={"#00275A"}
                >
                  Contact Us
                </Typography>
                <Typography fontSize={"20px"} color={"#607B9F"}>
                At MentorLink, our commitment is to be with you at every stage. Whether you require help, wish to provide feedback, or have media-related questions, our team is ready to assist you.
                </Typography>
              </Stack>
              {/* <Stack
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "58px",
                  justifyContent: "space-between",
                }}
              > */}
                
                <Stack>
                  <Typography
                    fontSize={"24px"}
                    fontWeight={"700"}
                    color={"#00275A"}
                  >
                    Address
                  </Typography>
                  <Typography
                    fontSize={"18px"}
                    color={"#607B9F"}
                    width={"300px"}
                  >
                    #122, Pipeline Road, Malleshwaram, 10th Cross, Bangalore, Karnataka, India.
                  </Typography>
                </Stack>
                <Stack>
                  <Typography
                    fontSize={"24px"}
                    fontWeight={"700"}
                    color={"#00275A"}
                  >
                    Contact Details
                  </Typography>
                  <Typography fontSize={"18px"} color={"#607B9F"}>
                    support@mentorlink.in
                  </Typography>
                  <Typography fontSize={"18px"} color={"#607B9F"}>
                  +91-9035588796
                  </Typography>
                </Stack>
              {/* </Stack> */}

              <Stack
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "14px",
                  justifyContent: "space-between",
                }}
              >
                <Stack>
                  <Typography
                    fontSize={"24px"}
                    fontWeight={"700"}
                    color={"#00275A"}
                  >
                    Feedback & Suggestions
                  </Typography>
                  <Typography fontSize={"18px"} color={"#607B9F"}>
                  We highly value your feedback and are always striving to improve MentorLink. Your input is essential in shaping the future of our platform.  
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack
              sx={{
                gap: "1rem",
                borderRadius: "16px",
                boxShadow: "0px 21px 45.8px 0px #0000002E",
                padding: "20px",
                backgroundColor: "#fff",
              }}
            >
              <Stack>
                <Typography
                  fontSize={"32px"}
                  fontWeight={"600"}
                  color={"#000"}
                  // alignSelf={"center"}
                  // textAlign={"center"}
                  marginBottom={"0px"}
                >
                  Get in touch
                </Typography>
                {/* <Typography
                  fontSize={"16px"}
                  fontWeight={"400"}
                  color={"#6C6C6C"}
                  marginTop={"0px"}
                  // alignSelf={"center"}
                  // textAlign={"center"}
                >
                  You can Reach us anytime
                </Typography> */}
              </Stack>
              <TextField
                variant="outlined"
                size="small"
                label="Full Name"
                fullWidth
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                sx={{ fontFamily: "Figtree" }}
              />
              {/* <TextField
                variant="outlined"
                size="small"
                label="Last Name"
                name="lastName"
                fullWidth
                value={formData.lastName}
                onChange={handleChange}
                sx={{ fontFamily: "Figtree" }}
              /> */}
              <TextField
                variant="outlined"
                size="small"
                label="Your email"
                name="email"
                fullWidth
                value={formData.email}
                onChange={handleChangeData}
                error={errors.email}
                helperText={
                  errors.email ? "Please enter a valid email address" : ""
                }
                sx={{ fontFamily: "Figtree" }}
              />
              <TextField
                variant="outlined"
                size="small"
                label="Mobile Number"
                name="mobileNo"
                fullWidth
                value={formData.mobileNo}
                onChange={handleChangeData}
                sx={{ fontFamily: "Figtree", "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                  "-webkit-appearance": "none",
                  margin: 0,
                },
                "& input[type=number]": {
                  "-moz-appearance": "textfield",
                }, }}
                helperText={
                  errors.mobileNo ? "Please enter a 10-digit mobile number" : ""
                }
                error={errors.mobileNo}
                type="number"
              />
              <TextareaAutosize
                style={{ height: "120px", fontWeight: "400", fontSize: "16px" }}
                rows={5}
                cols={5}
                placeholder="How can we help?"
                name="message"
                value={formData.message}
                onChange={handleChange}
              />
              <BlueButton
                style={{ alignSelf: "center", width: "70%", padding: "16px",fontSize:'16px',textTransform:'capitalize' }}
                text="Submit"
                onClick={handleSubmit}
              />
              <Typography
                fontSize={"16px"}
                fontWeight={"400"}
                color={"#8E8E8E"}
                alignSelf={"center"}
                textAlign={"center"}
                width={"70%"}
              >
                By contacting us, you agree to our{" "}
                <span style={{ color: "#3F3E3E", fontWeight: "600" }}>
                  {" "}
                  Terms of service
                </span>{" "}
                &{" "}
                <span style={{ color: "#3F3E3E", fontWeight: "600" }}>
                  Privacy policy
                </span>
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Container>
      <Snackbar
        open={snakbarValues.status}
        onClose={notificationClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={3000}
      >
        <Alert severity={snakbarValues.severity}>{snakbarValues.message}</Alert>
      </Snackbar>
    </Stack>
  );
};

export default ContactUs;
