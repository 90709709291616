import {
  Box,
  Button,
  colors,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Navbar from "../Home/Navbar";
import { styled, createTheme } from "@mui/material";
import { Link } from "react-router-dom";
import APIRequest from "../../../../utils/APIRequest";
import ConfigAPIURL from "../../../../config/ConfigAPIURL";
import { pink } from "@mui/material/colors";
import CheckBoxes from "./CheckBoxes";
import { Placeholder } from "react-bootstrap";
import "./filter.scss";
import { AccountCircle } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";

let priceRange = [
  "100-500",
  "500-1000",
  "1000-2000",
  "2000-3000",
  "3000-4000",
  "4000-5000",
];

const Filter = (props) => {
  const checkBoxes = ["Design", "Development", "Accounting", "Tally"];
  const [category, setCategory] = useState([props.query.category]);
  const [skills, setSkills] = useState([]);
  const [price, setPrice] = useState([]);
  const [showAllCategory, setShowAllCategory] = useState(false);
  const [showAllSkill, setShowAllSkill] = useState(false);
  const [showAllPrice, setShowAllPrice] = useState(false);

  // const [design,setDesign] = useState("design")

  const [data, setData] = useState([]);
  const [allCategory, setAllCategory] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  useEffect(() => {
    fetchCategories();
    fetchSkills();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await APIRequest.request(
        "GET",
        ConfigAPIURL.UserCategoriesAds,
        ""
      );
      if (response.data.responseCode === 109) {
        setData(response?.data?.categories);
      } else return;
    } catch {}
  };
  const fetchSkills = async () => {
    try {
      const response = await APIRequest.request(
        "POST",
        ConfigAPIURL.userAllSkills,
        JSON.stringify({limit:100})
      );
      if (response?.data?.responseCode === 109) {
        setAllCategory(response?.data?.result);
      } else return;
    } catch {}
  };

  const theme = createTheme({
    components: {
      MuiRadio: {},
    },
  });
  useEffect(() => {
    props.setQuery({
      ...props.query,
      keyword: searchTerm,
      page: 0,
    });
  }, [searchTerm]);

  useEffect(() => {
    props.setQuery({
      ...props.query,
      category: category.join("|"),
      page: 0,
    });
  }, [category]);

  useEffect(() => {
    props.setQuery({
      ...props.query,
      skill: skills,
      page: 0,
    });
  }, [skills]);
  useEffect(() => {
    props.setQuery({
      ...props.query,
      priceRange: price,
      page: 0,
    });
  }, [price]);

  const MyCustombutton = styled(Button)({
    padding: "8px, 17px, 8px, 17px",
    backgroundColor: "#1C3464",
    borderRadius: "37px",
    color: "white",
    width: "80px",
    height: "32px",
    fontsize: "16px",
    fontWeight: "700",
  });
  const MyFormControlLabel = styled(FormControlLabel)({
    color: "Black",
  });

  return (
    <ThemeProvider theme={theme}>
      <Stack
        className="sticky__div"
        mt={2}
        backgroundColor="white"
        py={2}
        sx={{
          border: "0.742701px solid #D2D2D2",
          borderRadius: "5px",
          mt: { xs: 2, md: 0 },
        }}
      >
        <Stack
          direction="row"
          px={2}
          borderBottom="1px solid #E6E6E6"
          pb={1}
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            className="heading"
            variant="subtitle1"
            sx={{ fontWeight: "600" }}
          >
            Filter
          </Typography>
          <Box
            sx={{ display: { xs: "block", md: "none" } }}
            onClick={() => {
              props.setShowFilter(!props.showFilter);
            }}
          >
            <MyCustombutton variant="contained">Apply</MyCustombutton>
          </Box>
        </Stack>
        <Stack mt={2} px={3} gap={3}>
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            label="Search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search by Keywords"
            autoFocus
            fullWidth
            size="small"
          />
          <Stack>
            <CheckBoxes
              data={!showAllCategory ? data.slice(0, 3) : data}
              state={category}
              setState={setCategory}
              accessor={"title"}
              title="Category"
            />
            <Typography
              onClick={() => {
                setShowAllCategory(!showAllCategory);
              }}
              style={styles}
            >
              {showAllCategory ? "Show less..." : "See all..."}
            </Typography>
          </Stack>
          <Stack>
            <CheckBoxes
              data={!showAllSkill ? allCategory.slice(0, 3) : allCategory}
              accessor={"skill"}
              title="Skills"
              state={skills}
              setState={setSkills}
            />
            <Typography
              onClick={() => {
                setShowAllSkill(!showAllSkill);
              }}
              style={styles}
            >
              {showAllSkill ? "Show less..." : "See all..."}
            </Typography>
          </Stack>
          <Stack>
            <CheckBoxes
              data={!showAllPrice ? priceRange.slice(0, 3) : priceRange}
              title="Price range"
              state={price}
              setState={setPrice}
            />
            <Typography
              onClick={() => {
                setShowAllPrice(!showAllPrice);
              }}
              style={styles}
            >
              {showAllPrice ? "Show less..." : "See all..."}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </ThemeProvider>
  );
};
const styles = {
  color: "#2C72FF",
  fontWeight: "600",
  fontSize: "16px",
  marginTop: ".3rem",
  cursor: "pointer",
};

export default Filter;
