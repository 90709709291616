import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import ConfigAPIURL from "../config/ConfigAPIURL";
import Navbar from "../components/privateRoute/userRoute/Home/Navbar";
import APIRequest from "../utils/APIRequest";
import { useHistory } from "react-router-dom";
import Loading from "./Loading";
import LocalStorage from "../config/LocalStorage";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { userDetails } from "../components/privateRoute/userRoute/Redux/UserSlice";

import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
// import "firebase/messaging";
import { firebaseConfig } from "../config/Firebase";

// const messaging = getMessaging(firebaseApp);
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

const Privateroute = (props) => {
  const location = useLocation();
  const userDatas = useSelector((state) => state.user.profileData);
  const dispatch = useDispatch();
  const [isLoggedIn, setisLoggedin] = useState(false);
  const [userData, setUserData] = useState({});
  const navigate = useNavigate();
  const [token, setToken] = useState("");

  function requestPermission() {
    //requesting permission
    if (Notification in window) {
      Notification?.requestPermission().then((permission) => {
        // console.log("currentToken:permission ", permission);
        //permission granted
        if (permission === "granted") {
          const app = initializeApp(firebaseConfig);

          const messaging = getMessaging(app);

          getToken(messaging, {
            vapidKey:
              "BPD9h3PoLxEdCo8KQiA-Xmod0Hag2i8BFL0Rbeaxgku35hnBorl3x7OyHHIVD5Tk38ZbKMre6ww8XDTGZ3bUDAA",
          }).then((currentToken) => {
            if (currentToken) {
              // console.log("currentToken: ", currentToken);
              setToken(currentToken);
            } else {
              console.log("Can not get token");
            }
          });
        } else {
          console.log("Do not have permission!");
        }
      });
    }
  }

  const userLogout = async () => {
    try {
      const response = await APIRequest.request(
        "GET",
        ConfigAPIURL.userLogout,
        ""
      );
      if (response?.data?.responseCode === 109) {
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } catch (err) {
      console.log(err);
    }
  };

  //to get the fcm token
  // useEffect(() => {
  //   requestPermission();
  // }, []);

  // checks wheher user is logged in or not
  const isLogIn = () => {
    APIRequest.request(
      "GET",
      ConfigAPIURL.userIsLoggedin + "?fcmToken=" + token,
      ""
    )
      .then((response) => {
        if (response?.data?.responseCode === 109) {
          // if email and password mathched
          if (response?.data?.userType !== null) {
            // usertype is not null then authenticated
            setisLoggedin(true);
            // console.log(response?.data?.user, "this is response from is login");
            localStorage.setItem(
              "userDetails",
              JSON.stringify(response?.data?.user)
            );
            dispatch(userDetails(response?.data?.user));
          } else {
            userLogout();
          }

          // store the user whole details in local storage
        } else navigate("/login");
      })
      .catch((err) => {
        console.warn(err);
      });
  };
  const isLogInAcnt = () => {
    APIRequest.request("GET", ConfigAPIURL.userIsLoggedin, "")
      .then((response) => {
        if (response?.data?.responseCode === 109) {
          // if email and password mathched
          if (response?.data?.userType !== null) {
            // usertype is not null then authenticated
            setisLoggedin(true);
            // console.log(response?.data?.user, "this is response from is login");
            localStorage.setItem(
              "userDetails",
              JSON.stringify(response?.data?.user)
            );
            dispatch(userDetails(response?.data?.user));
          } else {
            userLogout();
          }

          // store the user whole details in local storage
        } else navigate("/login");
      })
      .catch((err) => {
        console.warn(err);
      });
  };

  useEffect(() => {
    isLogInAcnt();
  }, [token]);
  // if logged in moves to the private  routes
  if (isLoggedIn) {
    return (
      <>
        {location.pathname !== "/update" && <Navbar />}
        <Outlet />
      </>
    );
  }
  if (!isLoggedIn) return <Loading />;
};

export default Privateroute;
