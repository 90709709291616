import { Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ConfigAPIURL from "../../../../config/ConfigAPIURL";
import { allImages } from "../../../../images";
import APIRequest from "../../../../utils/APIRequest";
import AchievementAccordion from "../mentorprofilepage/AchievementAccordion";
import CertificationAccordion from "../mentorprofilepage/CertificationAccordion";
import EducationAccordion from "../mentorprofilepage/EducationAccordion";
import ExperienceAccordion from "../mentorprofilepage/EducationAccordion";
import MentorDetailsAccordion from "../mentorprofilepage/MentorDetailsAccordion";
import ProfileExperience from "../mentorprofilepage/ProfileExperience";
import SheduleSession from "../mentorprofilepage/SheduleSession";
import Modal from "./Modal";
import ModalAbout from "./ModalAbout";
import ModalParent from "./ModalParent";
import ModelExperience from "./ModelExperience";

const UserAdditional = ({ data }) => {
  const [userData, setUserData] = useState();
  // whenever there is a change to render the ui again
  const [change, setChange] = useState(true);

  useEffect(() => {
    fetchUserAdditionalInfo();
  }, [change]);
  const fetchUserAdditionalInfo = async () => {
    const response = await APIRequest.request(
      "POST",
      ConfigAPIURL.userAdditionalInfo,
      JSON.stringify({
        userId: data._id,
      })
    );
    if (response?.data?.responseCode === 109) {
      setUserData(response?.data?.profile[0]);
    }
  };
  return (
    <Stack>
      {/* accordion */}
      {userData && (
        <>
          <MentorDetailsAccordion title="About" data={userData}>
            <ModalParent
              fetchUserAdditionalInfo={fetchUserAdditionalInfo}
              userData={userData}
              setState={setChange}
              change={change}
            />
          </MentorDetailsAccordion>

          <ProfileExperience
            title="Experience"
            setState={setChange}
            data={userData}
            fetchUserAdditionalInfo={fetchUserAdditionalInfo}
          />

          <EducationAccordion
            fetchUserAdditionalInfo={fetchUserAdditionalInfo}
            title="Education"
            setState={setChange}
            state={change}
            data={userData}
          />

          <AchievementAccordion
            title="Awards/Recognition"
            setState={setChange}
            fetchUserAdditionalInfo={fetchUserAdditionalInfo}
            data={userData}
          />
          <CertificationAccordion
            fetchUserAdditionalInfo={fetchUserAdditionalInfo}
            title="Certifications"
            setState={setChange}
            data={userData}
          />
        </>
      )}
    </Stack>
  );
};

export default UserAdditional;
