import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { allImages } from "../../../../images";
import CloseIcon from "@mui/icons-material/Close";

const CancelDialog = ({
  data,
  setIsCancel,
  handleBookingUpdate,
  resetForm,
  isMentor,
  userForm,
}) => {
  const [selectedOptions, setSelectedOptions] = useState("");


  const handleUpdate = async () => {
    if (selectedOptions === "other" && !data?.reason) return;
    const response = await handleBookingUpdate(
      data?._id,
      "cancelled",
      selectedOptions === "other" || isMentor ? data?.reason : selectedOptions
    );
    if (response) resetForm();
  };
  console.log(isMentor, "this is user details");
  return (
    <Stack sx={{ m: "0 2rem ", position: "relative" }}>
      {/* <Stack
        alignSelf="center"
        style={{
          width: "80px",
          height: "80px",
          justifyContent: "center",
          alignItems: "center",

          zIndex: "99999999999",
        }}
        sx={{
          boxShadow: "0px 1px 4px rgba(44, 114, 255, 0.25)",
          borderRadius: "100%",
        }}
      >
        <img
          style={{ width: "60px", height: "60px" }}
          src={allImages.sadImage}
          alt="sad face"
        />
      </Stack> */}
      <Stack style={{ position: "absolute", right: 0, top: 10 }}>
        <CloseIcon style={{ cursor: "pointer" }} onClick={resetForm} />
      </Stack>
      <DialogTitle
        style={{
          textAlign: "center",
          marginTop: 0,
          fontSize: "24px",
          fontWeight: 700,
        }}
      >
        Confirm Booking cancellation{" "}
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          style={{ textAlign: "center" }}
          className="sub__heading"
        >
          Are you sure, do you want to cancel the booking? If you’d still like
          to cancel, it would be helpful for me to know why?
        </DialogContentText>
        <Stack sx={{ mt: 3, gap: 2 }}>
          {!isMentor && (
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                value={selectedOptions}
                onChange={(e) => setSelectedOptions(e.target.value)}
              >
                <FormControlLabel
                  value="Wrong Booking Date & Time "
                  control={<Radio />}
                  label={
                    <div className="sub__heading">
                      Wrong Booking Date & Time{" "}
                    </div>
                  }
                />
                <FormControlLabel
                  value="Booking placed by mistake"
                  control={<Radio />}
                  label={
                    <div className="sub__heading">
                      Booking placed by mistake
                    </div>
                  }
                />
                <FormControlLabel
                  value="other"
                  control={<Radio />}
                  label={<div className="sub__heading">Others</div>}
                />
              </RadioGroup>
            </FormControl>
          )}
          {(selectedOptions === "other" || isMentor) && (
            <TextField
              className="textfield"
              onChange={(e) => {
                setIsCancel((p) => {
                  return {
                    ...p,
                    reason: e.target.value,
                  };
                });
              }}
              size="small"
              required={(selectedOptions === "other" || isMentor) && true}
              label="Type reason for cancelation"
            />
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          //   className="button__overlay"
          sx={{
            fontWeight: "700",
            fontSize: { xs: "14px", md: "14px" },
            // border: "2px solid #2A4B95",
            borderRadius: "37px",
            py: 0.5,
            px: 2,
          }}
          onClick={handleUpdate}
        >
          Proceed
        </Button>{" "}
      </DialogActions>
    </Stack>
  );
};

export default CancelDialog;
