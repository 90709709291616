import { Stack, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Herobanner from "./Herobanner";
import NavbarL from "./NavbarL";
import "./landing.scss";
import ChooseMentorLink from "./ChooseMentorLink";
import Skills from "./Skills";
import Testimonials from "./Testimonials";
import Faq from "./Faq";
import ActiveMembers from "./ActiveMembers";
import MentorLinkFeatures from "./MentorLinkFeatures";
import { IconButton } from "@mui/material";
import ChatIcon from "@mui/icons-material/Chat";
import ChatBotModal from "./ChatBotModal";
import ChatArea from "./ChatArea";
import APIRequest from "../../../utils/APIRequest";
import ConfigAPIURL from "../../../config/ConfigAPIURL";
import TopRatedMentors from "./TopRatedMentors";
import SlidingPanel from "./SlidingPanel";
import BottomMenu from "./BottomMenu";
import { allImages } from "../../../images";

const Index = () => {
  const [open, setOpen] = useState(false);
  const [isSlidingPanelOpen, setIsOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);
  const images = [allImages.RoboCloseEye, allImages.RoboOpenEye];
  const [showInitialView, setShowInitialView] = useState(true);
  const [developmentMessage, setDevelopmentMessage] = useState("");
  const [showBookingPrompt, setShowBookingPrompt] = useState(false);
  const [showSignupPrompt, setShowSignupPrompt] = useState(false);
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const [noMentorsFound, setNoMentorsFound] = useState(false);

  useEffect(() => {
    let interval;
    if (!isSlidingPanelOpen) {
      interval = setInterval(() => {
        setCurrentImage((prev) => (prev === 0 ? 1 : 0));
      }, 2000);
    } else {
      setCurrentImage(1); // Show the second image when chat is open
    }

    return () => clearInterval(interval);
  }, [isSlidingPanelOpen]);

  const handleClickOpen = () => {
    // setOpen(true);
    setIsOpen((prev) => !prev);
    setShowInitialView(true);
    setDevelopmentMessage("");
    setShowBookingPrompt(false);
    setShowSignupPrompt(false);
    setNoMentorsFound(false);
  };

  const handleClose = () => {
    setIsOpen(false);
  };
  return (
    <div>
      <NavbarL />
      <Stack sx={{ background: "white", py: 2 }}>
        <Stack
          onClick={handleClickOpen}
          sx={{
            position: "fixed",
            bottom: 16,
            right: 16,
            // background: "linear-gradient(360deg, #1E5EDF 0%, #00275A 100%)",
            color: "white",
            // "&:hover": {
            //   backgroundColor: "#0056b3",
            // },
            width: 66,
            height: 66,
            zIndex: 99999999,
            display: "flex",
            // flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "50%",
            paddingLeft: 0,
            cursor: "pointer",
          }}
        >
          <img
            src={images[isSlidingPanelOpen ? 0 : currentImage]}
            alt="Chat robot"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              transition: "opacity 0.5s ease-in-out",
            }}
          />
        </Stack>

        <Container>
          <Herobanner />
          <ChooseMentorLink />
          {/* <Skills /> */}
        </Container>
        <TopRatedMentors />
        <Container>
          <Skills />
        </Container>
        <ActiveMembers />
        {/* <Container> */}
        <Stack
          style={{
            marginLeft: !isSmallScreen && "45px",
            marginRight: !isSmallScreen && "45px",
          }}
        >
          <MentorLinkFeatures />
        </Stack>

        {/* </Container> */}
        <Testimonials />
        <Container>
          <Faq />
        </Container>
        {/* <SlidingPanel
          isSlidingPanelOpen={isSlidingPanelOpen}
          setIsOpen={setIsOpen}
        /> */}
        <ChatBotModal
          slideInDialog={isSlidingPanelOpen}
          handleClose={handleClose}
          fullWidth={false}
          maxWidth={"sm"}
          dialogActionsStatus={true}
          setSlideInDialog={handleClose}
          // title={"Smart Mentor Match Let’s Find Your Perfect Mentor"}
          contentComponent={
            <BottomMenu
              setIsOpen={setIsOpen}
              showInitialView={showInitialView}
              setShowInitialView={setShowInitialView}
              developmentMessage={developmentMessage}
              setDevelopmentMessage={setDevelopmentMessage}
              showBookingPrompt={showBookingPrompt}
              setShowBookingPrompt={setShowBookingPrompt}
              showSignupPrompt={showSignupPrompt}
              setShowSignupPrompt={setShowSignupPrompt}
              setNoMentorsFound={setNoMentorsFound}
              noMentorsFound={noMentorsFound}
            />
          }
        />
        {/* <ChatBotModal
          slideInDialog={open}
          handleClose={handleClose}
          fullWidth={true}
          maxWidth={"sm"}
          dialogActionsStatus={true}
          setSlideInDialog={handleClose}
          title={"Smart Mentor Match Let’s Find Your Perfect Mentor"}
          contentComponent={<ChatArea message={messages} />}
        /> */}
      </Stack>
    </div>
  );
};

export default Index;
