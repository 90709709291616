import { Container, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { allImages } from "../../../images";
import MentorLinkCard from "./MentorLinkCard";
import landingIMg1 from "../../../assets/landingPage1.png";
import landingImg2 from "../../../assets/landingImg2.png";
import landingImg3 from "../../../assets/landingImg3.png";
import landingImg4 from "../../../assets/landingImg4.png";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

// const images = [landingIMg1, landingImg2, landingImg3, landingImg4];

const data = [
  {
    img: allImages.findmentor,
    text: "Explore our network of seasoned mentors, ready to illuminate your career path. From various domains, they offer invaluable guidance and expertise.",
    title: "Find mentor in your domain",
  },
  {
    img: allImages.contionuslearn,
    text: "Embark on a journey of continuous growth through curated resources and tailored learning paths. Stay ahead with our comprehensive learning solutions.",
    title: "Continuous Learning and Development",
  },
  {
    img: allImages.CalenderTwo,
    text: "Enjoy the flexibility of scheduling mentor sessions and accessing learning materials anytime, anywhere. Adapt to your busy lifestyle with ease.",
    title: "Convenient Scheduling, Anytime, Anywhere",
  },
  {
    img: allImages.UserFriendly,
    text: "Navigate our intuitive platform effortlessly. Engage with mentors and access learning resources seamlessly, ensuring an enjoyable and productive experience.",
    title: "User-Friendly Platform, Seamless Experience",
  },
  {
    img: allImages.IndustryExperts,
    text: "Elevate your skills and knowledge by learning directly from industry experts.Gain invaluable insights and real-world experience to advance your career.",
    title: "Enhance skills with industry experts",
  },
  {
    img: allImages.YearofExperince,
    text: "Benefit from mentors with diverse experience and tailored guidance. Receive support from the start of your career journey and build a strong foundation for success.",
    title: "Years Of Experience, Early Career Support",
  },
];

const ChooseMentorLink = () => {
  const navigate = useNavigate();
  return (
    <Stack
      // sx={{ px: { xs: 3, md: 15 }, background: "white", py: 1 }}
      id="features"
      textAlign="center"
    >
      <Typography
        variant="subtitle1"
        className="landing__heading banner__title"
      >
        Why to choose us?
      </Typography>
      <Typography variant="subtitle1" className="landing__content" alignSelf={'center'} lineHeight={'28px'}
>
        We have experienced professionals from every field that you can think
        of. See what you can do by signing up to our platform here.
      </Typography>

    <Stack style={{ display:'flex',justifyContent: "center",flexDirection:'row',alignItems:"center", gap: 4, height: "100%",marginLeft:'40px',marginRight:'40px' }}
>
      <Grid
        container
        // sx={{ mt: 3.2, justifyContent: "space-between", gap: 4, height: "100%",marginLeft:'40px',marginRight:'40px',backgroundColor:'pink' }}
        sx={{ mt: 3.2,gap:4, height: "100%",width:'95%',justifyContent: "space-between",}}
      >
        {data?.map((value, ind) => {
          return (  
            <Grid item md={3} xs={12} lg={5.5} >
              <MentorLinkCard {...value} ind={ind} />
            </Grid>
          );
        })}
        {/* <Grid item xs={4}></Grid>
        <Grid item xs={4}></Grid> */}
      </Grid>
      </Stack>
    </Stack>
  );
};

export default ChooseMentorLink;
