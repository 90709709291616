import { Button, Stack, TextField, Typography } from "@mui/material";
import React from "react";
import { useState } from "react";

const RedeemCode = ({ handleApplyCoupons }) => {
  const [couponCode, setCouponCode] = useState("");
  return (
    <Stack className="coupons__rounded">
      <Stack className="coupons__title">
        <Typography className="coupons__heading">Apply Coupon</Typography>
      </Stack>
      <Stack
        direction="row"
        mt={2}
        mb={2}
        gap={2}
        sx={{ px: 3, width: { md: "50%", xs: "100%" } }}
        alignItems="end"
      >
        <TextField
          className="redeem"
          label="Enter the Coupon Code"
          variant="standard"
          fullWidth
          value={couponCode}
          onChange={(e) => setCouponCode(e.target.value)}
        />
        <Stack sx={{ flex: 3 }} alignItems="start">
          <Button
            className="coupon__button"
            variant="contained"
            sx={{
              background: "#47A561",
              borderRadius: "37.135px",
              color: "white",
              flexGrow: "1rem",
              fontWeight: "700",
              p: "8px 20px 8px 0px ",
            }}
            onClick={() => handleApplyCoupons(couponCode)}
          >
            Apply
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default RedeemCode;
