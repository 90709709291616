import { Box, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import ConfigAPIURL from "../../../../config/ConfigAPIURL";
import { allImages } from "../../../../images";
import APIRequest from "../../../../utils/APIRequest";
import Modal from "../UserProfile/Modal";
import SocialMediaModel from "../UserProfile/Models/SocialMediaModel";

const SocialMedia = ({ data, children }) => {
  const [socials, setSocialMedia] = useState([]);
  const [changeData, setChangeData] = useState(true);
  useEffect(() => {
    fetchUserAdditionalInfo();
  }, [changeData]);

  const fetchUserAdditionalInfo = async () => {
    // data id is present then fetch additional infos
    if (data?._id) {
      try {
        const response = await APIRequest.request(
          "POST",
          ConfigAPIURL.userAdditionalInfo,
          JSON.stringify({
            userId: data._id,
          })
        );
        if (response?.data?.responseCode === 109) {
          setSocialMedia(response?.data?.profile[0]?.socialMedia);
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <Stack mt={2.5}>
      <Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography
            mb={0}
            className="heading"
            fontWeight="600"
            fontSize="20px"
            variant="h6"
          >
            Socials
          </Typography>

          <SocialMediaModel
            data={socials}
            changeData={changeData}
            setChangeData={setChangeData}
          />
        </Stack>

        {/* renders all the social media */}
        {socials?.length > 0 &&
          socials?.map((value) => {
            return (
              <Stack direction="column" px={1} mt={0} key={value._id}>
                <Typography
                  textTransform="capitalize"
                  variant="subtitle1"
                  color="black"
                  className="sub__heading"
                  fontSize="16px"
                  fontWeight="700"
                >
                  {value?.title}
                </Typography>
                <a
                  className="content"
                  href={value?.link}
                  style={style}
                  variant="subtitle1"
                >
                  {value?.link}
                </a>
              </Stack>
            );
          })}
      </Stack>
    </Stack>
  );
};
const style = {
  color: "black",
  fontSize: "14px",
  fontWeight: "600",
  color: "blue",
};

export default SocialMedia;
