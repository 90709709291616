import React from "react";
import { useEffect } from "react";
import Index from "../components/privateRoute/userRoute/Manage/Index";

const ManagePage = () => {
  useEffect(() => {
    document.title = `Mentorlink manage`;
  }, []);
  return (
    <div>
      <Index />
    </div>
  );
};

export default ManagePage;
