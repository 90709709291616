import { Grid, Stack, Typography } from "@mui/material";
import React from "react";
import pageSkills from "../../../assets/landingPageSkills.png";
import { allImages } from "../../../images";
const Skills = () => {
  const imageData = [
    {
      id: 1,
      image: allImages.mentorlinkWorks1,
      text: "Gain career guidance and feedback from industry experts with Mentorlink.",
      step: "Step 1 - Search mentors",
    },
    {
      id: 2,
      image: allImages.mentorlinkWorks2,
      text: "Schedule sessions with mentors at your preferred time for personalized guidance.",
      step: "Step 2 - Schedule a session",
    },
    {
      id: 3,
      image: allImages.mentorlinkWorks3,
      text: "Connect, refine, expand on our networking platform for professionals.",
      step: "Step 3 - Get the mentorship",
    },
  ];

  return (
    <Stack id="purpose" textAlign="center" my={4} style={{marginBottom:'80px'}}>
      <Stack my={2}>
        <Typography
          variant="subtitle1"
          className="landing__heading banner__title"
        >
          How Mentorlink works
        </Typography>
        <Typography
          variant="subtitle1"
          className="landing__content"
          alignSelf={"center"}
          lineHeight={'28px'}
        >
          Search, schedule and get mentored! Get mentored from the best of the
          industry and learn skills that can help you land a job in no time.
        </Typography>
      </Stack>
      <Stack>
        <Grid container sx={{ height: "100%",justifyContent: "space-evenly",marginTop:'30px' }}>
          {imageData?.map((data) => {
            return (
              <Grid
                item
                md={5}
                xl={3.5}
                lg={3.5}
                style={{
                  border: "1px solid #D9E9FF",
                  justifyContent: "space-evenly",
                  display: "flex",
                  flexDirection: "column",
                  borderRadius:'12px',
                  overflow:'hidden',
                  margin:'12px'
                }}
              >
                <Stack
                  style={{
                    width: "100%",
                    height: "300px",
                    background:
                      "linear-gradient(180deg, #FFFFFF 0%, #D9E9FF 76.03%)",
                  }}
                  sx={{
                    justifyContent: "center",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Stack style={{ width: "220px", height: "200px" }}>
                    <img src={data.image} alt={data.image}style={{width:'100%',height:'100%'}} />
                  </Stack>
                </Stack>
                <Stack  justifyContent="center" style={{padding:'18px'}}>
                  <Typography
                    textAlign="left"
                    variant="subtitle1"
                    // className="landing__heading banner__title"
                    fontSize={"20px"}
                    color={'#0152BC'}
                    fontWeight={'600'}
                  >
                    {data?.step}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    // className="landing__content"
                    textAlign="left"
                    color={'#4D4D4DCC'}
                    fontWeight={'400'}
                    fontSize={'16px'}
                    lineHeight={'20px'}
                  >
                    {data?.text}
                  </Typography>
                </Stack>
              </Grid>
            );
          })}

          {/* <Grid item md={5}>
            <Stack my={2} height="100%" justifyContent="center">
              <Typography
                textAlign="left"
                variant="subtitle1"
                className="landing__heading banner__title"
              >
                Schedule a session
              </Typography>
              <Typography
                variant="subtitle1"
                className="landing__content"
                textAlign="left"
              >
                Schedule sessions with experts by choosing the date and time of
                your choice. Browse through a list of mentors who are available
                at your time.
              </Typography>
            </Stack>
          </Grid>
          <Grid item md={4}>
            <img src={allImages.skills2} alt={allImages.skills1} />
          </Grid>
          <Grid item md={4}>
            <img src={allImages.skills3} alt={allImages.skills1} />
          </Grid>
          <Grid item md={5}>
            <Stack my={2} height="100%" justifyContent="center">
              <Typography
                textAlign="left"
                variant="subtitle1"
                className="landing__heading banner__title"
              >
                Get the right mentorship
              </Typography>
              <Typography
                variant="subtitle1"
                className="landing__content"
                textAlign="left"
              >
                Talk to mentors, get your portfolio’s reviewed and widen your
                network using our platform.
              </Typography>
            </Stack>
          </Grid> */}
        </Grid>
      </Stack>
    </Stack>
  );
};

export default Skills;
