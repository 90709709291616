import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import ConfigAPIURL from "../../../../config/ConfigAPIURL.js";
import APIRequest from "../../../../utils/APIRequest.js";
import { useForm } from "react-hook-form";

const ContactDetails = ({
  userDetails,
  setUserDetails,
  userProfile,
  register,
}) => {
  // where all the contact details comes
  const [allCountrys, setAllCountrys] = useState([]);
  const [allState, setAllState] = useState([]);
  const [allCity, setAllcity] = useState([]);
  // const [email, setEmail] = useState(userProfile?.email || '');
  // const [phoneNumber, setPhoneNumber] = useState(userProfile?.mobileNo || '');

  // const { register, setValue } = useForm();

  // useEffect(() => {
  //   if (userProfile) {
  //     setEmail(userProfile.email || '');
  //     setPhoneNumber(userProfile.mobileNo || '');
  //     setValue('email', userProfile.email || '');
  //     setValue('contactNumber', userProfile.mobileNo || '');
  //   }
  // }, [userProfile, setValue]);
  useEffect(() => {
    fetchAllCountries();
  }, []);
  useEffect(() => {
    // if country is not selected by user then just return
    if (userDetails.country === "") return;
    fetchAllStates();
  }, [userDetails.country]);

  useEffect(() => {
    // if state is not selected by user then just return
    if (userDetails.state === "") return;
    fetchAllCitys();
  }, [userDetails.state]);

  const fetchAllCountries = async () => {
    try {
      const response = await APIRequest.request(
        "GET",
        ConfigAPIURL.getCountry,
        ""
      );
      if (response?.data?.responseCode === 109) {
        setAllCountrys(response?.data?.countries);
      }
    } catch {}
  };
  const fetchAllStates = async () => {
    try {
      const response = await APIRequest.request(
        "POST",
        ConfigAPIURL.getState,
        JSON.stringify({
          country: userDetails.country,
        })
      );
      if (response?.data?.responseCode === 109) {
        setAllState(response?.data?.states[0]);
      }
    } catch {}
  };

  const fetchAllCitys = async () => {
    try {
      const response = await APIRequest.request(
        "POST",
        ConfigAPIURL.getCity,
        JSON.stringify({
          state: userDetails.state,
          searchKey: "",
        })
      );
      if (response?.data?.responseCode === 109) {
        setAllcity(response?.data?.result);
      }
    } catch {}
  };

  const handleChange = (event) => {
    setUserDetails({
      ...userDetails,
      country: event.target.value,
    });
  };

  console.log("this is userprofile",userProfile);
  return (
    <Stack pt={2}>
      <Typography
            style={{ fontWeight: 600, fontSize: "22px", alignSelf: "start",marginBottom:'12px' }}
          >
            Contact Details
          </Typography>
      <form style={{ display: "flex", flexDirection: "column", gap: 24 }}>
      <Grid container spacing={4} >
      <Grid item xs={12} md={8} lg={6}>
        <TextField
          label="E-mail"
          disabled
          // type="email"
          InputLabelProps={{ style: { fontSize: 15 } }}
          size="small"
          fullWidth
          // value={email}
          value={userProfile?.email ? userProfile?.email :''}
          {...register("email")}
        />
        </Grid>
        <Grid item xs={12} md={8} lg={6}>
        <TextField
          label="Phone Number"
          disabled
          // type="number"
          fullWidth
          value={userProfile?.mobileNo ? userProfile?.mobileNo : ""}
          InputLabelProps={{ style: { fontSize: 15 } }}
          size="small"
          {...register("contactNumber")}
        />
        </Grid>
        <Grid item xs={12} md={8} lg={6}>
{/* select field */}
<FormControl style={{width:'100%'}}>
          <InputLabel
            sx={{ fontSize: "15px", lineHeight: '12px' }}
            id="demo-simple-select-autowidth-label"
          >
            Country
          </InputLabel>
          <Select
            labelId="demo-simple-select-autowidth-label"
            id="demo-simple-select-autowidth"
            value={userDetails.country}
            onChange={handleChange}
            autoWidth
            label="Country"
            fullWidth
            size="small"
            MenuProps={{
              PaperProps: { sx: { maxHeight: 250 } },
            }}
          >
            {allCountrys.length > 0 &&
              allCountrys.map((value) => {
                return (
                  <MenuItem key={value._id} value={value.name}>
                    {value.name}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
        </Grid>
        <Grid item xs={12} md={8} lg={6}>
        <FormControl style={{width:'100%'}}>
            <InputLabel
              sx={{ fontSize: "15px", lineHeight: '12px' }}
              id="demo-simple-select-autowidth-label"
            >
              State
            </InputLabel>
            <Select
              MenuProps={{
                PaperProps: { sx: { maxHeight: 250 } },
              }}
              labelId="demo-simple-select-autowidth-label"
              id="demo-simple-select-autowidth"
              value={userDetails.state}
              onChange={(e) =>
                setUserDetails({
                  ...userDetails,
                  state: e.target.value,
                })
              }
              autoWidth
              label="State"
              fullWidth
              size="small"
            >
              {allState.length > 0 &&
                allState.map((value) => {
                  return (
                    <MenuItem key={value._id} value={value.name}>
                      {value.name}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={8} lg={6}>
        <FormControl style={{width:'100%'}}>
            <InputLabel
              sx={{ fontSize: "15px", lineHeight: '12px' }}
              id="demo-simple-select-autowidth-label"
            >
              City
            </InputLabel>
            <Select
              MenuProps={{
                PaperProps: { sx: { maxHeight: 250 } },
              }}
              labelId="demo-simple-select-autowidth-label"
              id="demo-simple-select-autowidth"
              value={userDetails.city}
              onChange={(e) =>
                setUserDetails({
                  ...userDetails,
                  city: e.target.value,
                })
              }
              autoWidth
              label="City"
              fullWidth
              size="small"
            >
              {allCity.length > 0 &&
                allCity.map((value) => {
                  return (
                    <MenuItem key={value._id} value={value.name}>
                      {value.name}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>

        </Grid>
      </Grid>
        
        {/* city and state */}
        {/* <Stack direction="row" width="100%" gap={2}>
          
         
        </Stack> */}
      </form>
    </Stack>
  );
};

export default ContactDetails;
