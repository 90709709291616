import { Stack } from "@mui/material";
import React, { useState } from "react";
import SendIcon from "@mui/icons-material/Send";

const SendMessage = ({ sendMessage, selectedChat }) => {
  const [textMessage, setTextMessage] = useState("");
  const handleSendMessage = () => {
    if (!textMessage) return;
    sendMessage(selectedChat?._id, textMessage);
    setTextMessage("");
  };
  return (
    <>
      <Stack
        style={{
          width: "100%",
          height: "40px",
          border: "1.69px solid #efefef",
          borderRadius: "4px",
          flexDirection: "row",
          alignItems: "center",
          padding: "0 .5rem ",
          //   width: "90%",
          //   margin: "auto",
        }}
      >
        <input
          autoFocus
          onKeyDown={(e) => {
            if (e.code === "Enter") handleSendMessage();
          }}
          style={{ outline: "none", border: "none", flexGrow: 2 }}
          type="text"
          placeholder="Type a message"
          value={textMessage}
          onChange={(e) => setTextMessage(e.target.value)}
        />
        {textMessage && (
          <SendIcon style={{ cursor: "pointer" }} onClick={handleSendMessage} />
        )}
      </Stack>
    </>
  );
};

export default SendMessage;
