import React, { useEffect, useState } from "react";
import { allImages } from "../../../images";
import {
  Button,
  Collapse,
  Grid,
  IconButton,
  InputAdornment,
  keyframes,
  Stack,
  styled,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import NavbarL from "../LandingPage/NavbarL";
import { Container } from "react-bootstrap";
import SendIcon from "@mui/icons-material/Send";
import { useLocation, useNavigate } from "react-router-dom";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import APIRequest from "../../../utils/APIRequest";
import ConfigAPIURL from "../../../config/ConfigAPIURL";
import CircularProgress from "@mui/material/CircularProgress";
import ChatIcon from "@mui/icons-material/Chat";
import ChatBotModal from "../LandingPage/ChatBotModal";
import ChatArea from "../LandingPage/ChatArea";
import ChatAreaLoggenInUser from "../LandingPage/ChatAreaLoggenInUser";
import MentorsCard from "./MentorsCard";
import Navbar from "../../privateRoute/userRoute/Home/Navbar";

const ITEMS_PER_PAGE = 4;

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const RotatingImage = styled("img")`
  animation: ${rotate} 10s linear infinite;
  display: block;
  margin: auto;
`;

const MentorsListingPage = () => {
  const location = useLocation();
  //   const { receivedData } = location.state || {};
  const [receivedData, setReceivedData] = useState(
    location.state?.receivedData || []
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [typedMsg, setTypedMsg] = useState("");
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [loader, setLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery("(max-width:600px)");

  useEffect(() => {
    // fetchMessages();
  }, []);

  const fetchMessages = async () => {
    try {
      const response = await APIRequest.request(
        "GET",
        ConfigAPIURL.getChatQuestions,
        ""
      );
      if (response.data.responseCode === 109) {
        console.log("this is chat response", response);
        setMessages(response?.data?.result);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleNextPage = () => {
    if (currentPage < Math.ceil(receivedData?.length / ITEMS_PER_PAGE)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleExploreAll = () => {
    navigate("/mentor");
    // Logic to handle "Explore all" action, e.g., navigate to a different page
  };

  const currentItems = receivedData?.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  useEffect(() => {
    isLogin();
  }, []);
  const { state } = location;

  useEffect(() => {
    if (state?.skill) {
      const fetchMentors = async () => {
        try {
          const response = await APIRequest.request(
            "POST",
            ConfigAPIURL.getSearchedMentors,
            JSON.stringify({ skills: state.skill })
          );
          if (response.data.responseCode === 109) {
            // console.log("this is chat response", response);
            setReceivedData(response?.data?.users);
          }
        } catch (err) {
          console.log(err);
        }
      };

      fetchMentors();
    }
  }, [state?.skill]);

  // useEffect(() => {
  //   if (state?.category) {
  //     // Replace with your actual backend endpoint
  //     const fetchMentors = async () => {
  //       try {
  //         const response = await APIRequest.request(
  //           "POST",
  //           ConfigAPIURL.getSearchedMentorsCategories,
  //           JSON.stringify({ category: state.category })
  //         );
  //         if (response.data.responseCode === 109) {
  //           // console.log("this is chat response", response);
  //           setReceivedData(response?.data?.users);
  //         }
  //       } catch (err) {
  //         console.log(err);
  //       }
  //     };

  //     fetchMentors();
  //   }
  // }, [state?.category]);
  //check whether the user is logged in or nt and route to private route
  const isLogin = async () => {
    // setLoader(true);
    const response = await APIRequest.request(
      "GET",
      ConfigAPIURL.userIsLoggedin,
      ""
    );
    if (response?.data?.responseCode === 109) {
      setUserLoggedIn(true);
      //   setLoader(false);
      if (typedMsg !== "") {
        setLoader(true);
        scrollToBottom();
      }
    } else {
      //   setLoader(false);
      if (typedMsg !== "") {
        setLoader(false);
        scrollToBottom();
      }
      // setLoader(false);
      // navigate("/login");
    }
  };

  const scrollToBottom = () => {
    window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
  };

  //   console.log("this is recieved data", receivedData);
  const handleClickOpen = () => {
    fetchMessages();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [isVisible, setIsVisible] = useState(false);

  const handleToggle = () => {
    setIsVisible(!isVisible);
  };

  return (
    <div>
      {userLoggedIn === false && <NavbarL />}
      {userLoggedIn === true && <Navbar />}
      <Stack sx={{ background: "white", py: 6 }}>
        <Container>
          {/* ---------------------Recomended Mentors for you------------------------------ */}

          {/* {loader !== true && currentItems?.length === 0 && (
            <Stack>
              <img
                src={allImages.loadingAnimation}
                style={{
                  objectFit: "contain",
                }}
              />
            </Stack>
          )} */}

          <Stack>
            {loader === true ? (
              <Stack
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {/* <CircularProgress /> */}
              </Stack>
            ) : (
              <>
                <Stack
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "28px",
                    marginTop: "38px",
                    display: currentItems?.length === 0 ? "none" : "",
                  }}
                >
                  <Typography
                    variant="h4"
                    color={"#393939"}
                    // marginTop={"32px"}
                    fontSize={!isSmallScreen ? "32px" : "18px"}
                    fontWeight={"600"}
                  >
                    Ideal mentors for you
                  </Typography>
                  <Stack direction="row" spacing={1} alignItems="center">
                    {/* <Typography
                      onClick={handleExploreAll}
                      style={{
                        borderRadius: "5px",
                        border: "1px solid #000000",
                        padding: !isSmallScreen ? "12px 24px 12px 24px" : "8px",
                        cursor: "pointer",
                        fontSize: !isSmallScreen ? "16px" : "12px",
                        color: "#030303",
                        fontWeight: "500",
                      }}
                    >
                      Explore all
                    </Typography> */}
                    <IconButton
                      onClick={handlePreviousPage}
                      disabled={currentPage === 1}
                    >
                      <NavigateBeforeIcon />
                    </IconButton>
                    <IconButton
                      onClick={handleNextPage}
                      disabled={
                        currentPage >=
                        Math.ceil(receivedData?.length / ITEMS_PER_PAGE)
                      }
                    >
                      <NavigateNextIcon />
                    </IconButton>
                  </Stack>
                </Stack>
                <Grid
                  container
                  spacing={2}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  {currentItems?.map((mentorData, index) => (
                    <Grid item xs={6} sm={6} md={4} lg={3} key={index}>
                      <MentorsCard mentorData={mentorData} />
                    </Grid>
                  ))}
                  {currentItems?.length === 0 && (
                    <Typography style={{ marginTop: "46px" }}>
                      No Mentors found...
                    </Typography>
                  )}
                </Grid>
              </>
            )}
          </Stack>
        </Container>
      </Stack>
    </div>
  );
};

export default MentorsListingPage;
