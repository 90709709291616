import { allImages } from "../../../../images";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React, { Children } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ModelExperience from "../UserProfile/ModelExperience";
import EducationModel from "../UserProfile/Models/EducationModel";
import AchieventModel from "../UserProfile/Models/AchieventModel";
import ConfigAPIURL from "../../../../config/ConfigAPIURL";
import APIRequest from "../../../../utils/APIRequest";
import { useLocation } from "react-router-dom";

const AchievementAccordion = ({
  title,
  data,
  children,
  fetchUserAdditionalInfo,
}) => {
  const location = useLocation();
  const [expanded, setExpanded] = React.useState(
    data?.experience.length > 0 ? "panel1" : false
  );
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleRemoveAchievement = async (id) => {
    try {
      const response = await APIRequest.request(
        "POST",
        ConfigAPIURL.userProfileDataRemove,
        JSON.stringify({
          recordId: id,
          heading: "achievements",
        })
      );
      console.log(response);
    } catch {
    } finally {
      fetchUserAdditionalInfo();
    }
  };
  return (
    <Stack mt={1}>
      {/* <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
        sx={{ borderRadius: "15px" }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Stack direction="row" gap={1} alignItems="center">
            <Typography
              className="heading"
              sx={{ fontWeight: "600", fontSize: "20px" }}
            >
              {title}
            </Typography>
            {location.pathname === "/profile" ? (
              <AchieventModel
                userData={data.achievements}
                fetchUserAdditionalInfo={fetchUserAdditionalInfo}
              />
            ) : null}
          </Stack>
        </AccordionSummary>
        <Divider variant="middle" />
        <AccordionDetails>
          <Stack ml={6}></Stack>
          {data?.achievements?.length > 0 &&
            data?.achievements?.map((value) => {
              return (
                <Stack
                  sx={{ flexDirection: { xs: "column", md: "row" } }}
                  justifyContent="space-between"
                  alignItems="center"
                  key={value._id}
                  mx={1}
                  mt={2}
                  gap={1}
                >
                  <Stack width="100%">
                    <Stack
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Typography
                        variant="subtitle1"
                        fontSize="20px"
                        fontWeight="700"
                        color="#1C3464"
                        className="heading"
                        textTransform="capitalize"
                      >
                        {value?.title}
                      </Typography>
                      {location.pathname === "/profile" ? (
                        <>
                          <Stack direction="row" gap={3}>
                            <AchieventModel
                              userData={value}
                              id={value._id}
                              fetchUserAdditionalInfo={fetchUserAdditionalInfo}
                            />
                            <Box
                              className="mainicon"
                              display="flex"
                              style={{ cursor: "pointer" }}
                              onClick={() => handleRemoveAchievement(value._id)}
                            >
                              <img src={allImages.deleteButton} alt="delete" />
                            </Box>
                          </Stack>
                        </>
                      ) : null}
                    </Stack>

                    <Typography
                      className="sub__heading"
                      variant="subtitle1"
                      fontSize="14px"
                    >
                      On {value?.date?.slice(1, 10)}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      fontSize="14px"
                      className="content wrap"
                    >
                      {value?.description}
                    </Typography>
                  </Stack>
                </Stack>
              );
            })}
        </AccordionDetails>
      </Accordion> */}

      <Typography
        sx={{
          fontWeight: "600",
          fontSize: "28px",
          color: "#343434",
          marginBottom: "12px",
        }}
      >
        {title}
      </Typography>
      <Stack
        style={{
          backgroundColor: "#FEFEFE",
          borderRadius: "18px",
          padding: "20px",
        }}
      >
        {location.pathname === "/profile" ? (
          <AchieventModel
            userData={data.achievements}
            fetchUserAdditionalInfo={fetchUserAdditionalInfo}
          />
        ) : null}
        {data?.achievements?.length > 0 &&
          data?.achievements?.map((value) => {
            return (
              <Stack
                sx={{ flexDirection: { xs: "column", md: "column" } }}
                justifyContent="space-between"
                alignItems="center"
                key={value._id}
                mx={2}
                // mt={2}
                gap={1}
              >
                <Stack
                  width="100%"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Stack
                    flexDirection="row"
                    alignItems="center"
                    // justifyContent="space-between"
                  >
                    <Stack
                      style={{
                        backgroundColor: "#FFA4381A",
                        borderRadius: "12px",
                        padding: "18px",
                        marginRight: "8px",
                        height: "80px",
                        minWidth: "80px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img src={allImages.AwardsIcon} alt="" srcset="" />
                    </Stack>
                    <Stack flexDirection="column" gap={0}>
                      <Typography
                        variant="subtitle1"
                        fontSize="24px"
                        fontWeight="600"
                        color="#1C3464"
                        // className="heading"
                        textTransform="capitalize"
                        marginTop={"0px"}
                        paddingTop={"0px"}
                      >
                        {value?.title}
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        fontSize="16px"
                        // className="content wrap"
                      >
                        {value?.description}
                      </Typography>
                    </Stack>
                  </Stack>
                  {location.pathname === "/profile" ? (
                    <Stack
                      direction="row"
                      gap={3}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "end",
                        alignItems: "center",
                        marginTop: "8px",
                      }}
                    >
                      <AchieventModel
                        userData={value}
                        id={value._id}
                        fetchUserAdditionalInfo={fetchUserAdditionalInfo}
                        text="edit"
                      />
                      <Box
                        className="mainicon"
                        display="flex"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleRemoveAchievement(value._id)}
                      >
                        <img src={allImages.deleteButton} alt="delete" />
                      </Box>
                    </Stack>
                  ) : null}
                </Stack>

                <Typography
                  className="sub__heading"
                  variant="subtitle1"
                  fontSize="16px"
                  color={"#626266"}
                  alignSelf={"end"}
                >
                   {value?.date?.slice(3, 15)}
                </Typography>
                {/* </Stack> */}
              </Stack>
            );
          })}
      </Stack>
    </Stack>
  );
};

export default AchievementAccordion;
