import React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import Index from "../components/privateRoute/userRoute/BookingsPage/Index";

const BookingPage = () => {
  const navigate = useNavigate();
  const userData = useSelector((state) => state?.user?.profileData);
  
  useEffect(() => {
    document.title = `Mentorlink bookings`;
  }, []);

  if (userData?.mentor) {
    return (
      <>
        <Navigate to="/home" />
      </>
    );
  }

  return (
    <div>
      <Index />
    </div>
  );
};

export default BookingPage;
