import { Stack, Typography } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import ConfigAPIURL from "../../../../config/ConfigAPIURL";
import APIRequest from "../../../../utils/APIRequest";
import MentorCard2 from "./MentorCard2";

const PopularMentors = (props) => {
  const [popularMentors, setPopularMentors] = useState([]);
  const userData = useSelector((state) => state?.user?.profileData);

  useEffect(() => {
    fetchUserAd();
  }, []);

  const fetchUserAd = async () => {
    try {
      const response = await APIRequest.request(
        "GET",
        ConfigAPIURL.userAdvertisements,
        ""
      );
      console.log(response);
      if (response?.code === 100 && response.data.responseCode === 109) {
        setPopularMentors(response?.data?.rows[0]);
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      {popularMentors?.users && popularMentors?.users?.length > 0 && (
        <Stack
          sx={{
            background: "white",
            border: { md: "0.742701px solid #D2D2D2", xs: "none" },
          }}
          py={2}
          borderRadius="15px"
        >
          <Typography
            className="heading"
            sx={{
              fontWeight: "600",
              lineHeight: "32.68px",
              px: { xs: 0, sm: 1.5 },
            }}
          >
            {popularMentors?.title}
          </Typography>
          <Stack
            borderRadius="15px"
            px={1}
            pb={1}
            sx={{ border: { xs: "0.742701px solid #D2D2D2", md: "none" } }}
          >
            {popularMentors?.users &&
              popularMentors?.users.length > 0 &&
              popularMentors?.users.map((value, ind) => {
                return (
                  <MentorCard2
                    key={value._id}
                    details={value}
                    isMentor={userData?.mentor}
                  />
                );
              })}
          </Stack>
        </Stack>
      )}
    </>
  );
};

export default PopularMentors;
