import React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import Index from "../components/privateRoute/userRoute/coupons/Index";

const Coupons = () => {
  const navigate = useNavigate();
  const userData = useSelector((state) => state?.user?.profileData);
  useEffect(() => {
    document.title = `Mentorlink coupons`;
  }, []);

  if (userData?.mentor) {
    return (
      <>
        <Navigate to="/home" />
      </>
    );
  }
  return (
    <div>
      <Index />
    </div>
  );
};

export default Coupons;
