import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";

const Statistics = ({ text, amount, noBorder }) => {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      px={1}
      py={1}
      sx={{ borderLeft: noBorder ? "" : "1px solid #eaeded" }}
    >
      <Stack sx={{ pl: { xs: 1, md: 3 } }}>
        <Typography
          textTransform="capitalize"
          sx={{ fontSize: { xs: "12px", md: "14px", fontWeight: 400 } }}
        >
          {text}
        </Typography>
        <Typography
          sx={{ fontSize: { xs: "12px", md: "14px", fontWeight: 700 } }}
        >
          {amount}
        </Typography>
      </Stack>
      {/* <Box className="divider"></Box> */}
    </Stack>
  );
};

const Summary = ({ tableData }) => {
  const [statistics, setStatistics] = useState({});
  useEffect(() => {
    if (tableData && tableData.length > 0) {
      var totalAmount = 0;
      var remainingAmount = 0;
      var totalCount = 0;
      tableData.forEach((value, ind) => {
        // if active only goes through the loop
        if (!value?.active) return;
        // current date should be less than end date
        // if (value?.endAt < new Date() / 1000) return;
        let count = 1;
        totalAmount += value?.amount;
        remainingAmount += value?.remainingAmount;
        // totalCount = count += 1;
        totalCount += 1;
      });
      setStatistics({
        totalAmount: remainingAmount,
        remainingAmount: totalAmount - remainingAmount,
        totalCount,
      });
    }
  }, [tableData]);
  return (
    <Stack className="coupons__rounded" gap={1.5} pb={1.5} style={{marginTop:'18px'}}>
      <Stack className="coupons__title">
        <Typography className="coupons__heading">Summary</Typography>
      </Stack>
      {/* <Box className="divider"></Box> */}
      <Grid container sx={{ px: { xs: 1, md: 3 } }}>
        <Grid item xs={4}>
          <Statistics
            text={"Total amount remaining"}
            amount={("₹ ", statistics?.totalAmount)}
            noBorder={true}
          />
        </Grid>
        <Grid item xs={4}>
          <Statistics
            text={"Total amount used"}
            amount={("₹ ", statistics?.remainingAmount)}
          />
        </Grid>
        <Grid item xs={4}>
          <Statistics text={"Active Coupons"} amount={statistics?.totalCount} />
        </Grid>
      </Grid>
    </Stack>
  );
};

export default Summary;
