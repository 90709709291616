import { useEffect, useState } from "react";
// import { initialMenuItems } from "../utils/data";
// import { MenuItemButton } from "./MenuItemButton";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import APIRequest from "../../../utils/APIRequest";
import ConfigAPIURL from "../../../config/ConfigAPIURL";
import WaveAnimation from "./WaveAnimation";
import { allImages } from "../../../images";
import CloseIcon from "@mui/icons-material/Close";

const initialMenuItems = [
  { id: 1, label: "Search for a mentor" },
  { id: 2, label: "Assist on mentoring topics" },
  { id: 3, label: "Course on mentoring" },
];

const MenuItemButton = ({ label, name, onClick }) => {
  return (
    <button
      // className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded text-left"
      // style={{
      //   backgroundColor: "#3B82F6", // bg-blue-500
      //   color: "white",
      //   fontWeight: "bold",
      //   padding: "0.5rem 1rem", // py-2 px-4
      //   borderRadius: "0.25rem", // rounded
      //   textAlign: "left",
      //   cursor: "pointer", // to enable the hover effect
      //   transition: "background-color 0.2s ease-in-out", // for smooth transition
      // }}
      style={{
        backgroundColor: "#F3F4F6",
        color: "#000",
        padding: "0.75rem 1rem",
        borderRadius: "0.25rem",
        border: "none",
        textAlign: "left",
        cursor: "pointer",
        fontWeight: "500",
      }}
      // onMouseEnter={(e) => (e.target.style.backgroundColor = "#2563EB")} // hover:bg-blue-600
      // onMouseLeave={(e) => (e.target.style.backgroundColor = "#3B82F6")} // revert back to bg-blue-500
      name={name}
      onClick={onClick}
    >
      {label}
    </button>
  );
};

const BookingPrompt = ({
  mentorCount,
  onYes,
  onGoBack,
  labelData,
  categoryLabel,
}) => {
  const styles = {
    container: {
      backgroundColor: "white",
      borderRadius: "10px",
      // boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
      position: "relative",
      overflow: "hidden",
      width: "100%",
      maxWidth: "400px",
      margin: "20px auto",
    },
    content: {
      padding: "20px",
    },
    backButton: {
      background: "none",
      border: "none",
      fontSize: "24px",
      cursor: "pointer",
    },
    text: {
      margin: "20px 0",
      fontSize: "16px",
      color: "#000",
      fontWeight: "600",
    },
    button: {
      display: "block",
      width: "100%",
      padding: "10px",
      marginTop: "10px",
      backgroundColor: "#f0f0f0",
      border: "none",
      borderRadius: "5px",
      fontSize: "16px",
      cursor: "pointer",
    },
    wave: {
      position: "absolute",
      bottom: 0,
      left: 0,
      right: 0,
      height: "40px",
      backgroundColor: "#3b82f6",
      clipPath: "ellipse(50% 60% at 50% 100%)",
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.content}>
        <p style={styles.text}>
          There are {mentorCount} mentors available for {categoryLabel} in{" "}
          {labelData}. Would you like to book a mentor?
        </p>
        <button style={styles.button} onClick={onYes}>
          Yes
        </button>
        <button style={styles.button} onClick={onGoBack}>
          Go back
        </button>
      </div>
    </div>
  );
};

const SignupPrompt = ({ onSignUp, onGoBack }) => {
  const styles = {
    container: {
      backgroundColor: "white",
      borderRadius: "10px",
      // boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
      position: "relative",
      overflow: "hidden",
      width: "100%",
      maxWidth: "400px",
      margin: "20px auto",
    },
    content: {
      padding: "20px",
    },
    backButton: {
      background: "none",
      border: "none",
      fontSize: "24px",
      cursor: "pointer",
    },
    text: {
      margin: "20px 0",
      fontSize: "16px",
      color: "#000",
      fontWeight: "600",
    },
    button: {
      display: "block",
      width: "100%",
      padding: "10px",
      marginTop: "10px",
      backgroundColor: "#f0f0f0",
      border: "none",
      borderRadius: "5px",
      fontSize: "16px",
      cursor: "pointer",
    },
    wave: {
      position: "absolute",
      bottom: 0,
      left: 0,
      right: 0,
      height: "40px",
      backgroundColor: "#3b82f6",
      clipPath: "ellipse(50% 60% at 50% 100%)",
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.content}>
        <p style={styles.text}>
          Seems like you haven't created an account, please signup or login for
          free
        </p>
        <button style={styles.button} onClick={onSignUp}>
          Sign Up
        </button>
        <button style={styles.button} onClick={onGoBack}>
          Go back
        </button>
      </div>
    </div>
  );
};

const BottomMenu = ({
  setIsOpen,
  showInitialView,
  setShowInitialView,
  developmentMessage,
  setDevelopmentMessage,
  showBookingPrompt,
  setShowBookingPrompt,
  showSignupPrompt,
  setShowSignupPrompt,
  setNoMentorsFound,
  noMentorsFound

}) => {
  const [menuLabel, setMenuLabel] = useState("Main Menu");
  const [menuItems, setMenuItems] = useState(initialMenuItems);
  const [previousMenuItems, setPreviousMenuItems] = useState([]);
  const [previousMenuLabel, setPreviousMenuLabel] = useState("");
  // const [noMentorsFound, setNoMentorsFound] = useState(false);
  // const [developmentMessage, setDevelopmentMessage] = useState("");
  const navigate = useNavigate();
  // const [showInitialView, setShowInitialView] = useState(true);

  // const [showBookingPrompt, setShowBookingPrompt] = useState(false);
  // const [showSignupPrompt, setShowSignupPrompt] = useState(false);
  const [availableMentorsCount, setAvailableMentorsCount] = useState(0);

  useEffect(() => {
    setMenuItems(menuItems);
  }, [menuLabel]);

  const getCategories = async () => {
    try {
      const response = await APIRequest.request(
        "GET",
        ConfigAPIURL.getChatBotCategories
        // JSON.stringify({semanticQuery : typedMsg})
      );
      if (response) {
        const receivedData = response?.data?.categories; // Assuming this is the data you want to pass
        return receivedData;
      }
    } catch (err) {
      console.log(err);
    }
  };
  const getSkills = async (skills) => {
    try {
      const response = await APIRequest.request(
        "POST",
        ConfigAPIURL.getChatBotSkills,
        JSON.stringify({ category: skills })
      );
      if (response) {
        const receivedData = response?.data?.skills; // Assuming this is the data you want to pass
        return receivedData;
      }
    } catch (err) {
      console.log(err);
    }
  };

  // const getCategories = async () => {
  //   try {
  //     const response = await axios.get(
  //       "http://localhost:3001/users/categories"
  //     );
  //     return response.data;
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const [totalMentorsFound, setTotalMentorsFound] = useState([]);
  const [labelData, setLabelData] = useState("");
  const [categoryLabel, setCategoryLabel] = useState("");

  const fetchMentorsLists = async (label) => {
    try {
      const response = await APIRequest.request(
        "POST",
        ConfigAPIURL.getSearchedMentors,
        JSON.stringify({ skills: label })
      );
      if (response.data.responseCode === 109) {
        // console.log("this is chat response", response);
        setTotalMentorsFound(response?.data?.users);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getBackendData = async (label) => {
    let menuItemsUrl;

    const menuItemsUrlLists = {
      "Search for a mentor": `${ConfigAPIURL.getChatBotCategories}`,
      "Assist on mentoring topics": "",
      "Course on mentoring": "",
    };

    if (menuItemsUrlLists[label] === "") {
      setDevelopmentMessage("This feature is still under development");
      setMenuItems([]);
      return;
    }

    if (menuItemsUrlLists[label]) {
      menuItemsUrl = menuItemsUrlLists[label];
    } else {
      const categories = await getCategories();
      if (categories?.includes(label)) {
        // const skills = await getSkills(label);
        // console.log("this is skills", skills);
        menuItemsUrl = label;
        setCategoryLabel(label);
        // menuItemsUrl = `${ConfigAPIURL}/${label}/skills`;
      } else {
        console.log("i canem here");
        fetchMentorsLists(label);
        setShowBookingPrompt(true);
        setLabelData(label);
        return;
        navigate("/home/mentors/list", { state: { skill: label } });
        return;
      }
    }

    try {
      // const response = await axios.get(menuItemsUrl);
      let data = [];
      if (!menuItemsUrl.includes(`${ConfigAPIURL.getChatBotCategories}`)) {
        // Call another API if the URL does not match
        const response = await getSkills(menuItemsUrl);
        data = response;
        // Handle data from the other API
        // console.log("Data from another API:", data);
      } else {
        // Call the original API
        const response = await getCategories();
        data = response;
        console.log("from another API:", data);
        // console.log("Data from the original API:", data);
      }
      // console.log("ths is data data",data);
      if (data.length === 0) {
        setNoMentorsFound(true);
        setDevelopmentMessage("");
      } else {
        setNoMentorsFound(false);
        setDevelopmentMessage("");
        const menuItemsCollection = data.map((menuItem, index) => ({
          id: index + 1,
          label: menuItem,
        }));

        // Save current menu items and label
        setPreviousMenuItems(menuItems);
        setPreviousMenuLabel(menuLabel);
        setMenuItems(menuItemsCollection);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleMenuItem = (label) => {
    setPreviousMenuItems(menuItems);
    setPreviousMenuLabel(menuLabel);
    setMenuLabel(label);
    getBackendData(label);
  };

  //check whether the user is logged in or nt and route to private route
  const isLogin = async () => {
    const response = await APIRequest.request(
      "GET",
      ConfigAPIURL.userIsLoggedin,
      ""
    );
    if (response?.data?.responseCode === 109) {
      return true;
    } else {
      return false;
    }
  };

  // const goBack = () => {
  //   setMenuLabel(previousMenuLabel);
  //   setMenuItems(previousMenuItems);
  //   setNoMentorsFound(false);
  //   setDevelopmentMessage("");
  // };

  const goBack = () => {
    if (showSignupPrompt) {
      setShowBookingPrompt(true);
      setShowSignupPrompt(false);
    }
    if (showBookingPrompt) {
      setShowBookingPrompt(false);
    }
    // console.log("previousMenuItems", previousMenuItems);
    if (previousMenuItems.length > 0 && developmentMessage === "") {
      // console.log("this ia ,,",labelData);
      setMenuItems(previousMenuItems);
      setMenuLabel(previousMenuLabel);
      setPreviousMenuItems([]);
      setPreviousMenuLabel("");
    } else if (!showSignupPrompt && !showBookingPrompt) {
      setShowInitialView(true);
      setMenuItems(initialMenuItems);
      setDevelopmentMessage("");
      // If there are no previous menu items, we might be at the root level
      // You can either do nothing or navigate to a specific page
      // For example, navigate to the home page:
      // navigate('/home');
    }
    setNoMentorsFound(false);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
    setShowInitialView(true);
    setDevelopmentMessage("");
    setShowBookingPrompt(false);
    setShowSignupPrompt(false);
    setNoMentorsFound(false)
  };

  const linearGrad1 = "linear-gradient(to top, #8EA7E9 20%, #000 80%)";

  const [currentImage, setCurrentImage] = useState(0);
  const images = [
    allImages.RoboGhostStraight,
    allImages.RoboGhostLeft,
    allImages.RoboGhostStraight,
    allImages.RoboGhostRight,
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prev) => (prev + 1) % images.length);
    }, 2000);

    return () => clearInterval(interval);
  }, [images.length]);

  useEffect(() => {
    const scrollAnchor = document.querySelector(".scroll-anchor");
    if (scrollAnchor) {
      scrollAnchor.scrollIntoView({ behavior: "smooth" });
    }
  }, [menuItems]);

  return (
    <div
      style={{
        // minHeight: "100vh",
        // backgroundColor: "#F3F4F6",
        paddingTop: "2rem",
        paddingBottom: "0px", // to account for the wave
      }}
    >
      {!showInitialView && (
        <button
          onClick={goBack}
          style={{
            background: "none",
            border: "none",
            fontSize: "1.5rem",
            cursor: "pointer",
            marginBottom: "1rem",
            position: "absolute",
            top: "22px",
            left: "16px",
          }}
        >
          ←
        </button>
      )}
      <button
        onClick={handleCloseModal}
        style={{
          background: "none",
          border: "none",
          fontSize: "1.5rem",
          cursor: "pointer",
          marginBottom: "1rem",
          position: "absolute",
          top: "20px",
          right: "26px",
        }}
      >
        <CloseIcon />
      </button>
      {showInitialView ? (
        <div
          style={{
            backgroundColor: "white",
            padding: "2rem",
            borderRadius: "0.5rem",
            textAlign: "center",
            maxWidth: "400px",
            minHeight: "400px",
            // margin: "0 auto",
            // boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)"
          }}
        >
          <div
            style={{
              backgroundColor: "#4A5568",
              borderRadius: "50%",
              width: "80px",
              height: "80px",
              margin: "0 auto 1rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* <span style={{ color: "#FFA07A", fontSize: "2rem" }}>👁️</span> */}
            <img
              src={images[currentImage]}
              alt="Chat robot"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                transition: "opacity 0.5s ease-in-out",
              }}
            />
          </div>
          <h2
            style={{
              marginBottom: "0.5rem",
              fontWeight: "700",
              color: "#000",
              fontSize: "26px",
            }}
          >
            Hi, I'm Luna, Your AI assistant
          </h2>
          <p
            style={{
              marginBottom: "1rem",
              color: "#8E8E8E",
              fontSize: "17px",
              fontWeight: "500",
            }}
          >
            I'm here to assist you
          </p>
          <button
            style={{
              backgroundColor: "#00275A",
              color: "white",
              padding: "0.5rem 1rem",
              borderRadius: "0.25rem",
              border: "none",
              cursor: "pointer",
            }}
            onClick={() => {
              setMenuItems(initialMenuItems);
              setShowInitialView(false);
            }}
          >
            Ask Luna!
          </button>
        </div>
      ) : developmentMessage ? (
        <div
          style={{
            textAlign: "center", // text-center
            color: "#EF4444", // text-red-500
          }}
        >
          {developmentMessage}
        </div>
      ) : noMentorsFound ? (
        <div
          style={{
            textAlign: "center", // text-center
            color: "#EF4444", // text-red-500
          }}
        >
          No mentors found
        </div>
      ) : (
        <div
          style={{
            backgroundColor: "white",
            padding: "2rem",
            borderRadius: "0.5rem",
            textAlign: "center",
            maxWidth: "400px",
            minHeight: "400px",
          }}
          className="scroll-anchor"
        >
          {!showBookingPrompt && !showSignupPrompt && (
            <div
              style={{
                display: "flex", // flex
                flexDirection: "column", // flex-col
                gap: "0.5rem", // gap-2
                color: "var(--custom-brown)", // text-custom-brown
              }}
              // className="scroll-anchor"
            >
              {menuItems?.map((menuItem) => (
                <MenuItemButton
                  key={menuItem.id}
                  name={menuItem.label}
                  label={menuItem.label}
                  onClick={() => {
                    handleMenuItem(menuItem.label);
                  }}
                />
              ))}
            </div>
          )}
          {/* ---------------------------------------------------- */}
          {showBookingPrompt && (
            <BookingPrompt
              mentorCount={totalMentorsFound?.length}
              onYes={async () => {
                setShowBookingPrompt(false);
                const isLoginUser = await isLogin();
                if (!isLoginUser) {
                  setShowSignupPrompt(true);
                } else {
                  navigate("/home/mentors/list", {
                    state: { skill: labelData },
                  });
                }
              }}
              onGoBack={() => {
                setShowBookingPrompt(false);
                goBack();
                // Reset to previous menu state
              }}
              labelData={labelData}
              categoryLabel={categoryLabel}
            />
          )}

          {showSignupPrompt && (
            <SignupPrompt
              onSignUp={() => {
                // Handle signup logic
                setShowSignupPrompt(false);
                // navigate("/login");
                navigate("/signup", {
                  state: { skill: labelData },
                });
                // navigate("/home/mentors/list", { state: { skill: labelData } });
              }}
              onGoBack={() => {
                setShowSignupPrompt(false);
                goBack();
                // Reset to previous menu state
              }}
            />
          )}
        </div>
      )}

      {/* Wave animation */}

      <div
        style={{
          zIndex: 999999999999,
          position: "sticky",
          bottom: "0px",
          height: "90px",
        }}
      >
        <div
          style={{
            position: "relative", // Change to relative to allow absolute positioning of child elements
            height: "100%",
          }}
        >
          {!showBookingPrompt && !showSignupPrompt && !showInitialView && (
            <div
              style={{
                backgroundColor: "#4A5568",
                borderRadius: "50%",
                width: "40px",
                height: "40px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "absolute", // Change to absolute for top-right positioning
                top: "15px", // Adjust the value to position the image properly
                right: "10px", // Adjust the value to position the image properly
                zIndex: 99999999999999,
              }}
            >
              <img
                src={images[currentImage]}
                alt="Chat robot"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  transition: "opacity 0.5s ease-in-out",
                }}
              />
            </div>
          )}
          <WaveAnimation linearGrad={linearGrad1} />
        </div>
      </div>
    </div>

    // <div
    //   style={{
    //     backgroundColor: "#D1D5DB", // bg-gray-300
    //     padding: "1rem", // p-4
    //     margin: "1rem", // m-4
    //     borderRadius: "0.25rem", // rounded
    //     maxHeight: "600px", // max-h-[600px]
    //     overflowY: "auto", // overflow-y-auto
    //   }}
    // >
    //   <h3
    //     style={{
    //       fontSize: "1.125rem", // text-lg
    //       fontWeight: "bold", // font-bold
    //       marginBottom: "0.5rem", // mb-2
    //       textAlign: "center", // text-center
    //     }}
    //   >
    //     {menuLabel}
    //   </h3>

    //   {developmentMessage ? (
    //     <div
    //       style={{
    //         textAlign: "center", // text-center
    //         color: "#EF4444", // text-red-500
    //       }}
    //     >
    //       {developmentMessage}
    //     </div>
    //   ) : noMentorsFound ? (
    //     <div
    //       style={{
    //         textAlign: "center", // text-center
    //         color: "#EF4444", // text-red-500
    //       }}
    //     >
    //       No mentors found
    //     </div>
    //   ) : (
    //     <div
    //       style={{
    //         display: "flex", // flex
    //         flexDirection: "column", // flex-col
    //         gap: "0.5rem", // gap-2
    //         color: "var(--custom-brown)", // text-custom-brown
    //       }}
    //     >
    //       {menuItems?.map((menuItem) => (
    //         <MenuItemButton
    //           key={menuItem.id}
    //           name={menuItem.label}
    //           label={menuItem.label}
    //           onClick={() => {
    //             handleMenuItem(menuItem.label);
    //           }}
    //         />
    //       ))}
    //     </div>
    //   )}

    //   <div
    //     style={{
    //       display: "flex", // flex
    //       justifyContent: "center", // justify-center
    //       marginTop: "1rem", // mt-4
    //     }}
    //   >
    //     <button
    //       style={{
    //         backgroundColor: "#6B7280", // bg-gray-500
    //         color: "white", // text-white
    //         padding: "0.5rem 1rem", // py-2 px-4
    //         borderRadius: "0.25rem", // rounded
    //       }}
    //       onClick={goBack}
    //     >
    //       Go Back
    //     </button>
    //   </div>
    // </div>
  );
};

export default BottomMenu;
