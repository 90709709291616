import { Stack } from "@mui/material";
import React from "react";
import { allImages } from "../../../../../../images";

const RecievedMsg = ({ content, image }) => {
  return (
    <Stack
      style={{
        flexDirection: "row",
        ustifyContent: "end",
        gap: 8,
        alignItems: "center",
      }}
    >
      {/* <Stack
        style={{
          width: "28px",
          height: "28px",
          borderRadius: "12px",
          overflow: "hidden",
          // background: "#D8D8D8",
        }}
      >
        <img
          src={image || allImages.avatar}
          alt="image"
          style={{ objectFit: "cover" }}
        />
      </Stack> */}
      <Stack className="recievermsg">{content}</Stack>
    </Stack>
  );
};

export default RecievedMsg;
