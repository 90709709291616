import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { allImages } from "../../../../images";
import APIRequest from "../../../../utils/APIRequest";
import BlueButton from "../BlueButton";
import Modal from "./Modal";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ExperienceSkills from "./ExperienceSkills";
import ConfigAPIURL from "../../../../config/ConfigAPIURL";
import { useFormik } from "formik";
import { useForm } from "react-hook-form";
import { Update } from "@mui/icons-material";
import Toaster from "../../../../utils/Toaster";
import BasicDatePicker from "../../../general/datePicker/Index";

// formik is used for state managment
const ModelExperience = ({
  userData,
  addvalue,
  id,
  setState,
  fetchUserAdditionalInfo,
  text
}) => {
  const [value, setValue] = React.useState(new Date("2014-08-18T21:11:54"));
  const [open, setOpen] = useState(false);
  const [currentlyWorking, setCurrentlyWorking] = useState(true);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const { register, handleSubmit } = useForm();

  const [skills, setSkills] = useState([]);

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const handleExperienceUpdate = async (e) => {
    console.log("Form values:", e); // Log form values for debugging

    try {
      if (
        !e.Company ||
        !e.Position ||
        !e.Location ||
        // !e.EmploymentType ||
        !e.Industry ||
        !e.description
      )
        return Toaster.error("Please fill all the fields.");
      if (id) {
        const response = await APIRequest.request(
          "POST",
          ConfigAPIURL.userProfileSingleUpdate,
          JSON.stringify({
            recordId: id,
            heading: "experience",
            experience: {
              company: e.Company,
              position: e.Position,
              location: e.Location,
              employmentType: e.EmploymentType,
              currentlyWorking: currentlyWorking,
              fromDate: startDate.getTime(),
              tillDate: !currentlyWorking ? endDate.getTime() : 0,
              industry: e.Industry,
              description: e.description,
              skills: skills,
              _id: id,
            },
          })
        );
        if (response.data.responseCode === 109) {
          setOpen(false);
        }
      } else {
        startDate.getTime();
        const response = await APIRequest.request(
          "PUT",
          ConfigAPIURL.userUpdateAdditionalInfo,
          JSON.stringify({
            experience: [
              {
                company: e.Company,
                position: e.Position,
                location: e.Location,
                employmentType: e.EmploymentType,
                currentlyWorking: currentlyWorking,
                industry: e.Industry,
                description: e.description,
                skills: skills,
                fromDate: startDate.getTime(),
                tillDate: !currentlyWorking ? endDate.getTime() : 0,
              },
              ...userData,
            ],
          })
        );
        if (response.data.responseCode === 109) {
          setState(true);
          setOpen(false);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      fetchUserAdditionalInfo();
    }
  };

  // console.log("thata userDatauserDatauserDatauserData",userData);
  return (
    <div>
      <Stack direction="row" gap={3} style={{display:'flex',flexDirection:'row',justifyContent:'end',alignItems:'end',}}>
        <Stack   onClick={() => setOpen(true)} style={{cursor:"pointer"}}>
          {/* <img
            // style={{ width: "20px", height: "20px" }}
            style={{ cursor: "pointer" }}
            className="mainicon"
            src={allImages.editPencil}
            alt=""
          /> */}
          {text ?  <img
            // style={{ width: "20px", height: "20px" }}
            style={{ cursor: "pointer" }}
            className="mainicon"
            src={allImages.editPencil}
            alt=""
          /> : <Typography style={{textDecoration:'underline'}}>Add</Typography>}
        </Stack>
      </Stack>
      {
        <Modal open={open} text="Experience" onClose={() => setOpen()}>
          {/* to be displayed inside the modal */}
          <Stack my={4}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <TextField
                  required
                  size="small"
                  label="Company"
                  defaultValue={userData?.company}
                  {...register("Company")}
                  fullWidth
                />
              </Grid>

              {/* <Grid item xs={12} md={6}>
                <TextField
                  size="small"
                  required
                  defaultValue={userData?.employmentType}
                  label="EmploymentType"
                  {...register("EmploymentType")}
                  fullWidth
                />
              </Grid> */}
              <Grid item xs={12} md={6}>
                <TextField
                  required
                  size="small"
                  defaultValue={userData?.industry}
                  label="Industry"
                  {...register("Industry")}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  required
                  size="small"
                  defaultValue={userData?.position}
                  label="Position"
                  {...register("Position")}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  required
                  size="small"
                  defaultValue={userData?.location}
                  label="Location"
                  {...register("Location")}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={12}>
                {/* <InputLabel id="demo-multiple-name-label">Name</InputLabel> */}
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="demo-simple-select-helper-label">
                    Currently Working
                  </InputLabel>
                  <Select
                    size="small"
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    label="Currently Working"
                    value={currentlyWorking}
                    onChange={(e) => setCurrentlyWorking(e.target.value)}
                  >
                    <MenuItem value={false}>No</MenuItem>
                    <MenuItem value={true}>Yes</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12}>
                <Stack direction="row" gap={2} mt={2}>
                  <Stack width="100%">
                    <Typography variant="subtitle1">From </Typography>
                    <DatePicker
                      // peekNextMonth
                      // showMonthDropdown
                      // showMonthYearDropdown
                      // showMonthYearPicker

                      dropdownMode="select"
                      dateFormat="dd/MM/yyyy"
                      showYearDropdown
                      className="datepicker"
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                    />
                    {/* <BasicDatePicker
                      value={startDate}
                      handleChange={(date) => setStartDate(date)}
                    /> */}
                  </Stack>
                  {!currentlyWorking && (
                    <Stack width="100%">
                      <Typography variant="subtitle1">To</Typography>
                      <DatePicker
                        dropdownMode="select"
                        dateFormat="dd/MM/yyyy"
                        showYearDropdown
                        className="datepicker"
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        style={{ height: "20px" }}
                      />
                    </Stack>
                  )}
                </Stack>
              </Grid>

              <Grid item xs={12} md={12}>
                <TextareaAutosize
                  aria-label="empty textarea"
                  placeholder="Description"
                  style={{
                    width: "100%",
                    height: "70px",
                    borderRadius: "4px",
                    padding: "5px 10px ",
                    fontWeight: 400,
                  }}
                  defaultValue={userData?.description}
                  {...register("description")}
                />
              </Grid>
              <Grid item xs={12}>
                <ExperienceSkills
                  onClose={() => setOpen()}
                  userSkills={userData?.skills ? userData?.skills : []}
                  setSkills={setSkills}
                />
              </Grid>
            </Grid>
            <Box
              mt={3}
              alignSelf="end"
              onClick={handleSubmit(handleExperienceUpdate)}
            >
              <BlueButton text="Submit" size="lg" />
            </Box>
          </Stack>
        </Modal>
      }
    </div>
  );
};

export default ModelExperience;
