import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { allImages } from "../../../../../images";
import APIRequest from "../../../../../utils/APIRequest";
import BlueButton from "../../BlueButton";
import Modal from "../Modal";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ExperienceSkills from "../ExperienceSkills";
import ConfigAPIURL from "../../../../../config/ConfigAPIURL";
import { useFormik } from "formik";
import { useForm } from "react-hook-form";
import Toaster from "../../../../../utils/Toaster";
import JoditEditor from "jodit-react";
import { useRef } from "react";
// formik is used for state managment
const OfferingModel = ({ userData, addvalue, id, fetchUserInfo }) => {
  const [value, setValue] = React.useState(new Date("2014-08-18T21:11:54"));
  const [open, setOpen] = useState(false);
  const [currentlyWorking, setCurrentlyWorking] = useState(true);
  const [startDate, setStartDate] = useState(new Date());
  const { register, handleSubmit } = useForm();

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const handleExperienceUpdate = async (e) => {
    if (!e?.title) return Toaster.error("Please fill all the fields.");
    const encodedString = btoa(editorValue);

    try {
      if (id) {
        const response = await APIRequest.request(
          "POST",
          ConfigAPIURL.userOfferingsUpdate,
          JSON.stringify({
            _id: id,
            title: e.title,
            approximateSessionTime: 10,
            amount: 0,
            description: encodedString,
          })
        );
        if (response?.data?.responseCode === 109) {
          setOpen(false);
        }
      } else {
        console.log("creating");
        try {
          const response = await APIRequest.request(
            "POST",
            ConfigAPIURL.userCreateOfferings,
            JSON.stringify({
              title: e.title,
              approximateSessionTime: e.approximateSessionTime,
              amount: e.amount,
              description: encodedString,
            })
          );
          if (response?.data?.responseCode === 109) {
            setOpen(false);
          }
        } catch (err) {
          console.log(err);
        }
      }
    } catch (err) {
    } finally {
      fetchUserInfo();
    }
  };
  const [editorValue, setEditorValue] = useState(
    userData?.description ? atob(userData.description) : ""
  );
  const editor = useRef();

  return (
    <div>
      <Stack direction="row" gap={3} alignItems="center">
        <Typography variant="h5">{""}</Typography>
        <Box display="flex" className="mainicon" onClick={() => setOpen(true)}>
          <img
            style={{ cursor: "pointer" }}
            className="mainicon"
            src={allImages.editPencil}
            alt=""
          />
        </Box>
      </Stack>
      {
        <Modal
          open={open}
          text="Offerings"
          onClose={() => {
            setOpen();
          }}
        >
          {/* to be displayed inside the modal */}
          <Stack my={2}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={12}>
                <TextField
                  required
                  size="small"
                  defaultValue={userData?.title}
                  label="Title"
                  {...register("title")}
                  fullWidth
                />
              </Grid>
              {/* <Grid item xs={12} md={6}>
                <TextField
                  defaultValue={userData?.amount}
                  label="Amount"
                  type="number"
                  {...register("amount")}
                  fullWidth
                />
              </Grid> */}

              <Grid item xs={12} md={12}>
                {/* <TextField
                  type="number"
                  defaultValue={userData?.approximateSessionTime}
                  label="Approximate Session Time"
                  {...register("approximateSessionTime")}
                  fullWidth
                /> */}
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography
                  variant="subtitle1"
                  className="content"
                  fontWeight={700}
                >
                  Description
                </Typography>
                <JoditEditor
                  ref={editor}
                  value={editorValue}
                  onChange={(e) => setEditorValue(e)}
                />
                {/* <TextareaAutosize
                  required
                  defaultValue={userData?.description}
                  aria-label="empty textarea"
                  placeholder="Description"
                  style={{
                    width: "100%",
                    height: "90px",
                    borderRadius: "4px",
                    padding: "5px 10px ",
                  }}
                  {...register("description")}
                /> */}
              </Grid>
            </Grid>
            <Box
              mt={3}
              alignSelf="end"
              onClick={handleSubmit(handleExperienceUpdate)}
            >
              <BlueButton text="Submit" size="lg" />
            </Box>
          </Stack>
        </Modal>
      }
    </div>
  );
};

export default OfferingModel;
