import React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Index from "../components/privateRoute/userRoute/Sessions/Index";

const Session = () => {
  const userData = useSelector((state) => state?.user?.profileData);
  const navigate = useNavigate();
  if (userData?.mentor) navigate("/home");
  useEffect(() => {
    document.title = `Mentorlink sessions`;
  }, []);
  return (
    <div>
      <Index />
    </div>
  );
};

export default Session;
