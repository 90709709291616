import React from "react";
import { Link, useNavigate } from "react-router-dom";
import BlueButton from "../privateRoute/userRoute/BlueButton";
import {
  AppBar,
  Box,
  Button,
  Typography,
  Grid,
  Container,
  CssBaseline,
  Stack,
  useMediaQuery,
} from "@mui/material";
import { allImages } from "../../images";

import { useState } from "react";
import { motion } from "framer-motion";
import ResponsiveHeader from "dna-responsive-nav";
import "dna-responsive-nav/dist/dna-rn.css";
import NavbarL from "./LandingPage/NavbarL";

const AboutUs = () => {
  const [open, setOpen] = useState(false);
  const isSmallScreen = useMediaQuery("(max-width:600px)");

  const navigate = useNavigate();
  const cardsData = [
    {
      imgSrc: `${allImages.ExpertMentorsAboutUS}`,
      title: "Expert Mentors",
      description:
        "Access a diverse network of industry leaders providing tailored guidance to achieve your career goals.",
    },
    {
      imgSrc: `${allImages.PersonalisedGuidanceAboutIUS}`,
      title: "Personalized Guidance",
      description:
        "Get tailored advice specific to your aspirations. Our mentors offer one-on-one sessions for confident career navigation.",
    },
    {
      imgSrc: `${allImages.FlexibleAboutUS}`,
      title: "Flexible Scheduling",
      description:
        "Easily schedule sessions at your convenience. Connect with mentors at times that suit your busy lifestyle.",
    },
    {
      imgSrc: `${allImages.CallingAboutUS}`,
      title: "Comprehensive Support",
      description:
        "From resume reviews to skill development, MentorLink offers tools to support your professional growth.",
    },
  ];

  const ChooseUsCard = ({ imgSrc, title, description }) => {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          padding: "16px",
          backgroundColor: "#F0F0F0",
          borderRadius: "16px",
          // boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.18)",
          margin: "26px 0",
          minHeight: "200px",

          // flexDirection: "column",
        }}
      >
        <div
          style={{
            position: "relative",
            top: "-100px",
            // left: "10px",
            // backgroundColor: "#00275A",
            borderRadius: "50%",
            // width: "100px",
            // height: "100px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            padding: "10px",
            background: "linear-gradient(180deg, #FFFFFF 0%, #C2DCFF 100%)",
          }}
        >
          <Box
            component="img"
            src={imgSrc}
            alt={title}
            sx={{
              width: "40px",
              height: "40px",
              padding: "4px",
              // marginRight: "16px",
              // position: "relative",
              // top: "-50px",
              // backgroundColor:
              //   "linear-gradient(180deg, #FFFFFF 0%, #C2DCFF 100%)",
            }}
          />
        </div>
        <Box style={{ marginLeft: "18px" }}>
          <Typography
            variant="h6"
            sx={{ fontWeight: "600", color: "#00275A" }}
            fontSize={"22px"}
          >
            {title}
          </Typography>
          <Typography
            sx={{ color: "#383A3C" }}
            fontWeight={"400"}
            fontSize={"18px"}
            lineHeight={"24px"}
          >
            {description}
          </Typography>
        </Box>
      </Box>
    );
  };

  return (
    <>
      <Stack
        height="65px"
        direction="row"
        alignItems="center"
        width="100%"
        // className="appbar"
      >
        {/* Navbar */}
        <NavbarL />
      </Stack>
      {/*NavbarEnds*/}
      {/* headbanner */}

      <Stack
        style={{
          padding: isSmallScreen ? "12px" : "34px",
          backgroundColor: "#fff",
          paddingLeft: "50px",
          paddingRight: "50px",
        }}
      >
        <Grid
          Container
          direction={"row"}
          style={{
            flexDirection: isSmallScreen ? "" : "row",
            display: isSmallScreen ? "" : "flex",
            alignItems: "center",
          }}
        >
          <Grid item xs={12} lg={6}>
            <img
              src={allImages.aboutUsImageone}
              alt="logo"
              style={{
                // position: "absolute",
                // top: " 80px",
                width: isSmallScreen ? "100%" : "530px",
                // right: "154px",
                height: isSmallScreen ? "220px" : "369px",
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            lg={6}
            style={{
              // width: "238px",
              overflowWrap: "breakWord",
              marginLeft: isSmallScreen ? "0px" : "36px",
            }}
          >
            <Typography color={"#00275A"} fontSize={"32px"} fontWeight={"600"}>
              About Mentorlink
            </Typography>
            <Typography
              color={"#616161"}
              fontWeight={"400"}
              fontSize={isSmallScreen ? "18px" : "24px"}
              lineHeight={"34px"}
            >
              Welcome to MentorLink, the premier platform dedicated to fostering
              professional growth and career success. At MentorLink, we believe
              that everyone deserves access to expert guidance, invaluable
              insights, and the encouragement needed to reach their full
              potential. Our mission is to connect ambitious individuals with
              seasoned mentors across a wide array of industries, creating a
              supportive community where knowledge and experience drive success.
            </Typography>
          </Grid>
        </Grid>
        {/* headbanner ends */}

        <Grid
          container
          // direction={"row"}
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop: isSmallScreen ? "28px" : "100px",
            justifyContent: "space-between",
          }}
        >
          <Grid item xs={12} lg={7} style={{ paddingRight: "20px" }}>
            <Typography color={"#00275A"} fontSize={"32px"} fontWeight={"600"}>
              Our Mission
            </Typography>
            <Typography
              color={"#616161"}
              fontWeight={"400"}
              fontSize={isSmallScreen ? "18px" : "24px"}
              lineHeight={"34px"}
            >
              At MentorLink, we empower individuals by connecting them with
              experienced mentors for personalized guidance and support. Our
              platform fosters meaningful connections and continuous learning to
              help you confidently navigate your career. We promote knowledge
              sharing, enhance professional development, build a supportive
              community, and foster innovation. MentorLink is committed to your
              success, offering support every step of the way. Join us to
              experience the transformative power of mentorship.
            </Typography>
          </Grid>
          <Grid item xs={12} lg={5}>
            <img
              src={allImages.anoutUsOurMission}
              alt="about"
              style={{
                // position: "absolute",
                // top: " 80px",
                width: isSmallScreen ? "100%" : "100%",
                // right: "154px",
                height: isSmallScreen ? "220px" : "369px",
              }}
            />
          </Grid>
        </Grid>
        {/* <Stack style={{ margin: "20px" }}>
          <Grid container style={{ width: "100%" }}>
            <Typography variant="h5">
              Mentoring is underrated and often abused
            </Typography>

            <Typography
              variant="h6"
              style={{ display: "flex", textAlign: "start" }}
            >
              Is just giving a suggestion or making a connection makes someone a
              Mentor? Let’s face it, in an ecosystem which is dynamically
              evolving on a daily basis in terms of Tech, Regulations, Consumer
              Demands, Market Dynamics etc, every founder needs a sounding
              board, a mentor to guide in the right direction towards success.
              We feel that having a right Mentor is the key to success for a
              founder and his venture. A good mentor will understand the
              concept, assess the opportunities, brings thought leadership,
              connects and so on. “Question is, how to find one and get their
              time?” That is the precise reason, after being in the startup
              ecosystem for over 15 years, meeting thousands of founders,
              evaluating thousands of pitch decks, understanding why so many
              startups fail?, we felt that a platform like Mentorlink is needed
              to find the right Mentor and connect with them to get guided in
              the right direction. Time is money, but right timing is more than
              money! Finding a right mentor takes time and that is exactly why
              MentorLink is the best. It helps you find, connect and engage with
              your preferred Mentor instantly. On mentorlink platform, you will
              be able to find mentors based on your preferred Sectors, Tech
              Verticals, Region/ Location, Experience, Markets and many more.
              You will be able to see their bio, a short video which will help
              you zero-in on your options.
            </Typography>
          </Grid>
          <Grid container style={{ width: "100%" }}>
            <Typography variant="h5">
              "Question is, how to find one and get their time?"
            </Typography>

            <Typography
              variant="h6"
              style={{ display: "flex", textAlign: "start" }}
            >
              That is the precise reason, after being in the startup ecosystem
              for over 15 years, meeting thousands of founders, evaluating
              thousands of pitch decks, understanding why so many startups
              fail?, we felt that a platform like Mentorlink is needed to find
              the right Mentor and connect with them to get guided in the right
              direction.
            </Typography>
            <Typography
              variant="h6"
              style={{ display: "flex", textAlign: "start", marginTop: "20px" }}
            >
              Time is money, but right timing is more than money! Finding a
              right mentor takes time and that is exactly why MentorLink is the
              best. It helps you find, connect and engage with your preferred
              Mentor instantly. On mentorlink platform, you will be able to find
              mentors based on your preferred Sectors, Tech Verticals, Region/
              Location, Experience, Markets and many more. You will be able to
              see their bio, a short video which will help you zero-in on your
              options.
            </Typography>
          </Grid>
        </Stack>
        <Stack style={{ marginTop: "40px" }}>
          <BlueButton
            style={{
              textTransform: "capitalize",
              marginTop: 0,
              width: "125px",
              position: "absolute",
              left: "47%",
            }}
            text="Explore More"
            onClick={() => navigate("/signup")}
          />
        </Stack> */}
        <Stack style={{ marginTop: isSmallScreen ? "28px" : "100px" }}>
          <Typography
            color={"#00275A"}
            fontSize={"40px"}
            fontWeight={"600"}
            alignSelf={"center"}
            marginBottom={"38px"}
          >
            Why to choose us ?
          </Typography>

          <Grid container spacing={2} justifyContent="center">
            {cardsData?.map((card, index) => (
              <Grid item xs={12} md={5} key={index}>
                <ChooseUsCard {...card} />
              </Grid>
            ))}
          </Grid>
        </Stack>
        <Stack style={{ marginTop: isSmallScreen ? "28px" : "100px" }}>
          <Typography
            color={"#00275A"}
            fontSize={isSmallScreen ? "34px" : "40px"}
            fontWeight={"600"}
            alignSelf={"center"}
            // marginBottom={"38px"}
          >
            Join the MentorLink Community
          </Typography>
          <img
            src={allImages.JoinCommunityAboutUS}
            alt="logo"
            style={{
              // position: "absolute",
              // top: " 80px",
              width: isSmallScreen ? "360px" : "600px",
              // right: "154px",
              height: "100%",
              alignSelf: "center",
            }}
          />
          <Typography
            color={"#383A3C"}
            fontSize={isSmallScreen ? "18px" : "24px"}
            fontWeight={"400"}
            alignSelf={"center"}
            lineHeight={"34px"}
          >
            Become a part of the MentorLink community and take the first step
            towards a brighter professional future. With our platform, you gain
            access to invaluable insights, practical advice, and a supportive
            network of mentors and peers. Thank you for choosing MentorLink –
            let's grow, learn, and succeed together! Join us now to embark on
            your journey to professional growth and success.
          </Typography>
        </Stack>
      </Stack>
    </>
  );
};

export default AboutUs;
