import { Button, Container, Grid, Stack } from "@mui/material";
import { shouldForwardProp } from "@mui/styled-engine";
import React, { Suspense, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ConfigAPIURL from "../../../../config/ConfigAPIURL";
import { allImages } from "../../../../images";
import APIRequest from "../../../../utils/APIRequest";
import Loader from "../Loader";
import Demo from "./Demo";
import Filter from "./Filter";
import PopularMentors from "./PopularMentors";
const DiscoverMentor = React.lazy(() => import("./DiscoverMentor"));

const queryDetails = {
  keyword: "",
  category: "",
  skill: "",
  page: 0,
  pageSize: 20,
  priceRange: [],
};

const Index = () => {
  const title = useParams();
  queryDetails.category = title.title;
  // const [mentoData, setMentorData] = useState([]);

  const [data, setData] = useState([]);
  const [query, setQuery] = useState(queryDetails);
  const [showFilter, setShowFilter] = useState(false);
  useEffect(() => {
    fetchMentors();
  }, [query]);

  const fetchMentors = async () => {
    try {
      const response = await APIRequest.request(
        "POST",
        ConfigAPIURL.getMentors,
        JSON.stringify(query)
      );
      if (response?.code === 100 && response?.data?.responseCode === 109) {
        setData(response?.data?.result);
      } else return;
    } catch {}
  };

  const handleCategory = (query) => {
    setQuery(query);
  };

  // useEffect(() => {
  //   APIRequest.request(
  //     "GET",
  //     ConfigAPIURL.userSearch + "?" + "keyword=stranger@gmail.com",
  //     ""
  //   ).then((response) => {});
  // }, []);

  return (
    <Container maxWidth="lg">
      <Grid container py={4} spacing={1}>
        <Grid
          sx={{ display: { xs: !showFilter ? "none" : "block", md: "block" } }}
          item
          xs={12}
          md={3}
        >
          <Filter
            setQuery={handleCategory}
            query={query}
            showFilter={showFilter}
            setShowFilter={setShowFilter}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={5.6}
          sx={{ display: showFilter ? "none" : "block" }}
        >
          <Suspense fallback={<Loader />}>
            <DiscoverMentor data={data} />
          </Suspense>
        </Grid>
        <Grid
          item
          xs={12}
          md={3.4}
          sx={{ display: showFilter ? "none" : "block" }}
        >
          <PopularMentors />
        </Grid>
      </Grid>
      {!showFilter && (
        <Stack
          sx={{ display: { md: "none" } }}
          mt={2}
          display="flex"
          alignItems="center"
          position="fixed"
          top="90%"
          width="100%"
        >
          <Button
            variant="contained"
            sx={{
              background: "#2A4B95",
              borderRadius: "37px",
              textTransform: "capitalize",
              fontSize: "14px",
              fontWeight: "700",
              px: 3,
            }}
            onClick={() => setShowFilter(!showFilter)}
          >
            <img
              src={allImages.filter2}
              alt="filter"
              style={{
                width: "19px",
                height: "13.57px",
                marginRight: ".7rem",
              }}
            />
            Filter
          </Button>
        </Stack>
      )}
    </Container>
  );
};

export default Index;
