import Slider from "react-slick";
import { Box, Typography, Avatar, Stack, styled } from "@mui/material";
import { Card, CardContent } from "@mui/material";
import { allImages } from "../../../images";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React, { useEffect, useState } from "react";
import ConfigAPIURL from "../../../config/ConfigAPIURL";
import APIRequest from "../../../utils/APIRequest";

// const MentorCard = ({ img, text, title, subhead }) => {
//   return (
//     <Stack
//       sx={{
//         borderRadius: "46px",
//         boxShadow: 3,
//         textAlign: "center",
//         backgroundColor: "#fff",
//         display: "flex",
//         flexDirection: "row",
//         padding: "28px",
//       }}
//     >
//       <Stack style={{ marginRight: "8px", }}>
//         <Avatar
//           src={img}
//           alt={title}
//           sx={{ width: 92, height: 92, margin: "auto" }}
//         />
//       </Stack>
//       <Stack>
//         <Typography  fontWeight={"400"} fontSize={'14px'}>
//           {text}
//         </Typography>
//         <Typography
//           sx={{
//             fontWeight: "500",
//             fontSize: "14px",
//             color: "#00275A",
//             alignSelf: "end",
//             marginTop: "14px",
//           }}
//         >
//           -{title}, {subhead}
//         </Typography>
//       </Stack>
//     </Stack>
//   );
// };
const StyledCard = styled(Card)({
  position: "relative",
  borderRadius: "16px",
  boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
  overflow: "visible", // Ensure the pseudo-element is visible
  // backgroundColor:'pink'
});

const RightBorder = styled("div")(({ theme }) => ({
  content: '""',
  position: "absolute",
  top: "10px",
  right: "-10px",
  width: "100%",
  height: "100%",
  border: "2px solid #ccc",
  borderRadius: "7px",
  zIndex: -1, // Ensure the border is behind the card
}));
const MentorCard = ({
  profileImage,
  fname,
  lname,
  designation,
  organization,
}) => {
  return (
    <Box sx={{ display: "inline-block", position: "relative", margin: "16px" }}>
      <StyledCard>
        <RightBorder />
        {/* <CardContent sx={{ textAlign: 'center' }}> */}
        <div
          style={{
            width: "232px",
            height: "290px",
            borderRadius: "7px",
            overflow: "hidden",
          }}
        >
          <img
            src={profileImage}
            alt={fname}
            sx={{
              width: "232px",
              height: "290px",
              margin: "auto",
              marginBottom: "16px",
              borderRadius: "7px",
            }}
          />
        </div>
        {/* </CardContent> */}
      </StyledCard>
      <Typography
        fontSize={"22px"}
        fontWeight={"600"}
        color={"#2F2F2F"}
        marginTop={"14px"}
      >{`${fname} ${lname}`}</Typography>
      <Typography color={"#2F2F2F"} fontSize={"16px"} fontWeight={"400"}>
        {`${designation} at ${organization}`}
      </Typography>
    </Box>
  );
};

const TopRatedMentors = () => {
  const [mentorsData, setMentorsData] = useState([]);

  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 10000,
    slidesToShow: 5.5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: "linear",
    pauseOnHover: true,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1390,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 498,
        settings: {
          slidesToShow: 1.8,
        },
      },
      {
        breakpoint: 458,
        settings: {
          slidesToShow: 1.5,
        },
      },
    ],
  };

  useEffect(() => {
    getAllTopRatedMentors();
  }, []);

  const getAllTopRatedMentors = async () => {
    const response = await APIRequest.request(
      "GET",
      ConfigAPIURL.getAllTopRatedMentors
    );
    if (response) {
      setMentorsData(response?.data?.result);
      // console.log("this is data response",response?.data?.result);
    }
  };
  return (
    <Box
      id="purpose"
      textAlign="center"
      my={4}
      style={{
        // backgroundColor: "#00275A",
        // height: "100%",
        paddingTop: "20px",
        paddingBottom: "20px",
      }}
    >
      <Stack my={6}>
        <Typography
          variant="subtitle1"
          className="landing__heading banner__title"
          lineHeight={'33px'}
          marginBottom={'8px'}
        >
          Learn From Our Top-Rated Mentors
        </Typography>
        <Typography
          variant="subtitle1"
          className="landing__content"
          alignSelf={"center"}
          lineHeight={"28px"}
        >
          Get expert guidance and achieve your career goals with our top-rated
          mentors. Enjoy personalized support from the industry's best.
        </Typography>
      </Stack>
      <Slider {...settings}>
        {mentorsData && mentorsData?.map((val, ind) => (
          <Box key={ind} px={2} >
            <MentorCard {...val} />
          </Box>
        ))}
      </Slider>
    </Box>
  );
};

export default TopRatedMentors;
