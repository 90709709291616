export const dateConvert = {
  //convert seconds to days,minu
  toDaysMinutesSeconds: function (totalSeconds) {
    const seconds = Math.floor(totalSeconds % 60);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const hours = Math.floor((totalSeconds % (3600 * 24)) / 3600);
    const days = Math.floor(totalSeconds / (3600 * 24));

    const secondsStr = this.makeHumanReadable(seconds, "second");
    const minutesStr = this.makeHumanReadable(minutes, "minute");
    const hoursStr = this.makeHumanReadable(hours, "hour");
    const daysStr = this.makeHumanReadable(days, "day");

    return `${daysStr}${hoursStr}${minutesStr}${secondsStr}`.replace(
      /,\s*$/,
      ""
    );
  },
  // convert to human readable like days ,hrs etc
  makeHumanReadable: (num, singular) => {
    return num > 0
      ? num + (num === 1 ? ` ${singular}, ` : ` ${singular}s, `)
      : "";
  },
};
