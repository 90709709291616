import { Stack, Typography } from "@mui/material";
import React from "react";
import { allImages } from "../../../images";

const Resources = () => {
  return (
    <Stack sx={{ width: "100%", background: "#2C72FF", minHeight: "90vh" }}>
      <img
        style={{
          objectFit: "cover",
          width: "50%",
          margin: "auto",
        }}
        src={allImages.underConstruction}
        alt="under construction"
      />
      <Stack
        sx={{
          bgcolor: "white",
          width: "100%",
          textAlign: "center",
          gap: 2,
          pt:5,
          height: "200px",
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{ fontWeight: 600, fontSize: "40px", lineHeight: "54.4px" }}
        >
          We’re Launching Soon!
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{ fontWeight: 400, fontSize: "16px", lineHeight: "27.4px" }}
        >
          We're putting in our best efforts to create an amazing webpage
        </Typography>
      </Stack>
    </Stack>
  );
};

export default Resources;
