import {
  Autocomplete,
  Box,
  Button,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  ListItem,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import ConfigAPIURL from "../../../../config/ConfigAPIURL";
import APIRequest from "../../../../utils/APIRequest";
// import ReactDropdown from "./ReactDropdown";
// import Select from "react-select";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const ProffesionalDetails = ({
  userDetails,
  setUserDetails,
  register,
  userProfile,
}) => {
  // (userProfile);
  const [allCategories, setAllCategories] = useState([]);
  // const [allSubCategory, setAllSubcategory] = useState([]);
  const [allTechnolgyVerticals, setAllTechnolgyVerticals] = useState([]);
  const [allSkills, setAllSkills] = useState([]);
  const [categoryName, setCategoryName] = useState([]);
  const theme = useTheme();
  const [personName, setPersonName] = React.useState(userDetails.skills);
  const [selectedOptions, setSelectedOptions] = useState();
  const [subCategoryOptions, setSubcategoryOptions] = useState([]);

  const [otherCategories, setOtherCategories] = useState(false);
  const [otherIndustry, setOtherIndustry] = useState(false);
  console.log(userDetails, "this is all skills");

  const optionList = [{ value: "red", label: "Red" }];
  useEffect(() => {
    setUserDetails({
      ...userDetails,
      skills: personName,
    });
  }, [personName]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  useEffect(() => {
    fetchCategories();
    fetchTechnolgyVertical();
  }, []);
  useEffect(() => {
    // if (userDetails.category === "") return;
    filterSkills();
    fetchSubCategories();
  }, [userDetails.category]);

  const fetchCategories = async () => {
    try {
      const response = await APIRequest.request(
        "GET",
        ConfigAPIURL.UserCategoriesAds,
        ""
      );
      if (response?.data?.responseCode === 109) {
        const categoriesOnly = [];
        response.data.categories.forEach((val) => {
          categoriesOnly.push(val.title);
        });
        setCategoryName(categoriesOnly);
        setAllCategories(response?.data?.categories);
      }
    } catch {
      console.log("error");
    }
  };

  const fetchSubCategories = async () => {
    try {
      const response = await APIRequest.request(
        "POST",
        ConfigAPIURL.subCategories,
        JSON.stringify({
          parentTitle: userDetails?.category,
        })
      );
      if (response?.data?.responseCode === 109) {
        const totalSubcategory = [];
        if (response?.data?.categories.length > 0) {
          response?.data?.categories.forEach((val) => {
            totalSubcategory.push(val.title);
          });
        }
        setSubcategoryOptions(totalSubcategory);
        // setAllSubcategory(response?.data?.categories);
      }
    } catch {}
  };
  const fetchTechnolgyVertical = async () => {
    try {
      const response = await APIRequest.request(
        "GET",
        ConfigAPIURL.technolgyVertical,
        ""
      );
      if (response?.data?.responseCode === 109) {
        const totalTechnolgyVerticals = [];
        response?.data?.technologyVertical.map((val) => {
          if (val?.title) {
            totalTechnolgyVerticals.push(val?.title);
          }
        });
        setAllTechnolgyVerticals(totalTechnolgyVerticals);
      }
    } catch {}
  };

  const filterSkills = () => {
    const value = allCategories?.filter((val) => {
      return val?.title === userDetails?.category;
    });
    if (value.length > 0) setAllSkills(value[0]?.skills);
  };
  function handleSelect(data) {
    setSelectedOptions(data);
  }
  const handleDelete = (ind) => {
    console.log(ind, personName, "this is index and person name");
    const data = [...personName];
    data.splice(ind, 1);
    setPersonName(data);
  };
  const [userInput, setUserInput] = useState("");
  const [isSuggest, setIsSuggest] = useState(true);
  return (
    <Stack mt={4}>
      <Typography
            style={{ fontWeight: 600, fontSize: "22px", alignSelf: "start",marginBottom:'12px' }}
          >
            Professional Experience
          </Typography>
      <form style={{ display: "flex", flexDirection: "column", gap: 16 }}>
        {/* <ReactDropdown/> */}
        <Grid container spacing={4}>
        <Grid item xs={12} md={8} lg={6}>
        <FormControl style={{ width: "100%" }}>
          <Autocomplete
            size="small"
            fullWidth
            id="controllable-states-demo"
            disablePortal
            options={categoryName}
            value={userDetails?.category ? userDetails?.category : ""}
            onChange={(e, term) => {
              term
                ? setUserDetails({
                    ...userDetails,
                    category: term,
                  })
                : setUserDetails({
                    ...userDetails,
                    category: "",
                  });
              term === "Others"
                ? setOtherCategories(true)
                : setOtherCategories(false);
            }}
            renderInput={(params) => (
              <TextField {...params} label="Industry / Sector" />
            )}
          />
        </FormControl>
        </Grid>
        {otherCategories && (
        <Grid item xs={12} md={8} lg={6}>
        <FormControl style={{ width: "100%" }}>
          {otherCategories && (
            <TextField
              fullWidth
              size="small"
              label="Preferred Industry"
              value={userDetails?.otherCategory}
              onChange={(e) => {
                setUserDetails({
                  ...userDetails,
                  otherCategory: e.target.value,
                });
              }}
            />
          )}
        </FormControl>
        </Grid>
        )}
        <Grid item xs={12} md={8} lg={6}>
        <FormControl style={{ width: "100%" }}>
          <Autocomplete
            size="small"
            fullWidth
            id="controllable-states-demo"
            disablePortal
            options={subCategoryOptions}
            value={userDetails?.subCategory ? userDetails?.subCategory : ""}
            onChange={(e, term) => {
              term
                ? setUserDetails({
                    ...userDetails,
                    subCategory: term,
                  })
                : setUserDetails({
                    ...userDetails,
                    subCategory: "",
                  });
              term === "Others"
                ? setOtherIndustry(true)
                : setOtherIndustry(false);
            }}
            renderInput={(params) => (
              <TextField {...params} label="Industry / Subsector" />
            )}
          />
        </FormControl>
        </Grid>
        {otherIndustry && (
        <Grid item xs={12} md={8} lg={6}>
        <FormControl style={{ width: "100%" }}>
          {otherIndustry && (
            <TextField
              fullWidth
              size="small"
              label="Preferred Industry Subsector"
              value={userDetails?.otherSubSector}
              onChange={(e) => {
                setUserDetails({
                  ...userDetails,
                  otherCategory: e.target.value,
                });
              }}
            />
          )}
        </FormControl>
        </Grid>
        )}
        <Grid item xs={12} md={8} lg={6}>
        <FormControl style={{ width: "100%" }}>
          <Autocomplete
            size="small"
            fullWidth
            id="controllable-states-demo"
            disablePortal
            options={allTechnolgyVerticals}
            value={
              userDetails?.technologyVertical
                ? userDetails?.technologyVertical
                : ""
            }
            onChange={(e, term) => {
              term
                ? setUserDetails({
                    ...userDetails,
                    technologyVertical: term,
                  })
                : setUserDetails({
                    ...userDetails,
                    technologyVertical: "",
                  });
            }}
            renderInput={(params) => (
              <TextField {...params} label="Technology Verticals" />
            )}
          />
        </FormControl>
        </Grid>

        </Grid>

        {/* skills select field */}
        {/* <FormControl>
          <InputLabel id="Core Skills / Specialisation">
            Core Skills / Specialisation
          </InputLabel>
          <Select
            labelId="Core Skills / Specialisation"
            id="Core Skills / Specialisation"
            fullWidth
            size="small"
            value={personName}
            onChange={handleChange}
            input={
              <OutlinedInput
                id="Core Skills / Specialisation"
                label="Core Skills / Specialisation"
              />
            }
            renderValue={(selected) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected?.map((value, ind) => (
                  <Chip
                    onDelete={() => console.log("hello")}
                    key={value}
                    label={value}
                  />
                ))}
              </Box>
            )} */}
        {/* // MenuProps={MenuProps}
          > */}
        {/* {allSkills.map((name, ind) => (
              <MenuItem
                key={ind}
                value={name}
                style={getStyles(name, personName, theme)}
              >
                {name}
              </MenuItem>
            ))} */}
        {/* </Select>
        </FormControl> */}
        <InputLabel
          id="Core Skills / Specialisation"
          style={{ marginLeft: 5, fontSize: "12px" }}
        >
          Core Skills / Specialization
        </InputLabel>
        <Paper
          onFocus={() => setIsSuggest(true)}
          // onBlur={() => setIsSuggest(false)}

          sx={{
            display: "flex",
            justifyContent: "start",
            flexWrap: "wrap",
            listStyle: "none",
            p: 2.5,
            m: 0,
            rowGap: 1,
            columnGap: 2,
          }}
        >
          {personName?.map((data, ind) => {
            console.log(data, "this is the data");
            return (
              // <ListItem key={ind}>
              <Chip label={data} onDelete={() => handleDelete(ind)} />
              // </ListItem>
            );
          })}
          <input
            value={userInput}
            onChange={(e) => setUserInput(e.target.value)}
            onKeyUp={(event) => {
              if (event.key === "Enter") {
                if (personName?.includes(userInput)) return;
                setPersonName((p) => [...p, userInput]);
                setUserInput("");
              }
            }}
            type="text"
            style={{
              width: "100%",
              height: "10px",
              border: "none",
              outline: "none",
            }}
          />
          {isSuggest && (
            <>
              <InputLabel
                id="Core Skills / Specialisation"
                style={{ marginLeft: 5 }}
              >
                Suggestions
              </InputLabel>
              <Stack
                mt={1}
                direction="row"
                style={{
                  flexWrap: "wrap",
                  maxHeight: "80px",
                  overflow: "auto",
                }}
              >
                {allSkills &&
                  allSkills?.map((val) => {
                    return (
                      <Button
                        sx={{}}
                        variant="h5"
                        key={val._id}
                        onClick={() => {
                          if (personName?.includes(val)) return;
                          setPersonName((p) => [...p, val]);
                        }}
                      >
                        {val}
                      </Button>
                    );
                  })}
              </Stack>
            </>
          )}
        </Paper>

        {/* skills text field ending */}

        {/* <TextField
          label="Specialization Area"
          type="text"
          defaultValue={
            userProfile?.professionalExperience?.specializationArea
              ? userProfile?.professionalExperience?.specializationArea
              : ""
          }
          {...register("specializationArea")}
          InputLabelProps={{ style: { fontSize: 15 } }}
          size="small"
        /> */}
        {/* <TextField
          label="Functional Area"
          type="text"
          defaultValue={
            userProfile?.professionalExperience?.functionalArea
              ? userProfile?.professionalExperience?.functionalArea
              : ""
          }
          InputLabelProps={{ style: { fontSize: 15 } }}
          size="small"
          {...register("functionalArea")}
        /> */}
      </form>
    </Stack>
  );
};

export default ProffesionalDetails;
