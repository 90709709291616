import { Button } from "@mui/material";
import React from "react";

const BlueButton = ({ text, size, d, transform, style, onClick }) => {
  size === "lg" ? (size = "17px") : (size = "13px");
  size === "md" ? (size = "12px") : (size = "13px");
  const textMode = transform === "capitalize" ? "capitalize" : "uppercase";
  return (
    <>
      <Button
        onClick={() => onClick && onClick()}
        // p="10px 21px"
        variant="contained"
        className="submit__button"
        sx={{
          textTransform: "capitalize",
          // background: "#1C3464",
          borderRadius: "37.135px",
          // color: "white",
          fontWeight: "700",
          fontSize: { md: size, xs: "14px" },
          // py: { xs: ".3rem", md: ".5rem" },
          cursor: "pointer",
          textTransform: textMode,
          ...style,
        }}
        style={{
          ...style,
          marginTop: "10px",
          // padding: "6px 10px"
        }}
      >
        {text}
      </Button>
    </>
  );
};

export default BlueButton;
