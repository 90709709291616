import { Box, Stack, TextareaAutosize } from "@mui/material";
import React from "react";
import { useState } from "react";
import { Button } from "react-bootstrap";
import ConfigAPIURL from "../../../../config/ConfigAPIURL";
import APIRequest from "../../../../utils/APIRequest";
import BlueButton from "../BlueButton";

const ModalAbout = ({ data, onClose, setChange, change }) => {
  const [text, setText] = useState(data.about);

  const handleSubmit = async () => {
    const response = await APIRequest.request(
      "PUT",
      ConfigAPIURL.userUpdateAdditionalInfo,
      JSON.stringify({
        about: text,
      })
    );
    onClose();
    setChange(!change);
  };
  return (
    <Stack alignItems="start" gap={4}>
      <TextareaAutosize
        maxRows={4}
        aria-label="maximum height"
        placeholder="Add some details about yourself"
        value={text}
        onChange={(e) => setText(e.target.value)}
        style={{
          height: "200px",
          width: "100%",
          borderRadius: "4px",
          padding: "1rem",
          fontSize: "14px",
          resize: "none",
          fontWeight: 400,
        }}
      />
      <Box sx={{ alignSelf: "end" }} onClick={handleSubmit}>
        <BlueButton text="Submit" size="lg" />
      </Box>
    </Stack>
  );
};

export default ModalAbout;
