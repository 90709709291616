import { Box, Divider, Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { allImages } from "../../../images";
import HomeIcon from "@mui/icons-material/Home";
// import "./Footer.css"

const Footer = () => {
  const isSmallScreen = useMediaQuery("(max-width:600px)");

  return (
    <Stack
      className="appbar"
      sx={{ px: { xs: 3, md: 5 }, py: 2 }}
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Grid container sx={{ color: "white" }}>
        <Grid
          item
          xs={2.3}
          lg={6}
          alignItems="start"
          sx={{ paddingRight: "1rem", }}
        >
          <Stack sx={{ padding: "12px" }}>
            <img
              style={{
                objectFit: "cover",
                height: "82px",
                width: "300px",
                padding: "12px",
              }}
              src={allImages.logoFooter}
              alt=""
            />
          </Stack>
        </Grid>

        <Grid item xs={12} md={3} lg={3}>
          <Stack className="footer__section">
            {/* <Typography px={0} mb={0.1} variant="h5">
            About
            </Typography>
            <Divider variant="middle" /> */}
            <Stack px={0}>
            <Typography mt={0} variant="subtitle1">
                <Link
                  target="_blank"
                  style={{ color: "white",fontSize :isSmallScreen &&'20px' }}
                  to="/login"
                >
                  Find Mentor
                </Link>{" "}
              </Typography>
              <Typography mt={0} variant="subtitle1">
                <Link
                  target="_blank"
                  style={{ color: "white",fontSize :isSmallScreen &&'20px' }}
                  to="/signup"
                >
                  Become a Mentor
                </Link>{" "}
              </Typography>
              <Typography mt={0} variant="subtitle1">
                <Link
                  target="_blank"
                  style={{ color: "white" ,fontSize :isSmallScreen &&'20px'}}
                  to="/about"
                >
                  About us
                </Link>
              </Typography>
              {/* <Typography mt={0} variant="subtitle1">
                <Link
                  target="_blank"
                  style={{ color: "white" }}
                  to="/terms&condition"
                >
                  FAQ
                </Link>
              </Typography> */}
              <Typography mt={0} variant="subtitle1">
                <Link
                  target="_blank"
                  style={{ color: "white",fontSize :isSmallScreen &&'20px' }}
                  to="/contact-us"
                >
                  Contact us
                </Link>{" "}
              </Typography>
              
            </Stack>
          </Stack>
        </Grid>

        <Grid item xs={12} md={3.5} lg={3}>
          <Stack className="footer__section">
            <Stack flexDirection="row" alignItems="center">
              <Typography  variant="h5" style={{fontSize :isSmallScreen &&'24px',marginBottom:'8px'}}>
                Address
              </Typography>
              {/* <HomeIcon /> */}
            </Stack>
            <Divider variant="middle" />
            <Stack>
              <Typography
                variant="p"
                fontSize={"14px"}
                fontWeight={"400"}
                color={"#FFFFFFB0"}
              >
                INNOCATALYSTS ADVISORS PRIVATE LIMITED <br /> #122, Pipeline
                Road, Malleshwaram 10th Cross,
                <br />
                Bangalore, Karnataka,India.
              </Typography>
            </Stack>
            <Stack
              style={{ display: "flex", flexDirection: "row", margin: "8px",marginLeft:'0px' }}
            >
              <Stack
                style={{
                  padding: "8px",
                  borderRadius: "50%",
                  backgroundColor: "#fff",
                  width: "40px",
                  height: "40px",
                  margin: "8px",
                  marginLeft:'0px' 
                }}
              >
                <img src={allImages.InstagramIcon} />
              </Stack>
              <Stack
                style={{
                  padding: "8px",
                  borderRadius: "50%",
                  backgroundColor: "#fff",
                  width: "40px",
                  height: "40px",
                  margin: "8px",
                }}
              >
                <img src={allImages.Linkedin} alt="" srcset="" />
              </Stack>
              <Stack
                style={{
                  padding: "8px",
                  borderRadius: "50%",
                  backgroundColor: "#fff",
                  width: "40px",
                  height: "40px",
                  margin: "8px",
                }}
              >
                <img src={allImages.XIcon} alt="" srcset="" />
              </Stack>
              <Stack
                style={{
                  padding: "8px",
                  borderRadius: "50%",
                  backgroundColor: "#fff",
                  width: "40px",
                  height: "40px",
                  margin: "8px",
                }}
              >
                <img src={allImages.YoutubeIcon} alt="" srcset="" />
              </Stack>
            </Stack>
          </Stack>
        </Grid>
        {/* <Grid item xs={12} md={3}>
          <Stack>
            <Typography px={1} mb={1} variant="h5">
              Mail
            </Typography>
            <Divider variant="middle" />
            <Stack px={2}>
              <Typography mt={1} variant="subtitle1">
                E mail : mentorlink12@gmail.com
              </Typography>
            </Stack>
          </Stack>
        </Grid> */}
        {/* <Grid item xs={12} md={3}>
          <Stack className="footer__section">
            <Typography px={1} mb={0.1} variant="h5">
              Contact Us
            </Typography>
            <Divider variant="middle" />
            <Stack px={2}>
              <Typography mt={1} variant="subtitle1">
                <Box
                  sx={{ cursor: "pointer" }}
                  onClick={() =>
                    (window.location = "mailto:innocatalyst67@gmail.com")
                  }
                >
                  support@mentorlink.in{" "}
                </Box>
              </Typography>
              <Typography mt={0} variant="subtitle1">
                <Box
                  sx={{ cursor: "pointer" }}
                  onClick={() =>
                    (window.location = "mailto:mentorlink12@gmail.com")
                  }
                >
                  8095355599
                </Box>
              </Typography>
            </Stack>
          </Stack>
        </Grid> */}
        {/* <Grid item xs={12} md={3}>
          <Stack className="footer__section">
            <Typography px={0} mb={0.1} variant="h5">
              Policy
            </Typography>
            <Divider variant="middle" />
            <Stack px={0}>
              <Typography mt={0} variant="subtitle1">
                <Link
                  target="_blank"
                  style={{ color: "white" }}
                  to="/terms&condition"
                >
                  Terms of Services
                </Link>
              </Typography>
              <Typography mt={0} variant="subtitle1">
                <Link
                  target="_blank"
                  style={{ color: "white" }}
                  to="/privacy-policy"
                >
                  Privacy Policy
                </Link>{" "}
              </Typography>
              <Typography mt={0} variant="subtitle1">
                <Link
                  target="_blank"
                  style={{ color: "white" }}
                  to="/refund-policy"
                >
                  Refund Policy
                </Link>{" "}
              </Typography>
            </Stack>
          </Stack>
        </Grid> */}
      </Grid>
      <Divider variant="middle" color="#fff" />
      <Stack
        style={{
          display: "flex",
          flexDirection:isSmallScreen ?"column": "row",
          justifyContent: "space-between",
          marginTop:'16px',
          marginLeft:isSmallScreen ? '0px':'18px',
          marginRight:'18px',
       
        }}
      >
        <Typography variant="p" color="#fff" style={{
          fontSize:isSmallScreen && '14px',
          marginRight: isSmallScreen && '12px',
          marginBottom :isSmallScreen && '12px'
        }}>
        © Copyright 2022, All Rights Reserved
        </Typography>
        <Stack style={{ display: "flex", flexDirection: "row" }}>
          <Typography mt={0} variant="subtitle1" marginRight={'16px'} style={{
          fontSize:isSmallScreen && '14px'
        }}>
            <Link
              target="_blank"
              style={{ color: "#DBDBDBD4", marginRight: "8px" }}
              to="/privacy-policy"
            >
              Privacy Policy
            </Link>{" "}
          </Typography>
          <Typography mt={0} variant="subtitle1"marginRight={'16px'} style={{
          fontSize:isSmallScreen && '14px'
        }}>
            <Link
              target="_blank"
              style={{ color: "#DBDBDBD4" }}
              to="/refund-policy"
            >
              Refund Policy
            </Link>
          </Typography>
          <Typography mt={0} variant="subtitle1" style={{
          fontSize:isSmallScreen && '14px'
        }}>
            <Link
              target="_blank"
              style={{ color: "#DBDBDBD4" }}
              to="/terms&condition"
            >
              Terms and Conditions
            </Link>
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Footer;
