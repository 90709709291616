import { allImages } from "../../../../images";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React, { Children } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ModelExperience from "../UserProfile/ModelExperience";
import EducationModel from "../UserProfile/Models/EducationModel";
import APIRequest from "../../../../utils/APIRequest";
import ConfigAPIURL from "../../../../config/ConfigAPIURL";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

const EducationAccordion = ({
  title,
  data,
  children,
  setState,
  state,
  fetchUserAdditionalInfo,
}) => {
  const location = useLocation();
  const [expanded, setExpanded] = React.useState(
    data?.education.length > 0 ? "panel1" : false
  );

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleDeleteEducation = async (id) => {
    try {
      const response = await APIRequest.request(
        "POST",
        ConfigAPIURL.userProfileDataRemove,
        JSON.stringify({
          recordId: id,
          heading: "education",
        })
      );
      if (response.data.responseCode === 109) {
        setState(!state);
      }
    } catch {
    } finally {
      fetchUserAdditionalInfo();
    }
  };
  return (
    <Stack mt={1} style={{ marginTop: "16px" }}>
      {/* <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
        sx={{ borderRadius: "15px" }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Stack direction="row" gap={1} alignItems="center">
            <Typography
              className="heading"
              sx={{ fontWeight: "600", fontSize: "20px" }}
            >
              {title}
            </Typography>
            {location.pathname === "/profile" ? (
              <EducationModel
                userData={data.education}
                fetchUserAdditionalInfo={fetchUserAdditionalInfo}
              />
            ) : null}
          </Stack>
        </AccordionSummary>
        <Divider variant="middle" />
        <AccordionDetails>
          <Stack ml={6}></Stack>
          {data?.education.length > 0 &&
            data.education.map((value) => {
              return (
                <Stack
                  sx={{ flexDirection: { xs: "column", md: "row" } }}
                  justifyContent="space-between"
                  alignItems="center"
                  key={value._id}
                  mx={2}
                  // mt={2}
                  gap={1}
                >
                  <Stack width="100%">
                    <Stack
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Stack flexDirection="row" alignItems="center" gap={2}>
                        <Typography
                          variant="subtitle1"
                          fontWeight="700"
                          color="#1C3464"
                          fontSize="20px"
                          className="heading"
                          textTransform="capitalize"
                        >
                          {value?.school}
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          fontWeight="600"
                          fontSize="16px"
                          className="sub__heading"
                          textTransform="capitalize"
                        >
                          {value?.degree}
                        </Typography>
                      </Stack>
                      {location.pathname === "/profile" ? (
                        <>
                          <Stack direction="row" gap={3}>
                            <EducationModel
                              userData={value}
                              id={value._id}
                              fetchUserAdditionalInfo={fetchUserAdditionalInfo}
                            />
                            <Box
                              display="flex"
                              className="mainicon"
                              style={{ cursor: "pointer" }}
                              onClick={() => handleDeleteEducation(value._id)}
                            >
                              <img src={allImages.deleteButton} alt="delete" />
                            </Box>
                          </Stack>
                        </>
                      ) : null}
                    </Stack>

                    <Typography
                      variant="subtitle1"
                      fontWeight="400"
                      fontSize="16px"
                      className="sub__heading"
                    >
                      {value?.fieldOfStudy}
                    </Typography>

                    <Typography variant="subtitle1" className="sub__heading">
                      {value?.grade}
                    </Typography>

                    <Typography variant="subtitle1" className="sub__heading">
                      From
                      {value?.startDate
                        ? new Date(parseInt(value?.startDate, 10))
                            .toString("MM/dd/yy HH:mm:ss")
                            .slice(3, 16)
                        : null}
                      to
                      {value?.endDate
                        ? new Date(parseInt(value?.endDate, 10))
                            .toString("MM/dd/yy HH:mm:ss")
                            .slice(3, 15)
                        : null}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      fontSize="14px"
                      className="content wrap"
                    >
                      {value?.description}
                    </Typography>
                  </Stack>
                </Stack>
              );
            })}
        </AccordionDetails>
      </Accordion> */}

      <Typography
        sx={{
          fontWeight: "600",
          fontSize: "28px",
          color: "#343434",
          marginBottom: "12px",
        }}
      >
        {title}
      </Typography>
      <Stack
        style={{
          backgroundColor: "#FEFEFE",
          borderRadius: "18px",
          padding: "20px",
        }}
      >
        {location.pathname === "/profile" ? (
          <EducationModel
            userData={data.education}
            fetchUserAdditionalInfo={fetchUserAdditionalInfo}
          />
        ) : null}
        {data?.education.length > 0 &&
          data.education.map((value) => {
            return (
              <Stack
                sx={{ flexDirection: { xs: "column", md: "row" } }}
                justifyContent="space-between"
                alignItems="center"
                key={value._id}
                mx={2}
                // mt={2}
                gap={1}
              >
                <Stack width="100%">
                  <Stack
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Stack
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Stack
                        style={{
                          backgroundColor: "#A063FF1A",
                          borderRadius: "12px",
                          padding: "18px",
                          marginRight: "8px",
                          height: "80px",
                          width: "80px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img src={allImages.EducationIcon} alt="" srcset="" />
                      </Stack>
                      <Stack flexDirection="column" gap={0}>
                        <Stack flexDirection="row" alignItems="center" gap={2}>
                          <Typography
                            // variant="subtitle1"
                            fontWeight="700"
                            color="#1C3464"
                            fontSize="24px"
                            // className="heading"
                            textTransform="capitalize"
                          >
                            {value?.school}
                          </Typography>
                          <Typography
                            variant="subtitle1"
                            fontWeight="600"
                            fontSize="16px"
                            className="sub__heading"
                            textTransform="capitalize"
                          >
                            {value?.degree}
                          </Typography>
                        </Stack>

                        <Stack>
                          <Typography
                            variant="subtitle1"
                            fontWeight="400"
                            fontSize="18px"
                            // className="sub__heading"
                          >
                            {value?.fieldOfStudy}
                          </Typography>
                        </Stack>
                      </Stack>
                    </Stack>
                    {location.pathname === "/profile" ? (
                      <Stack
                        direction="row"
                        gap={3}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "end",
                          alignItems: "center",
                          marginTop: "8px",
                        }}
                      >
                        <EducationModel
                          userData={value}
                          id={value._id}
                          fetchUserAdditionalInfo={fetchUserAdditionalInfo}
                          text={"edit"}
                        />
                        <Box
                          display="flex"
                          className="mainicon"
                          style={{ cursor: "pointer" }}
                          onClick={() => handleDeleteEducation(value._id)}
                        >
                          <img src={allImages.deleteButton} alt="delete" />
                        </Box>
                      </Stack>
                    ) : null}
                  </Stack>

                  {/* <Typography variant="subtitle1" className="sub__heading">
                      {value?.grade}
                    </Typography> */}

                  <Typography
                    variant="subtitle1"
                    // className="sub__heading"
                    alignSelf={"end"}
                    color={"#676768"}
                    fontSize={"14px"}
                    fontWeight="500"
                  >
                    {/* From */}
                    {value?.startDate
                      ? new Date(parseInt(value?.startDate, 10))
                          .toString("MM/dd/yy HH:mm:ss")
                          .slice(3, 16)
                      : null}
                    {/* to */} -
                    {value?.endDate
                      ? new Date(parseInt(value?.endDate, 10))
                          .toString("MM/dd/yy HH:mm:ss")
                          .slice(3, 15)
                      : null}
                  </Typography>
                  {/* <Typography
                      variant="subtitle1"
                      fontSize="14px"
                      className="content wrap"
                    >
                      {value?.description}
                    </Typography> */}
                </Stack>
              </Stack>
            );
          })}
      </Stack>
    </Stack>
  );
};

export default EducationAccordion;
