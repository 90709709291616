import { Box, Button, Container, Grid, Stack, Typography } from "@mui/material";
import { motion } from "framer-motion";
import React, { Suspense, useEffect, useState } from "react";
import { useRef } from "react";
import ConfigAPIURL from "../../../../config/ConfigAPIURL";
import { allImages } from "../../../../images";
import Session from "../../../../pages/Session";
import APIRequest from "../../../../utils/APIRequest";
import Filter from "../categoriespage/Filter";
import Loader from "../Loader";
const FeaturedSessions = React.lazy(() => import("./FeaturedSessions"));

const Index = () => {
  const filterHold = useRef();
  const filterIcon = useRef();
  const queryDetails = {
    keyword: "",
    category: "",
    skill: "",
    page: 0,
    pageSize: 6,
    priceRange: [],
  };
  const [query, setQuery] = useState(queryDetails);
  const [data, setData] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [blockScrolling, setBlockScrolling] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleCategory = (query) => {
    setQuery(query);
  };

  useEffect(() => {
    fetchMentors();
  }, [query]);

  const filterHandle = () => {
    filterIcon.current.style.opacity = "0";
    filterHold.current.style.display = "block";
  };
  const filterClose = () => {
    filterHold.current.style.display = "none";
    filterIcon.current.style.opacity = ".9";
  };

  const fetchMentors = async () => {
    const datas = { ...query };
    datas.category = datas.category?.replace("|", "");
    try {
      const response = await APIRequest.request(
        "POST",
        ConfigAPIURL.getMentors,
        JSON.stringify(datas)
      );
      if (response?.data?.responseCode === 109) {
        if (response?.data?.result?.length === 0) {
          setBlockScrolling(true);
        }
        if (query.page !== 0) {
          const previousData = [...data];
          console.log(previousData, "this is whole data");
          previousData.push(...response?.data?.result);
          setData(previousData);
          // setData(response?.data?.result);
        } else {
          setData(response?.data?.result);
        }
      } else return;
    } catch (err) {
      console.log(err);
    }
  };
  window.onscroll = function (ev) {
    if (blockScrolling) return;
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
      setQuery({
        ...query,
        page: query?.page + 1,
      });
    }
  };

  if (isLoading) return <Loader />;

  return (
    <Container maxWidth={"lg"} sx={{ my: { xs: 8, md: 3 } }}>
      <Grid container spacing={1}>
        <Grid
          item
          xs={12}
          md={3}
          sx={{ display: { xs: !showFilter ? "none" : "block", md: "block" } }}
        >
          <Filter
            setQuery={handleCategory}
            query={query}
            showFilter={showFilter}
            setShowFilter={setShowFilter}
          />
        </Grid>

        <Grid
          item
          xs={12}
          md={9}
          sx={{ display: showFilter ? "none" : "block", marginTop: "24px" }}
        >
          <Suspense fallback={<Loader />}>
            <FeaturedSessions data={data} />
          </Suspense>
        </Grid>
      </Grid>
      {!showFilter && (
        <Stack
          sx={{ display: { md: "none" } }}
          mt={2}
          display="flex"
          alignItems="center"
          position="fixed"
          top="90%"
          width="100%"
        >
          <Button
            variant="contained"
            sx={{
              background: "#2A4B95",
              borderRadius: "37px",
              textTransform: "capitalize",
              fontSize: "14px",
              fontWeight: "700",
              px: 3,
            }}
            className="submit__button"
            onClick={() => setShowFilter(!showFilter)}
          >
            <img
              src={allImages.filter2}
              alt="filter"
              style={{
                width: "19px",
                height: "13.57px",
                marginRight: ".7rem",
              }}
            />
            Filter
          </Button>
        </Stack>
      )}
    </Container>
  );
};

export default Index;
