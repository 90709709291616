import React, { useEffect, useState } from "react";
import { allImages } from "../../../images";
import {
  Button,
  Collapse,
  Grid,
  IconButton,
  InputAdornment,
  keyframes,
  Stack,
  styled,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import NavbarL from "../LandingPage/NavbarL";
import { Container } from "react-bootstrap";
import SendIcon from "@mui/icons-material/Send";
import MentorsCard from "./MentorsCard";
import { useLocation, useNavigate } from "react-router-dom";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import APIRequest from "../../../utils/APIRequest";
import ConfigAPIURL from "../../../config/ConfigAPIURL";
import CircularProgress from "@mui/material/CircularProgress";
import ChatIcon from "@mui/icons-material/Chat";
import ChatBotModal from "../LandingPage/ChatBotModal";
import ChatArea from "../LandingPage/ChatArea";
import ChatAreaLoggenInUser from "../LandingPage/ChatAreaLoggenInUser";
import SlidingPanelLoggedInUser from "../LandingPage/SlidingPanelLoggedInUser";
import BottomMenuLoggedInUser from "../LandingPage/BottomMenuLoggedInUser";

const ITEMS_PER_PAGE = 4;

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const RotatingImage = styled("img")`
  animation: ${rotate} 10s linear infinite;
  display: block;
  margin: auto;
`;

const ChatHomePage = () => {
  const location = useLocation();
  //   const { receivedData } = location.state || {};
  const [receivedData, setReceivedData] = useState(
    location.state?.receivedData || []
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [typedMsg, setTypedMsg] = useState("");
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [loader, setLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const [userQuery, setUserQuery] = useState("");
  const [initialButtons, setInitialButtons] = useState(true);

  useEffect(() => {
    // fetchMessages();
  }, []);

  const fetchMessages = async () => {
    try {
      const response = await APIRequest.request(
        "GET",
        ConfigAPIURL.getChatQuestions,
        ""
      );
      if (response.data.responseCode === 109) {
        console.log("this is chat response", response);
        setMessages(response?.data?.result);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleNextPage = () => {
    if (currentPage < Math.ceil(receivedData?.length / ITEMS_PER_PAGE)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleExploreAll = () => {
    navigate("/mentor");
    // Logic to handle "Explore all" action, e.g., navigate to a different page
  };

  const currentItems = receivedData?.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  useEffect(() => {
    isLogin();
  }, []);
  //check whether the user is logged in or nt and route to private route
  const isLogin = async () => {
    // setLoader(true);
    const response = await APIRequest.request(
      "GET",
      ConfigAPIURL.userIsLoggedin,
      ""
    );
    if (response?.data?.responseCode === 109) {
      setUserLoggedIn(true);
      //   setLoader(false);
      if (typedMsg !== "") {
        setLoader(true);
        scrollToBottom();
        sendTypedMessage();
      }
    } else {
      //   setLoader(false);
      if (typedMsg !== "") {
        setLoader(false);
        scrollToBottom();
        sendTypedMessage();
      }
      // sendTypedMessage();
      // setLoader(false);
      // navigate("/login");
    }
  };

  const scrollToBottom = () => {
    window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
  };

  const sendTypedMessage = async () => {
    try {
      const response = await APIRequest.request(
        "POST",
        ConfigAPIURL.sendTypedMessage,
        JSON.stringify({ semanticQuery: typedMsg })
      );
      if (response.data.responseCode === 109) {
        const newData = response?.data?.data;
        scrollToBottom();
        setReceivedData(newData);
        setLoader(false);
        setTypedMsg("");
        setCurrentPage(1);
      }
    } catch (err) {
      console.log(err);
    }
  };

  //   console.log("this is recieved data", receivedData);
  // const handleClickOpen = () => {
  //   fetchMessages();
  //   setOpen(true);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  // };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      setLoader(true);
      sendTypedMessage();
    }
  };
  const [isSlidingPanelOpen, setIsOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);
  const images = [allImages.RoboCloseEye, allImages.RoboOpenEye];
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    let interval;
    if (!isSlidingPanelOpen) {
      interval = setInterval(() => {
        setCurrentImage((prev) => (prev === 0 ? 1 : 0));
      }, 2000);
    } else {
      setCurrentImage(1); // Show the second image when chat is open
    }

    return () => clearInterval(interval);
  }, [isSlidingPanelOpen]);

  const handleToggle = () => {
    setIsVisible(!isVisible);
  };

  const handleClickOpen = () => {
    // setOpen(true);
    setUserQuery("");
    setInitialButtons(true);
    setIsOpen((prev) => !prev);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  // const RotatingCircles = () => {
  //   const circleStyles = (duration) => ({
  //     position: "absolute",
  //     width: "100%",
  //     height: "100%",
  //     display: "flex",
  //     justifyContent: "center",
  //     alignItems: "center",
  //     animation: `rotate ${duration}s linear infinite`,
  //     border: "2px solid pink",
  //     borderRadius: "50%",
  //   });

  //   const keyframes = `
  //     @keyframes rotate {
  //       from {
  //         transform: rotate(0deg);
  //       }
  //       to {
  //         transform: rotate(360deg);
  //       }
  //     }
  //   `;

  //   return (
  //     <div
  //       style={{
  //         position: "relative",
  //         width: "300px",
  //         height: "300px",
  //         margin: "0 auto",
  //       }}
  //     >
  //       <style>{keyframes}</style>
  //       <div style={circleStyles(10)}>
  //         <div
  //           style={{
  //             position: "absolute",
  //             bottom: "-20px",
  //             width: "100%",
  //             textAlign: "center",
  //             fontSize: "12px",
  //             fontWeight: "bold",
  //           }}
  //         >
  //           <img
  //             src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREoRGyXmHy_6aIgXYqWHdOT3KjfmnuSyxypw&s"
  //             alt="Google Software Engineer"
  //             style={{ width: "50px", height: "50px", borderRadius: "50%" }}
  //           />
  //           Google Software Engineer
  //         </div>
  //       </div>
  //       <div style={circleStyles(15)}>
  //         <img
  //           src="amazon.jpg"
  //           alt="E-commerce Veteran"
  //           style={{ width: "50px", height: "50px", borderRadius: "50%" }}
  //         />
  //         <div
  //           style={{
  //             position: "absolute",
  //             bottom: "-20px",
  //             width: "100%",
  //             textAlign: "center",
  //             fontSize: "12px",
  //             fontWeight: "bold",
  //           }}
  //         >
  //           E-commerce Veteran
  //         </div>
  //       </div>
  //       <div style={circleStyles(20)}>
  //         <img
  //           src="fb.jpg"
  //           alt="Tech Industry Mentor"
  //           style={{ width: "50px", height: "50px", borderRadius: "50%" }}
  //         />
  //         <div
  //           style={{
  //             position: "absolute",
  //             bottom: "-20px",
  //             width: "100%",
  //             textAlign: "center",
  //             fontSize: "12px",
  //             fontWeight: "bold",
  //           }}
  //         >
  //           Tech Industry Mentor
  //         </div>
  //       </div>
  //       {/* Add more circles as needed */}
  //     </div>
  //   );
  // };

  return (
    <div>
      {userLoggedIn === false && <NavbarL />}
      {userLoggedIn && (
        <Stack sx={{ background: "white", py: 2 }}>
          <Stack
            onClick={handleClickOpen}
            sx={{
              position: "fixed",
              bottom: 16,
              right: 16,
              // background: "linear-gradient(360deg, #1E5EDF 0%, #00275A 100%)",
              color: "white",
              // "&:hover": {
              //   backgroundColor: "#0056b3",
              // },
              width: 56,
              height: 56,
              zIndex: 99999999,
              display: "flex",
              // flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "50%",
              paddingLeft: 0,
              cursor: "pointer",
            }}
          >
            {/* <ChatIcon /> */}
            <img
              src={images[isSlidingPanelOpen ? 0 : currentImage]}
              alt="Chat robot"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                transition: "opacity 0.5s ease-in-out",
              }}
            />
          </Stack>
        </Stack>
      )}
      <Stack sx={{ background: "white", py: 6 }}>
        <Container>
          <Stack
            style={{
              marginTop: "60px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              // backgroundColor: "violet",
              alignItems: "center",
            }}
          >
            {/* <Typography
              variant="h3"
              sx={{
                color: "#00275A",
                fontWeight: "700",
                textAlign: "center",
                fontSize: !isSmallScreen ? "42px" : "30px",
                //   maxWidth: "900px",
              }}
            >
              Revolutionize Mentor Link With AI Chatbot Integration For Enhanced
              User Experience
            </Typography> */}
            {/* -----------------------------Chat Area----------------------------------------- */}
            {/* <Stack
              //   onSubmit={handleSendMessage}
              style={{
                width: "80%",
                // backgroundColor: "white",
                borderRadius: "16px",
                marginTop: "40px",
                boxShadow:
                  "0px 0px 30px rgba(44, 95, 255, 0.18), 0px 0px 8px rgba(44, 95, 255, 0.3)",
              }}
            >
              <TextField
                fullWidth
                onKeyDown={handleKeyPress}
                variant="outlined"
                placeholder="Ask what you are looking for...."
                value={typedMsg}
                onChange={(e) => setTypedMsg(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        type="submit"
                        style={{
                          background:
                            "linear-gradient(90deg, #01295D 0%, #0151BB 100%)",
                          color: "#fff",
                          borderRadius: "10px",
                          padding: "14px",
                          fontWeight: "400",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                          marginRight: "6px",
                        }}
                        onClick={isLogin}
                      >
                        {!isSmallScreen && (
                          <span className="askMeBtn">Ask me</span>
                        )}

                        <SendIcon style={{ marginLeft: "8px" }} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "16px",
                    border: "2px solid #02306D",
                    padding: "10px",
                    outline: "none",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "transparent", // Remove border color for default state
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "transparent", // Remove border color on focus
                    },
                    "&.Mui-focused": {
                      outline: "none",
                      boxShadow: "none", // Remove box shadow on focus
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "transparent", // Remove border color on hover
                    },
                  },

                  "& .MuiInputBase-input::placeholder": {
                    fontSize: !isSmallScreen ? "22px" : "14px", // Increase the font size
                    color: "#02306C", // Change the color
                    fontWeight: "500", // Optional: make the placeholder text bold
                  },
                  "& .MuiInputBase-input": {
                    fontSize: !isSmallScreen ? "20px" : "18", // Increase the font size of the input text
                    color: "#02306C",
                  },
                }}
              />
            </Stack> */}

            {/* <Stack
              style={{
                marginTop: "38px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "90%",
              }}
            >
              <Button
                // variant="contained"
                // color="primary"
                onClick={handleToggle}
                style={{
                  marginBottom: "16px",
                  backgroundColor: "#FFFFFF",
                  // border: "1px solid #FAFAFA",
                  // boxShadow: "0px 2px 1px rgba(0, 0, 0, 0.1)",
                  width: "100px",
                }}
              >
                {isVisible ? (
                  <img
                    src={allImages.chevronUP}
                    style={{ width: "100%", height: "50px" }}
                  />
                ) : (
                  <img
                    src={allImages.chevronDown}
                    style={{ width: "100%", height: "50px" }}
                  />
                )}
              </Button>
              <Collapse in={isVisible} timeout="auto" unmountOnExit>
                <Grid
                  container
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <Grid
                    item
                    lg={5.5}
                    xl={5.5}
                    xs={12}
                    style={{
                      border: "1px solid #282828",
                      borderRadius: "14px",
                      padding: "20px",
                      height: "100%",
                      margin: "16px 16px 16px 16px",
                    }}
                  >
                    <Typography
                      color={"#00275A"}
                      fontWeight={"600"}
                      fontSize={"24px"}
                    >
                      Find career guidance
                    </Typography>
                    <Typography
                      variant="body1"
                      color={"#4D4D4D"}
                      marginTop={"12px"}
                    >
                      Connect me with a mentor experienced in transitioning from
                      engineering to product management.
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={5.5}
                    xl={5.5}
                    xs={12}
                    style={{
                      border: "1px solid #282828",
                      borderRadius: "14px",
                      padding: "20px",
                      height: "100%",
                      margin: "16px 16px 16px 0px",
                    }}
                  >
                    <Typography
                      variant="h5"
                      color={"#00275A"}
                      fontWeight={"600"}
                      fontSize={"24px"}
                    >
                      Exploring industry insights
                    </Typography>
                    <Typography
                      variant="body1"
                      color={"#4D4D4D"}
                      marginTop={"12px"}
                    >
                      I want to learn about emerging trends in renewable energy.
                      Find me a mentor with expertise in this industry.
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <Grid
                    item
                    lg={5.5}
                    xl={5.5}
                    xs={12}
                    style={{
                      border: "1px solid #282828",
                      borderRadius: "14px",
                      padding: "20px",
                      height: "100%",
                      margin: "0px 16px 16px 0px",
                    }}
                  >
                    <Typography
                      variant="h5"
                      color={"#00275A"}
                      fontWeight={"600"}
                      fontSize={"24px"}
                    >
                      Requesting skill development support
                    </Typography>
                    <Typography
                      variant="body1"
                      color={"#4D4D4D"}
                      marginTop={"12px"}
                    >
                      I need to improve my public speaking skills for important
                      work presentations. Please suggest a qualified mentor
                      specializing in advanced communication training.
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={5.5}
                    xl={5.5}
                    xs={12}
                    style={{
                      border: "1px solid #282828",
                      borderRadius: "14px",
                      padding: "20px",
                      height: "100%",
                      margin: "0px 0px 16px 0px",
                    }}
                  >
                    <Typography
                      variant="h5"
                      color={"#00275A"}
                      fontWeight={"600"}
                      fontSize={"24px"}
                    >
                      Exploring entrepreneurship guidance
                    </Typography>
                    <Typography
                      variant="body1"
                      color={"#4D4D4D"}
                      marginTop={"12px"}
                    >
                      As I embark on my entrepreneurship journey, seeking
                      invaluable mentorship for the critical initial steps from
                      a seasoned startup mentor. Your expert guidance truly
                      matters.
                    </Typography>
                  </Grid>
                </Grid>
              </Collapse>
            </Stack> */}
          </Stack>

          {/* <RotatingCircles /> */}
          {/* ---------------------Recomended Mentors for you------------------------------ */}

          {loader !== true && currentItems?.length === 0 && (
            <Stack>
              <img
                src={allImages.loadingAnimation}
                style={{
                  objectFit: "contain",
                }}
              />
            </Stack>
          )}

          <Stack>
            {loader === true ? (
              <Stack
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "28px",
                  }}
                >
                  {/* <img
                    src={allImages.LoaderImages}
                    style={{ width: "180px", height: "180px" }}
                  /> */}
                  <RotatingImage
                    src={allImages.LoaderImages}
                    alt="Loading Animation"
                    style={{ width: "180px", height: "180px" }}
                  />
                  <Typography
                    fontSize={"28px"}
                    fontWeight={"500"}
                    color={"#313131"}
                    marginTop={"28px"}
                  >
                    Finding the perfect mentor for you. Please wait a moment.
                  </Typography>
                </div>
                {/* <CircularProgress /> */}
              </Stack>
            ) : (
              <>
                <Stack
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "28px",
                    marginTop: "38px",
                    display: currentItems?.length === 0 ? "none" : "",
                  }}
                >
                  <Typography
                    variant="h4"
                    color={"#393939"}
                    // marginTop={"32px"}
                    fontSize={!isSmallScreen ? "32px" : "18px"}
                    fontWeight={"600"}
                  >
                    Ideal mentors for you
                  </Typography>
                  <Stack direction="row" spacing={1} alignItems="center">
                    {/* <Typography
                      onClick={handleExploreAll}
                      style={{
                        borderRadius: "5px",
                        border: "1px solid #000000",
                        padding: !isSmallScreen ? "12px 24px 12px 24px" : "8px",
                        cursor: "pointer",
                        fontSize: !isSmallScreen ? "16px" : "12px",
                        color: "#030303",
                        fontWeight: "500",
                      }}
                    >
                      Explore all
                    </Typography> */}
                    <IconButton
                      onClick={handlePreviousPage}
                      disabled={currentPage === 1}
                    >
                      <NavigateBeforeIcon />
                    </IconButton>
                    <IconButton
                      onClick={handleNextPage}
                      disabled={
                        currentPage >=
                        Math.ceil(receivedData?.length / ITEMS_PER_PAGE)
                      }
                    >
                      <NavigateNextIcon />
                    </IconButton>
                  </Stack>
                </Stack>
                <Grid
                  container
                  spacing={2}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  {currentItems?.map((mentorData, index) => (
                    <Grid item xs={6} sm={6} md={4} lg={3} key={index}>
                      <MentorsCard mentorData={mentorData} />
                    </Grid>
                  ))}
                </Grid>
              </>
            )}
          </Stack>
        </Container>

        {/* --------------------Chat-Box for logegdin User---------------------- */}
        {/* <SlidingPanelLoggedInUser
          isSlidingPanelOpen={isSlidingPanelOpen}
          setIsOpen={setIsOpen}
        /> */}
        <ChatBotModal
          slideInDialog={isSlidingPanelOpen}
          handleClose={handleClose}
          fullWidth={false}
          maxWidth={"sm"}
          dialogActionsStatus={true}
          setSlideInDialog={handleClose}
          // title={"Smart Mentor Match Let’s Find Your Perfect Mentor"}
          contentComponent={
            <BottomMenuLoggedInUser
              setIsOpen={setIsOpen}
              userQuery={userQuery}
              setUserQuery={setUserQuery}
              initialButtons={initialButtons}
              setInitialButtons={setInitialButtons}
            />
          }
        />
        {/* <ChatBotModal
          slideInDialog={open}
          handleClose={handleClose}
          fullWidth={true}
          maxWidth={"sm"}
          dialogActionsStatus={true}
          setSlideInDialog={handleClose}
          title={"Smart Mentor Match Let’s Find Your Perfect Mentor"}
          contentComponent={
            <ChatAreaLoggenInUser
              initialQuestions={messages}
              setReceivedData={setReceivedData}
              setOpen={setOpen}
            />
          }
        /> */}
      </Stack>
    </div>
  );
};

export default ChatHomePage;
