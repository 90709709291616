import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { allImages } from "../../../images";

const MentorLinkCard = ({ img, text, ind, title }) => {

  
  return (
    <>
      <Stack
        sx={{
          // boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.25)",
          borderRadius: "4px",
          // px: 3,
          // py: 2,
          height: "100%",
        }}
      >
        <Box sx={{ width: "60px", height: "60px" }}>
          <img src={img} alt="img" />
        </Box>
        {/* <Typography
          variant="subtitle1"
          textAlign="start"
          fontWeight="600"
          pl={1}
        >
          0{ind + 1}
        </Typography> */}
        <Typography
          // variant="subtitle1"
          textAlign="start"
          fontSize="22px"
          fontWeight="600"
          lineHeight="28px"
          marginTop={"12px"}
        >
          {title}
        </Typography>
        <Typography variant="subtitle1" textAlign="start" marginTop={"8px"} lineHeight="22px">
          {text}
        </Typography>
      </Stack>
    </>
  );
};

export default MentorLinkCard;
