import React from 'react'
import { Button, Grid, styled } from '@mui/material'
import Filter from "../components/privateRoute/userRoute/categoriespage/Filter"
import Demo from "../components/privateRoute/userRoute/categoriespage/Demo"
import Index from '../components/privateRoute/userRoute/categoriespage/Index'
const Categoriespage = () => {


  return (
    <div>
      <Index />
    </div>
  )
}

export default Categoriespage