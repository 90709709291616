import {
  Box,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Slide,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { useEffect } from "react";
import AvailableTime from "./AvailableTime";
import APIRequest from "../../../../utils/APIRequest";
import ConfigAPIURL from "../../../../config/ConfigAPIURL";
import { useState } from "react";
import BlueButton from "../BlueButton";
import HolidaysCalendar from "./HolidaysCalendar";
import { useForceUpdate } from "framer-motion";
import { useNavigate } from "react-router-dom";

// transition Upwards
function TransitionUp(props) {
  return <Slide {...props} direction="up" />;
}

const Availability = () => {
  const navigate = useNavigate();
  const [weeklySlots, setWeeklySlots] = useState({});
  const [id, setId] = useState();
  const [value, setValue] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [transition, setTransition] = React.useState(undefined);
  const [snackBar, setSnackBar] = useState({
    message: "",
  });

  const handleClick = (Transition) => () => {
    setTransition(() => Transition);
    handleSubmit().then((value) => {
      if (value?.data?.responseCode === 109) {
        setSnackBar({
          message: "Updated Successfully ",
        });
      } else
        setSnackBar({
          message: "Cannot update try again later ",
        });
    });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  // console.log(weeklySlots);

  useEffect(() => {
    fetchUSerSlots();
  }, []);

  const fetchUSerSlots = async () => {
    try {
      const response = await APIRequest.request(
        "GET",
        ConfigAPIURL.getUserSlots,
        ""
      );
      if (response?.data?.responseCode === 109) {
        // console.log(response?.data?.result);
        setWeeklySlots(response?.data?.result);
        setId(response?.data?.result._id);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const addSlots = async (data) => {
    //copy of weekly slots and update it api updation will be on submit
    const datas = [...weeklySlots.weeklySlots];
    datas[data.day].timeSlots.push({
      slot: {
        from: "12:00am",
        till: "12:00 pm",
      },
    });

    setWeeklySlots({
      ...weeklySlots,
      weeklySlots: datas,
    });
  };

  const deleteSlots = async (data) => {
    const slotsData = [...weeklySlots.weeklySlots];
    // deleting only one at a time
    slotsData[data.day].timeSlots.splice(data.ind, 1);
    // delete slotsData[data.day].timeSlots[data.ind];

    setWeeklySlots({
      ...weeklySlots,
      weeklySlots: slotsData,
    });
  };

  function tConvert(time) {
    // Check correct time format and split into components
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? "AM" : "PM"; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(""); // return adjusted time or original string
  }

  const slotsUpdate = async (data) => {
    const slotsData = [...weeklySlots.weeklySlots];
    const startDate = tConvert(data.startValue).toLowerCase();
    const endDate = tConvert(data.endValue).toLowerCase();
    //updates only in the selected index
    slotsData[data.day].timeSlots[data.ind] = {
      slot: {
        from: startDate,
        till: endDate,
      },
    };
    setWeeklySlots({
      ...weeklySlots,
      weeklySlots: slotsData,
    });
  };

  const setHolidays = async (data) => {
    const slotsData = [...weeklySlots.weeklySlots];
    //holiday will be set according to the checked value
    slotsData[data.day].isHoliday = data.checked;
    setWeeklySlots({
      ...weeklySlots,
      weeklySlots: slotsData,
    });
  };

  const handleSubmit = async () => {
    // return 1
    // converting r date to timestamp
    const englishTime = [];
    value.forEach((val) => {
      if (!val) return;
      //if its in milliseconds then push the whole as ms only
      if (typeof val === "number") {
        englishTime.push({ date: val });
      } //otherways push in milliseconds format
      else englishTime.push({ date: new Date(val.format()).getTime() });
    });
    try {
      const response = await APIRequest.request(
        "POST",
        ConfigAPIURL.userUpdateSlots,
        JSON.stringify({
          _id: id,
          weeklySlots: weeklySlots.weeklySlots,
          holidays: englishTime,
        })
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Stack
      className="stack__round"
      sx={{ py: 3, px: { xs: 0, sm: 2 }, gap: { xs: 0, md: 1 } }}
    >
      <Typography
        className="heading"
        fontWeight="600"
        fontSize="20px"
        lineHeight="32px"
      >
        Availability{" "}
      </Typography>
      <Typography
        className="sub__heading"
        component="h5"
        color="rgba(0, 0, 0, 0.66)"
        fontWeight="600"
        fontSize="16px"
        sx={{ mb: { xs: 0, md: 1 } }}
      >
        Set your day and time{" "}
      </Typography>
      <Stack
        py={1}
        sx={{
          border: { md: "0.742701px solid #D2D2D2", sm: "none" },
          borderRadius: { xs: "8px", md: "none" },
          px: { xs: 0, sm: 2 },
        }}
      >
        {weeklySlots.weeklySlots &&
          weeklySlots.weeklySlots.map((value) => {
            return (
              <Stack key={value?._id}>
                <AvailableTime
                  data={value}
                  day={value.day}
                  addSlots={addSlots}
                  deleteSlots={deleteSlots}
                  updateSlots={slotsUpdate}
                  setHolidays={setHolidays}
                />
                <Divider variant="middle" sx={{ my: 1.4 }} />
              </Stack>
            );
          })}
      </Stack>
      <Typography
        ps={3}
        variant="h3"
        fontWeight="600"
        fontSize="20px"
        lineHeight="32px"
        className="heading"
      >
        Holidays Calendar{" "}
        <span style={{ fontSize: "14px" }}>
          (Please specify unavailable days)
        </span>
      </Typography>
      <HolidaysCalendar
        data={weeklySlots.holidays}
        value={value}
        setValue={setValue}
      />
      <Box
        sx={{ mt: { xs: 2, md: 0 } }}
        onClick={handleClick(TransitionUp)}
        alignSelf="end"
      >
        <BlueButton text="Submit" size="md" />
      </Box>

      <Snackbar
        open={open}
        onClose={handleClose}
        TransitionComponent={transition}
        message={snackBar.message}
        key={transition ? transition.name : ""}
        autoHideDuration={3000}
      />
    </Stack>
  );
};

export default Availability;
