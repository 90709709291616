import { Box, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ConfigAPIURL from "../../../../config/ConfigAPIURL";
import APIRequest from "../../../../utils/APIRequest";
import CountUp from "react-countup";

const Profiles = ({ no, text, color }) => {
  return (
    <Stack
      direction="row"
      gap={1.5}
      mt={1.5}
      alignItems="center"
      sx={{ position: "relative" }}
    >
      <Stack
        width="29px"
        height="29px"
        sx={{ background: "#00275A" }}
        justifyContent="center"
        alignItems="center"
        borderRadius="100%"
      >
        <Typography
          // className="submit__button sub__heading"
          color="white"
          variant="subtitle1"
          // sx={{ fontSize: "18px" }}
          fontWeight="700"
          // className="sub__heading"
        >
          {no}
        </Typography>
      </Stack>
      <Typography
        color="black"
        variant="subtitle1"
        sx={{ fontSize: "16px" }}
        fontWeight="400"
        className="sub__heading"
      >
        {text}
      </Typography>
    </Stack>
  );
};

const CompletnessRatio = () => {
  const [userData, setUserData] = useState(null);
  const [totalDatas, setTotalDatas] = useState([]);
  const [total, setTotal] = useState(0);
  const [userSkills, setUserSkills] = useState([]);
  const userDatas = useSelector((state) => state.user.profileData);
  useEffect(() => {
    try {
      APIRequest.request(
        "PUT",
        ConfigAPIURL.UserProfileUpdate,
        JSON.stringify({ profileCompletion: total })
      );
    } catch (err) {
      console.log(err);
    }
  }, [total]);
  useEffect(() => {
    fetchUserAdditionalInfo();
    fetchAdditionalInfo();
  }, []);

  const fetchUserAdditionalInfo = async () => {
    try {
      const response = await APIRequest.request(
        "POST",
        ConfigAPIURL.userAdditionalInfo,
        JSON.stringify({
          userId: userDatas._id,
        })
      );
      if (response?.data?.responseCode === 109) {
        setUserData(response?.data?.profile[0]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchAdditionalInfo = async () => {
    const response = await APIRequest.request(
      "GET",
      ConfigAPIURL.userProfile,
      ""
    );
    if (response?.data?.responseCode === 109) {
      setUserSkills(response?.data?.profile?.skills);
      console.log(response?.data?.profile?.skills);
      // setUserData(response?.data?.profile);
    }
  };

  const totalPercentage = (marks) => {
    let total = 0;
    marks.forEach((element) => {
      total += element?.marks;
    });
    return total;
  };

  useEffect(() => {
    const percentage = [];
    if (!userData) return;
    percentage.push({
      title: "Core Skills",
      marks: userSkills?.length > 0 ? 10 : 0,
    });
    percentage.push({
      title: "About",
      marks: userData?.about ? 18 : 0,
    });
    percentage.push({
      title: "Achievements",
      marks: userData?.achievements?.length > 0 ? 18 : 0,
    });
    percentage.push({
      title: "Certificates",
      marks: userData?.certificates?.length > 0 ? 18 : 0,
    });
    percentage.push({
      title: "Education",
      marks: userData?.education?.length > 0 ? 18 : 0,
    });
    percentage.push({
      title: "Experience",
      marks: userData?.experience?.length > 0 ? 18 : 0,
    });
    setTotalDatas(percentage);
    setTotal(totalPercentage(percentage));
  }, [userData, userSkills]);

  console.log("this is total", total);
  console.log("this is Userdata", userData);
  return (
    <Stack className="rounded " sx={{ position: "relative" }} px={2} py={1}>
      <Stack
        py={1}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography className="heading" variant="subtitle1" fontWeight="700">
          Profile Completion
        </Typography>
        <Typography
          // className="heading"
          color="#00275A"
          variant="subtitle1"
          sx={{ fontSize: "18px" }}
          fontWeight="700"
        >
          <CountUp start={0} end={total ? total : "0"} duration={2.75} />%
        </Typography>
      </Stack>
      <Box
        sx={{
          // width: "1px",
          position: "absolute",
          height: "70%",
          borderLeft: "3px dashed #385392",
          // background: "",
          top: "73px",
          left: "30px",
        }}
      ></Box>
      {/* <Profiles no="1" text="Core Skills" color="#3053A4" />
      <Profiles no="3" text="Experience" color="#888989" /> */}
      {totalDatas &&
        totalDatas.length > 0 &&
        totalDatas.map((value, ind) => {
          // setTotal(value?.marks);
          return (
            <Profiles
              key={ind}
              no={ind + 1}
              text={value?.title}
              color={value?.marks ? "#2c72ff" : "#888989"}
            />
          );
        })}
    </Stack>
  );
};

export default CompletnessRatio;
