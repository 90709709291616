import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import APIRequest from "../../../utils/APIRequest";
import ConfigAPIURL from "../../../config/ConfigAPIURL";
import { allImages } from "../../../images";
import CloseIcon from "@mui/icons-material/Close";
import { Alert, Snackbar, useMediaQuery } from "@mui/material";

const BottomMenuLoggedInUser = ({ setIsOpen,userQuery,setUserQuery,initialButtons,setInitialButtons }) => {
  // const [userQuery, setUserQuery] = useState("");
  // const [initialButtons, setInitialButtons] = useState(true);
  const navigate = useNavigate();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snakbarValues, setSnakbarValues] = useState({
    status: false,
    severity: "",
    message: "",
  });

  const handleInputChange = (e) => {
    setUserQuery(e.target.value);
  };

  const getCategories = async (skills) => {
    try {
      const response = await APIRequest.request(
        "GET",
        ConfigAPIURL.getChatBotCategories
        // JSON.stringify({ category: skills })
      );
      if (response) {
        const receivedData = response?.data; // Assuming this is the data you want to pass
        return receivedData;
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleQuerySubmit = async () => {
    try {
      // const response = await getCategories();
      // const categories = response.categories;
      // for (const category of categories) {
      // if (userQuery.toLowerCase().includes(category.toLowerCase())) {
      //   navigate("/mentors", { state: { category: category } });
      navigate("/home/mentors/list", {
        state: { skill: userQuery.toLowerCase() },
      });
      //   }
      // }
      setSnakbarValues({
        status: true,
        severity: "error",
        message: "Sorry, no mentors found for your query.",
      });
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const notificationClose = (event) => {
    setSnakbarValues({ status: false, severity: "", message: "" });
  };

  const [currentImage, setCurrentImage] = useState(0);
  const images = [
    allImages.RoboGhostStraight,
    allImages.RoboGhostLeft,
    allImages.RoboGhostStraight,
    allImages.RoboGhostRight,
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prev) => (prev + 1) % images.length);
    }, 2000);

    return () => clearInterval(interval);
  }, [images.length]);

  const [userName, setUserName] = useState("");

  useEffect(() => {
    const localStorageData = localStorage.getItem("userDetails");

    if (localStorageData) {
      try {
        const userData = JSON.parse(localStorageData);
        setUserName(`${userData.fname} ${userData.lname}`);
      } catch (error) {
        console.error("Error parsing user data:", error);
      }
    }
  }, []);
  const isSmallScreen = useMediaQuery("(max-width:600px)");

  // console.table("this is local data", userName);

  return (
    <div
      style={{
        padding: "16px",
        margin: "16px",
        borderRadius: "8px",
        maxHeight: "600px",
        minHeight: "400px",
        overflowY: "auto",
        width:isSmallScreen ? '340px' : "400px",
      }}
    >
      <button
        onClick={() => {
          setInitialButtons(true)
          setUserQuery("")
          setIsOpen(false)}
        }
        style={{
          background: "none",
          border: "none",
          fontSize: "1.5rem",
          cursor: "pointer",
          marginBottom: "1rem",
          position: "absolute",
          top: "20px",
          right: "26px",
        }}
      >
        <CloseIcon />
      </button>
      <div
        style={{
          backgroundColor: "#4A5568",
          borderRadius: "50%",
          width: "80px",
          height: "80px",
          margin: "0 auto 1rem",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* <span style={{ color: "#FFA07A", fontSize: "2rem" }}>👁️</span> */}
        <img
          src={images[currentImage]}
          alt="Chat robot"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            transition: "opacity 0.5s ease-in-out",
          }}
        />
      </div>
      <h3
        style={{
          fontSize: "18px",
          fontWeight: "550",
          marginBottom: "8px",
          textAlign: "center",
          color: "#000",
        }}
      >
        Hi {userName}, Hope you are doing great today! I am Luna, your AI
        assistant, Do you want me to search for a mentor in Aviation sector?
      </h3>
      {initialButtons && (
        <div>
          <button
            style={{
              display: "block",
              width: "100%",
              padding: "10px",
              marginTop: "10px",
              backgroundColor: "#f0f0f0",
              border: "none",
              borderRadius: "5px",
              fontSize: "16px",
              cursor: "pointer",
            }}
            onClick={() => {
              navigate("/home/mentors/list", {
                state: { skill: "Aviation" },
              });
            }}
          >
            Yes
          </button>
          <button
            style={{
              display: "block",
              width: "100%",
              padding: "10px",
              marginTop: "10px",
              backgroundColor: "#f0f0f0",
              border: "none",
              borderRadius: "5px",
              fontSize: "16px",
              cursor: "pointer",
            }}
            onClick={() => setInitialButtons(false)}
          >
            No
          </button>
        </div>
      )}
      {!initialButtons && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <textarea
            value={userQuery}
            onChange={handleInputChange}
            placeholder="Type your issues here..."
            draggable="false"
            style={{
              padding: "16px",
              border: "2px solid #D1D5DB",
              backgroundColor: "#fff",
              borderRadius: "8px",
              width: "100%",
              marginBottom: "16px",
              resize: "none",
              boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
              outline: "none",
              focus: { boxShadow: "0 0 0 3px #2563EB" },
            }}
          />
          <button
            style={{
              backgroundColor: "#00275A",
              color: "#fff",
              padding: "8px 16px",
              borderRadius: "8px",
              width: "120px",
              textAlign: "center",
            }}
            onClick={handleQuerySubmit}
          >
            Send
          </button>
        </div>
      )}
      {/* <Snackbar
                          open={snackbarOpen}
                          autoHideDuration={2000}
                          onClose={() => setSnackbarOpen(false)}
                          message="Password changed successfully. Redirecting to login page."
                        /> */}
      <Snackbar
        open={snakbarValues.status}
        onClose={notificationClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={3000}
      >
        <Alert severity={snakbarValues.severity}>{snakbarValues.message}</Alert>
      </Snackbar>
    </div>
  );
};

export default BottomMenuLoggedInUser;
