import React, { useEffect, useState } from "react";
import {
  AppBar,
  Button,
  // Container,
  CssBaseline,
  Divider,
  FormControl,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import "../Home/Home.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { allImages } from "../../../../images";
import SearchIcon from "@mui/icons-material/Search";
import { Container } from "react-bootstrap";
import SearchResultsBar from "./SearchResultsBar";
import { motion } from "framer-motion";
import LocalStorage from "../../../../config/LocalStorage";
import APIRequest from "../../../../utils/APIRequest";
import ConfigAPIURL from "../../../../config/ConfigAPIURL";
import { useSelector } from "react-redux";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import BlueButton from "../BlueButton";
import PopoverComponent from "../../../popover/Index";
import ProfileDetails from "./ProfileDetails";

const Navbar = ({ data }) => {
  const userData = useSelector((state) => state.user.profileData);
  const location = useLocation();
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [navcollapse, setNavcollapse] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [showPopup, setShowpopup] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const isSmallScreenTwo = useMediaQuery("(max-width:1280px)");
  // Check if the current route is /booking/id
  const isBookingRoute = location.pathname.startsWith("/booking");
  const isProfileRoute = location.pathname.startsWith("/profile");
  const isNotificationRoute = location.pathname.startsWith("/notification");
  const isChatRoute = location.pathname.startsWith("/chats");

  useEffect(() => {
    //fetching according to the search
    fetChUsers(searchTerm);
  }, [searchTerm]);

  const profileNavigate = () => {
    navigate("/profile");
    setShowpopup(!showPopup);
  };
  const settingsNavigate = () => {
    navigate("/settings/availability");
    setShowpopup(!showPopup);
  };
  const fetChUsers = async (term) => {
    if (term === "") return setSearchResults([]);
    try {
      const response = await APIRequest.request(
        "GET",
        ConfigAPIURL.userResults + "?" + "keyword=" + term,
        ""
      );
      if (response?.data?.responseCode === 109) {
        setSearchResults(response?.data?.result);
        console.log(response?.data?.result);
      }
    } catch {}
  };
  // window.onclick = () => {
  //   if (!showPopup) setShowpopup(!showPopup);
  // };

  const userLogout = async () => {
    try {
      const response = await APIRequest.request(
        "GET",
        ConfigAPIURL.userLogout,
        ""
      );
      if (response.data.responseCode === 109) {
        window.location.reload();
      }
    } catch (err) {
      console.log(err);
    }
  };

  //logout popup by custom alert
  const submit = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <Stack alignItems="center" sx={{}}>
              <img
                style={{ objectFit: "cover", width: "179px" }}
                src={allImages.logoutIllustration}
                alt="logout"
              />
            </Stack>
            <Stack alignItems="center" mt={1}>
              <Typography
                variant="subtitle1"
                sx={{
                  fontWeight: 600,
                  color: "black",
                  lineHeight: "27px",
                  fontSize: "18px",
                }}
              >
                Sign out
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{ color: "black", lineHeight: "27px" }}
                className="heading"
              >
                Are you sure do you want to logout?
              </Typography>
              <Stack flexDirection="row" alignSelf="end" mt={2}>
                <FormControl tabIndex="1">
                  <button
                    tabIndex="2"
                    type="submit"
                    style={{
                      fontSize: "16px",
                      lineHeight: "22px",
                      color: "#868686",
                    }}
                    onClick={onClose}
                  >
                    Cancel
                  </button>
                  <button
                    tabIndex="3"
                    onKeyDown={() => {
                      console.log("Cancel");
                    }}
                    style={{
                      fontSize: "16px",
                      lineHeight: "22px",
                      color: "black",
                    }}
                    onClick={() => {
                      userLogout();
                      onClose();
                    }}
                  >
                    Logout
                  </button>
                </FormControl>
              </Stack>
            </Stack>
            {/* <div style={style}>
              <button className="navbar__logout--button" onClick={onClose}>
                No
              </button>
              <button
                onClick={() => {
                  userLogout();
                  onClose();
                }}
              >
                Yes
              </button>
            </div> */}
          </div>
        );
      },
    });
  };

  const style = {
    display: "flex",
  };

  const navbarNavigation = [
    {
      title: "Home",
      path: "/home",
      src: allImages.homeIcon,
      Permissions: false,
    },
    {
      title: "Categories",
      path: "/mentor",
      src: allImages.categoriesIcon,
      Permissions: false,
    },
    {
      title: "Chats",
      path: "/chats",
      src: allImages.categoriesIcon,
      Permissions: false,
    },
    {
      title: "Sessions",
      path: "/sessions",
      src: allImages.map,
      Permissions: userData?.mentor ? true : false,
    },
    {
      title: "Coupons",
      path: "/coupons",
      src: allImages.couponsIcon,
      Permissions: userData?.mentor ? true : false,
    },
    {
      title: "Notification",
      path: "/notification",
      src: allImages.bell,
      Permissions: false,
    },
    {
      title: "Bookings",
      path: "/manage",
      src: allImages.manageIcon,
      Permissions: false,
    },
  ];

  return (
    <>
      <Stack height="55px">
        <AppBar
          className="appbar"
          sx={{ display: { xs: "none", md: "block" } }}
          height="55px"
        >
          <CssBaseline />
          {/* <Container> */}
          <Stack style={{ marginLeft: "20px", marginRight: "20px" }}>
            <Stack
              height="55px"
              padding="1rem 0"
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Stack direction="row" alignItems="center">
                {/* <Stack direction="row" gap={1} alignItems="center"> */}
                <Box
                  onClick={() => navigate("/home")}
                  sx={{
                    borderRadius: "100%",
                    cursor: "pointer",
                  }}
                >
                  <img
                    style={{
                      objectFit: "cover",
                      // height: "50px",
                      width: "120px",
                      height: "55px",
                      width: !isSmallScreen ? "100%" : "120px",
                      padding: !isSmallScreenTwo ? "12px" : "16px",
                    }}
                    src={allImages?.logoicon}
                    alt="logo"
                  />
                </Box>
                {/* </Stack> */}
                <Box position="relative">
                  <Stack
                    alignItems="center"
                    direction="row"
                    // justifyContent="center"
                    className="search__bar"
                    // onMouseEnter={{ width: "380px" }}
                    style={{
                      // width: searchTerm && "380px",
                      borderRadius: searchTerm && "10px",
                      padding: searchTerm && "0 1rem",
                    }}
                  >
                    {!searchTerm ? (
                      <img
                        src={allImages.searchIcon}
                        alt=""
                        className="search__icon"
                      />
                    ) : (
                      <img
                        src={allImages.searchClose}
                        alt=""
                        className="search__icon"
                        onClick={() => setSearchTerm("")}
                      />
                    )}
                    <input
                      type="text"
                      style={{
                        display: searchTerm && "flex",
                        fontSize: "16px",
                      }}
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      className="navbar__input"
                      placeholder="Search for mentor"
                    />
                  </Stack>

                  {/* search results appearing if there is data */}
                  {searchResults.length > 0 && (
                    <Stack
                      width="100%"
                      maxHeight="300px"
                      position="absolute"
                      pb={2}
                      top={65}
                      borderRadius="2px"
                      border="0.742701px solid #D2D2D2"
                      className="search__results"
                      sx={{
                        background: "white",
                        overflowY: "scroll",
                        overflowX: "hidden",
                        scrollbarWidth: "auto",
                      }}
                    >
                      <SearchResultsBar
                        data={searchResults}
                        setSearchTerm={setSearchTerm}
                      />
                    </Stack>
                  )}
                </Box>
              </Stack>

              {/* rendering navbar */}
              <Stack flexDirection={"row"} gap={4} alignItems="center">
                {navbarNavigation.map((val, ind) => {
                  return (
                    <>
                      <Link
                        to={val?.path}
                        className="navbar__active navbar__logo"
                        style={{ display: val?.Permissions && "none" }}
                      >
                        <Box
                          display="flex"
                          flexDirection="column"
                          alignItems="center"
                        >
                          {/* <Box sx={{ width: "30px", height: "30px" }}>
                            {" "}
                          </Box> */}
                          {/* <img src={val?.src} alt="" />{" "} */}
                          <Typography sx={{ color: "white", mb: 0.9 }}>
                            {val?.title}
                          </Typography>
                          {location.pathname === val.path ? (
                            <Box className="line" />
                          ) : (
                            <Box
                              className="line"
                              style={{ background: "transparent" }}
                            />
                          )}
                        </Box>
                      </Link>
                    </>
                  );
                })}
                <Stack position="relative" alignSelf="center">
                  <Box
                    className="profile roundedprofile"
                    sx={{ width: "40px", height: "40px", cursor: "pointer" }}
                    onClick={(e) => {
                      setAnchorEl(e.currentTarget);
                      setShowpopup(!showPopup);
                    }}
                  >
                    <img
                      style={{ objectFit: "cover", cursor: "pointer" }}
                      src={
                        userData?.profileImage
                          ? userData?.profileImage
                          : allImages.avatar
                      }
                      alt=""
                    />
                  </Box>
                  <PopoverComponent
                    anchorEl={anchorEl}
                    setAnchorEl={setAnchorEl}
                  >
                    <ProfileDetails
                      userData={userData}
                      handleClick={submit}
                      resetAncherEl={() => setAnchorEl(null)}
                    />
                  </PopoverComponent>
                  {false && (
                    <>
                      <Stack
                        onBlur={() => setShowpopup(!showPopup)}
                        component={motion.div}
                        animate={{}}
                        className="profile__float"
                        // px={1}
                        py={2}
                        sx={{ height: "auto" }}
                      >
                        <Stack alignItems="center" gap={1}>
                          <Box width="80px">
                            <img
                              style={{ objectFit: "cover" }}
                              src={
                                userData?.profileImage
                                  ? userData?.profileImage
                                  : allImages.avatar
                              }
                              alt=""
                            />
                          </Box>
                          <Stack color="black">
                            <Typography
                              variant="subtitle1"
                              fontSize="18px"
                              fontWeight="600"
                              textTransform="capitalize"
                            >
                              {userData?.fname}
                            </Typography>
                          </Stack>
                        </Stack>

                        <button
                          className="profile__btn"
                          onClick={profileNavigate}
                        >
                          View Profile
                        </button>
                        {
                          <Stack display={userData?.mentor ? "block" : "none"}>
                            <Divider
                              variant="middle"
                              sx={{ color: "black", m: 0, my: "5px" }}
                            />
                            <button
                              className="link__profile"
                              onClick={settingsNavigate}
                              sx={{ cursor: "pointer" }}
                            >
                              <Typography>Settings & Availability</Typography>
                            </button>
                          </Stack>
                        }
                        <Divider
                          variant="middle"
                          sx={{ color: "black", m: 0, my: "5px" }}
                        />

                        <button className="link__profile" onClick={submit}>
                          <Typography color="black">Sign Out</Typography>
                        </button>

                        <></>
                      </Stack>
                    </>
                  )}
                </Stack>
              </Stack>
              {/* ending navbar */}
              {/* <Stack flexDirection={"row"} gap={4}>
                <Link to="/home" className="navbar__active navbar__logo">
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <Box sx={{ width: "30px", height: "30px" }}>
                      {" "}
                      <img src={allImages.homeIcon} alt="" />{" "}
                    </Box>
                    <Typography sx={{ color: "white" }}>Home</Typography>
                    {location.pathname === "/home" ? (
                      <Box className="line" />
                    ) : null}
                  </Box>
                </Link>
                <Link to="/mentor" className="navbar__logo">
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <Box sx={{ width: "30px", height: "30px" }}>
                      {" "}
                      <img src={allImages.categoriesIcon} alt="" />{" "}
                    </Box>
                    <Typography sx={{ color: "white" }}>Categories</Typography>
                    {location.pathname === "/mentor" ? (
                      <Box className="line" />
                    ) : null}
                  </Box>
                </Link>
                {!userData?.mentor && (
                  <Link to="/sessions" className="navbar__logo">
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                    >
                      <Box sx={{ width: "30px", height: "30px" }}>
                        <img src={allImages.map} alt="" />{" "}
                      </Box>
                      <Typography sx={{ color: "white" }}>Sessions</Typography>
                      {location.pathname === "/sessions" ? (
                        <Box className="line" />
                      ) : null}
                    </Box>
                  </Link>
                )}
                {!userData?.mentor && (
                  <Link to="/coupons" className="navbar__logo">
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                    >
                      <Box sx={{ width: "30px", height: "30px" }}>
                        <img src={allImages.couponsIcon} alt="" />
                      </Box>
                      <Typography sx={{ color: "white" }}>Coupons</Typography>
                      {location.pathname === "/coupons" ? (
                        <Box className="line" />
                      ) : null}
                    </Box>
                  </Link>
                )}
                <Link to="/notification" className="navbar__logo">
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <Box sx={{ width: "30px", height: "30px" }}>
                      {" "}
                      <img src={allImages.bell} alt="" />{" "}
                    </Box>
                    <Typography sx={{ color: "white" }}>
                      Notification
                    </Typography>
                    {location.pathname === "/notification" ? (
                      <Box className="line" />
                    ) : null}
                  </Box>
                </Link>
                <Link to="/manage" className="navbar__logo">
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <Box sx={{ width: "30px", height: "30px" }}>
                      {" "}
                      <img src={allImages.manageIcon} alt="" />{" "}
                    </Box>
                    <Typography sx={{ color: "white" }}>Manage</Typography>
                    {location.pathname === "/manage" ? (
                      <Box className="line" />
                    ) : null}
                  </Box>
                </Link>
                */}

              {/* </Stack>  */}
            </Stack>
          </Stack>
          {/* </Container> */}
        </AppBar>
        <Box
          className="navbar__menu"
          p={2}
          sx={{
            display: { md: "none", xs: "flex" },
            flexDirection: "column",
            // alignItems: "flex-end",
            borderBottom:'none',
          }}
        >
          <Stack direction="row" alignItems="center" justifyContent={'space-between'}>
          <Box
            onClick={() => navigate("/home")}
            sx={{
              borderRadius: "100%",
              cursor: "pointer",
            }}
          >
            <img
              style={{
                objectFit: "cover",
                // height: "50px",
                width: "100%",
                height: "55px",
                // width: !isSmallScreen ? "100%" : "120px",
                padding: !isSmallScreenTwo ? "12px" : "16px",
              }}
              src={allImages?.logoNew}
              alt="logo"
            />
          </Box>
          <Stack direction="row" alignItems="center">
            {!isBookingRoute && (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                className="profile roundedprofile"
                sx={{
                  width: "35px",
                  height: "35px",
                  cursor: "pointer",
                  position: "relative",
                  // zIndex: "3000000000000000000000",
                }}
                onClick={() => navigate("/profile")}
              >
                <img
                  src={
                    userData?.profileImage
                      ? userData?.profileImage
                      : allImages.avatar
                  }
                  alt=""
                />
              </Box>
            )}
            <Button onClick={() => setNavcollapse(!navcollapse)}>
              {navcollapse ? (
                <img src={allImages.menuWhite} alt="" className="menu-icon" />
              ) : (
                <img
                  src={allImages.menuicon}
                  alt="menu-icon"
                  className="menu-icon"
                />
              )}
            </Button>
            </Stack>
          </Stack>
          {!isBookingRoute && !isProfileRoute && !isNotificationRoute && !isChatRoute && (
            <Box mt={1} position="relative"  width="100%" height="100%" style={{borderBottom:'none'}}>
              <input
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="navbar__input"
                placeholder="Search for mentor"
              />
              {!isSmallScreen && (
                <img
                  src={allImages.searchicon2}
                  alt=""
                  className="search__icon search__small"
                />
              )}
            </Box>
          )}
          {searchResults.length > 0 && (
            <Stack
              width="93%"
              margin="auto"
              height="200px"
              // left={4}
              position="absolute"
              zIndex="999999999999999"
              top={145}
              borderRadius="2px"
              border="0.742701px solid #D2D2D2"
              className="search__results"
              sx={{
                background: "white",
                overflowY: "scroll",
                overflowX: "hidden",
                scrollbarWidth: "auto",
              }}
            >
              <SearchResultsBar
                data={searchResults}
                setSearchTerm={setSearchTerm}
              />
            </Stack>
          )}
          {/* <hr /> */}
        </Box>

        {navcollapse && (
          <Stack
            initial={{ height: 0 }}
            animate={{ height: "100vh" }}
            transition={{
              type: "tween",
              duration: 0.7,
            }}
            exit={{ width: 0 }}
            component={motion.div}
            className="navbar__show"
            position="fixed"
            sx={{
              backgroundColor: "black",
              zIndex: "1000000000",
              top: "0",
              bottom: "0",
              width: "100%",
              // height: "100%",
            }}
          >
            <Stack
              sx={{ width: "100%", height: "100%" }}
              gap={4}
              justifyContent="center"
              alignItems={"center"}
            >
              <Stack width={"130px"}>
                <Link to="/home">
                  <Box
                    onClick={() => setNavcollapse(!navcollapse)}
                    display="flex"
                    flexDirection="row"
                    gap={2}
                    alignItems="flex-end"
                  >
                    <Box sx={{ width: "30px", height: "30px" }}>
                      <img src={allImages.homeIcon} alt="" />
                    </Box>
                    <Typography sx={{ color: "white" }}>Home</Typography>
                  </Box>
                </Link>
              </Stack>
              <Stack width={"130px"}>
                <Link to="/chats">
                  <Box
                    onClick={() => setNavcollapse(!navcollapse)}
                    display="flex"
                    flexDirection="row"
                    gap={2}
                    alignItems="flex-end"
                  >
                    <Box sx={{ width: "30px", height: "30px" }}>
                      <img src={allImages.chatIcon} alt="" />
                    </Box>
                    <Typography sx={{ color: "white" }}>Chats</Typography>
                  </Box>
                </Link>
              </Stack>
              <Stack width={"130px"}>
                <Link to="/mentor">
                  <Box
                    onClick={() => setNavcollapse(!navcollapse)}
                    display="flex"
                    flexDirection="row"
                    gap={2}
                    alignItems="flex-end"
                  >
                    <Box sx={{ width: "30px", height: "30px" }}>
                      <img src={allImages.categoriesIcon} alt="" />{" "}
                    </Box>
                    <Typography sx={{ color: "white" }}>Categories</Typography>
                  </Box>
                </Link>
              </Stack>
              {!userData?.mentor && (
                <Stack width={"130px"}>
                  <Link to="/sessions">
                    <Box
                      onClick={() => setNavcollapse(!navcollapse)}
                      display="flex"
                      flexDirection="row"
                      gap={2}
                      alignItems="flex-end"
                    >
                      <Box sx={{ width: "30px", height: "30px" }}>
                        {" "}
                        <img src={allImages.map} alt="" />{" "}
                      </Box>
                      <Typography sx={{ color: "white" }}>Sessions</Typography>
                    </Box>
                  </Link>
                </Stack>
              )}
              {!userData?.mentor && (
                <Stack width={"130px"}>
                  <Link to="/coupons">
                    <Box
                      onClick={() => setNavcollapse(!navcollapse)}
                      display="flex"
                      flexDirection="row"
                      gap={2}
                      alignItems="flex-end"
                    >
                      <Box sx={{ width: "30px", height: "30px" }}>
                        {" "}
                        <img src={allImages.couponsIcon} alt="" />{" "}
                      </Box>
                      <Typography sx={{ color: "white" }}>Coupons</Typography>
                    </Box>
                  </Link>
                </Stack>
              )}
              <Stack width={"130px"}>
                <Link to="/notification">
                  <Box
                    onClick={() => setNavcollapse(!navcollapse)}
                    display="flex"
                    flexDirection="row"
                    gap={2}
                    alignItems="flex-end"
                  >
                    <Box sx={{ width: "30px", height: "30px" }}>
                      {" "}
                      <img src={allImages.bell} alt="" />{" "}
                    </Box>
                    <Typography sx={{ color: "white" }}>
                      Notification
                    </Typography>
                  </Box>
                </Link>
              </Stack>
              <Stack width={"130px"}>
                <Link to="/manage">
                  <Box
                    onClick={() => setNavcollapse(!navcollapse)}
                    display="flex"
                    flexDirection="row"
                    gap={2}
                    alignItems="flex-end"
                  >
                    <Box sx={{ width: "30px", height: "30px" }}>
                      {" "}
                      <img src={allImages.manageIcon} alt="" />{" "}
                    </Box>
                    <Typography sx={{ color: "white" }}>Bookings</Typography>
                  </Box>
                </Link>
              </Stack>
              {userData?.mentor && (
                <Stack width={"130px"}>
                  <Link to="/settings/availability">
                    <Box
                      onClick={() => setNavcollapse(!navcollapse)}
                      display="flex"
                      flexDirection="row"
                      gap={2}
                      alignItems="flex-end"
                    >
                      <Box sx={{ width: "30px", height: "30px" }}>
                        {" "}
                        <img src={allImages.map} alt="" />{" "}
                      </Box>
                      <Typography sx={{ color: "white" }}>
                        Availability
                      </Typography>
                    </Box>
                  </Link>
                </Stack>
              )}
              <Stack width={"130px"}>
                <Box
                  onClick={() => {
                    setNavcollapse(!navcollapse);
                    userLogout();
                  }}
                  display="flex"
                  flexDirection="row"
                  gap={2}
                  alignItems="flex-end"
                >
                  <Box>
                    {" "}
                    <img
                      style={{ width: "24px", height: "24px" }}
                      src={allImages.logout}
                      alt=""
                    />{" "}
                  </Box>
                  <Typography sx={{ color: "white" }}>Logout</Typography>
                </Box>
              </Stack>
            </Stack>
          </Stack>
        )}
      </Stack>
    </>
  );
};

export default Navbar;
