import { Box, Stack } from "@mui/material";
import { logDOM } from "@testing-library/react";
import React, { useState } from "react";
import { useEffect } from "react";
import TimePicker from "react-time-picker";
import { allImages } from "../../../../images";
import uuid from "react-uuid";

const Timings = ({ data, deleteSlots, ind, selected, day, updateSlots }) => {
  // (data);
  // const converttimeToHourFormatstartDate = (e) => {
  //   const time = e.slice(3, 5) !== "00" ? e.slice(0, 3) + "00" : null;
  //   setStartValue(time);
  //

  //   //
  // };
  //function to convert 12 to 24 hr format
  function get24hTime(str) {
    str = String(str).toLowerCase().replace(/\s/g, "");
    var has_am = str.indexOf("am") >= 0;
    var has_pm = str.indexOf("pm") >= 0;
    // first strip off the am/pm, leave it either hour or hour:minute
    str = str.replace("am", "").replace("pm", "");
    // if hour, convert to hour:00
    if (str.indexOf(":") < 0) str = str + ":00";
    // now it's hour:minute
    // we add am/pm back if striped out before
    if (has_am) str += " am";
    if (has_pm) str += " pm";
    // now its either hour:minute, or hour:minute am/pm
    // put it in a date object, it will convert to 24 hours format for us
    var d = new Date("1/1/2011 " + str);
    // make hours and minutes double digits
    var doubleDigits = function (n) {
      return parseInt(n) < 10 ? "0" + n : String(n);
    };
    return doubleDigits(d.getHours()) + ":" + doubleDigits(d.getMinutes());
  }
  //---values are in 24 hrs formats ---
  const [startValue, setStartValue] = useState(get24hTime(data?.slot?.from));
  const [endValue, setEndValue] = useState(get24hTime(data?.slot?.till));
  if (!data) return;

  return (
    <Stack
      sx={{ gap: { xs: 0.5, md: 3 }, flexDirection: { xs: "row" } }}
      alignItems="center"
    >
      <TimePicker
        // amPmAriaLabel="Select AM/PM"
        onChange={(e) => {
          setStartValue(e);
          updateSlots({
            day,
            ind,
            startValue: e,
            endValue,
          });
        }}
        clockIcon="null"
        format="hh:mm a"
        disableClock={true}
        value={startValue}
        className="time__picker"
        clockClassName="time__picker"
      />
      <TimePicker
        onChange={(e) => {
          setEndValue(e);
          updateSlots({
            day,
            ind,
            endValue: e,
            startValue,
          });
        }}
        format="hh:mm a"
        clockIcon="null"
        disableClock={true}
        // disableClock={false}
        value={endValue}
        className="time__picker"
        clockClassName="time__picker"
      />

      <Box
        height="20px"
        width="20px"
        onClick={() =>
          deleteSlots({
            ind,
            selected,
            day,
          })
        }
      >
        <img
          style={{ cursor: "pointer" }}
          src={allImages.deleteButton}
          alt="delete"
        />
      </Box>
    </Stack>
  );
};

export default Timings;
