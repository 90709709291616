import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Container, Grid, Modal, Stack } from "@mui/material";
import ProfileDetails from "./ProfileDetails";
import { useState } from "react";
import { Container as Contain } from "react-bootstrap";
import ContactDetails from "./ContactDetails";
import AcademicDetails from "./AcademicDetails";
import ProffesionalDetails from "./ProffesionalDetails";
import { useEffect } from "react";
import APIRequest from "../../../../utils/APIRequest";
import ConfigAPIURL from "../../../../config/ConfigAPIURL";
import { useForm } from "react-hook-form";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userDetails as userUpdate } from "../Redux/UserSlice";
import BlueButton from "../BlueButton";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { allImages } from "../../../../images";

const steps = [
  "Personal Details",
  "Contact Details",
  "Academic Details",
  "Professional Experience",
];

export default function HorizontalLinearStepper() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  console.log("this is from upodate page,,", location.state);

  const userData = useSelector((state) => state.user.profileData);

  const { register, handleSubmit, reset } = useForm();
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const [userProfile, setUserProfile] = useState(null);
  const [data, setData] = useState(null);

  const [userDetails, setUserDetails] = useState({
    country: "",
    city: "",
    state: "",
    qualification: "",
    specialization: "",
    category: "",
    subCategory: "",
    otherCategory: "",
    industrySpecialisation: "",
    otherSubSector: "",
    functional: "",
    technologyVertical: "",
    profileImg: null,
    skills: [],
  });

  useEffect(() => {
    fetchUserProfile();
  }, []);

  const fetchUserProfile = async () => {
    try {
      const response = await APIRequest.request(
        "GET",
        ConfigAPIURL.userProfile,
        ""
      );
      if (response?.data?.responseCode === 109) {
        setUserProfile(response?.data?.profile);
        setUserDetails({
          country: response?.data?.profile?.address?.country,
          state: response?.data?.profile?.address?.state,
          city: response?.data?.profile?.address?.city,
          category: response?.data?.profile?.category,
          subCategory: response?.data?.profile?.subCategory,
          technologyVertical: response?.data?.profile?.technologyVertical,
          profileImg: response?.data?.profile?.profileImage,
          skills: response?.data?.profile?.skills,
          education: response?.data?.profile?.education,
        });
      }
    } catch {}
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleProfileSubmission = async (e) => {
    try {
      const response = await APIRequest.request(
        "PUT",
        ConfigAPIURL.UserProfileUpdate,
        JSON.stringify({
          fname: e.fname,
          lname: e.lname,
          gender: e.gender,
          designation: e.designation,
          organization: e.organization,
          email: e.email,
          address: {
            country: userDetails.country,
            state: userDetails.state,
            city: userDetails.city,
          },
          education: {
            specialization: e.specialization,
            highestQualification: e.highestQualification,
          },
          professionalExperience: {
            specializationArea: e.specialization,
            functionalArea: e.functionalArea,
          },
          category:
            userDetails.category !== "Others"
              ? userDetails.category
              : userDetails?.otherCategory,
          subCategory:
            userDetails.subCategory !== "Others"
              ? userDetails.subCategory
              : userDetails?.otherSubSector,
          technologyVertical: userDetails.technologyVertical,
          profileImage: userDetails?.profileImg,
          skills: userDetails.skills,
        })
      );
      if (response?.data?.responseCode === 109) {
        console.log(userData, "this is the user data");
        dispatch(
          userUpdate({
            ...userData,
            profileImage: userDetails?.profileImg,
            fname: e.fname,
            lname: e.lname,
            email: e.email,
          })
        );
        const fetchUSerSlots = async () => {
          try {
            const response = await APIRequest.request(
              "GET",
              ConfigAPIURL.getUserSlots,
              ""
            );
          } catch (err) {
            console.log(err);
          }
        };
        fetchUSerSlots();
        setIsModalOpen(true); // Open the modal instead of navigating
        // navigate("/home");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    if (location?.state?.skill) {
      navigate("/home/mentors/list", {
        state: { skill: location?.state.skill },
      });
    } else {
      navigate("/home");
    }
  };

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  // const handleReset = () => {
  //   setActiveStep(0);
  // };

  // Define a function to reset the fields
  const handleReset = () => {
    reset({
      fname: "",
      lname: "",
      gender: "",
      designation: "",
      organization: "",
    });
  };
  const localdata = JSON.parse(localStorage.getItem("userDetails"));
  console.log("this is data localdata", localdata);
  return (
    <Stack
      width="100%"
      // height="100vh"
      position="relative"
      style={{ backgroundColor: "#F1F6FF" }}
    >
      <Grid container style={{ justifyContent: "space-between" }}>
        <Grid item sx={{ display: { xs: "none", md: "block" } }} md={4}>
          <Stack className="sticky__div" style={{ top: 0 }}>
            <img
              style={{ height: "100vh", objectFit: "cover" }}
              src={allImages.ProfileComplete}
              alt="image"
            />
          </Stack>
        </Grid>

        {/* <Grid item xs={12} md={8} lg={7}>
          <Container sx={{ mt: { xs: 9, md: 6 } }}>
            <Box sx={{ width: "100%" }}>
              <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                  const stepProps = {};
                  const labelProps = {};
                  if (isStepSkipped(index)) {
                    stepProps.completed = false;
                  }
                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
              {activeStep === steps.length ? (
                <React.Fragment>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: { lg: "80%", md: "65%" },
                      margin: " 2.4rem auto",
                      px: 2.5,
                      py: 4,
                      border: "1.62742px solid #EEEEEE",
                      borderRadius: "1px",
                    }}
                  >
                    <Stack>
                      <Typography>
                        All steps are completed Do you want to continue{" "}
                      </Typography>
                    </Stack>
                    <Stack direction="row" gap={2} mt={3}>
                      <Box
                        sx={{ display: "flex", flexDirection: "row", pt: 2 }}
                      >
                        <Box sx={{ flex: "1 1 auto" }} />
                        <Button onClick={handleReset}>Reset</Button>
                      </Box>
                      <Box
                        sx={{ display: "flex", flexDirection: "row", pt: 2 }}
                      >
                        <Box sx={{ flex: "1 1 auto" }} />
                        <Button
                          variant="contained"
                          onClick={handleSubmit(handleProfileSubmission)}
                        >
                          Submit
                        </Button>
                      </Box>
                    </Stack>
                  </Box>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: { xs: "100%", md: "70%", lg: "52%" },
                      margin: " 2.4rem auto",
                      minHeight: "70vh",
                      px: 2.5,
                      py: 2,
                      // mt: 2,
                      border: "1.62742px solid #EEEEEE",
                      borderRadius: "15px",
                      background: "white",
                      justifyContent: "space-between",
                    }}
                  >
                    <Stack>
                      <Button
                        variant="text"
                        sx={{
                          "&:hover": { backgroundColor: "transparent" },
                          color: "black",
                          alignSelf: "end",
                        }}
                        onClick={() => navigate("/home")}
                      >
                        Skip
                      </Button>
                      {activeStep === 0 && (
                        <ProfileDetails
                          register={register}
                          userProfile={userProfile}
                          userDetails={userDetails}
                          setUserDetails={setUserDetails}
                          localdata={localdata}
                        />
                      )}
                      {activeStep === 1 && (
                        <ContactDetails
                          register={register}
                          userProfile={userProfile}
                          userDetails={userDetails}
                          setUserDetails={setUserDetails}
                        />
                      )}
                      {activeStep === 2 && (
                        <AcademicDetails
                          register={register}
                          userProfile={userProfile}
                        />
                      )}
                      {activeStep === 3 && (
                        <ProffesionalDetails
                          userDetails={userDetails}
                          setUserDetails={setUserDetails}
                          register={register}
                          userProfile={userProfile}
                        />
                      )}
                    </Stack>
                    <Stack direction="row" mt={3}>
                      {activeStep !== 0 && (
                        <Button
                          color="inherit"
                          disabled={activeStep === 0}
                          onClick={handleBack}
                          sx={{
                            mr: 1,
                            fontFamily: "inherit",
                            "&:hover": {
                              background: "transparent",
                            },
                          }}
                        >
                          <ArrowBackIosNewIcon style={{ fontSize: "14px" }} />
                          <ArrowBackIosNewIcon style={{ fontSize: "14px" }} />
                          Previous
                        </Button>
                      )}
                      <Box sx={{ flex: "1 1 auto" }} />
                     
                      <Stack
                        onClick={
                          activeStep === steps.length - 1
                            ? handleSubmit(handleProfileSubmission)
                            : handleNext
                        }
                      >
                        <BlueButton
                          text={
                            activeStep === steps.length - 1 ? "Finish" : "Next"
                          }
                        />
                      </Stack>
                    </Stack>
                  </Box>
                </React.Fragment>
              )}
            </Box>
          </Container>
        </Grid> */}

        <Grid item xs={12} md={8} lg={8}>
          {/* <Container sx={{ mt: { xs: 9, md: 6 } }}> */}
          <Box sx={{ width: "100%" }}>
            <React.Fragment>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: { lg: "100%%", md: "100%" },
                  // margin: "2.4rem auto",
                  px: 4.5,
                  py: 4,
                  border: "1.62742px solid #EEEEEE",
                  borderRadius: "1px",
                  background: "white",
                }}
              >
                <Stack>
                  <ProfileDetails
                    register={register}
                    userProfile={userProfile}
                    userDetails={userDetails}
                    setUserDetails={setUserDetails}
                    localdata={localdata}
                  />
                  <ContactDetails
                    register={register}
                    userProfile={userProfile}
                    userDetails={userDetails}
                    setUserDetails={setUserDetails}
                  />
                  <AcademicDetails
                    register={register}
                    userProfile={userProfile}
                  />

                  <ProffesionalDetails
                    userDetails={userDetails}
                    setUserDetails={setUserDetails}
                    register={register}
                    userProfile={userProfile}
                  />
                </Stack>
                <Stack direction="row" mt={3} spacing={2}>
                  {/* <Box sx={{ flex: "1 1 auto" }} /> */}
                  <Button onClick={handleReset} style={{ width: "50%" }}>
                    Reset
                  </Button>
                  <Button
                    variant="contained"
                    onClick={handleSubmit(handleProfileSubmission)}
                    style={{ width: "50%", backgroundColor: "#00275A" }}
                  >
                    Submit
                  </Button>
                </Stack>
              </Box>
            </React.Fragment>
          </Box>
          <Modal
            open={isModalOpen}
            onClose={handleModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 400,
                bgcolor: "background.paper",
                border: "2px solid #000",
                boxShadow: 24,
                p: 4,
                textAlign: "center",
              }}
            >
              {/* <img
                src="/path-to-your-success-gif.gif"
                alt="Success"
                style={{ width: "100px", height: "100px" }}
              /> */}
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Profile Details Updated Successfully
              </Typography>
              <Button
                onClick={handleModalClose}
                variant="contained"
                sx={{
                  mt: 2,
                  backgroundColor: "#00275A",
                  borderRadius: "6px",
                  width: "140px",
                }}
              >
                Done
              </Button>
            </Box>
          </Modal>
          {/* </Container> */}
        </Grid>
      </Grid>
    </Stack>
  );
}
