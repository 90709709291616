import React from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import Index from "../components/privateRoute/userRoute/mentorprofilepage/Index";
import MentorFollow from "../components/privateRoute/userRoute/mentorprofilepage/MentorFollow";

const MentorProfilepage = () => {
  useEffect(() => {
    document.title = `Mentorlink mentor `;
  }, []);
  return (
    <>
      <Index />
    </>
  );
};

export default MentorProfilepage;
