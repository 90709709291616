import { Divider, Stack, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import LeftChat from "./SingleChat.component";
import { DataContext } from "../../Index";

const Sidebar = ({ setSelectedChat }) => {
  const isSmallScreen = useMediaQuery("(max-width:600px)");

  const { sideChatList, selectedChat, userData, calculateMinutes } =
    React.useContext(DataContext);
  return (
    <div style={{}}>
      <Stack className="space-between">
        <Typography
          variant="h2"
          className="heading space-between"
          style={{ fontWeight: 600, fontSize:isSmallScreen ? '16px': "24px", my: 2 }}
        >
          Messages
        </Typography>
      </Stack>
      <Divider variant="fullWidth" />
      <Stack
        className="space-between"
        sx={{ mt: "1rem", overflow: "auto", height: "75vh" }}
      >
        {sideChatList?.length > 0 &&
          sideChatList?.map((value, ind) => {
            const reciever =
              value?.sender?.senderId?._id === userData?._id
                ? value?.receiver?.receiverId
                : value?.sender?.senderId;
            // if true then that person is the sender
            const isSender = value?.sender?.senderId?._id === userData?._id;
            return (
              <React.Fragment key={ind}>
                <LeftChat
                  isSender={isSender}
                  time={value?.createdAt}
                  selectedChat={selectedChat}
                  setSelectedChat={setSelectedChat}
                  id={ind}
                  recieverDetails={reciever}
                  lastMessage={value?.message}
                  unreadCount={
                    value?.sender?.senderId?._id !== userData?._id &&
                    value?.unreadCount
                  }
                  calculateMinutes={calculateMinutes}
                />
              </React.Fragment>
            );
          })}
      </Stack>
    </div>
  );
};

export default Sidebar;
