// import { Box, Grid, Stack, TextField } from "@mui/material";
// import React, { useRef, useState } from "react";
// import { useEffect } from "react";
// import { Link } from "react-router-dom";
// import { allImages } from "../../../../../images";
// import Modal from "../Modal";
// import { useForm } from "react-hook-form";
// import BlueButton from "../../BlueButton";
// import APIRequest from "../../../../../utils/APIRequest";
// import ConfigAPIURL from "../../../../../config/ConfigAPIURL";
// import uuid from "react-uuid";

// const SocialRender = ({
//   value,
//   ind,
//   addSocialMedia,
//   submit,
//   changeData,
//   setChangeData,
//   updateSocialMediaState,
//   setSocialMedia
// }) => {

//   const valueId= value?._id;
//   const { register, handleSubmit } = useForm();
//   const [term, setTerm] = useState(value?.title);
//   const [link, setLink] = useState(value?.link);
//   const termRef = useRef(null);
//   const linkRef = useRef(null);

 
//   const handleTermChange = (e) => {
//     setTerm(e.target.value);
//     setSocialMedia((prevSocialMedia) =>
//     prevSocialMedia?.map((entry) =>
//       entry._id === valueId
//         ? {
//             ...entry,
//             title: e.target.value 
//             // link: data === 'link' ? e.target.value : entry.link,
//           }
//         : entry
//     )
//   );
//     // updateSocialMediaState(value._id, e.target.value,"term");
//     termRef.current.focus();
//   };

//   const handleLinkChange = (e) => {
//     setLink(e.target.value);
//     // updateSocialMediaState(value._id,  e.target.value,'link');
//     linkRef.current.focus();
//   };

//   const updateSocialMedia = async (id) => {
//     if (!term || !link) return;
//     try {
//       const response = await APIRequest.request(
//         "POST",
//         ConfigAPIURL.userProfileSingleUpdate,
//         JSON.stringify({
//           recordId: id,
//           heading: "socialMedia",
//           socialMedia: {
//             title: term,
//             link: link,
//             _id: id,
//           },
//         })
//       );
//       if (response?.data?.responseCode === 109) {
//         setChangeData(!changeData);
//       }
//     } catch {}
//   };

//   const socialMediaRemove = async (id) => {
//     try {
//       const response = await APIRequest.request(
//         "POST",
//         ConfigAPIURL.userProfileDataRemove,
//         JSON.stringify({
//           recordId: value?._id,
//           heading: "socialMedia",
//         })
//       );
//       if (response?.data?.responseCode === 109) {
//         setChangeData(!changeData);
//       }
//     } catch {}
//   };


//   return (
//     <Grid item xs={12}>
//       <Stack direction="row" alignItems="center" justifyContent="space-between">
//         <TextField
//           label="Social Media Name "
//           {...register("example")}
//           value={term}
//           onChange={(e) =>handleTermChange(e)}
//           inputRef={termRef}
//           // onChange={(e) => {setTerm(e.target.value)}}
//         />
//         <TextField
//           label="Enter your Url "
//           value={link}
//           onChange={handleLinkChange}
//           inputRef={linkRef}

//           // onChange={(e) => setLink(e.target.value)}
//         />
//         <Box width="24px" height="24px" sx={{ cursor: "pointer" }}>
//           <img
//             onClick={() => socialMediaRemove()}
//             src={allImages.deleteButton}
//             alt="delete"
//           />
//         </Box>
//         <Box width="24px " height="24px" sx={{ cursor: "pointer" }}>
//           {term !== value.title || link !== value.link ? (
//             <img
//               onClick={() => updateSocialMedia(value._id)}
//               src={allImages.tickIcon}
//               alt=""
//             />
//           ) : null}
//         </Box>
//       </Stack>
//     </Grid>
//   );
// };

// const SocialMediaModel = ({ data, changeData, setChangeData }) => {
//   const [socialMedia, setSocialMedia] = useState([]);
//   const [open, setOpen] = useState(false);
//   useEffect(() => setSocialMedia(data), [data]);

//   const createSocialMedia = async () => {
//     if (!socialMedia) {
//       console.error("socialMedia is undefined");
//       return;
//     }
//     const response = await APIRequest.request(
//       "PUT",
//       ConfigAPIURL.userUpdateAdditionalInfo,
//       JSON.stringify({
//         socialMedia: [
//           {
//             title: "",
//             link: "",
//           },
//           ...socialMedia,
//         ],
//       })
//     );
//     if (response?.data?.responseCode === 109) {
//       setChangeData(!changeData);
//     }
//   };
//   const createSocialMediaAgain = async () => {
//     if (!socialMedia) {
//       console.error("socialMedia is undefined");
//       return;
//     }
//     const response = await APIRequest.request(
//       "PUT",
//       ConfigAPIURL.userUpdateAdditionalInfo,
//       JSON.stringify({
//         socialMedia: [
//           ...socialMedia,
//         ],
//       })
//     );
//     if (response?.data?.responseCode === 109) {
//       setChangeData(!changeData);
//     }
//   };


//   const updateSocialMediaState = (id, value, data, submit = false) => {
//     setSocialMedia((prevSocialMedia) =>
//       prevSocialMedia?.map((entry) =>
//         entry._id === id
//           ? {
//               ...entry,
//               title: data === 'term' ? value : entry.title,
//               link: data === 'link' ? value : entry.link,
//             }
//           : entry
//       )
//     );
//   };
  


//   return (
//     <div>
//       <Link to="/profile" onClick={() => setOpen(true)}>
//         <img
//           style={{ width: "20px", height: "20px" }}
//           src={allImages.editPencil}
//           alt=""
//         />
//       </Link>
//       <Modal text="Social Media" open={open} onClose={() => setOpen()}>
//         <Stack gap={4} alignItems="center">
//           <Box
//             alignSelf="end"
//             width="35px"
//             height="35px"
//             sx={{
//               background: "#1C3464",
//               borderRadius: "100%",
//               cursor: "pointer",
//             }}
//             onClick={() => createSocialMedia()}
//             color="white"
//             alignItems="center"
//             justifyContent="center"
//             display="flex"
//             fontSize="25px"
//           >
//             +
//           </Box>
//           <Grid container spacing={2}>
//             {socialMedia?.length > 0 &&
//               socialMedia?.map((value, ind) => {
//                 return (
//                   <SocialRender
//                     changeData={changeData}
//                     setChangeData={setChangeData}
//                     value={value}
//                     id={value._id}
//                     key={uuid()}
//                     updateSocialMediaState={updateSocialMediaState}
//                     setSocialMedia={setSocialMedia}

//                   />
//                 );
//               })}
//           </Grid>
//         </Stack>
//         <Box mt={4} alignSelf="end" onClick={() => createSocialMediaAgain()}>
//           <BlueButton text="submit" size="lg" />
//         </Box>
//       </Modal>
//     </div>
//   );
// };

// export default SocialMediaModel;
import { Box, Grid, Stack, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { allImages } from "../../../../../images";
import Modal from "../Modal";
import BlueButton from "../../BlueButton";
import APIRequest from "../../../../../utils/APIRequest";
import ConfigAPIURL from "../../../../../config/ConfigAPIURL";

const SocialMediaModel = ({ data }) => {
  const [socialMedia, setSocialMedia] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setSocialMedia(data || []);
  }, [data]);

  const handleSocialMediaChange = (index, field, value) => {
    const updatedSocialMedia = [...socialMedia];
    updatedSocialMedia[index][field] = value;
    setSocialMedia(updatedSocialMedia);
  };

  const addNewSocialMedia = () => {
    setSocialMedia([...socialMedia, { title: "", link: "" }]);
  };

  const removeSocialMedia = (index) => {
    const updatedSocialMedia = [...socialMedia];
    updatedSocialMedia.splice(index, 1);
    setSocialMedia(updatedSocialMedia);
  };

  const handleSubmit = async () => {
    try {
      const response = await APIRequest.request(
        "PUT",
        ConfigAPIURL.userUpdateAdditionalInfo,
        JSON.stringify({ socialMedia })
      );
      if (response?.data?.responseCode === 109) {
        // Handle successful response
        setOpen(false)
        console.log("Social media updated successfully");
      } else {
        // Handle error response
        console.error("Error updating social media");
      }
    } catch (error) {
      console.error("Error updating social media:", error);
    }
  };

  const handleDelete = async (index) => {
    const socialMediaToDelete = socialMedia[index];
    try {
      const response = await APIRequest.request(
        "POST",
        ConfigAPIURL.userProfileDataRemove,
        JSON.stringify({
          recordId: socialMediaToDelete._id,
          heading: "socialMedia",
        })
      );
      if (response?.data?.responseCode === 109) {
        // Handle successful response
        removeSocialMedia(index);
      } else {
        // Handle error response
        console.error("Error deleting social media");
      }
    } catch (error) {
      console.error("Error deleting social media:", error);
    }
  };

  return (
    <div>
      <div onClick={() => setOpen(true)}>
        <img
          style={{ width: "20px", height: "20px" }}
          src={allImages.editPencil}
          alt=""
        />
      </div>
      <Modal text="Social Media" open={open} onClose={() => setOpen(false)}>
        <Stack gap={4} alignItems="center">
          <Box
            alignSelf="end"
            width="35px"
            height="35px"
            sx={{
              background: "#1C3464",
              borderRadius: "100%",
              cursor: "pointer",
            }}
            onClick={addNewSocialMedia}
            color="white"
            alignItems="center"
            justifyContent="center"
            display="flex"
            fontSize="25px"
          >
            +
          </Box>
          <Grid container spacing={2}>
            {socialMedia?.map((socialMedia, index) => (
              <Grid item xs={12} key={socialMedia._id || index}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <TextField
                    label="Social Media Name"
                    value={socialMedia.title}
                    onChange={(e) =>
                      handleSocialMediaChange(index, "title", e.target.value)
                    }
                    style={{marginRight:'12px'}}
                  />
                  <TextField
                    label="Enter your URL"
                    value={socialMedia.link}
                    onChange={(e) =>
                      handleSocialMediaChange(index, "link", e.target.value)
                    }
                    style={{marginRight:'12px'}}
                  />
                  <Box
                    width="24px"
                    height="24px"
                    sx={{ cursor: "pointer" }}
                    onClick={() => handleDelete(index)}
                  >
                    <img src={allImages.deleteButton} alt="delete" />
                  </Box>
                </Stack>
              </Grid>
            ))}
          </Grid>
        </Stack>
        <Box mt={4} alignSelf="end" onClick={handleSubmit}>
          <BlueButton text="Submit" size="lg" />
        </Box>
      </Modal>
    </div>
  );
};

export default SocialMediaModel;