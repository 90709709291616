import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { allImages } from "../../../../../images";
import APIRequest from "../../../../../utils/APIRequest";
import BlueButton from "../../BlueButton";
import Modal from "../Modal";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ExperienceSkills from "../ExperienceSkills";
import ConfigAPIURL from "../../../../../config/ConfigAPIURL";
import { useFormik } from "formik";
import { useForm } from "react-hook-form";
import Toaster from "../../../../../utils/Toaster";
// formik is used for state managment
const EducationModel = ({
  userData,
  addvalue,
  id,
  fetchUserAdditionalInfo,
  text
}) => {
  const [value, setValue] = React.useState(new Date("2014-08-18T21:11:54"));
  const [open, setOpen] = useState(false);
  const [currentlyWorking, setCurrentlyWorking] = useState(true);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const { register, handleSubmit } = useForm();

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const handleExperienceUpdate = async (e) => {
    if (!e.school) return Toaster.error("Please fill all the fields.");
    try {
      if (id) {
        const response = await APIRequest.request(
          "POST",
          ConfigAPIURL.userProfileSingleUpdate,
          JSON.stringify({
            recordId: id,
            heading: "education",
            education: {
              school: e.school,
              degree: e.degree,
              fieldOfStudy: e.fieldOfStudy,
              grade: e.grade,
              description: e.description,
              activities: e.activities,
              endDate: endDate.getTime(),
              startDate: startDate.getTime(),
              _id: id,
            },
          })
        );
        if (response.data.responseCode === 109) {
          setOpen(false);
        }
      } else {
        const response = await APIRequest.request(
          "PUT",
          ConfigAPIURL.userUpdateAdditionalInfo,
          JSON.stringify({
            education: [
              {
                school: e.school,
                degree: e.degree,
                fieldOfStudy: e.fieldOfStudy,
                grade: e.grade,
                description: e.description,
                activities: e.activities,
                endDate: endDate.getTime(),
                startDate: startDate.getTime(),
              },
              ...userData,
            ],
          })
        );
        console.log(response);
        setOpen(false);
      }
    } catch {
    } finally {
      fetchUserAdditionalInfo();
    }
  };
  return (
    <div>
      <Stack direction="row" gap={3} style={{display:'flex',flexDirection:'row',justifyContent:'end',alignItems:'end',}}>
        <Stack
          display="flex"
          onClick={() => setOpen(true)}
          style={{ cursor: "pointer" }}
        >
          {/* <img className="mainicon" src={allImages.editPencil} alt="" /> */}
          {text ?  <img
            // style={{ width: "20px", height: "20px" }}
            style={{ cursor: "pointer" }}
            className="mainicon"
            src={allImages.editPencil}
            alt=""
          />  : <Typography style={{textDecoration:'underline'}}>Add</Typography> }
        </Stack>
      </Stack>
      {
        <Modal open={open} text="Education" onClose={() => setOpen()}>
          {/* to be displayed inside the modal */}
          <Stack my={4}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  size="small"
                  required
                  label="School"
                  placeholder="Ex:Boston University"
                  defaultValue={userData?.school}
                  {...register("school")}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  size="small"
                  label="Grade"
                  defaultValue={userData?.grade}
                  {...register("grade")}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  size="small"
                  label="Degree"
                  placeholder="Ex:Bachelors"
                  defaultValue={userData?.degree}
                  {...register("degree")}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  size="small"
                  label="Field of Study"
                  defaultValue={userData?.fieldOfStudy}
                  {...register("fieldOfStudy")}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  size="small"
                  label="Activities"
                  defaultValue={userData?.activities}
                  {...register("activities")}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Stack direction="row" gap={2} mt={2}>
                  <Stack spacing={1}>
                <Typography variant="body2">From</Typography>
                  <DatePicker
                    dropdownMode="select"
                    dateFormat="dd/MM/yyyy"
                    showYearDropdown
                    className="datepicker"
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                  />
                  </Stack>
                  {currentlyWorking && (
                    <Stack spacing={1}>
                     <Typography variant="body2">To</Typography>
                    <DatePicker
                      dropdownMode="select"
                      dateFormat="dd/MM/yyyy"
                      showYearDropdown
                      className="datepicker"
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      style={{ height: "20px" }}
                    />
                    </Stack>
                  )}
                </Stack>
              </Grid>

              <Grid item xs={12} md={12}>
                <TextareaAutosize
                  aria-label="empty textarea"
                  placeholder="Description"
                  defaultValue={userData?.description}
                  style={{
                    width: "100%",
                    height: "90px",
                    borderRadius: "4px",
                    padding: "5px 10px",
                    fontWeight: 400,
                  }}
                  {...register("description")}
                />
              </Grid>
            </Grid>
            <Box
              mt={3}
              alignSelf="end"
              onClick={handleSubmit(handleExperienceUpdate)}
            >
              <BlueButton text="Submit" size="lg" />
            </Box>
          </Stack>
        </Modal>
      }
    </div>
  );
};

export default EducationModel;
