import React from "react";
import axios from "axios";
import { allImages } from "../../../../images";

function App() {
  
  

  return (
    <div className="App">
      <header className="App-header">
        <img src={allImages.logo} className="App-logo" alt="logo" />
        <p>Buy React now!</p>
        <button className="App-link" >
          Pay ₹500
        </button>
      </header>
    </div>
  );
}

export default App;
