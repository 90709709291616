import { Container, Stack } from "@mui/material";
import React from "react";
import ChooseMentorLink from "../components/general/LandingPage/ChooseMentorLink";
import Footer from "../components/general/LandingPage/Footer";
import Herobanner from "../components/general/LandingPage/Herobanner";
import NavbarL from "../components/general/LandingPage/NavbarL";
import Skills from "../components/general/LandingPage/Skills";
import Testimonials from "../components/general/LandingPage/Testimonials";
import "../config/firebase-messaging-sw";
import { LinkedInCallback } from "react-linkedin-login-oauth2";
import Index from "../components/general/LandingPage/Index";

const LandingPage = () => {
  return (
    <>
      <Stack sx={{ display: "none" }}>
        <LinkedInCallback />
      </Stack>
      <Index />
      {/* <NavbarL />
      <Herobanner />
      <ChooseMentorLink />
      <Skills />
      <Testimonials />
       */}
      <Footer />
    </>
  );
};

export default LandingPage;
