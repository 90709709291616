import { Grid, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

const MentorCoreSkills = ({ userSkills, children }) => {
  return (
    <Stack mt={0.5}>
      <Stack direction="row" justifyContent="space-between" borderTop={'1px solid #1414141F'} paddingTop={'6px'}>
        <Typography
          variant="h2"
          className="heading"
          // fontSize="20px"
          fontWeight="600"
          color={'#343434'}
          marginBottom={'0px'}
        >
          Core Skills
        </Typography>
        {children}
      </Stack>
      <Grid container spacing={1} mt={0.5}>
        {userSkills?.skills?.length > 0 &&
          userSkills?.skills?.map((val, ind) => {
            return (
              <Grid item key={ind}>
                <Typography
                  textTransform="capitalize"
                  p="3px 12px"
                  fontWeight="500"
                  // className="background__light sub__heading"
                  sx={{
                    background: "#2C72FF0D",
                    border: "1px solid #00275A",
                    fontSize: { xs: "12px", md: "14px" },
                    borderRadius: "15px",
                    color:"#00275A"
                  }}
                >
                  {val}
                </Typography>
              </Grid>
            );
          })}
      </Grid>
      <Stack direction="column" justifyContent="space-between" borderTop={'1px solid #1414141F'} paddingTop={'8px'} marginTop={'10px'} borderBottom={'1px solid #1414141F'} paddingBottom={'6px'}>
        <Typography
          variant="h2"
          className="heading"
          // fontSize="20px"
          fontWeight="600"
          color={'#343434'}
          marginTop={'6px'}
        >
          Current Working
        </Typography>
        <Typography
          fontSize="14px"
          fontWeight="400"
          marginTop={'6px'}
          backgroundColor={'#F18A271A'}
          padding={'12px'}
          borderRadius={'12px'}
          color={'#F0851D'}
        >
        {userSkills?.designation} at {userSkills?.organization}
        </Typography>

      </Stack>
    </Stack>
  );
};

export default MentorCoreSkills;
