import { Container, Stack, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { Accordion } from "react-bootstrap";

const Faq = () => {
  const isSmallScreen = useMediaQuery("(max-width:600px)");

  return (
    <>
      <Stack
        id="faq"
        textAlign="center"
        my={2}
        style={{
          display: "flex",
          flexDirection:isSmallScreen ? 'column' : "row",
          justifyContent: "space-between",
          marginTop: "28px",
          marginBottom: "28px",
        }}
      >
        <Stack my={isSmallScreen ? 0 :2}>
          <Typography
            variant="subtitle1"
            className="landing__heading banner__title"
            lineHeight={'33px'}
            marginBottom={'8px'}
          >
            Frequently Asked Questions
          </Typography>
          <Typography
            variant="p"
            padding={"20px"}
            margin={"20px"}
            backgroundColor={"#E6EFFF"}
            borderRadius={"8px"}
            color={'#4A4A4A'}
            fontWeight={'600'}
          >
            Unable to locate the information you seek? <span style={{color:'#00275A'}}> Feel free to get in touch
            with us!</span>
          </Typography>
        </Stack>
        <Container maxWidth="md">
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>What's Mentorlink all about?</Accordion.Header>
              <Accordion.Body style={{ textAlign: "start" }}>
                Mentorlink is an online web platform where startups/ founders
                can search for mentors, get connected with them and engage on a
                mentoring session.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                How does MentorLink platform work?
              </Accordion.Header>
              <Accordion.Body style={{ textAlign: "start" }}>
                Users can register on MentorLink platform as a Mentor or
                Startup. After completing the signup and signin process along
                with the profile, the platform will matchmake Mentors and
                Startups using its proprietory AI (Artificial Intelligence) code
                based on Sector, Area of Expertise, Tech Vertical, Skillset,
                Geogrpahy etc. A Mentee can send a connection request to a
                desired Mentor. Once the request is accepted by the Mentor, the
                Mentee can book a session by completing the payment.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                Will I get a refund, If the session gets cancelled?
              </Accordion.Header>
              <Accordion.Body style={{ textAlign: "start" }}>
                Yes please refer to the refund policy
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>
                Can you video chat in this platform?
              </Accordion.Header>
              <Accordion.Body style={{ textAlign: "start" }}>
                Yes
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Container>
      </Stack>
    </>
  );
};

export default Faq;
