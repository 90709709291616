import { Stack } from "@mui/material";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const MenuArea = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <Stack className="stack__round" sx={{ minHeight: "220px", pt: 3 }}>
      {/* <button
        style={{
          background: pathname === "/settings/general" ? "#D4DBEB" : "",
        }}
        onClick={() => navigate("general")}
      >
        General
      </button> */}
      <button
        style={{
          background: pathname === "/settings/availability" ? "#D4DBEB" : "",
          fontSize: "20px",
        }}
        onClick={() => navigate("availability")}
      >
        Availability
      </button>
      <button
        style={{
          background: pathname === "/settings/bankdetails" ? "#D4DBEB" : "",
        }}
        onClick={() => navigate("bankdetails")}
      >
        Bank Details
      </button>
    </Stack>
  );
};
const style = {
  //
};
export default MenuArea;
