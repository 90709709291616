import { Alert, Container, Snackbar, Stack, Typography } from "@mui/material";
import React from "react";
import RedeemCode from "./RedeemCode";
import RedeemingCredit from "./RedeemingCredit";
import Summary from "./Summary";
import "./coupons.scss";
import { Suspense } from "react";
import Loader from "../Loader";
import APIRequest from "../../../../utils/APIRequest";
import ConfigAPIURL from "../../../../config/ConfigAPIURL";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { encryptandDecrypt } from "../../../../config/EncrytDecryptData";

const Index = () => {
  const [snakbarValues, setSnakbarValues] = useState({
    status: false,
    severity: "",
    message: "",
  });

  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    fetchAllCoupons();
  }, [snakbarValues]);
  const notificationClose = (event) => {
    setSnakbarValues({ status: false, severity: "", message: "" });
  };

  const location = useLocation();
  const [decryptedId, setDecryptedId] = useState(null);
  useEffect(() => {
    // Extract the payload from the URL fragment
    const hash = location.hash;
    const params = new URLSearchParams(hash.substring(1)); // Remove the leading #
    const encryptedPayload = params.get("payload");

    if (encryptedPayload) {
      const decrypted = encryptandDecrypt.decrypt(encryptedPayload);
      setDecryptedId(decrypted);
    }
  }, [location]);
  console.log("this is decrypted", decryptedId);

  const handleApplyCoupons = async (couponId) => {
    try {
      const response = await APIRequest.request(
        // get the users id from localstorage
        "GET",
        `${ConfigAPIURL.applyCoupons}?couponId=${couponId}&mentorId=${decryptedId}`,

        // ConfigAPIURL.applyCoupons +
        //   "?couponId=" +
        //   couponId +
        //   "&mentorId=" +
        //   decryptedId,
        ""
      );
      console.log(response);
      if (response?.data?.responseCode === 126) {
        return setSnakbarValues({
          status: true,
          severity: "success",
          message: "You have Successfully Applied the Coupon",
        });
      }
      if (response?.data?.responseCode === 127) {
        return setSnakbarValues({
          status: true,
          severity: "error",
          message: "Invalid Coupon or You have already applied this coupon",
        });
      }
      if (response?.data?.responseCode === 129) {
        return setSnakbarValues({
          status: true,
          severity: "info",
          message: "You are not an Elligible Customer to apply this Coupon",
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  // get all coupons and credits
  const fetchAllCoupons = async () => {
    try {
      const response = await APIRequest.request(
        // get the users id from localstorage
        "GET",
        ConfigAPIURL.allCouponsDetails,
        ""
      );
      if (response?.data?.responseCode === 109) {
        setTableData(response?.data?.result);
        // setAvailableDates(response?.data?.result);
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div>
      <Container maxWidth="md" sx={{ mt: { xs: 7, md: 2 } }}>
        <Stack mb={1.5}></Stack>
        <Stack gap={2}>
          <Suspense fallback={<Loader />}>
            <Summary tableData={tableData} />
          </Suspense>
          <Suspense fallback={<Loader />}>
            <RedeemingCredit tableData={tableData} />
          </Suspense>

          <Suspense fallback={<Loader />}>
            <RedeemCode handleApplyCoupons={handleApplyCoupons} />
          </Suspense>
        </Stack>
      </Container>
      <Snackbar
        open={snakbarValues.status}
        onClose={notificationClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={3000}
      >
        <Alert severity={snakbarValues.severity}>{snakbarValues.message}</Alert>
      </Snackbar>
    </div>
  );
};

export default Index;
