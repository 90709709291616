import { Stack } from "@mui/material";
import React from "react";
import { allImages } from "../../../../../../images";

const SenderMsg = ({ content = "Message content goes here", image }) => {
  return (
    <Stack style={{ flexDirection: "row", justifyContent: "end", gap: 8,alignItems:"center" }}>
      <Stack
        className="recievermsg sendermsg"
        style={{ flexDirection: "row", position: "relative" }}
      >
        {content}
        {/* <span
          style={{
            fontSize: "9px",
            alignSelf: "end",
            position: "absolute",
            bottom: 0,
            right: 10,
          }}
        >
          12:30pm
        </span>{" "} */}
      </Stack>
      {/* <Stack
        style={{
          width: "28px",
          height: "28px",
          borderRadius: "12px",
          overflow: "hidden",
          // background: "#D8D8D8",
        }}
      >
        <img
          src={image || allImages.avatar}
          alt="image"
          style={{ objectFit: "cover" }}
        />
      </Stack> */}
    </Stack>
  );
};

export default SenderMsg;
