import { Divider, Stack, Typography } from "@mui/material";
import React from "react";
import { allImages } from "../../../../images";
import { useNavigate } from "react-router-dom";

const ProfileDetails = ({ userData, handleClick, resetAncherEl }) => {
  const navigate = useNavigate();
  return (
    <Stack
      sx={{
        width: "255px",
        background: "white",
        backgroundColor: "0px 4px 4px rgba(0, 0, 0, 0.15)",
        borderRadius: "8px",
        position: "relative",
        right: 0,
      }}
    >
      <Stack sx={{ height: "68px", background: "#E7EFFF" }}></Stack>
      <Stack alignItems="center" position="relative" height="38px">
        <img
          style={{
            width: "48px",
            height: "48px",
            position: "absolute",
            top: "-24px",
            objectFit: "cover",
          }}
          src={userData?.profileImage || allImages.avatar}
          alt="profile"
        />
      </Stack>
      <Typography
        variant="subtitle1"
        sx={{
          fontSize: "16px",
          lineHeight: "22px",
          fontWeight: 600,
          textAlign: "center",
        }}
      >
        {userData?.fname}
      </Typography>
      <Typography
        variant="subtitle1"
        sx={{
          fontSize: "12px",
          lineHeight: "22px",
          fontWeight: 400,
          textAlign: "center",
        }}
      >
        {userData?.email}
      </Typography>
      <Stack mx={"18.5px"} mt={2} gap={1}>
        <Stack
          flexDirection="row"
          gap="14.5px"
          sx={{ cursor: "pointer" }}
          onClick={() => {
            resetAncherEl();
            navigate("/profile");
          }}
        >
          <img
            style={{ width: "20px", height: "20px" }}
            src={allImages?.profileIcon}
          />
          <Typography
            variant="subtitle1"
            sx={{ fontSize: "14px", lineHeight: "22px", fontWeight: 400 }}
          >
            Profile{" "}
          </Typography>
        </Stack>
        {userData?.mentor && (
          <Stack
            sx={{ cursor: "pointer" }}
            flexDirection="row"
            gap="14.5px"
            onClick={() => {
              resetAncherEl();
              navigate("/settings/availability");
            }}
          >
            <img
              style={{ width: "20px", height: "20px" }}
              src={allImages?.settingsIcon}
            />
            <Typography
              variant="subtitle1"
              sx={{ fontSize: "14px", lineHeight: "22px", fontWeight: 400 }}
            >
              Settings{" "}
            </Typography>
          </Stack>
        )}
      </Stack>
      <Divider sx={{ mt: 1 }} variant="fullWidth" />
      <Stack
        onClick={handleClick}
        mb={1}
        alignItems="center"
        flexDirection={"row"}
        justifyContent={"center"}
        my={0.9}
        sx={{ cursor: "pointer" }}
        gap={1}
      >
        <img
          style={{ width: "16px", height: "16px" }}
          src={allImages.logoutNewIcon}
          alt="logout"
        />
        <Typography
          variant="subtitle1"
          sx={{ fontSize: "14px", lineHeight: "16px", fontWeight: 400 }}
        >
          LOG OUT{" "}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default ProfileDetails;
