import {
  Alert,
  Button,
  Grid,
  InputAdornment,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Box, Container } from "@mui/system";
import React, { useState, useEffect, Component } from "react";
import { allImages } from "../../images";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Link, useNavigate } from "react-router-dom";
import ConfigAPIURL from "../../config/ConfigAPIURL";
import APIRequest from "../../utils/APIRequest";
import Toaster from "../../utils/Toaster";
import { checksUserExist, signInwithGoogle } from "../../config/Firebase";
import LocalStorage from "../../config/LocalStorage";
import Spinner from "react-bootstrap/Spinner";

import ProfileUpdate from "../privateRoute/userRoute/ProfileUpdate";
import { toast } from "react-toastify";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useLocation } from "react-router-dom";
import { useLinkedIn } from "react-linkedin-login-oauth2";
// You can use provided image shipped by this package or using your own
import { LinkedIn } from "react-linkedin-login-oauth2";
import linkedin from "react-linkedin-login-oauth2/assets/linkedin.png";
import { linkedinAuth } from "../../utils/LinkedinAuth";
import "bootstrap/dist/css/bootstrap.min.css";
import BlueButton from "../privateRoute/userRoute/BlueButton";

const Signin = ({ passcheck }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [visibile, setVisible] = useState(false);
  const [eMail, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginForm, setLoginForm] = useState(false);
  const [spinner, setSpinner] = useState(true);
  const [snakbarValues, setSnakbarValues] = useState({
    status: false,
    severity: "",
    message: "",
  });

  const { state } = location;

  // console.log("this is state from islogin", state);

  useEffect(() => {
    isLogin();
  }, []);

  const notificationClose = (event) => {
    setSnakbarValues({ status: false, severity: "", message: "" });
  };

  const isLogin = async () => {
    const response = await APIRequest.request(
      "GET",
      ConfigAPIURL.userIsLoggedin,
      ""
    );
    if (response?.data?.responseCode === 109) {
      if (response.data.userType !== null && !state) {
        return navigate("/home");
      } else if (state) {
        return navigate("/home/mentors/list", {
          state: { skill: state.skill },
        });
      } else {
        setSnakbarValues({
          status: true,
          severity: "error",
          message: "User does not have permission to login",
        });
      }
    }
    if (response?.data?.responseCode === 108) {
    } else {
      setSnakbarValues({
        status: true,
        severity: "error",
        message: "Server Error, Please try after sometime",
      });
    }
  };

  const handleGooglesignin = async () => {
    // if the response is true redirects to home page
    const response = await signInwithGoogle();
    if (response?.code === 100 && response?.data?.responseCode === 109)
      isLogin();
    if (
      response?.response?.code === 100 &&
      response?.response?.data?.responseCode === 114
    ) {
      navigate("/signup", { state: response });
    }
  };
  const userNameANDpasswordLOgin = () => {
    return APIRequest.request(
      "POST",
      ConfigAPIURL.userAccountlogin,
      JSON.stringify({
        email: eMail?.toLowerCase(),
        password: password,
      })
    );
  };

  // const handleForgotPassword = async () => {
  //   if (eMail === "")
  //     return setSnakbarValues({
  //       status: true,
  //       severity: "error",
  //       message: "Please enter the Email before Proceeding",
  //     });
  //   const response = await APIRequest.request(
  //     "GET",
  //     ConfigAPIURL.userForgotpassword + "?username=" + eMail,
  //     ""
  //   );

  //   if (response?.data?.responseCode === 116) {
  //     return setSnakbarValues({
  //       status: true,
  //       severity: "error",
  //       message:
  //         "User password has generated and successfully send over to the mail",
  //     });
  //   }
  //   if (response?.data?.responseCode === 103) {
  //     return setSnakbarValues({
  //       status: true,
  //       severity: "error",
  //       message: "Email id is not yet! Registered",
  //     });
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (eMail === "")
      return setSnakbarValues({
        status: true,
        severity: "error",
        message: "Enter your Email",
      });
    if (password === "")
      return setSnakbarValues({
        status: true,
        severity: "error",
        message: "Enter your password",
      });
    setSpinner(false);

    const response = await userNameANDpasswordLOgin();
    if (response?.data?.responseCode === 105) {
      setSnakbarValues({
        status: true,
        severity: "success",
        message: "You have successfully logged into your account",
      });
      return setTimeout(() => {
        isLogin();
      }, 1000);
    } else {
      setSpinner(true);
      if (response?.data?.responseCode === 104)
        return setSnakbarValues({
          status: true,
          severity: "error",
          message: "Password Mismatch Try again",
        });
      if (
        response?.data?.responseCode === 107 ||
        response?.data?.responseCode === 116
      )
        return setSnakbarValues({
          status: true,
          severity: "error",
          message: "Exceeded Password attempt try again later",
        });
      if (response?.data?.responseCode === 103) {
        return setSnakbarValues({
          status: true,
          severity: "error",
          message: "User email id not found / wrong email id",
        });
      }
      if (response?.data?.responseCode === 102) {
        return setSnakbarValues({
          status: true,
          severity: "error",
          message: "User account is suspended",
        });
      } else {
        return setSnakbarValues({
          status: true,
          severity: "error",
          message: "Server error please try again later",
        });
      }
    }
  };
  let count = 0;

  const linkedinData = async (code) => {
    console.log("fetching happening");
    count += 1;
    if (count >= 2) {
      return;
    }
    console.log("indide fetch method");
    const response = await APIRequest.request(
      "GET",
      ConfigAPIURL.getLinkedinUserDetails + "?code=" + code,
      ""
    );
    // check whether the user Exists or not
    const uid = response?.data?.results?.userProfile?.uid;
    const email = response?.data?.results?.userEmail;
    const isUserExists = await checksUserExist(uid, email);
    console.log(isUserExists);

    //if exists navigate to home page

    //else navigate to create the account signup page

    if (response?.code === 100 && response?.data?.responseCode === 109) {
      navigate("/signup", { state: response });
    }
  };

  const { linkedInLogin } = useLinkedIn({
    clientId: "8679odh9j8xh0o",
    redirectUri: `${window.location.origin}`,
    onSuccess: (code) => {
      // linkedinAuth.linkedinTokenGeneration(code);
      linkedinData(code);
    },
    scope: "r_emailaddress r_liteprofile",
    onError: (error) => {
      console.log("error occured", error);
    },
  });
  return (
    <>
      {
        <Stack
          width="100%"
          sx={{
            background: "white",
            minHeight: "100vh",
            alignItems: { xs: "center", md: "start" },
          }}
          className="signin__form"
        >
          <Grid container height="100vh" style={{ position: "relative" }}>
            <Grid
              item
              lg={4.5}
              md={5}
              // className="Signin__img"
              sx={{
                display: { xs: "none", md: "block", lg: "flex" },
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#00275A",
                flexDirection: "column",
              }}
            >
              <img
                style={{
                  width: "200px",
                  height: "40px",
                  marginBottom: "48px",
                  alignSelf: "start",
                  marginLeft: "36px",
                  cursor: "pointer",
                  position: "absolute",
                  top: "20px",
                }}
                onClick={() => navigate("/")}
                // src="https://s3.ap-south-1.amazonaws.com/mentorlink/placeholder/1666162305789_Group%2034722%20(2).png"
                src={allImages.logoicon}
              />
              <img
                style={{ objectFit: "cover", width: "400px", height: "400px" }}
                // src="https://s3.ap-south-1.amazonaws.com/mentorlink/placeholder/1666162305789_Group%2034722%20(2).png"
                src={allImages.LoginImage}
              />
              <Typography fontSize={"20px"} fontWeight={"600"} color={"#fff"}>
                Connect with mentors effortlessly
              </Typography>
              <Typography fontSize={"16px"} fontWeight={"400"} color={"#fff"}>
                Everything you need for professional growth, all in one place.
              </Typography>
            </Grid>
            <Grid item lg={1.5} md={1}></Grid>
            <Grid
              item
              lg={3}
              md={5}
              sm={6}
              xs={12}
              sx={{
                alignSelf: "center",
                justifyContent: { xs: "center", md: "start" },
              }}
            >
              <Stack
                style={{
                  alignSelf: "end",
                  marginBottom: "90px",
                  flexDirection: "row",
                  alignItems: "center",
                  position: "absolute",
                  gap: 8,
                  top: 20,
                  right: 50,
                }}
              >
                <Typography
                  justifySelf="end"
                  variant="subtitle1"
                  textAlign="center"
                  sx={{
                    fontWeight: 400,
                    fontSize: { xs: "16px", md: "16px" },
                    lineHeight: "19.53px",
                    marginTop: "10px",
                  }}
                >
                  New to Mentorlink?{" "}
                </Typography>
                {/* <Link
                      to="/login"
                      style={{
                        fontSize: "700",
                        color: "#1C3464",
                        fontSize: "18px",
                      }}
                    > */}{" "}
                <BlueButton
                  style={{ textTransform: "capitalize", marginTop: 0 }}
                  text="Sign Up"
                  onClick={() => navigate("/signup")}
                />
                {/* </Link> */}
              </Stack>
              <>
                <Typography
                  mb={2}
                  textAlign="center"
                  sx={{
                    fontWeight: "700",
                    fontSize: { xs: "24px" },
                  }}
                >
                  WELCOME TO MENTORLINK
                </Typography>

                <Box
                  px={3}
                  py={2}
                  sx={{
                    // border: { sm: "1px solid #EEEEEE" },
                    borderRadius: "15px",
                    overflow: "hidden",
                    background: "white",
                    mt: { lg: "1rem", xs: "2rem" },
                  }}
                >
                  <Typography
                    mb={2}
                    textAlign="start"
                    sx={{
                      fontWeight: "600",
                      fontSize: { xs: "24px", lg: "24px" },
                    }}
                  >
                    {/* Log in */}
                  </Typography>
                  <form>
                    <Stack>
                      <TextField
                        value={eMail}
                        onChange={(e) => setEmail(e.target.value)}
                        autoFocus
                        sx={{
                          borderRadius: "6px",
                          outline: "none",
                          fontSize: "24px",
                          fontWeight: "400",
                        }}
                        variant="outlined"
                        type="text"
                        fullWidth
                        label="E-mail or User name"
                        mb={3}
                        size="small"
                      />
                      <br />
                      <TextField
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        fontSize="24px"
                        mt="1rem"
                        variant="outlined"
                        label="Password"
                        fullWidth
                        size="small"
                        type={visibile ? "text" : "password"}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              sx={{ cursor: "pointer" }}
                            >
                              {!visibile ? (
                                <VisibilityOffIcon
                                  onClick={() => setVisible(!visibile)}
                                />
                              ) : (
                                <VisibilityIcon
                                  onClick={() => setVisible(!visibile)}
                                />
                              )}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Stack>
                    <Typography
                      mt={1}
                      mb={1}
                      variant="subtitle1"
                      textAlign="end"
                      sx={{
                        fontWeight: "400",
                        fontSize: { xs: "14px", lg: "16px" },
                        color: "#393939",
                        cursor: "pointer",
                      }}
                      onClick={() => navigate("/forgot-password")}
                    >
                      {/* <Link
                        to="/forgot-password"
                        style={{ color: "black" }}
                        // style={buttonLink}
                        // onClick={handleForgotPassword}
                      > */}
                      Forgot password?
                      {/* </Link> */}
                    </Typography>
                    <Button
                      type="submit"
                      variant="contained"
                      className="submit__button"
                      sx={{
                        width: "100%",
                        background: "#2C72FF",
                        height: { xs: "50px", md: "42px" },
                        mt: "20px",
                        borderRadius: { xs: "6px", md: "6px" },
                        fontSize: { md: "20px", xs: "22px" },
                        fontWeight: 600,
                        textTransform: "capitalize",
                      }}
                      onClick={handleSubmit}
                    >
                      {spinner ? (
                        "Log In"
                      ) : (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      )}
                    </Button>
                  </form>
                  <Typography
                    mb={1}
                    mt={1}
                    sx={{
                      fontSize: { lg: "16px" },
                      fontWeight: "400",
                      color: "#393939",
                    }}
                    variant="subtitle2"
                    textAlign="center"
                  >
                    or
                  </Typography>
                  {/* sign in with google button */}
                  <Stack gap={2}>
                    <Button
                      onClick={() => {
                        handleGooglesignin();
                      }}
                      sx={{
                        width: "100%",
                        height: { xs: "50px", md: "42px" },
                        border: "2px solid #D1D1D1",
                        borderRadius: { xs: "6px", md: "6px" },
                      }}
                    >
                      <Box mr={3} height="22px" width="22px">
                        <img src={allImages.googleIcon} alt="" />
                      </Box>
                      <Typography
                        variant="subtitle1"
                        sx={{ color: "black", fontSize: { xs: "14px" } }}
                      >
                        Sign in with Google
                      </Typography>
                    </Button>
                    <Button
                      sx={{
                        width: "100%",
                        height: { xs: "50px", lg: "42px" },
                        border: "2px solid #D1D1D1",
                        borderRadius: { xs: "6px", md: "6px" },
                        display: "flex",
                        alignItems: "center",
                      }}
                      onClick={linkedInLogin}
                    >
                      <Box
                        mr={3}
                        height="22px"
                        width="22px"
                        style={{ marginLeft: "4px" }}
                      >
                        <img src={allImages.linkedinIcon} alt="" />
                      </Box>
                      <Typography
                        variant="subtitle1"
                        sx={{ color: "black", fontSize: { xs: "14px" } }}
                      >
                        Sign in with LinkedIn
                      </Typography>
                    </Button>
                  </Stack>
                </Box>
              </>
            </Grid>
            <Grid item md={2}></Grid>
          </Grid>

          <Snackbar
            open={snakbarValues.status}
            onClose={notificationClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            autoHideDuration={3000}
          >
            <Alert severity={snakbarValues.severity}>
              {snakbarValues.message}
            </Alert>
          </Snackbar>
        </Stack>
      }
    </>
  );
};

const buttonLink = {
  color: "black",
};

export default Signin;
