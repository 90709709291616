import { Checkbox, Stack, Switch, Typography } from '@mui/material'
import React from 'react'
import { useState } from 'react'


const General = () => {
  const [checked,setChecked] = useState()
  return (
    <Stack className='stack__round' px={4}  py={4} direction="row" alignItems="center" >
      <Typography>Do you wanna switch back as the {"Mentor"}</Typography>
      <Switch  checked={checked} onChange={(e) => setChecked(e.target.checked)}  />
    </Stack>
  )
}

export default General