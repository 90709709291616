import { Button, Container, Grid, Stack, Typography } from "@mui/material";
import React, { useRef } from "react";
import { useEffect } from "react";
import MentorFollow from "../mentorprofilepage/MentorFollow";
import DateSelection from "./DateSelection";
import MentorProfile from "./MentorProfile";
import TimeSlots from "./TimeSlots";
import ConfigAPIURL from "../../../../config/ConfigAPIURL";
import APIRequest from "../../../../utils/APIRequest";
import { useState } from "react";
import PopularMentors from "../categoriespage/PopularMentors";
import { Link, useLocation, useParams } from "react-router-dom";
import SlotsBooking from "./SlotsBooking";
import Summary from "./Summary";
import lottie from "lottie-web";
import { v4 as uuid } from "uuid";
import App from "./Demo";
import CalendarDates from "./Calendar";
import { encryptandDecrypt } from "../../../../config/EncrytDecryptData";
import Coupons from "./Coupons";
import MentorCoreSkills from "../mentorprofilepage/MentorCoreSkills";

const Index = () => {
  // const id = useParams();
  const location = useLocation();
  let userId;

  // useEffect(() => {
  //   if (location?.search) {
  //     userId = encryptandDecrypt.decrypt(location?.search?.split("=")[1]);
  //     console.log("this is the userId", userId);
  //     setQuerys({
  //       ...querys,
  //       mentorId: userId,
  //     });
  //   }
  // }, [location]);

  const query = {
    mentorId: encryptandDecrypt.decrypt(location?.search?.split("=")[1]),
    sessionTime: [],
    appointmentFee: 0,
    convenienceFee: 0,
    tax: 0,
    totalPayment: 0,
    paymentRefId: "",
    sessionDate: "",
    price: "",
    couponDetails: null,
  };
  const [availabilityDates, setAvailableDates] = useState({});
  const [userInfo, setUserInfo] = useState();
  const [querys, setQuerys] = useState(query);
  const [indexValue, setIndexValue] = useState(false);
  const [totalRating, setTotalRating] = useState();
  const [snakbarValues, setSnakbarValues] = useState({
    status: false,
    severity: "",
    message: "",
  });
  const container = useRef(null);
  // const indexValue = useRef(null)
  // indexValue?.current?.style?.zIndex ? indexValue?.current?.style?.zIndex : "1000": null
  useEffect(() => {
    const lottieAnimation = lottie.loadAnimation({
      container: container.current,
      renderer: "svg",
      loop: false,
      autoplay: false,
      animationData: require("../../../../assets/success.json"),
    });

    return () => {
      lottie.destroy();
    };
  }, []);

  // test purpose

  useEffect(() => {
    fetchAvailabilty();
    fetchMentorsData();
    fetchMentorTotalRating();
    // fetchUserStatistics();
  }, []);

  const fetchMentorsData = async () => {
    try {
      const response = await APIRequest.request(
        "GET",
        ConfigAPIURL.userProfile + "?userId=" + querys?.mentorId,
        ""
      );
      if (response?.data?.responseCode === 109) {
        setUserInfo(response?.data?.profile);
        setQuerys({
          ...querys,
          price: response?.data?.profile?.session?.amount,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchAvailabilty = async () => {
    try {
      const response = await APIRequest.request(
        // get the users id from localstorage
        "POST",
        ConfigAPIURL.getMentorBookingSlots,
        JSON.stringify({
          userId: querys?.mentorId,
        })
      );
      if (response?.data?.responseCode === 109) {
        setAvailableDates(response?.data?.result);
      }
    } catch (err) {
      console.log(err);
    }
  };
  //statistics
  const fetchMentorTotalRating = async () => {
    try {
      const response = await APIRequest.request(
        "POST",
        ConfigAPIURL.totalRatingReviewCount,
        JSON.stringify({
          mentorId: querys?.mentorId,
        })
      );
      if (response?.data?.responseCode === 109) {
        console.log(response);
        setTotalRating(response?.data?.result[0]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  // console.log("this is userInfo", userInfo);
  const handleApplyDiscount = async (couponCode, setState) => {
    if (!couponCode)
      return setSnakbarValues({
        status: true,
        severity: "error",
        message: "Please enter the Coupon code",
      });
    try {
      const response = await APIRequest.request(
        "GET",
        // ConfigAPIURL.applyCoupons + "?couponId=" + couponCode,
        `${ConfigAPIURL.applyCoupons}?couponId=${couponCode}&mentorId=${userInfo?._id}`,
        ""
      );
      console.log(response);
      if (response?.data?.responseCode === 126) {
        const result = response?.data?.result;
        // console.log(result?.amount);

        setQuerys((p) => {
          return { ...p, couponDetails: result };
        });
        // setState("");
        return setSnakbarValues({
          status: true,
          severity: "success",
          message: "You have Successfully Applied the Coupon",
        });
      }

      if (response?.data?.responseCode === 127) {
        return setSnakbarValues({
          status: true,
          severity: "error",
          message: "Invalid Coupon or You have already applied this coupon",
          // message: "Invalid Coupon or You have already applied this coupon",
        });
      }
      if (response?.data?.responseCode === 129) {
        return setSnakbarValues({
          status: true,
          severity: "info",
          message: "You are not an Elligible Customer to apply this Coupon",
        });
      }
      if (response?.data?.responseCode === 111) {
        return setSnakbarValues({
          status: true,
          severity: "error",
          message: "Coupon is Expired",
        });
      }
    } catch (err) {
      console.log(err);
    }
    // try {
    //   const response = await APIRequest.request(
    //     "POST",
    //     ConfigAPIURL.applyCoupon,
    //     JSON.stringify({
    //       mentor: userInfo?.email,
    //       couponId: couponCode,
    //     })
    //   );

    //   if (response?.data?.responseCode === 126) {
    //     setSnakbarValues({
    //       status: true,
    //       severity: "success",
    //       message: "Successfully Applied Coupon",
    //     });
    //     setState("");
    //     const result = response?.data?.result;
    //     console.log(result?.amount);
    //     setQuerys((p) => {
    //       return { ...p, couponDetails: result };
    //     });
    //   }
    //   if (response?.data?.responseCode === 127) {
    //     setSnakbarValues({
    //       status: true,
    //       severity: "error",
    //       message: "Coupon has been already applied or Coupon is not valid",
    //     });
    //   }
    //   if (response?.data?.responseCode === 129) {
    //     setSnakbarValues({
    //       status: true,
    //       severity: "error",
    //       message: "Coupon is not matching with the mentor",
    //     });
    //   }
    // } catch (err) {
    //   console.log(err);
    // }
  };

  // razorpay
  return (
    <Container maxWidth="lg" sx={{ mt: { xs: 0, md: 2 }, mb: 2 }}>
      <Grid container spacing={1} sx={{ mt: 0 }}>
        {/* image placeholder */}
        <Grid item md={4} sx={{ display: { xs: "none", md: "block" } }}>
          {/* <Stack
            sx={{ opacity: !indexValue ? 1 : 0 }}
            // className="booking__placeholder sticky__div"
          >


          </Stack> */}
          <Stack
            className="sticky__div"
            mt={3}
            px={2}
            py={3}
            sx={{
              background: "white",
              border: { md: "0.742701px solid #D2D2D2" },
              borderRadius: "28px",
            }}
          >
            {userInfo && (
              <>
                <MentorFollow
                  // changes={{ change, setChange }}
                  // userStatics={userStatics}
                  userDetails={userInfo}
                />
                {/* <MentorFollowRating
                      userStatics={userStatics}
                      userData={userInfo}
                      totalRating={totalRating}
                    /> */}
                <MentorCoreSkills userSkills={userInfo} />
              </>
            )}
            {/* {userAdditionalInfo && <SocialMedia2 data={userAdditionalInfo} />} */}
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          md={8}
          // maxWidth="md"
          sx={{ position: "relative", zIndex: 200, marginTop: "28px" }}
        >
          <Stack sx={{ opacity: !indexValue ? 1 : 0 }}>
            <Stack
              mb={1}
              sx={{
                background: "white",
                border: "0.742701px solid #D2D2D2",
                borderRadius: "16px",
                py: 3,
                px: { md: 4, xs: 1 },
              }}
            >
              {/* <MentorProfile
                data={userInfo}
                querys={querys}
                setQuerys={setQuerys}
                totalRating={totalRating}
              /> */}
              {/* <Calendar/> */}
              {/* <CalendarDates/> */}

              <DateSelection
                availabilityDates={availabilityDates}
                setQuerys={setQuerys}
                querys={querys}
              />
            </Stack>
            <Stack mb={2}>
              <Coupons
                handleApplyDiscount={handleApplyDiscount}
                querys={querys}
                setQuerys={setQuerys}
              />
            </Stack>
            {/* coupons */}
            {/* <Stack mb={1.5}></Stack> */}
            <Stack>
              {/* booking confirmation page */}
              <Summary
                setSnackBarMessage={setSnakbarValues}
                snackBarMessage={snakbarValues}
                querys={querys}
                setQuerys={setQuerys}
                lottie={lottie}
                setIndexValue={setIndexValue}
              />
            </Stack>
          </Stack>
          <Stack
            position="fixed"
            display="flex"
            justifyContent="center"
            alignItems="center"
            // border="2px solid black"
            width="100%"
            height="100%"
            top="0"
            margin-left=" auto"
            margin-right="auto"
            left={0}
            right={0}
            sx={{
              zIndex: !indexValue ? -1 : 1000000000000000000,
              opacity: !indexValue ? 0 : 1,
            }}
          >
            <Stack
              width={450}
              height={385}
              className="container"
              sx={{ background: "white", borderRadius: "10px" }}
              alignItems="center"
              // border="2px solid black"
              // onMouseEnter={() => lottie.play()}
            >
              <Stack ref={container} width={280} height={280}></Stack>
              <Typography
                color="black"
                component="h1"
                fontWeight="600"
                fontSize="16px"
              >
                {" "}
                Your booking has been confirmed
              </Typography>
              <Typography
                color="black"
                component="h1"
                fontWeight="600"
                fontSize="16px"
              >
                {" "}
                Check your email for more details
              </Typography>
              <Link to="/manage" style={style}>
                Done
              </Link>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
};

const style = {
  color: "black",
  border: "2px solid black",
  borderRadius: "25px",
  padding: "0px 10px",
  marginTop: "5.5px",
};

export default Index;
