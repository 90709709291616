import { Container, Grid, Stack } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import ConfigAPIURL from "../../../../config/ConfigAPIURL";
import APIRequest from "../../../../utils/APIRequest";
import BookingCalendar from "./BookingCalendar";
import Bookings from "./Bookings";
import CompletnessRatio from "./CompletnessRatio";
import "./mentorpage.css";
import utilController from "../../../../utils/utilController";
import Settings from "./Settings";

const Index = () => {
  const [weeklySlots, setWeeklySlots] = useState({});
  const [bookingDates, setBookingDates] = useState([]);
  console.log(weeklySlots, "this is a weekly");
  const dateFilter = (data) => {
    const datesArr = [];
    if (data.length === 0) return;
    data.forEach((val) => {
      datesArr.push(val?.date);
    });

    return datesArr;
  };

  useEffect(() => {
    fetchUSerSlots();
    fetchDatesBookingSlots();
  }, []);

  const fetchWeeklyHolidays = async (data) => {
    const result = [];
    let dt = new Date();
    let no_of_months = 4;
    const next_3_months = new Date(dt.setMonth(dt.getMonth() + no_of_months));

    data?.forEach((value) => {
      const allDates = utilController?.getDaysBetweenDates(
        new Date(),
        next_3_months,
        value
      );
      allDates.forEach((date) => {
        const d = new Date(date).toLocaleDateString();
        const twelwe_HR_Format = d + " " + "12:00";
        // this returns the date in milliseconds
        result.push(new Date(twelwe_HR_Format).getTime());
      });
    });
    return result;
  };

  const fetchUSerSlots = async () => {
    try {
      const response = await APIRequest.request(
        "GET",
        ConfigAPIURL.getUserSlots,
        ""
      );
      if (response?.data?.responseCode === 109) {
        // (response?.data?.result);
        const filteredDates = dateFilter(response?.data?.result?.holidays);
        const weeklyHolidays = response?.data?.result?.weeklySlots
          ?.filter((value) => value?.isHoliday)
          ?.map((value) => value?.day);
        const holidaysWeek = await fetchWeeklyHolidays(weeklyHolidays);
        console.log(holidaysWeek);
        setWeeklySlots([...filteredDates, ...holidaysWeek]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchDatesBookingSlots = async () => {
    try {
      const response = await APIRequest.request(
        "GET",
        ConfigAPIURL.bookedDateSlots,
        ""
      );
      if (response?.data?.responseCode === 109) {
        // (response?.data?.result);
        setBookingDates(response?.data?.result);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Container maxWidth="lg">
      <Grid container spacing={1} sx={{ mt: { xs: "2.8rem", md: 0 } }}>
        <Grid
          item
          md={2.7}
          sx={{ my: 1, display: { xs: "none", md: "block", gap: 3 } }}
        >
          <Stack className="sticky__div" style={{ top: "88px" }}>
            <CompletnessRatio />
            <Settings
              title="Settings and Availability"
              content="Set your available Date and Time"
              navigateLink="/settings/availability"
              btnTitle="Schedule Now"
            />

            <Settings
              title="Bank Details"
              content="Update your Bank Details"
              navigateLink="/settings/bankdetails"
              btnTitle="Update"
            />
          </Stack>
        </Grid>
        <Grid item xs={12} md={6} sx={{ my: 3 }}>
          <Bookings />
        </Grid>
        <Grid item xs={12} md={3.3} sx={{ my: 3 }}>
          <Stack className="sticky__div">
            <BookingCalendar
              weeklySlots={weeklySlots}
              bookingDates={bookingDates}
            />
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Index;
