import React from 'react'
// import "./demo.css"
import "./loading.css"
const Loading = () => {
  return (
    <div>
    <div className="containerarea">
  <span className="fab fa-linkedin"></span>
  <div className="linearea">
    <div className="inner"></div>
  </div>
</div>
    </div>
  )
}

export default Loading