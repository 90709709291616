import { Stack, Typography } from "@mui/material";
import React from "react";
import BlueButton from "../BlueButton";
import { useHistory, useNavigate } from "react-router-dom";

const Settings = ({ title, content, navigateLink, btnTitle }) => {
  const navigate = useNavigate();
  return (
    <Stack
      className="rounded"
      sx={{ position: "relative" }}
      px={2}
      py={1}
      mt={1}
    >
      <Typography className="heading" variant="subtitle1" fontWeight="700">
        {title}
      </Typography>
      <Typography
        className="content"
        variant="subtitle1"
        fontWeight="400"
        // fontSize="12"
      >
        {content}
      </Typography>
      <Stack alignItems="end" onClick={() => navigate(navigateLink)}>
        <BlueButton
          style={{
            fontSize: "10px",
            textTransform: "capitalize",
          }}
          text={btnTitle}
          size="sm"
        />
      </Stack>
    </Stack>
  );
};

export default Settings;
