import {
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";

function createData(expirationDate, couponCode, amount, validity, appliedDate) {
  return { expirationDate, couponCode, amount, validity, appliedDate };
}

const RedeemingCredit = ({ tableData }) => {
  const [rows, setRows] = useState();
  useEffect(() => {
    let resultData = [];
    if (!tableData) return;
    tableData.forEach((value) => {
      if (!value?.active) return;
      resultData.push(
        createData(
          new Date(value?.endAt * 1000)?.toDateString()?.slice(3),
          value?.couponCode,
          value?.amount,
          value?.validity,
          new Date(value?.startAt * 1000)?.toDateString()?.slice(3)
        )
      );
    });
    setRows(resultData);
  }, [tableData]);
  return (
    <>
      <Stack>
        <TableContainer component={Paper}>
          <Stack className="coupons__rounded">
            <Typography className="coupons__title">Redeemed Coupons</Typography>
          </Stack>
          <Table
            sx={{
              [`& .${tableCellClasses.root}`]: {
                borderBottom: "none",
              },
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell>Expiration Date</TableCell>
                <TableCell align="right">Coupon Code</TableCell>
                <TableCell align="right">Amount</TableCell>
                <TableCell align="right">Validity (Days)</TableCell>
                <TableCell align="right">Applied Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows && rows.length > 0
                ? rows.map((row, ind) => (
                    <TableRow
                      key={ind}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="left">{row.expirationDate}</TableCell>
                      <TableCell align="right">{row.couponCode}</TableCell>
                      <TableCell align="right">{row.amount}</TableCell>
                      <TableCell align="right">{row.validity}</TableCell>
                      <TableCell align="right">{row.appliedDate}</TableCell>
                    </TableRow>
                  ))
                : null}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </>
  );
};

export default RedeemingCredit;
