import {
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Availability from "./Availability";
import MenuArea from "./MenuArea";
import "./settings.scss";

const Index = () => {
  const { pathname } = useLocation();
  console.log();
  const navigate = useNavigate();
  const [selectedMenu, setSelectedMenu] = useState(
    pathname?.split("/")[2] ? pathname?.split("/")[2] : ""
  );
  const routesNavigate = (e) => {
    setSelectedMenu(e.target.value);
    navigate(e.target.value.toLowerCase());
  };

  return (
    <Container maxWidth={"lg"} sx={{ mt: 4 }}>
      <Grid container spacing={1}>
        <Grid
          item
          xs={12}
          lg={3}
          sx={{ mt: 3, display: { xs: "none", lg: "block" } }}
        >
          <Stack className="sticky__div">
            <MenuArea />
          </Stack>
        </Grid>
        <Grid item xs={12} lg={7} sx={{ mt: 3 }}>
          {/* select options */}
          <Stack mt={3} alignItems="end" sx={{ display: { lg: "none" } }}>
            <FormControl sx={{ width: "130px" }}>
              <InputLabel id="demo-simple-select-label">Menu</InputLabel>
              <Select
                size="small"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedMenu}
                label="Menu"
                onChange={routesNavigate}
              >
                {/* <MenuItem value={"general"}>General</MenuItem> */}
                <MenuItem value={"availability"}>Availability</MenuItem>
                <MenuItem value={"bankdetails"}>Bank Details</MenuItem>
              </Select>
            </FormControl>
          </Stack>
          <Outlet />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Index;
