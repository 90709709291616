import { Checkbox, FormControlLabel, Stack, Typography } from "@mui/material";
import React from "react";

const Filtering = ({ data, title, state, setState, accessor }) => {
  const handleCheck = (e, val) => {
    let updateList = [...state];
    if (!updateList?.includes(val)) {
      updateList = [...state, e.target.value];
    } else updateList.splice(state.indexOf(e.target.value), 1);
    setState(updateList);
  };

  return (
    <Stack>
      <Typography variant="h5" fontSize="18px" fontWeight="600" mb={1}>
        {" "}
        {title}{" "}
      </Typography>
      {data &&
        data.map((val, ind) => {
          return (
            <FormControlLabel
              control={<Checkbox defaultChecked={state?.includes(val)} />}
              name="category"
              value={accessor ? val[accessor] : val}
              onChange={(e) => handleCheck(e, val)}
              label={accessor ? val[accessor] : val}
              key={ind}
            />
          );
        })}
    </Stack>
  );
};

export default Filtering;
