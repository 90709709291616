import { Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ConfigAPIURL from "../../../../config/ConfigAPIURL";
import { allImages } from "../../../../images";
import APIRequest from "../../../../utils/APIRequest";
import BlueButton from "../BlueButton";
import MessageIcon from "@mui/icons-material/Message";

const MentorFollow = ({ userDetails, children, userStatics, changes }) => {
  // console.log(userStatics);
  const userData = useSelector((state) => state?.user?.profileData);
  const navigate = useNavigate();
  const location = useLocation();
  // follow api
  const handleFollowAndUnfollow = async (status) => {
    const { change, setChange } = changes;

    try {
      const response = await APIRequest.request(
        "PUT",
        ConfigAPIURL.followAndUnfollow,
        JSON.stringify({
          status: status === "follow" ? "follow" : "unfollow",
          currentUserId: userDetails?._id,
        })
      );

      if (response?.data?.responseCode === 109) {
        console.log(response);
        setChange(!change);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const localdata = JSON.parse(localStorage.getItem("userDetails"));

  // console.log("userDetailsuserDetails",userDetails);

  return (
    <>
      <Stack>
        {/* <Box width="23px" gap={3} height="23px" alignSelf="flex-end">
          {location.pathname !== "/profile" ? (
            <Link to="/update">
              <img src={allImages.ShareIcon} alt="" />
            </Link>
          ) : null}
          {children}
        </Box> */}
        <Stack
          width={ !userDetails?.profileImage ? "100%": '100%'}
          height={ !userDetails?.profileImage ? "160px": '370px'}
          alignSelf="center"
          // className="roundedprofile"
          sx={{ cursor: "pointer",borderRadius:'14px',marginTop:'-7px' }}
          // position="relative"
        >
          <Stack
            width={ !userDetails?.profileImage ? "50%": '100%'}
            alignSelf="center"
            position="relative"
            height={ !userDetails?.profileImage ? "160px": '370px'}

          >
          <img
            src={
              userDetails?.profileImage
              ? userDetails?.profileImage
              : allImages?.avatar
            }
            style={{borderRadius:'14px'}}
            alt="profile image"
            />
            </Stack>
          {location.pathname === "/profile" && (
            <>
            <Box
              position="absolute"
              width="30px"
              height="30px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ background: "white", borderRadius: "100%" }}
              top={108}
              right={userDetails?.profileImage ?"20px" : "78px"}
              onClick={() => navigate("/update")}
            >
              <img
                style={{ width: "20px", height: "20px" }}
                src={allImages.editPencil}
                alt="edit"
              />
            </Box>
            <Stack style={{width:'100%'}}>
              {localdata?.associatedOrganization?.name !== "" ? (
                  <Typography 
                  style={{ fontSize: '13px', fontWeight: "500", }}>
                    Your associated organization is{" "}
                    {localdata?.associatedOrganization?.name}{" "}
                  </Typography>
              ) : (
                  <Typography 
                  style={{ fontSize: '13px', fontWeight: "500" }}>You are not assigned for any organization</Typography>
              )}
            </Stack>
            </>
          )}
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mt={2}
        >
          <Stack>
          
            <Typography
              // variant="h3"
              // className="heading"
              textTransform="capitalize"
              style={{ fontSize: '28px', fontWeight: "600" }}
            >
              {userDetails?.fname} {userDetails?.lname}
            </Typography>
            <Typography
              variant="subtitle1"
              className="sub__heading"
              textTransform="capitalize"
              sx={{
                fontSize: { xs: "14px", md: "20px" },
                fontWeight: "500",
                lineHeight: "27px",
                color: "#6D6D6D",
              }}
            >
              {userDetails?.designation}, {userDetails?.professionalExperience?.specializationArea}

            </Typography>
          </Stack>
          {/* {location.pathname !== "/profile" &&
          userDetails?._id !== userData?._id ? (
            <Stack flexDirection="row" alignItems="center" gap={2}>
              <Box
                onClick={() =>
                  handleFollowAndUnfollow(userStatics?.followingStatus)
                }
              >
                <BlueButton
                  transform="capitalize"
                  text={
                    userStatics?.followingStatus === "follow"
                      ? "Follow"
                      : "Following"
                  }
                  size="lg"
                />
              </Box>
              <Link
                style={{ marginTop: 15 }}
                to={"/chats"}
                state={{ ...userDetails }}
              >
                <Box>
                  <MessageIcon sx={{ color: "#2c72ff" }} />
                </Box>
              </Link>
            </Stack>
          ) : null} */}
        </Stack>
      </Stack>
    </>
  );
};

export default MentorFollow;
