import { Box, Button, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import MentorFollow from "../mentorprofilepage/MentorFollow";
import MentorCoreSkills from "../mentorprofilepage/MentorCoreSkills.jsx";
import { allImages } from "../../../../images";
import { Link } from "react-router-dom";
import Modal from "./Modal";
import ProfileUpdate from "../../userRoute/ProfileUpdate";
import ModalSkills from "./ModalSkills";
import APIRequest from "../../../../utils/APIRequest";
import ConfigAPIURL from "../../../../config/ConfigAPIURL";
import MentorFollowRating from "../mentorprofilepage/MentorFollowRating";
import SocialMedia from "../mentorprofilepage/SocialMedia";
const MentorProfileArea = ({ data, totalRating, userStatics }) => {
  const [userData, setUserData] = useState(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    fetchUserAdditionalInfo();
  }, [open]);
  // fetching the user additional info skills and all
  const fetchUserAdditionalInfo = async () => {
    const response = await APIRequest.request(
      "GET",
      ConfigAPIURL.userProfile,
      ""
    );
    if (response?.data?.responseCode === 109) {
      setUserData(response?.data?.profile);
    }
  };
  // this is the logic container
  return (
    <>
      {/* <MentorFollowRating
        userStatics={userStatics}
        totalRating={totalRating}
        userData={userData}
      /> */}
      {userData && (
        <>
          <MentorCoreSkills userSkills={userData}>
            {/* usercoreskill update area with having an modal  */}
            <Link to="/profile" onClick={() => setOpen(true)}>
              <img
                className="mainicon"
                // style={{ width: "20px", height: "20px" }}
                src={allImages.editPencil}
                alt=""
              />
            </Link>
            {/* modal to show */}
            <Modal open={open} text="Skills" onClose={() => setOpen(false)}>
              {/* to be displayed inside the modal */}
              <ModalSkills
                userSkills={userData}
                onClose={() => setOpen(false)}
              />
            </Modal>
          </MentorCoreSkills>
        </>
      )}
    </>
  );
};

export default MentorProfileArea;
