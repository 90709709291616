import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { allImages } from "../../../../images";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { dateConvert } from "../../../../utils/DatesConvert";
const para =
  "Join us and the healthcare professionals, partners, and innovators from around the industry in Las Vegas next week.";

const SingleNotification = ({ data, updateNotifications }) => {
  function display(a) {
    var hours = Math.trunc(a / 60);
    var minutes = a % 60;
    return hours + "hrs ";
  }
  const [mins, setMins] = useState(null);
  useEffect(() => {
    calculateMinutes();
  }, []);

  const calculateMinutes = () => {
    let duration = new Date() - new Date(data?.updatedAt * 1000);
    duration /= 1000;

    // get seconds
    var seconds = Math.round(duration);
    var minutes = Math.floor(seconds / 60);

    if (minutes < 60) {
      setMins(minutes + " mins ");
    }
    if (minutes > 60) {
      setMins(display(minutes));
    }
    if (minutes > 1440) {
      setMins(dateConvert.toDaysMinutesSeconds(duration).slice(0, 7));
    }
  };

  return (
    <Stack my={1}>
      <Stack
        direction="row"
        alignItems="start"
        width="100%"
        justifyContent="space-between"
        sx={{ px: { md: 3, xs: 0 }, cursor: "pointer" }}
        onClick={() => updateNotifications(data?._id)}
      >
        <Stack direction="row" alignItems="center" gap={1}>
          <Stack alignItems="center" gap={1}>
            <Box
              sx={{
                borderRadius: "100%",
                // padding: ".3rem",
                width: "55px",
                height: "55px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                overflow: "hidden",
                border: "1px solid rgba(0, 0, 0, 0.15)",
              }}
            >
              <img
                style={{ width: "42px", height: "42px" }}
                src={
                  "https://s3.ap-south-1.amazonaws.com/mentorlink/notification/1665753237620_Notification%20Bell.png"
                }
                alt=""
              />
            </Box>
          </Stack>
          <Stack pl={1.5}>
            <Typography
              variant="subtitle1"
              fontSize="10.85px"
              fontWeight="600"
              color=" #727272"
              textTransform="capitalize"
            >
              {/* {data?.title ? data?.title : "Title"}{" "} */}
              {/* {data?.senderId?.lname} */}
            </Typography>
            <Typography
              variant="h6"
              fontWeight="400"
              sx={{ fontSize: { xs: "12px", md: "14px" } }}
              lineHeight="24.51px"
            >
              {" "}
              {data?.body + " "}{" "}
            </Typography>
          </Stack>
        </Stack>
        <Stack direction="row" alignItems="center">
          {mins && (
            <Typography
              color="#727272"
              sx={{ fontSize: { xs: "10px", md: "14px" } }}
              variant="subtitle1"
            >
              {mins}
            </Typography>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default SingleNotification;
