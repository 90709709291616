import { Alert, Box, Button, Snackbar, Stack, Typography } from "@mui/material";
import React from "react";
import { useState } from "react";
import ReactDom from "react-dom";
import { allImages } from "../../../../images";

const MODAL_STYLES = {
  position: "fixed",
  top: "55%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "#FFF",
  padding: "20px 0px",
  zIndex: 1000,
  width: "40%",
  maxHeight: "80vh",
  borderRadius: "15px",
  border: "0.742701px solid #D2D2D2",
  overflow: "auto",
  // marginTop:"10rem"
};

const OVERLAY_STYLES = {
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(0, 0, 0, .7)",
  zIndex: 1000,
};

export default function Modal({ open, children, onClose, text }) {
  const notificationClose = (event) => {
    setSnakbarValues({ status: false, severity: "", message: "" });
  };

  const [snakbarValues, setSnakbarValues] = useState({
    status: false,
    severity: "",
    message: "",
  });

  if (!open) return null;
  return ReactDom.createPortal(
    <>
      <div style={OVERLAY_STYLES} />
      <div style={MODAL_STYLES} className="modal__styles">
        <Stack sx={{}}>
          <Stack
            width="100%"
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            // px={10}
            sx={{ px: { xs: 2, md: 6 } }}
          >
            <Typography
              sx={{ fontSize: { xs: "16px", md: "24px" } }}
              fontWeight="600"
              className="heading"
            >
              {text}
            </Typography>
            <Box
              sx={{
                width: {
                  xs: "20px",
                  md: "20px",
                  borderRadius: "100%",
                },
              }}
              onClick={onClose}
              style={{ border: "none", alignSelf: "end", cursor: "pointer" }}
            >
              <img src={allImages.closeIcon} />
            </Box>
          </Stack>
          <hr />
          <Stack sx={{ px: { xs: 2, md: 6 } }}>{children}</Stack>
          <Snackbar
            open={snakbarValues.status}
            onClose={notificationClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            autoHideDuration={3000}
          >
            <Alert severity={snakbarValues.severity}>
              {snakbarValues.message}
            </Alert>
          </Snackbar>
        </Stack>
      </div>
    </>,
    document.getElementById("portal")
  );
}
