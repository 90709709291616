import { Box, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { encryptandDecrypt } from "../../../../config/EncrytDecryptData";
import { allImages } from "../../../../images";
import "./Home.scss";
const SearchResultsBar = ({ data, setSearchTerm }) => {
  const navigate = useNavigate();

  const handleNavigation = async (id) => {
    navigate(`/mentors/id?payload=${encryptandDecrypt.encrypt(id)}`);
    setSearchTerm("");
  };
  return (
    <div>
      {data &&
        data?.map((val) => {
          // (val);
          return (
            <Box
              className="dataItem"
              key={val?._id}
              mt={2}
              onClick={() => handleNavigation(val?._id)}
              sx={{ cursor: "pointer" }}
            >
              <Box
                sx={{
                  width: "50px",
                  height: "50px",
                  borderRadius: "100%",
                  overflow: "hidden",
                }}
              >
                <img
                  src={val?.profileImage ? val?.profileImage : allImages.avatar}
                  alt="profile"
                />
              </Box>
              <Stack>
                <Typography
                  variant="h5"
                  fontWeight="600"
                  fontSize="18px"
                  display="block"
                >
                  {val?.fname} {val?.lname}
                </Typography>
                <Typography
                  variant="h5"
                  fontWeight="500"
                  fontSize="16px"
                  color="#797979"
                >
                  {val?.designation}
                </Typography>
              </Stack>
            </Box>
          );
        })}
    </div>
  );
};

export default SearchResultsBar;
