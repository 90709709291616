import { createTheme } from "@mui/material";
export const theme = createTheme({
  components: {
    MuiTypography: {
      defaultProps: {
        fontFamily: "Figtree",
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: "Figtree",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiInputBase-input": {
            fontFamily: "Figtree, Arial, sans-serif",
          },
        },
      },
    },
  },
});
