import React from "react";
import { useEffect } from "react";
import Mentorspage from "../components/privateRoute/userRoute/Mentorspage";
const Mentor = () => {
  
  useEffect(() => {
    document.title = `Mentorlink categories`;
  }, []);

  return (
    <div>
      <Mentorspage />
    </div>
  );
};

export default Mentor;
