import { Stack } from "@mui/material";
import moment from "moment/moment";
import React, { useState } from "react";
import { useEffect } from "react";
import { Calendar, DateObject } from "react-multi-date-picker";

export default function HolidaysCalendar({ data, value, setValue }) {
  //   (value);

  // if data then sorts the array accordingly
  useEffect(() => {
    if (!data) return;
    dateFilter();
  }, [data]);

  //selcts only the date if the lngth of data > 0
  const dateFilter = () => {
    const datesArr = [];
    if (data.length === 0) return;
    data.forEach((val) => {
      datesArr.push(val?.date);
    });

    setValue(datesArr);
  };

  return (
    <Stack alignItems="center">
      <Calendar
        minDate={moment().toDate()}
        value={value}
        onChange={setValue}
        disabledWeekDays={[0, 3]}
      />
    </Stack>
  );
}
