import { Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import SlotsBooking from "./SlotsBooking";

const TimeOrder = ({
  value,
  ind,
  setsplittedTimeSlot,
  setSelected,
  selected,
}) => {
  return (
    <>
      <Grid item xs={8} md={4} sx={{ cursor: "pointer" }} key={ind}>
        <Stack
          sx={{
            // border: "1px solid rgba(0, 0, 0, 0.38)",
            border: "1px solid #00275A",
            borderRadius: "8px",
            justifyContent: "center",
            // background: selected === ind ? "#1C3464" : "white",
            background: selected === ind ? "#EAF1FF" : "white",
            // color: selected === ind ? "white" : "black",
            color: selected === ind ? "#00275A" : "black",
            padding:'12px'
          }}
          onClick={() => {
            setsplittedTimeSlot(value);
            setSelected(ind);
          }}
        >
          <Typography
            textAlign="center"
            sx={{ fontSize: { md: "15px", xs: "13px" }, overflow: "hidden" }}
            fontWeight="600"
            px={0.5}
            variant="subtitle1"
          >
            {value?.slot?.from} - {value?.slot?.till}
          </Typography>
        </Stack>
      </Grid>
    </>
  );
};

const TimeSlots = ({ totalSlots, setQuerys, querys }) => {
  const [splittedTimeSlot, setSplittedTimeSlot] = useState();
  const [selected, setSelected] = useState();
  useEffect(() => {
    // (totalSlots[0]);
    if (totalSlots.length < 0) return;
    if (!totalSlots[0]) return;
    setSplittedTimeSlot(totalSlots[0]);
    setSelected(0);
  }, [totalSlots]);

  return (
    <Stack mt={2}>
      <Typography
        variant="h5"
        sx={{ fontSize: { md: "20px", xs: "16px" } }}
        fontWeight="600"
      >
        Select a Slots Between
      </Typography>
      <Grid container mt={0.9} spacing={1.5}>
        {totalSlots &&
          totalSlots?.length > 0 &&
          totalSlots?.map((value, ind) => {
            if (!value) return null;
            return (
              <TimeOrder
                value={value}
                key={ind}
                ind={ind}
                setsplittedTimeSlot={setSplittedTimeSlot}
                setSelected={setSelected}
                selected={selected}
              />
            );
          })}
      </Grid>
      {splittedTimeSlot && (
        <SlotsBooking
          splittedTimeSlot={splittedTimeSlot}
          setQuerys={setQuerys}
          querys={querys}
        />
      )}
    </Stack>
  );
};

export default TimeSlots;
