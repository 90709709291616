import React from "react";
import parse from "html-react-parser";

const text = `<p style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";'><strong><span style='font-size:16px;font-family:"Segoe UI","sans-serif";color:#363636;background:white;'>Privacy Policy</span></strong></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";background:white;'><span style='font-size:16px;font-family:"Segoe UI","sans-serif";color:#4A4A4A;'>INNOCATALYSTS ADVISORS PRIVATE LIMITED built the Mentorlink app as a Commercial &nbsp;Web application. This SERVICE is provided by INNOCATALYSTS ADVISORS PRIVATE LIMITED and is intended for use as is.</span></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";background:white;'><span style='font-size:16px;font-family:"Segoe UI","sans-serif";color:#4A4A4A;'>This page is used to inform visitors regarding our policies regarding the collection, use, and disclosure of Personal Information if anyone decided to use our Service.</span></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";background:white;'><span style='font-size:16px;font-family:"Segoe UI","sans-serif";color:#4A4A4A;'>If you choose to use our Service, then you agree to the collection and use of information in relation to this policy. The Personal Information that we collect is used for providing and improving the Service. We will not use or share your information with anyone except as described in this Privacy Policy.</span></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";background:white;'><span style='font-size:16px;font-family:"Segoe UI","sans-serif";color:#4A4A4A;'>The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which are accessible at Mentorlink unless otherwise defined in this Privacy Policy.</span></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";background:white;'><strong><span style='font-size:16px;font-family:"Segoe UI","sans-serif";color:#363636;'>Information Collection and Use</span></strong></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";background:white;'><span style='font-size:16px;font-family:"Segoe UI","sans-serif";color:#4A4A4A;'>For a better experience, while using our Service, we may require you to provide us with certain personally identifiable information, including but not limited to Name, Phone number, Email id, educational details, Professional details, and Gender. The information that we request will be retained by us and used as described in this privacy policy.</span></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";background:white;'><span style='font-size:16px;font-family:"Segoe UI","sans-serif";color:#4A4A4A;'>The app does use third-party services that may collect information used to identify you.</span></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";background:white;'><span style='font-size:16px;font-family:"Segoe UI","sans-serif";color:#4A4A4A;'>Link to the privacy policy of third-party service providers used by the app</span></p>
<ul style="list-style-type: disc;margin-left:20px;">
    <li><a href="https://firebase.google.com/policies/analytics" target="_blank"><span style='font-family:"Segoe UI","sans-serif";text-decoration:none;'>Google Analytics for Firebase</span></a></li>
    <li><a href="https://firebase.google.com/support/privacy/" target="_blank"><span style='font-family:"Segoe UI","sans-serif";text-decoration:none;'>Firebase Crashlytics</span></a></li>
</ul>
<p style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";background:white;'><strong><span style='font-size:16px;font-family:"Segoe UI","sans-serif";color:#363636;'>Log Data</span></strong></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";background:white;'><span style='font-size:16px;font-family:"Segoe UI","sans-serif";color:#4A4A4A;'>We want to inform you that whenever you use our Service, in a case of an error in the app we collect data and information (through third-party products) on your phone called Log Data. This Log Data may include information such as your device Internet Protocol (&ldquo;IP&rdquo;) address, device name, operating system version, the configuration of the app when utilizing our Service, the time and date of your use of the Service, and other statistics.</span></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";background:white;'><strong><span style='font-size:16px;font-family:"Segoe UI","sans-serif";color:#363636;'>Cookies&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span></strong></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";background:white;'><span style='font-size:16px;font-family:"Segoe UI","sans-serif";color:#4A4A4A;'>Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers. These are sent to your browser from the websites that you visit and are stored on your device&apos;s internal memory.</span></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";background:white;'><span style='font-size:16px;font-family:"Segoe UI","sans-serif";color:#4A4A4A;'>This Service does not use these &ldquo;cookies&rdquo; explicitly. However, the app may use third-party code and libraries that use &ldquo;cookies&rdquo; to collect information and improve their services. You have the option to either accept or refuse these cookies and know when a cookie is being sent to your device. If you choose to refuse our cookies, you may not be able to use some portions of this Service.</span></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";background:white;'><span style='font-size:16px;font-family:"Segoe UI","sans-serif";color:#4A4A4A;'>&nbsp;</span></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";background:white;'><strong><span style='font-size:16px;font-family:"Segoe UI","sans-serif";color:#363636;'>Service Providers</span></strong></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";background:white;'><span style='font-size:16px;font-family:"Segoe UI","sans-serif";color:#4A4A4A;'>We may employ third-party companies and individuals due to the following reasons:</span></p>
<ul style="list-style-type: disc;margin-left:20px;">
    <li><span style='font-family:"Segoe UI","sans-serif";font-size:12.0pt;color:#4A4A4A;'>To facilitate our Service;</span></li>
    <li><span style='font-family:"Segoe UI","sans-serif";font-size:12.0pt;color:#4A4A4A;'>To provide the Service on our behalf;</span></li>
    <li><span style='font-family:"Segoe UI","sans-serif";font-size:12.0pt;color:#4A4A4A;'>To perform Service-related services; or</span></li>
    <li><span style='font-family:"Segoe UI","sans-serif";font-size:12.0pt;color:#4A4A4A;'>To assist us in analyzing how our Service is used.</span></li>
</ul>
<p style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";background:white;'><span style='font-size:16px;font-family:"Segoe UI","sans-serif";color:#4A4A4A;'>We want to inform users of this Service that these third parties have access to their Personal Information. The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose.</span></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";background:white;'><strong><span style='font-size:16px;font-family:"Segoe UI","sans-serif";color:#363636;'>Security</span></strong></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";background:white;'><span style='font-size:16px;font-family:"Segoe UI","sans-serif";color:#4A4A4A;'>We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.</span></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";background:white;'><strong><span style='font-size:16px;font-family:"Segoe UI","sans-serif";color:#363636;'>Links to Other Sites</span></strong></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";background:white;'><span style='font-size:16px;font-family:"Segoe UI","sans-serif";color:#4A4A4A;'>This Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by us. Therefore, we strongly advise you to review the Privacy Policy of these websites. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.</span></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";background:white;'><strong><span style='font-size:16px;font-family:"Segoe UI","sans-serif";color:#363636;'>Children&rsquo;s Privacy</span></strong></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";background:white;'><span style='font-size:16px;font-family:"Segoe UI","sans-serif";color:#4A4A4A;'>These Services do not address anyone under the age of 13. We do not knowingly collect personally identifiable information from children under 13 years of age. In the case we discover that a child under 13 has provided us with personal information, we immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that we will be able to do the necessary actions.</span></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";background:white;'><strong><span style='font-size:16px;font-family:"Segoe UI","sans-serif";color:#363636;'>Changes to This Privacy Policy</span></strong></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";background:white;'><span style='font-size:16px;font-family:"Segoe UI","sans-serif";color:#4A4A4A;'>We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page.</span></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";background:white;'><span style='font-size:16px;font-family:"Segoe UI","sans-serif";color:#4A4A4A;'>This Policy is effective as of 2022-10-01</span></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";background:white;'><strong><span style='font-size:16px;font-family:"Segoe UI","sans-serif";color:#363636;'>Contact Us</span></strong></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";background:white;'><span style='font-size:16px;font-family:"Segoe UI","sans-serif";color:#4A4A4A;'>If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at support@mentorlink.in.</span></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:10.0pt;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri","sans-serif";'>&nbsp;</p>`;

const PrivacyPolicy = () => {
  return (
    <div
      className="terms"
      style={{ background: "white", padding: "1rem 2rem", color: "black" }}
    >
      {parse(text)}
    </div>
  );
};

export default PrivacyPolicy;
