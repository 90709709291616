import {
  Alert,
  Backdrop,
  Box,
  Button,
  Container,
  Divider,
  Fade,
  Modal,
  Snackbar,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useRef } from "react";
import UpcomingSession from "./UpcomingSession";
import PreviousSession from "./PreviousSession";
import APIRequest from "../../../../utils/APIRequest";
import ConfigAPIURL from "../../../../config/ConfigAPIURL";
import { useState } from "react";
import Loader from "../Loader";
import { allImages } from "../../../../images";
import CheckBoxes from "../categoriespage/CheckBoxes";
import BlueButton from "../BlueButton";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Filtering from "./Filtering";
import { encryptandDecrypt } from "../../../../config/EncrytDecryptData";
import Invoice from "./Invoice";
import { savePdf } from "../../../node";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import "./billingAdress.scss";

var CryptoJS = require("crypto-js");

const Index = () => {
  const query = {
    fname: "",
    lName: "",
    email: "",
    billingAddress: "",
    recordId: "",
  };
  const [paginatedData, setPaginatedData] = useState([]);
  const [isMentor, setIsMentor] = useState(false);
  const [reload, setReload] = useState(false);
  const [totalPage, setTotalPage] = useState(4);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState();
  const [filter, setFilter] = useState([]);
  const userData = useSelector((state) => state?.user?.profileData);
  const navigate = useNavigate();
  const [userForm, setUserForm] = useState(query);
  const isSmallScreen = useMediaQuery("(max-width:600px)");

  // (filter.toString());
  //

  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);

  //

  useEffect(() => {
    fetchBookingHistory();
  }, [reload, open]);

  useEffect(() => {
    (async () => {
      const response = await APIRequest.request(
        "GET",
        ConfigAPIURL.getTime,
        ""
      );
      if (response?.data) {
        console.log(new Date(response?.data?.date));

        // console.log(gmtString + "Z");
        // const serverDate = new Date(gmtString + "Z");

        // const hrs = serverDate.getHours();
        // const mins = serverDate.getMinutes();
        // console.log(serverDate, "this is backend response");
      }
    })();
  }, []);

  const fetchBookingHistory = async () => {
    console.log(filter, "this is filter");
    try {
      const response = await APIRequest.request(
        "GET",
        ConfigAPIURL.bookingHistory +
          "?" +
          "status=" +
          filter.toString().toLowerCase() +
          "&pageLength=" +
          totalPage +
          "&pageNo=" +
          0,
        ""
      );
      if (response?.data?.responseCode === 109) {
        if (response?.data?.pageCount === 1) {
          setLoading(false);
        }
        setPageCount(response?.data?.pageCount);
        setPaginatedData(response.data.result);
        // setLoading(!loading);
      }
    } catch (err) {
      console.log(err);
    }
  };

  window.onscroll = function (ev) {
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
      if (pageCount !== 1) {
        setTotalPage(totalPage + 3);
        fetchBookingHistory();
        setLoading(true);
      }
      // filterData();
    }
  };

  const handleBookingUpdate = async (recordId, status, reason = "") => {
    console.log(recordId, status, reason);
    try {
      const response = await APIRequest.request(
        "PUT",
        ConfigAPIURL.updateBooking,
        JSON.stringify({
          recordId,
          status,
          reason,
        })
      );
      if (response?.data?.responseCode === 109) {
        setReload(!reload);
        return true;
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleTwilio = (id, endingTime) => {
    let fullName = userData?.fname + " " + userData?.lname;
    //if full name is undefined or null or 0 then it will be string
    fullName = fullName ? fullName : " ";
    console.log(endingTime);
    if (!id) return;
    const data = {
      name: fullName,
      roomName: id,
      endingTime,
    };
    //finally encrypt the data and send as payload parameter
    var ciphertext = CryptoJS.AES.encrypt(
      JSON.stringify(data),
      "my-secret-key@123"
    ).toString();
    //validate if the cipher text is found
    if (!ciphertext) return;
    window.open(ConfigAPIURL.twilioUrl + "/?payload=" + ciphertext, "_blank");
  };

  const handleDownload = async () => {
    //record Id can be taken from user form
    try {
      const response = await APIRequest.request(
        // get the users id from localstorage
        "POST",
        ConfigAPIURL.getOrderDetails,
        JSON.stringify({
          recordId: userForm?.recordId,
        })
      );
      if (response?.data?.responseCode === 109) {
        let userDetails = response?.data?.result;
        console.log(userDetails);
        savePdf(
          <Invoice data={userDetails} isMentor={false} />,
          "Invoice-" + ".pdf"
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  //billing address popup
  const fetchUserInfo = async (record) => {
    console.log("fetching user info");
    try {
      const response = await APIRequest.request(
        // get the users id from localstorage
        "GET",
        ConfigAPIURL.userProfile,
        ""
      );
      if (response?.data?.responseCode === 109) {
        let userResult = response?.data?.profile;
        setUserForm({
          ...userForm,
          fname: userResult?.fname ? userResult?.fname : "",
          lName: userResult?.lname ? userResult?.lname : "",
          email: userResult?.email ? userResult?.email : "",
          billingAddress: userResult?.billingAddress
            ? userResult?.billingAddress
            : "",
          recordId: record,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleProfileSubmit = async () => {
    try {
      const response = await APIRequest.request(
        // get the users id from localstorage
        "PUT",
        ConfigAPIURL.UserProfileUpdate,
        JSON.stringify({
          billingAddress: userForm?.billingAddress,
        })
      );
      if (response?.data?.responseCode === 109) {
        handleClose2();
        handleDownload();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const submitBillingAddtress = (record) => {
    //fetches user info when click on invoice
    fetchUserInfo(record);
    handleOpen2();
  };

  const handleMentorGenerateInvoice = async (id) => {
    console.log("mentor Invoice", id);
    try {
      const response = await APIRequest.request(
        // get the users id from localstorage
        "GET",
        ConfigAPIURL.getMentorInvoice + "?id=" + id
      );
      if (response?.data?.responseCode === 109) {
        console.log("invoice ", response?.data?.result);
        let result = response?.data?.result;
        savePdf(<Invoice data={result} isMentor={true} />, "Invoice-" + ".pdf");
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      {paginatedData && paginatedData.length > 0 ? (
        <Container
          p={3}
          maxWidth="md"
          sx={{
            background: "white",
            border: { xs: "none", md: "1px solid #E0E0E0" },
            borderRadius: "15px",
            mt: { xs: "4.5rem", md: "1.5rem" },
            mb: 3,
            pb: 2,
            position: "relative",
          }}
        >
          {paginatedData?.map((value, ind) => {
            // (value.status);
            return (
              <Stack key={value?._id}>
                <UpcomingSession
                  data={value}
                  isMentor={userData?.mentor}
                  userForm={userForm}
                  handleBookingUpdate={handleBookingUpdate}
                  handleTwilio={handleTwilio}
                  setReload={setReload}
                  reload={reload}
                  handleDownload={handleDownload}
                  submitBillingAddtress={submitBillingAddtress}
                  handleMentorGenerateInvoice={handleMentorGenerateInvoice}
                />
              </Stack>
            );
          })}
          {/* filter btn */}

          {loading && <Loader />}
        </Container>
      ) : (
        <Container
          maxWidth="xs"
          sx={{ mt: 5, minHeight: "85vh", marginTop: "18px" }}
        >
          <Box>
            <img
              src={
                "https://s3.ap-south-1.amazonaws.com/mentorlink/manage/1667799114133_Push%20notifications-rafiki%201.svg"
              }
              alt="booking not found"
            />
            <Typography variant="subtitle1" textAlign="center">
              No Bookings Found
            </Typography>
          </Box>
        </Container>
      )}
      <>
        {paginatedData && (
          <Stack
            position="fixed"
            top="90%"
            left={isSmallScreen ? "40%" : "43%"}
            height="100vh"
          >
            <Button
              variant="contained"
              sx={{
                background: "#2A4B95",
                borderRadius: "37px",
                textTransform: "capitalize",
                fontSize: "14px",
                fontWeight: "700",
                px: 3,
              }}
              onClick={handleOpen}
              className="submit__button"
            >
              <img
                src={allImages.filter2}
                alt="filter"
                style={{
                  width: "19px",
                  height: "13.57px",
                  marginRight: ".7rem",
                }}
              />
              Filter
            </Button>
          </Stack>
        )}
        <Modal
          aria-labelledby="spring-modal-title"
          aria-describedby="spring-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          sx={{ borderRadius: "10px" }}
        >
          <Fade in={open}>
            <Box sx={style}>
              <Stack alignItems="start">
                <Filtering
                  title="Status Filter"
                  state={filter}
                  setState={setFilter}
                  data={priceRange}
                />
                <Box
                  alignSelf={isSmallScreen ? "center" : "end"}
                  onClick={handleClose}
                >
                  <BlueButton text="Apply" size="md" />
                </Box>
              </Stack>
            </Box>
          </Fade>
        </Modal>
        {/* invoice */}
        <Modal
          aria-labelledby="spring-modal-title"
          aria-describedby="spring-modal-description"
          open={open2}
          onClose={handleClose2}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          sx={{ borderRadius: "10px" }}
        >
          <Fade in={open2}>
            <Box sx={style}>
              <div className="custom-ui">
                {/* <div class="container"> */}
                <div className="form__name">Shipping and Billing Form</div>
                <Divider variant="middle" />
                <section className="form__personal">
                  <div className="sections">
                    <div className="box">1</div>
                    <span>Personal Information</span>
                  </div>
                  <div className="personal--form">
                    <form className="form--name" action="">
                      <div className="first">
                        <label htmlFor="firstname">First Name</label>
                        <input
                          value={userForm?.fname}
                          onChange={(e) =>
                            setUserForm({
                              ...userForm,
                              fname: e.target.value,
                            })
                          }
                          disabled
                          placeholder="e.g. Richard"
                          id="firstname"
                          type="text"
                        />
                      </div>
                      <div className="last">
                        <label htmlFor="lastname">Last Name</label>
                        <input
                          value={userForm?.lName}
                          onChange={(e) =>
                            setUserForm({
                              ...userForm,
                              lName: e.target.value,
                            })
                          }
                          disabled
                          placeholder="e.g. Bovell"
                          id="lastname"
                          type="text"
                        />
                      </div>
                      <br />
                      <div className="email">
                        <label htmlFor="email">Email</label>
                        <input
                          value={userForm?.email}
                          onChange={(e) =>
                            setUserForm({
                              ...userForm,
                              lname: e.target.value,
                            })
                          }
                          disabled
                          placeholder="e.g. rb@apple.com"
                          id="email"
                          type="email"
                        />
                      </div>
                    </form>
                  </div>
                </section>
                {/* billing address */}
                <section
                  className="form__billing"
                  // style={{ border: "2px solid black" }}
                >
                  <div className="sections">
                    <div className="box billing">2</div>
                    <span>Billing Address</span>
                  </div>
                  <div className="address">
                    <label htmlFor="billing">Address</label>
                    <br />
                    <textarea
                      style={{ width: "100%" }}
                      value={userForm?.billingAddress}
                      // ref={inputRef}
                      onChange={(e) =>
                        setUserForm({
                          ...userForm,
                          billingAddress: e.target.value,
                        })
                      }
                      placeholder=""
                      id="billing"
                      type="address"
                    />
                  </div>
                </section>

                <div
                  className="form__confirmation"
                  style={{
                    // border: "2px solid black",
                    display: "flex",
                    justifyContent: "end",
                  }}
                  onClick={() => handleProfileSubmit()}
                >
                  <BlueButton text="Confirm Information" />
                </div>
              </div>
            </Box>
          </Fade>
        </Modal>
      </>
    </div>
  );
};
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "43%",
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
let priceRange = ["Enquiry", "Confirmed", "Completed", "Cancelled"];

export default Index;
