import React, { useEffect, useRef } from "react";
import RecievedMsg from "./RecievedMsg";
import { CircularProgress, Stack, useMediaQuery } from "@mui/material";
import SenderMsg from "./SenderMsg";
import { allImages } from "../../../../../../images";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import { BottomScrollListener } from "react-bottom-scroll-listener";

const MainChatArea = ({
  conversation,
  fetchMessages,
  selectedChat,
  hasMoreConverstion,
}) => {
  const scrollRef = useRef(null);
  const isSmallScreen = useMediaQuery("(max-width:600px)");


  useEffect(() => {
    // this is for the pagination in chats
    const element = scrollRef.current;
    let currentPage = 0; // resets the current page whenever the selected chat changes
    const handleScroll = (e) => {
      const totalScrollHeight = element.scrollHeight - 750; // this is scroll total height of the div
      if (Math.abs(element.scrollTop) > totalScrollHeight) {
        // if the user has reached 800px height away from top the loading starts
        currentPage++;
        fetchMessages(selectedChat?._id, currentPage);
      }
    };
    element.addEventListener("scroll", handleScroll);
    return () => element.removeEventListener("scroll", handleScroll);
  }, [selectedChat]);

  return (
    <Stack
      className="scrollsmall"
      style={{
        // backgroundImage: `url(${allImages?.doodles})`,
        padding: isSmallScreen? '6px': "1rem 3rem",
        flexGrow: "2",
        flexDirection: "column-reverse",
        gap: 2,
        overflowY: "scroll",
        height: "400px",
      }}
      ref={scrollRef}
    >
      {conversation &&
        conversation?.length > 0 &&
        conversation?.map((value, ind) => {
          return (
            <React.Fragment key={ind}>
              {value?.isMessageSend ? (
                <SenderMsg content={value?.msg} image={value?.profileImage} />
              ) : (
                <RecievedMsg content={value?.msg} image={value?.profileImage} />
              )}
            </React.Fragment>
          );
        })}
      <Stack alignItems="center">
        {hasMoreConverstion && <CircularProgress />}
      </Stack>
    </Stack>
  );
};

export default MainChatArea;
