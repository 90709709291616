import { Button, Stack, TextField, Typography } from "@mui/material";
import React, { useState } from "react";

const Coupons = ({ handleApplyDiscount, querys, setQuerys }) => {
  // this is the logic container and Ui container
  const [couponCode, setCouponCode] = useState("");
  return (
    <Stack className="rounded__corner" gap={1} style={{
      backgroundColor:'#FEFEFE',
      borderRadius:'16px',
      padding:'24px'
    }}>
      <Typography sx={{ fontSize: "20px", fontWeight: "600" }}>
        Coupon Code
      </Typography>
      <Stack direction="row"  gap={2} style={{
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between',
        alignItems:'center'
      }} width="90%">
        <TextField
          variant="standard"
          className="redeem"
          // label="Enter the Coupon Code"
          fullWidth
          value={couponCode}
          onChange={(e) => setCouponCode(e.target.value)}
        />
        {/* <Stack sx={{}}> */}
          <Button
            className="coupon__button"
            variant="contained"
            sx={{
              background: "#47A561",
              borderRadius: "37.135px",
              color: "white",
              borderRadius:'8px',
              // flexGrow: "1rem",
              fontWeight: "700",
              padding: "12px 24px 12px 24px",
              width:'100px'
              // mt: 2,
            }}
            onClick={() =>
              !querys?.couponDetails
                ? handleApplyDiscount(couponCode, setCouponCode)
                : setQuerys((p) => {
                    return { ...p, couponDetails: null };
                  })
            }
          >
            {querys?.couponDetails ? "Remove" : "Apply"}
          </Button>
        {/* </Stack> */}
      </Stack>
    </Stack>
  );
};

export default Coupons;
