import { Box, Stack, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import BlueButton from "../BlueButton";
import parse from "html-react-parser";
import { encryptandDecrypt } from "../../../../config/EncrytDecryptData";
import { useSelector } from "react-redux";
import { defaultPrice } from "../../../../config/priceData";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
// const placeHolder = `Includes unlimited Q&A
// One-on-one conversation
// Provides high quality of services
// Quick responder
// Can cancel the meeting before the schedule date
// `;
const placeHolder = [
  "Includes unlimited Q&A ",
  "One-on-one conversation",
  "Provides high quality of services",
  "Quick responder",
  "Can cancel the meeting before the schedule date",
];

const SheduleSession = ({ data, children }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const userData = useSelector((state) => state?.user?.profileData);
  const isSmallScreen = useMediaQuery("(max-width:600px)");

  // console.log("thos ois ddata",data);

  return (
    <Stack
      px={2}
      py={0}
      // mt={3}
      mb={1}
      sx={{
        background: "#FEFEFE",
        border: " 0.742701px solid #D2D2D2",
        borderRadius: "15px",
        padding: "26px",
      }}
      // className="background__light"
    >
      <Stack direction="row" justifyContent={"space-between"}>
        <Stack direction="row" gap={3} alignItems="center">
          <Typography
            variant="h3"
            // className="heading"
            fontWeight="500"
            color={"#1F1F1F"}
            sx={{ fontSize: { xs: "19px", md: "28px" } }}
          >
            {data?.session?.title ? data?.session?.title : "Schedule Session"}
          </Typography>
          {children}
        </Stack>

        {/* <Typography
          variant="h3"
          fontWeight="700"
          sx={{ fontSize: { xs: "20px", md: "32px" } }}
        >
          ₹ {data?.session?.amount ? data?.session?.amount : defaultPrice} /hour{" "}
        </Typography> */}
      </Stack>
      {/* <Stack>
        <Typography
          className="sub__heading"
          variant="h3"
          fontWeight="600"
          sx={{ fontSize: { xs: "12px", md: "12px" } }}
        >
          What's Included
        </Typography>
      </Stack> */}
      <Stack mt={1} justifyContent="space-between">
        <Box
          style={{ whiteSpace: "pre-wrap", color: "#838181", fontSize: "18px" }}
        >
          {data?.session?.description
            ? parse(atob(data?.session?.description))
            : placeHolder?.map((data) => (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: "8px",
                  }}
                >
                  <CheckCircleOutlineOutlinedIcon />
                  <Typography
                    fontSize={"20px"}
                    color={"#838181"}
                    fontWeight={"500"}
                    marginLeft={"6px"}
                  >
                    {data}
                  </Typography>
                </div>
              ))}
        </Box>
        {pathname !== "/profile" && !userData?.mentor ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: "30px",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h3"
              fontWeight="600"
              sx={{ fontSize: { xs: "20px", md: "20px" } }}
            >
              <span style={{ color: "#9A9999", fontWeight: "600" }}>
                Price -
              </span>{" "}
              ₹ {data?.session?.amount ? data?.session?.amount : defaultPrice}{" "}
              /hour{" "}
            </Typography>

            <Box
              // alignSelf="end"
              pb={2}
              sx={{ flexGrow: "2rem", cursor: "pointer" }}
              onClick={() =>
                navigate(
                  `/booking/id?payload=${encryptandDecrypt.encrypt(data?._id)}`
                )
              }
            >
              <button
                text="Book Now"
                style={{
                  backgroundColor: "#FF5F73",
                  borderRadius: !isSmallScreen ? "38px" : "24px",
                  color: "#fff",
                  fontSize: !isSmallScreen ? "16px" : "12px",
                  padding: !isSmallScreen ? "16px 20px" : "8px 12px",
                }}
              >
                Book Now
              </button>
            </Box>
          </div>
        ) : null}
      </Stack>
    </Stack>
  );
};

export default SheduleSession;
