import { Grid, Rating, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";

const MentorRatings = ({ totalRating, allFeedback }) => {
  const [totalRate, setTotalRate] = useState(0);
  useEffect(() => {
    if (totalRating?.average) setTotalRate(totalRating?.average);
  }, [totalRating?.average]);
  console.log(allFeedback, "this is a feedback");
  return (
    <Stack>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Stack className="rounded">
            <Stack>
              <Typography variant="h3" className="textheading heading">
                Average Ratings
              </Typography>
            </Stack>
            <Stack my={1.5} alignItems="center">
              <Rating
                name="read-only"
                // value={totalRating?.average}
                value={totalRate}
                readOnly
                size="large"
              />
              <Typography variant="subtitle1" fontWeight="700" fontSize="20px">
                {totalRating?.average ? Math.round(totalRating?.average) : 0}
              </Typography>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack className="rounded">
            <Stack>
              <Typography variant="h3" className="textheading heading">
                Most Recent Reviews
              </Typography>
            </Stack>
            <Stack my={1.5} alignItems="center">
              <Typography
                variant="subtitle1"
                height="65px"
                px={2}
                lineHeight="19.1px"
                fontWeight="400"
                fontSize="14px"
              >
                <q>
                  {allFeedback && allFeedback?.length > 0
                    ? ` ${allFeedback[0]?.feedback} `
                    : ` No Reviews yet. `}
                </q>
                {/* " Best advice that I received and learnt new skills that will
                help for entire life. Thank you so much. " */}
              </Typography>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default MentorRatings;
