import React from "react";
import { Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import { allImages } from "../../../images";

const ActiveMembers = () => {
  const isSmallScreen = useMediaQuery("(max-width:600px)");

  return (
    <Stack
      style={{
        backgroundColor: "#F7F7F7",
        padding: "30px",
        height: isSmallScreen ? "200px" : "300px",
        justifyContent: "center",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginTop: "0px",
        marginBottom: "8px",
       
      }}
    >
      <Stack
        style={{
          width:isSmallScreen ? '98%' : "70%",
          display: "flex",
          flexDirection: "row",
          backgroundImage: `url(${allImages.socialProofFrame})`, // Add your image path here
          backgroundSize: "cover", // Cover the entire area
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center", // Center the image
          height: "90%",
          justifyContent: "space-evenly",
          alignItems: "center",
          borderRadius: isSmallScreen ? '12px': "30px",
          overflow: "hidden",
        }}
      >
        <Stack style={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
          <Typography
            variant="h4"
            style={{ color: "white", fontWeight: "bold",fontSize:isSmallScreen && '18px' }}
            alignSelf={"center"}
          >
            95%
          </Typography>
          <Typography
            variant="h5"
            style={{ color: "white", fontWeight: "600",textAlign:'center',fontSize:isSmallScreen && '11px' }}
          >
            Happy Members
          </Typography>
        </Stack>
        <Stack>
          <Typography
            variant="h4"
            style={{ color: "white", fontWeight: "bold",fontSize:isSmallScreen && '18px' }}
            alignSelf={"center"}
          >
            1000+
          </Typography>
          <Typography
            variant="h5"
            style={{ color: "white", fontWeight: "600",textAlign:'center' ,fontSize:isSmallScreen && '11px'}}
          >
            Expert Mentors
          </Typography>
        </Stack>
        <Stack>
          <Typography
            variant="h4"
            style={{ color: "white", fontWeight: "bold",fontSize:isSmallScreen && '18px' }}
            alignSelf={"center"}
          >
            10k+
          </Typography>
          <Typography
            variant="h5"
            style={{ color: "white", fontWeight: "600",textAlign:'center',fontSize:isSmallScreen && '11px' }}
          >
            Active Users
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ActiveMembers;
