import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { allImages } from "../../../../images";

const Profile = () => {
  return (
    <Stack
      sx={{ background: "white" }}
      py={2}
      border="0.745253px solid #D2D2D2"
      borderRadius=" 4.47152px"
      className="sticky__div"
    >
      <Stack display="flex" alignItems="center" width="100%">
        {/* <Box  width="121px" height="121px">
        <img  src={allImages.companyProfile} alt="profile" />
      </Box> */}
      </Stack>
      <Stack alignItems="center" mt={2}>
        <Typography
          variant="subtitle1"
          className="heading"
          fontWeight="600"
          fontSize="18px"
        >
          Notifications
        </Typography>
        <Typography
          className="sub__heading"
          variant="subtitle1"
          fontWeight="600"
          fontSize="14px"
          color="#727272"
        >
          Here is all your Notifications
        </Typography>
      </Stack>
      <Box>
        <hr />
      </Box>
      <Stack>
        <Link to="/profile" style={{ width: "100%" }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            gap={1}
            width="100%"
          >
            <Typography
              variant="subtitle1"
              color="#1C3464"
              fontWeight="600"
              fontSize="15px"
              className="heading"
            >
              View Profile
            </Typography>
            <Box width="20px" height="20px">
              {/* <img src={allImages.arrowvector} alt="arrow" /> */}
            </Box>
          </Stack>
        </Link>
      </Stack>
    </Stack>
  );
};

export default Profile;
