import React from "react";
import {
  AppBar,
  Box,
  Button,
  Container,
  CssBaseline,
  Stack,
} from "@mui/material";
import { allImages } from "../../../images";
import "./Footer.css";
import { HashLink as Link } from "react-router-hash-link";
import { useState } from "react";
import { motion } from "framer-motion";
import ResponsiveHeader from "dna-responsive-nav";
import "dna-responsive-nav/dist/dna-rn.css";
import { useLocation, useNavigate } from "react-router-dom";

const NavbarL = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleSearch = (kw) => {
    if (kw) {
      const url = "https://deniapps.com/search?kw=" + encodeURIComponent(kw);
      window.open(url, "_blank");
    }
  };

  // const handleClick = () => {
  //   sessionStorage.clear();
  //   localStorage.clear();
  //   navigate("/app/#/admin");
  // };

  return (
    <>
      <Stack
        height="65px"
        direction="row"
        alignItems="center"
        width="100%"
        className="appbar"
        sx={{ overflow: "hidden" }}
      >
        <CssBaseline />
        <Stack
          direction="row"
          justifyContent="space-between"
          width="100%"
          alignItems="center"
          sx={{ display: { md: "none" }, zIndex: "1000" }}
        >
          <Box
            ml={1}
            width="25px"
            height="25px"
            position="relative"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {!open ? (
              <img
                src={allImages.menuWhite}
                alt=""
                onClick={() => setOpen(!open)}
              />
            ) : (
              <img
                src={allImages.menuicon}
                alt=""
                onClick={() => setOpen(!open)}
              />
            )}
            {!open &&(
            <Box
              display="flex"
              alignItems="start"
              onClick={() => navigate("/")}
            >
              <img
                src={allImages.logoicon}
                alt="logo"
                style={{
                  objectFit: "cover",
                  height: "55px",
                  // width: "100%",
                  width: "200px",
                  padding: "12px",
                  cursor: "pointer",
                  objectFit: "contain",
                  // objectPosition: "100% 100%",
                }}
              />
            </Box>
             )}
          </Box>

          <Stack mr={1} sx={{ display: { md: "none" }, zIndex: "1000" }}>
            <Button
              href="#/home"
              variant="contained"
              className="button__login"
              sx={{
                // height: "45px",
                // width: "105px",
                borderRadius: "10px",
                background: "white",
                color: "#1C3464",
                font: "18px",
                fontWeight: "900",
                backgroundColor: "#fffff",
                "&.MuiButtonBase-root:hover": {
                  bgcolor: "white",
                  color: "#1C3464",
                },
              }}
            >
              Login
            </Button>
          </Stack>
        </Stack>

        {open && (
          <Stack
            initial={{ height: 0 }}
            animate={{ height: "100vh" }}
            transition={{
              type: "tween",
              duration: 0.7,
            }}
            exit={{ width: 0 }}
            component={motion.div}
            className="navbar__show"
            position="fixed"
            justifyContent="center"
            alignItems="center"
            sx={{
              backgroundColor: "#E1E1E1",
              zIndex: "10",
              top: "0",
              width: "100%",
              height: "100vh",
            }}
          >
            <Stack gap={5} alignItems="center">
            {location.pathname !== '/home/chat' && (
              <Box onClick={() => setOpen(!open)}>
                <Link to="/" style={styles2}>
                  {" "}
                  Home
                </Link>{" "}
              </Box>
            )}
              <Box onClick={() => setOpen(!open)}>
                <Link to="/contact-us" style={styles2}>
                  {" "}
                  Contact Us{" "}
                </Link>{" "}
              </Box>
              {location.pathname !== "/home/chat" && (
                <Box onClick={() => setOpen(!open)}>
                  <Link to="/about" style={styles2}>
                    {" "}
                    About Us{" "}
                  </Link>{" "}
                </Box>
              )}
              <Box onClick={() => setOpen(!open)}>
                <Link to="#purpose" style={styles2}></Link>{" "}
              </Box>
            </Stack>
          </Stack>
        )}
        <Stack
          sx={{ px: { xs: 5, md: 14 }, display: { xs: "none", md: "block" } }}
          alignItems="center"
          width="100%"
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box
              display="flex"
              alignItems="start"
              onClick={() => navigate("/")}
            >
              <img
                src={allImages.logoicon}
                alt="logo"
                style={{
                  objectFit: "cover",
                  height: "55px",
                  // width: "100%",
                  width: "200px",
                  padding: "12px",
                  cursor: "pointer",
                  objectFit: "contain",
                  // objectPosition: "100% 100%",
                }}
              />
            </Box>

            <Stack direction="row" gap={7}>
              <Stack direction="row" gap={5} alignItems="center">
                {/* <Box>
                  <Link to="#discover" style={styles}>
                    {" "}
                    Discover{" "}
                  </Link>{" "}
                </Box>

                <Box>
                  <Link to="#purpose" style={styles}>
                    {" "}
                    Purpose{" "}
                  </Link>{" "}
                </Box> */}

                {location.pathname !== "/home/chat" && (
                  <>
                    <Box>
                      <Link to="/home/chat" style={styles}>
                        {" "}
                        Home{" "}
                      </Link>{" "}
                    </Box>
                    {/* <Box>
                  <Link to="#features" style={styles}>
                    {" "}
                    Features{" "}
                  </Link>{" "}
                </Box> */}
                  </>
                )}
                {location.pathname !== "/home/chat" &&
                  location.pathname !== "/contact-us" &&
                  location.pathname !== "/about" && (
                    <Box>
                      <Link to="#features" style={styles}>
                        {" "}
                        Features{" "}
                      </Link>{" "}
                    </Box>
                  )}
                <Box>
                  {/* heheheheh */}
                  <Link to="/contact-us" style={styles}>
                    {" "}
                    Contact Us{" "}
                  </Link>{" "}
                </Box>
                {/* <Box>
                  <Link to="/resources" style={styles}>
                    {" "}
                    Resources{" "}
                  </Link>{" "}
                </Box> */}
                {location.pathname !== "/home/chat" && (
                  <Box>
                    <Link to="/about" style={styles}>
                      {" "}
                      About Us{" "}
                    </Link>{" "}
                  </Box>
                )}
              </Stack>
              <Box className="top-line"> </Box>
              <Stack direction="row" gap={4} alignItems="center">
                {location.pathname !== "/home/chat" &&
                  location.pathname !== "/contact-us" &&
                  location.pathname !== "/about" && (
                    <Box>
                      <a href="/app/#/admin" style={styles}>
                        Partner Login{" "}
                      </a>{" "}
                    </Box>
                  )}
                <Box>
                  <a href="#/signup" style={styles}>
                    Sign up{" "}
                  </a>{" "}
                </Box>
                <Button
                  href="#/login"
                  variant="contained"
                  className="button__login"
                  sx={{
                    height: "40px",
                    width: "105px",
                    borderRadius: "10px",
                    background: "white",
                    color: "#1C3464",
                    font: "18px",
                    fontWeight: "900",
                    backgroundColor: "#fffff",
                    "&.MuiButtonBase-root:hover": {
                      bgcolor: "white",
                      color: "#1C3464",
                    },
                  }}
                >
                  Login
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

const styles = {
  textDecoration: "none",
  color: "white",
  fontSize: "16px",
  fontWeight: "400",
  fontStyle: "normal",
};
const styles2 = {
  textDecoration: "none",
  color: "black",
  fontSize: "18px",
  fontWeight: "600",
  fontStyle: "normal",
};
export default NavbarL;
