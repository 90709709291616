import React from "react";
import { HashRouter, Routes, Route } from "react-router-dom";
import Categoriespage from "./pages/Categoriespage";
import Home from "./pages/Home";
import Mentor from "./pages/Mentor";
import { ToastContainer } from "react-toastify";
import Signin from "./components/general/Signin";
import Signup from "./components/general/Signup";
import ForgotPassword from "./components/general/ForgotPassword";
import AboutUs from "./components/general/AboutUs";
import MentorProfilepage from "./pages/MentorProfilepage";
import { createTheme, ThemeProvider } from "@mui/material";
import Privateroute from "./pages/Privateroute";
import LandingPage from "./pages/LandingPage";
import ProfileUpdate from "./components/privateRoute/userRoute/ProfileUpdate";
import { theme } from "../src/theme/theme";
import { Provider, useSelector } from "react-redux";
import { store } from "./components/privateRoute/userRoute/categoriespage/redux";
import ManagePage from "./pages/ManagePage";
import Notification from "./pages/Notification";
import Loader from "./components/privateRoute/userRoute/Loader";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Profile from "./pages/Profile";
import SettingsPage from "./pages/SettingsPage";
import Availability from "./components/privateRoute/userRoute/settings/Availability";
import General from "./components/privateRoute/userRoute/settings/General";
import Session from "./pages/Session";
import Index from "./components/privateRoute/userRoute/mentorProfileComplete/Index";
import MentorCompletness from "./pages/MentorProfileCompletness";
import BookingPage from "./pages/BookingPage";
import TermsCondition from "./components/general/LandingPage/Terms&Condition";
import PrivacyPolicy from "./components/general/LandingPage/PrivacyPolicy";
import RefundPolicy from "./components/general/LandingPage/RefundPolicy";
import Invoice from "./components/privateRoute/userRoute/Manage/Invoice";
import BankDetails from "./components/privateRoute/userRoute/settings/BankDetails";
import Coupons from "./pages/Coupons";
import LinkedInPage from "./components/general/LinkedInPage";
import "./components/Themes/themes.scss";
import Chats from "./components/privateRoute/userRoute/chats/Index";
import { useEffect } from "react";
import { io } from "socket.io-client";
import ContactUs from "./components/general/contactus/Index";
import Resources from "./components/general/resources/Index";
import ChatHomePage from "./components/general/ChatHomePage/Index";
import MentorsListingPage from "./components/general/MentorsListingPage/Index";
let socket;

const App = () => {
  const passcheck = false;

  return (
    <>
      <ThemeProvider theme={theme}>
        <Routes>
          <Route path={"/"} element={<LandingPage />} />
          <Route path={"terms&condition"} element={<TermsCondition />} />
          <Route path={"privacy-policy"} element={<PrivacyPolicy />} />
          <Route path={"refund-policy"} element={<RefundPolicy />} />
          <Route path={"/login"} element={<Signin />} />
          <Route path={"/signup"} element={<Signup />} />
          <Route
            path={"/forgot-password"}
            element={<ForgotPassword passcheck={passcheck} />}
          />
          <Route path={"/demo"} element={<Invoice />} />
          <Route path={"/about"} element={<AboutUs />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/resources" element={<Resources />} />
          <Route path="/home/chat" element={<ChatHomePage />} />
          <Route path="/home/mentors/list" element={<MentorsListingPage />} />

          {/* <Route path="/linkedin" element={< />} /> */}

          {/* private route */}
          <Route element={<Privateroute />}>
            <Route path={"home"} element={<Home />} />
            <Route path={"update"} element={<MentorCompletness />} />
            <Route path={"mentor"} element={<Mentor />} />
            <Route path={"categories/:title"} element={<Categoriespage />} />
            <Route path={"notification"} element={<Notification />} />
            <Route path={"profile"} element={<Profile />} />
            <Route path={"manage"} element={<ManagePage />} />
            <Route path={"mentors/:id"} element={<MentorProfilepage />} />
            <Route path={"sessions"} element={<Session />} />
            <Route path={"coupons"} element={<Coupons />} />
            <Route path={"booking/:id"} element={<BookingPage />} />
            <Route path={"chats"} element={<Chats socket={socket} />} />
            <Route path={"settings"} element={<SettingsPage />}>
              <Route path={"availability"} element={<Availability />} />
              <Route path={"general"} element={<General />} />
              <Route path={"bankdetails"} element={<BankDetails />} />
            </Route>
          </Route>
          {/* <Route path="/demo" element={<Demo />} /> */}
          <Route path="*" element={<div>Page does not exist</div>} />
        </Routes>
        <ToastContainer />
      </ThemeProvider>
    </>
  );
};

export default App;
