import { Container, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import ConfigAPIURL from "../../../../config/ConfigAPIURL";
import LocalStorage from "../../../../config/LocalStorage";
import APIRequest from "../../../../utils/APIRequest";
import MentorCoreSkills from "./MentorCoreSkills";
import MentorDetailsAccordion from "./MentorDetailsAccordion";
import MentorFollow from "./MentorFollow";
import MentorFollowRating from "./MentorFollowRating";
import MentorProfileArea from "./MentorProfileArea";
import SheduleSession from "./SheduleSession";
import OfferingsAccordion from "./OfferingsAccordion.jsx";
import Offerings from "../UserProfile/Offerings";
import ProfileExperience from "./ProfileExperience";
import EducationAccordion from "./EducationAccordion";
import AchievementAccordion from "./AchievementAccordion";
import CertificationAccordion from "./CertificationAccordion";
import SocialMedia from "./SocialMedia";
import SocialMedia2 from "./SocialMedia2";
import Demo from "../UserProfile/Demo";
import { encryptandDecrypt } from "../../../../config/EncrytDecryptData";
const Index = () => {
  const [userInfo, setUserInfo] = useState();
  const [userAdditionalInfo, setUserAdditionalInfo] = useState();
  const [totalRating, setTotalRating] = useState();
  const [userStatics, setUserStatics] = useState();
  const [change, setChange] = useState(false);

  // const params = useParams();
  const { search } = useLocation();

  //fetching mentor profile and additional profile data
  useEffect(() => {
    const mentorId = encryptandDecrypt?.decrypt(search?.split("=")[1]);
    if (!mentorId) return;
    fetchMentorsData(mentorId);
    fetchMentorsAdditionalData(mentorId);
    fetchMentorTotalRating(mentorId);
  }, [search]);

  useEffect(() => {
    const mentorId = encryptandDecrypt.decrypt(search?.split("=")[1]);
    if (!mentorId) return;
    fetchUserStatistics(mentorId);
  }, [change, search]);

  const fetchMentorsData = async (mentorId) => {
    try {
      const response = await APIRequest.request(
        "GET",
        ConfigAPIURL.userProfile + "?userId=" + mentorId,
        ""
      );
      if (response?.data?.responseCode === 109) {
        setUserInfo(response?.data?.profile);
      }
    } catch {}
  };

  const fetchMentorsAdditionalData = async (mentorId) => {
    try {
      const response = await APIRequest.request(
        "POST",
        ConfigAPIURL.userAdditionalInfo,
        JSON.stringify({
          userId: mentorId,
        })
      );
      if (response?.data?.responseCode === 109) {
        setUserAdditionalInfo(response?.data?.profile[0]);
      }
    } catch {}
  };
  //total rating
  const fetchMentorTotalRating = async (mentorId) => {
    try {
      const response = await APIRequest.request(
        "POST",
        ConfigAPIURL.totalRatingReviewCount,
        JSON.stringify({
          mentorId: mentorId,
        })
      );
      if (response?.data?.responseCode === 109) {
        setTotalRating(response?.data?.result[0]);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const fetchUserStatistics = async (mentorId) => {
    try {
      const response = await APIRequest.request(
        "GET",
        ConfigAPIURL.userStatistics + "?currentUserId=" + mentorId,
        ""
      );
      if (response?.code === 100 && response?.data?.responseCode === 109) {
        // setTotalReviews(response?.data?.result);
        console.log(response);
        setUserStatics(response?.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      {/* <Container maxWidth="lg"> */}
      <Grid container spacing={1} sx={{ mb: 2 }}>
        {/* <Grid item xs={12} md={2}></Grid> */}
        <Container>
          <Grid
            item
            xs={12}
            md={12}
            sx={{
              // mt: { xs: 2, md: 0 },
              // mx: { xs: 1, md: 0 },
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <Grid container spacing={1}>
              <Grid item xs={12} md={4}>
                {/* only renders if data is present */}
                <Stack
                  className="sticky__div"
                  mt={3}
                  px={2}
                  py={3}
                  sx={{
                    background: "white",
                    border: { md: "0.742701px solid #D2D2D2" },
                    borderRadius: "24px",
                    padding: "26px",
                  }}
                >
                  {userInfo && (
                    <>
                      <MentorFollow
                        changes={{ change, setChange }}
                        userStatics={userStatics}
                        userDetails={userInfo}
                      />
                      {/* <MentorFollowRating
                      userStatics={userStatics}
                      userData={userInfo}
                      totalRating={totalRating}
                    /> */}
                      <MentorCoreSkills userSkills={userInfo} />
                    </>
                  )}
                  {userAdditionalInfo && (
                    <SocialMedia2 data={userAdditionalInfo} />
                  )}
                </Stack>
              </Grid>
              <Grid item xs={12} md={8} justifySelf="center">
                {/* {userInfo && (
                <>
                  <SheduleSession data={userInfo} />
                  <Offerings data={userInfo} />
                </>
              )} */}

                {/*if  userAdditional  available  */}
                {userAdditionalInfo && (
                  <div style={{ margin: "20px" }}>
                    <MentorDetailsAccordion
                      title={"About"}
                      data={userAdditionalInfo}
                    />
                    <ProfileExperience
                      title="Work Experience"
                      data={userAdditionalInfo}
                    />

                    <EducationAccordion
                      title="Education"
                      data={userAdditionalInfo}
                    />
                    <AchievementAccordion
                      title="Awards/Recognitions"
                      data={userAdditionalInfo}
                    />
                    <CertificationAccordion
                      title="Certifications"
                      data={userAdditionalInfo}
                    />
                  </div>
                )}
              </Grid>
            </Grid>
          </Grid>

          {/* <Container> */}
          <Grid
            item
            xs={12}
            md={12}
            sx={{ mt: { xs: 2, md: 0 }, mx: { xs: 2, md: 0 } }}
            // style={{ marginLeft: "90px", marginRight: "90px" }}
          >
            <Typography
              sx={{
                fontWeight: "600",
                fontSize: "28px",
                color: "#343434",
                marginBottom: "0px",
                marginTop: "24px",
              }}
            >
              Book a Session with Mentor
            </Typography>
            <Stack
              className="sticky__div"
              mt={1.5}
              px={0}
              py={0}
              sx={
                {
                  // background: "#FEFEFE",
                  // border: { md: "0.742701px solid #D2D2D2" },
                  // borderRadius: "28px",
                }
              }
            >
              {userInfo && (
                <>
                  <SheduleSession data={userInfo} />
                  {/* <Offerings data={userInfo} /> */}
                </>
              )}
            </Stack>
          </Grid>
        </Container>
      </Grid>
      {/* </Container> */}
    </>
  );
};

export default Index;
