import React from "react";
import parse from "html-react-parser";
import "./landingpage.css";

const terms = ` <p style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";'><strong><span style='font-size:16px;font-family:"Segoe UI","sans-serif";color:#363636;background:white;'>Terms &amp; Conditions</span></strong></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";background:white;'><span style='font-size:16px;font-family:"Segoe UI","sans-serif";color:#4A4A4A;'>By using the web app, these terms will automatically apply to you &ndash; you should make sure therefore that you read them carefully before using the app. You&rsquo;re not allowed to copy or modify the app, any part of the app, or our trademarks in any way. You&rsquo;re not allowed to attempt to extract the source code of the app, and you also shouldn&rsquo;t try to translate the app into other languages or make derivative versions. The app itself, and all the trademarks, copyright, database rights, and other intellectual property rights related to it, still belong to INNOCATALYSTS ADVISORS PRIVATE LIMITED.</span></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";background:white;'><span style='font-size:16px;font-family:"Segoe UI","sans-serif";color:#4A4A4A;'>INNOCATALYSTS ADVISORS PRIVATE LIMITED is committed to ensuring that the app is as useful and efficient as possible. For that reason, we reserve the right to make changes to the app or to charge for its services, at any time and for any reason. We will never charge you for the app or its services without making it very clear to you exactly what you&rsquo;re paying for.</span></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";background:white;'><span style='font-size:16px;font-family:"Segoe UI","sans-serif";color:#4A4A4A;'>The Mentorlink app stores and processes personal data that you have provided to us, to provide our Service. It&rsquo;s your responsibility to keep your phone and access to the app secure. We therefore recommend that you do not jailbreak or root your phone, which is the process of removing software restrictions and limitations imposed by the official operating system of your device. It could make your phone vulnerable to malware/viruses/malicious programs, compromise your phone&rsquo;s security features and it could mean that the Mentorlink app won&rsquo;t work properly or at all.</span></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";background:white;'><span style='font-size:16px;font-family:"Segoe UI","sans-serif";color:#4A4A4A;'>The app does use third-party services that declare their Terms and Conditions.</span></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";background:white;'><span style='font-size:16px;font-family:"Segoe UI","sans-serif";color:#4A4A4A;'>Link to Terms and Conditions of third-party service providers used by the app</span></p>
<ul style="list-style-type: disc;margin-left:20px;">
    <li><a href="https://firebase.google.com/terms/analytics" target="_blank"><span style='font-family:"Segoe UI","sans-serif";text-decoration:none;'>Google Analytics for Firebase</span></a></li>
    <li><a href="https://firebase.google.com/terms/crashlytics" target="_blank"><span style='font-family:"Segoe UI","sans-serif";text-decoration:none;'>Firebase Crashlytics</span></a></li>
</ul>
<p style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";background:white;'><span style='font-size:16px;font-family:"Segoe UI","sans-serif";color:#4A4A4A;'>You should be aware that there are certain things that INNOCATALYSTS ADVISORS PRIVATE LIMITED will not take responsibility for. Certain functions of the app will require the app to have an active internet connection. The connection can be Wi-Fi or provided by your mobile network provider, but INNOCATALYSTS ADVISORS PRIVATE LIMITED cannot take responsibility for the app not working at full functionality if you don&rsquo;t have access to Wi-Fi, and you don&rsquo;t have any of your data allowance left.</span></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";background:white;'><span style='font-size:16px;font-family:"Segoe UI","sans-serif";color:#4A4A4A;'>If you&rsquo;re using the app outside of an area with Wi-Fi, you should remember that the terms of the agreement with your mobile network provider will still apply. As a result, you may be charged by your mobile provider for the cost of data for the duration of the connection while accessing the app, or other third-party charges. In using the app, you&rsquo;re accepting responsibility for any such charges, including roaming data charges if you use the app outside of your home territory (i.e. region or country) without turning off data roaming. If you are not the bill payer for the device on which you&rsquo;re using the app, please be aware that we assume that you have received permission from the bill payer for using the app.</span></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";background:white;'><span style='font-size:16px;font-family:"Segoe UI","sans-serif";color:#4A4A4A;'>Along the same lines, INNOCATALYSTS ADVISORS PRIVATE LIMITED cannot always take responsibility for the way you use the app i.e. You need to make sure that your device stays charged &ndash; if it runs out of battery and you can&rsquo;t turn it on to avail the Service, INNOCATALYSTS ADVISORS PRIVATE LIMITED cannot accept responsibility.</span></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";background:white;'><span style='font-size:16px;font-family:"Segoe UI","sans-serif";color:#4A4A4A;'>With respect to INNOCATALYSTS ADVISORS PRIVATE LIMITED&rsquo;s responsibility for your use of the app, when you&rsquo;re using the app, it&rsquo;s important to bear in mind that although we endeavor to ensure that it is updated and correct at all times, we do rely on third parties to provide information to us so that we can make it available to you. INNOCATALYSTS ADVISORS PRIVATE LIMITED accepts no liability for any loss, direct or indirect, you experience as a result of relying wholly on this functionality of the app.</span></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";background:white;'><span style='font-size:16px;font-family:"Segoe UI","sans-serif";color:#4A4A4A;'>At some point, we may wish to update the app. The app is currently available on Android &ndash; the requirements for the system(and for any additional systems we decide to extend the availability of the app to) may change, and you&rsquo;ll need to download the updates if you want to keep using the app. INNOCATALYSTS ADVISORS PRIVATE LIMITED does not promise that it will always update the app so that it is relevant to you and/or works with the Android version that you have installed on your device. However, you promise to always accept updates to the application when offered to you, We may also wish to stop providing the app, and may terminate use of it at any time without giving notice of termination to you. Unless we tell you otherwise, upon any termination, (a) the rights and licenses granted to you in these terms will end; (b) you must stop using the app, and (if needed) delete it from your device.
<br/>
<br/>
This agreement is governed by Indian laws, with exclusive jurisdiction of courts in Bengaluru

<br/>
<br/>
<b>LIABILITY</b>
<br/>
User agrees that neither Company nor its group companies, directors, officers or employees shall be liable for any direct or/and indirect or/and incidental or/and special or/and consequential or/and exemplary damages, resulting from the use or/and the inability to use the service or resulting from any data or/and information or/and services purchased or/and obtained or/and messages received or/and transactions entered into through or/and from the service or/and resulting from unauthorized access to or/and alteration of user’s transmissions or/and data or/and arising from any other matter relating to the service, including but not limited to, damages for loss of profits or/and use or/and data or other intangible, even if Company has been advised of the possibility of such damages. User further agrees that Company shall not be liable for any damages arising from interruption, suspension or termination of service, including but not limited to direct or/and indirect or/and incidental or/and special consequential or/and exemplary damages, whether such interruption or/and suspension or/and termination was justified or not, negligent or intentional, inadvertent or advertent. User agrees that Company shall not be responsible or liable to user, or anyone, for the statements or conduct of any third party of the service. In sum, in no event shall Company’s total liability to the User for all damages or/and losses or/and causes of action exceed the amount paid by the User to Company, if any, that is related to the cause of action.
<br/>
<br/>

</span></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";background:white;'><strong><span style='font-size:16px;font-family:"Segoe UI","sans-serif";color:#363636;'>Changes to This Terms and Conditions</span></strong></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";background:white;'><span style='font-size:16px;font-family:"Segoe UI","sans-serif";color:#4A4A4A;'>We may update our Terms and Conditions from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you of any changes by posting the new Terms and Conditions on this page.</span></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";background:white;'><span style='font-size:16px;font-family:"Segoe UI","sans-serif";color:#4A4A4A;'>These terms and conditions are effective as of 2022-10-01</span></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";background:white;'><strong><span style='font-size:16px;font-family:"Segoe UI","sans-serif";color:#363636;'>Contact Us</span></strong></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";background:white;'><span style='font-size:16px;font-family:"Segoe UI","sans-serif";color:#4A4A4A;'>If you have any questions or suggestions about our Terms and Conditions, do not hesitate to contact us at support@mentorlink.in.</span></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:10.0pt;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri","sans-serif";'>&nbsp;</p> `;

const TermsCondition = () => {
  return (
    <div
      className="terms"
      style={{ background: "white", padding: "1rem 2rem", color: "black" }}
    >
      {parse(terms)}
    </div>
  );
};

export default TermsCondition;
