import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ConfigAPIURL from "../../../../config/ConfigAPIURL";
import APIRequest from "../../../../utils/APIRequest";
import Loader from "../Loader";
import SingleNotification from "./SingleNotification";

const Notifications = () => {
  const [query, setQuery] = useState({
    skipVal: 0,
    pageLength: 10,
  });
  const [notificationDate, setNotificationData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    //fetches all notifications
    fetchAllNotifications();
  }, []);
  const fetchAllNotifications = async (recordId) => {
    setIsLoading(true);
    try {
      const response = await APIRequest.request(
        "POST",
        ConfigAPIURL.userAllNotifications,
        JSON.stringify(query)
      );
      if (response?.code === 100 && response?.data?.responseCode === 109) {
        setNotificationData(response?.data?.result);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const updateNotifications = async (recordId) => {
    console.log(recordId);

    try {
      const response = await APIRequest.request(
        "PUT",
        ConfigAPIURL.updateNotifications,
        JSON.stringify({ recordId })
      );
      if (response?.code === 100 && response?.data?.responseCode === 109) {
        navigate("/manage");
      }
    } catch (err) {
      console.log(err);
    }
  };

  window.onscroll = function (ev) {
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
      console.log("reached down");
    }
  };
  if (isLoading) return <Loader />;

  if (notificationDate.length === 0) {
    return (
      <Stack alignItems="center">
        <Box width="350px" alignSelf="center">
          <img
            src={
              "https://s3.ap-south-1.amazonaws.com/mentorlink/profile/1664432569933_Group%2034753.png"
            }
            style={{ objectFit: "contain" }}
            alt="booking Not found"
          />
          <Typography variant="subtitle1" textAlign="center">
            No Notifications Found
          </Typography>
        </Box>
      </Stack>
    );
  }

  return (
    <Stack
      sx={{
        background: "white",
        mt: {
          xs: "1rem",
          md: "0",
          border: { xs: 0, md: "0.742701px solid #D2D2D2" },
        },
      }}
      borderRadius="4.4562px"
      py={2}
      mb={2}
    >
      {notificationDate && notificationDate?.length > 0 ? (
        notificationDate?.map((val, ind) => {
          if (!val?.body) return;
          return (
            <SingleNotification
              data={val}
              key={ind}
              updateNotifications={updateNotifications}
            />
          );
        })
      ) : (
        <Box sx={{ width: "60%", margin: "auto" }}>
          <img
            src="https://s3.ap-south-1.amazonaws.com/mentorlink/notification/1667626561779_rafiki.svg"
            alt="no notification found"
          />
          <Typography variant="subtitle1" textAlign="center">
            No notifications yet
          </Typography>
        </Box>
      )}
    </Stack>
  );
};

export default Notifications;
