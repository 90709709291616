import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { useState } from "react";
import { allImages } from "../../../../images";

const SocialMedia2 = ({ data }) => {
  return (
    <div>
      {data?.socialMedia?.length > 0 && (

        <Typography  variant="h2"
        className="heading"
        // fontSize="20px"
        fontWeight="600"
        color={'#343434'}
        marginTop={'8px'}
        >
        Contacts
      </Typography>
            )}
      {data?.socialMedia?.length > 0 &&
        data?.socialMedia?.map((value) => {
          return (
            <Stack direction="column" key={value._id}>
              <Typography
                textTransform="capitalize"
                variant="subtitle1"
                color="black"
                fontSize="16px"
                fontWeight="700"
              >
                {value?.title}
              </Typography>
              <Stack direction="row" gap={3}>
                <Typography style={style} variant="subtitle1">
                  {value?.link}
                </Typography>
                {value?.link && value?.title && (
                  <Box width="15px" height="14px">
                    <a href={value?.link}>
                      <img src={allImages.linkShareIcon} alt="link share" />
                    </a>
                  </Box>
                )}
              </Stack>
            </Stack>
          );
        })}
    </div>
  );
};
const style = {
  color: "black",
  fontSize: "14px",
  fontWeight: "600",
  color: "blue",
};

export default SocialMedia2;
