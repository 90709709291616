import {
  FormControl,
  Select,
  Stack,
  TextField,
  InputLabel,
  MenuItem,
  Typography,
  Grid,
} from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import ConfigAPIURL from "../../../../config/ConfigAPIURL";
import APIRequest from "../../../../utils/APIRequest";

const AcademicDetails = ({ register, userProfile }) => {
  const [allQualification, setAllQualification] = useState([]);
  const [highestQualification, setHighestQualification] = useState('');

  // Fetch data from API and update state
  useEffect(() => {
    if (userProfile?.education?.highestQualification) {
      setHighestQualification(userProfile.education.highestQualification);
    }
  }, [userProfile]);

  useEffect(() => {
    fetchQualification();
  }, []);

  const fetchQualification = async () => {
    try {
      const response = await APIRequest.request(
        "GET",
        ConfigAPIURL.highestQualification,
        ""
      );
      if (response?.data?.responseCode === 109) {
        setAllQualification(response?.data?.qualifications);
      }
    } catch (err) {
      console.log(err)

    }
  };

  console.log("this ia acadmic",userProfile);
  return (
    <Stack mt={4}>
      <Typography
            style={{ fontWeight: 600, fontSize: "22px", alignSelf: "start",marginBottom:'12px' }}
          >
            Academic Details
          </Typography>
      <form style={{ display: "flex", flexDirection: "row", gap: 24 }}>
        <Grid container spacing={4}>
      <Grid item xs={12} md={8} lg={6} >
        <FormControl style={{width:'100%'}}>
          <InputLabel
            sx={{
              fontSize: "14px",   
              lineHeight: '12px', // Adjust this value to vertically center the label
          }}
            id="demo-simple-select-autowidth-label"
          >
            Highest Qualification
          </InputLabel>
          <Select
          style={{display:'flex',justifyContent:'center',alignItems:'center'}}
            labelId="demo-simple-select-autowidth-label"
            id="demo-simple-select-autowidth"
            autoWidth
            label="Highest Qualification"
            fullWidth
            size="small"
            // defaultValue={userProfile?.education?.highestQualification}
            value={userProfile?.education?.highestQualification?userProfile?.education?.highestQualification :highestQualification}
            {...register("highestQualification")}
          >
            {allQualification?.length > 0 &&  allQualification?.map((value, ind) => {
              return <MenuItem key={ind} value={value?.title} style={{minWidth:"300px"}}
              onClick={() => setHighestQualification(value?.title)}
              >
                {value.title}
              </MenuItem>;
            })}
          </Select>
        </FormControl>
        </Grid>
        <Grid item xs={12} md={8} lg={6}>
        <TextField
          label="Specialization"  
          fullWidth
          // defaultValue={userProfile?.education?.specialization}
          defaultValue={userProfile?.education?.specialization ? userProfile?.education?.specialization :''}
          {...register("specialization")}
          InputLabelProps={{ style: { fontSize: 15 } }}
          size="small"
          type="text"
        />
        </Grid>
        </Grid>
      </form>
    </Stack>
  );
};

export default AcademicDetails;
