// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import React from "react";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import APIRequest from "../utils/APIRequest";
import ConfigAPIURL from "./ConfigAPIURL";

export const firebaseConfig = {
  apiKey: "AIzaSyAPZdKWEwVLxPBiHChNJQYfft1OxVf-9SM",
  authDomain: "mentorlink-bd1d1.firebaseapp.com",
  projectId: "mentorlink-bd1d1",
  storageBucket: "mentorlink-bd1d1.appspot.com",
  messagingSenderId: "751146626232",
  appId: "1:751146626232:web:142f47782d83797f09584d",
  measurementId: "G-G5YQFE7MHW",
};
// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

// firebase initialise

export const provider = new GoogleAuthProvider();
// to create the account in firebase
const accountCreate = async (uid, name, email, profileImage) => {
  return APIRequest.request(
    "POST",
    ConfigAPIURL.userAccountCreate,
    JSON.stringify({
      fname: name,
      email: email,
      signUpId: uid,
      profileImage: profileImage,
    })
  );
};
// check  whether the user exist
export const checksUserExist = async (uid, email) => {
  return APIRequest.request(
    "POST",
    ConfigAPIURL.userExist,
    JSON.stringify({
      signUpId: uid,
      email,
    })
  );
};

export const signInwithGoogle = async () => {
  const response = await signInWithPopup(auth, provider);
  const uid = response?.user?.uid;
  const name = response?.user?.displayName;
  const email = response?.user?.email;
  const profileImage = response?.user?.photoURL;
  // if no uid then returns
  if (!uid) return;
  // check user exist with same signup uid and same email then redirect back to home else go to signup page to create account
  const responseData = await checksUserExist(uid, email);
  if (responseData?.data?.responseCode === 109) {
    return responseData;
  }
  if (responseData?.data?.responseCode === 125) {
    //account creation
    const response = await accountCreate(uid, name, email, profileImage);
    return {
      response,
      uid,
      name,
      email,
      // profileImage,
    };
    // return Navigate("/update");
  }
};
