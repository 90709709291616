class LocalStorage {
  //Base URL For Live & Staging
  static theme = "dark";
  static lng = {
    displayName: "English (India /en_IN)",
    code: "en_IN",
  };
  static shopId = "";
  static shopName = "";
  static logo = "";

  static userDetails = {
    shopId: "",
    userId: "",
    email: "",
    mobileNo: "",
    name: "",
    permission: null,
    profileImage: "",
    userName: "",
    userType: "",
    deliveryAddress: [],
    associatedOrganization: {},
  };
  static userPermission = [];
}

export default LocalStorage;
