import {
  Alert,
  Button,
  Grid,
  Snackbar,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import "../LandingPage/landingpage.css";
import BlueButton from "../../privateRoute/userRoute/BlueButton";
import { Container } from "react-bootstrap";
import { allImages } from "../../../images";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import { styled } from "@mui/system";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate } from "react-router-dom";

const Herobanner = () => {
  const navigate = useNavigate();

  const SnackbarArea = () => {
    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
      setOpen(true);
    };

    const handleClose = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }

      setOpen(false);
    };
    return (
      <>
        <Button onClick={handleClick}>Open simple snackbar</Button>
        <Snackbar
          open={open}
          autoHideDuration={1000}
          onClose={handleClose}
          message="Note archived"
          // action={action}
        >
          <Alert
            onClose={handleClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            This is a success message!
          </Alert>
        </Snackbar>
      </>
    );
  };

  const StyledAvatarGroup = styled(AvatarGroup)(({ theme }) => ({
    "& .MuiAvatar-root": {
      width: 48, // Increase the size of the avatars
      height: 48, // Increase the size of the avatars
      fontSize: 24, // Adjust the font size if needed
    },
    "& .MuiAvatarGroup-avatar": {
      backgroundColor: "#00275A", // Change the background color of the surplus circle
      color: "white", // Change the text color inside the surplus circle
      fontSize: "16px",
    },
  }));
  const isSmallScreen = useMediaQuery("(max-width:600px)");

  return (
    <Grid
      container
      sx={{ my: 5, height: "100%", justifyContent: "space-between", rowGap: 5 }}
    >
      <Grid item md={6} xs={12}>
        <Stack
          sx={{
            justifyContent: "center",
            height: "100%",
            gap: 3,
            // rowGap:3
          }}
        >
          <Typography
            variant="subtitle1"
            className="banner__title  landing__title"
          >
            Boost Your Skills With Personalized 1 On 1 Mentorship Join
            Mentorlink Today
          </Typography>
          {isSmallScreen && (
            <Grid item md={12} xs={12} lg={5}>
              <img
                src={allImages.landingPageBannerTwo}
                alt="image banner"
                style={{
                  objectFit: "cover",
                  width: "80%",
                  maxWidth: isSmallScreen ? "100%" : "90%",
                  height: "auto",
                }}
              />
            </Grid>
          )}
          <Typography variant="subtitle1" className="landing__subheading">
            Unlock your potential with personalized, one-on-one mentorship
            tailored to your goals. Connect with experts, get actionable
            insights, and accelerate your growth with Mentorlink
          </Typography>
          <button
            style={{
              backgroundColor: "#00275A",
              width: "225px",
              color: "#fff",
              borderRadius: "8px",
              padding: "20px 30px 20px 30px",
              fontWeight: "400",
              textAlign: "center",
              marginBottom: "14px",
            }}
            onClick={() => navigate("/login")}
          >
            Get Started <ArrowForwardIcon style={{ marginLeft: "8px" }} />
          </button>
          <Stack
            sx={{
              justifyContent: "start",
              height: "100%",
              gap: 2,
              display: "flex",
              flexDirection: "row",
              // rowGap:3
            }}
          >
            <StyledAvatarGroup
              renderSurplus={(surplus) => (
                <span>+{surplus.toString()[0] + [0]}k</span>
              )}
              total={10251}
              max={6}
            >
              <Avatar alt="Remy Sharp" src={allImages.persona1} />
              <Avatar alt="Travis Howard" src={allImages.persona2} />
              <Avatar alt="Agnes Walker" src={allImages.persona3} />
              <Avatar alt="Trevor Henderson" src={allImages.persona4} />
              <Avatar alt="Trevor" src={allImages.persona5} />
            </StyledAvatarGroup>
          </Stack>
        </Stack>
      </Grid>
      {!isSmallScreen && (
      <Grid item md={12} xs={12} lg={5}>
        <img
          src={allImages.landingPageBannerTwo}
          alt="image banner"
          style={{
            objectFit: "cover",
            width: isSmallScreen ? "100%" : "100%",
            maxWidth: isSmallScreen ? "100%" : "90%",
            height: "auto",
          }}
        />
      </Grid>
      )}
    </Grid>
  );
};

export default Herobanner;
