import { Backdrop, CircularProgress } from "@mui/material";
import React from "react";

const BackdropComponent = ({ isOpen }) => {
//   const handleClose = () => {
//     setOpen(false);
//   };
  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={isOpen}
      //   onClick={handleClose}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default BackdropComponent;
