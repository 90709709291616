import { Box } from "@mui/material";
import React, { Children, useState } from "react";
import { Link } from "react-router-dom";
import { allImages } from "../../../../images";
import Modal from "./Modal";
import ModalAbout from "./ModalAbout";

const ModalParent = ({
  userData,
  children,
  setState,
  change,
  fetchUserAdditionalInfo,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <Box onClick={() => setOpen(true)} display="flex">
        <img
          style={{ width: "20px", height: "20px" }}
          className="mainicon"
          src={allImages.editPencil}
          alt="edit"
        />
      </Box>
      <Modal
        open={open}
        text="About"
        onClose={() => {
          fetchUserAdditionalInfo();
          setOpen();
        }}
      >
        {/* to be displayed inside the modal */}
        <ModalAbout
          data={userData}
          setChange={setState}
          change={change}
          onClose={() => setOpen()}
        />
      </Modal>
    </div>
  );
};

export default ModalParent;
