import { Box, Grid, TextField } from "@mui/material";
import React, { useState } from "react";
import { Stack } from "react-bootstrap";
import { Link } from "react-router-dom";
import { allImages } from "../../../../../images";
import Modal from "../Modal";
import JoditEditor from "jodit-react";
import { useRef } from "react";
import { useForm } from "react-hook-form";
import BlueButton from "../../BlueButton";
import APIRequest from "../../../../../utils/APIRequest";
import ConfigAPIURL from "../../../../../config/ConfigAPIURL";
import { useEffect } from "react";

const SheduleModels = ({ data, fetchUserInfo }) => {
  const [open, setOpen] = useState(false);
  const [editorValue, setEditorValue] = useState();
  const editor = useRef();
  const { register, handleSubmit } = useForm();
  useEffect(() => {
    if (data?.session?.description)
      setEditorValue(atob(data?.session?.description));
  }, []);

  const UpdateSession = async (e) => {
    const encodedString = btoa(editorValue);

    try {
      const response = await APIRequest.request(
        "PUT",
        ConfigAPIURL.UserProfileUpdate,
        JSON.stringify({
          session: {
            title: e.title,
            description: "Includes unlimited Q&A",
            approximateSessionTime: 30,
            amount: e.amount,
            description: encodedString,
          },
        })
      );
      fetchUserInfo();
      setOpen(false);
    } catch {}
  };

  return (
    <div>
      <Link to="/profile" onClick={() => setOpen(true)}>
        <img
          className="mainicon"
          // style={{ width: "20px", height: "20px" }}
          src={allImages.editPencil}
          alt=""
        />
      </Link>
      {/* modal to show */}
      <Modal open={open} text="Mentoring Plan" onClose={() => setOpen(false)}>
        <Stack>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                size="small"
                label="Plan Name"
                defaultValue={data?.session?.title}
                fullWidth
                {...register("title")}
              />
            </Grid>
            {/* <Grid item xs={6}>
              <TextField
                type="number"
                defaultValue={data?.session?.approximateSessionTime}
                {...register("sessionTime")}
                label="Approximate Session Time"
                fullWidth
              />
            </Grid> */}
            <Grid item xs={12}>
              <TextField
                size="small"
                type="number"
                defaultValue={data?.session?.amount}
                label="Plan Amount"
                fullWidth
                {...register("amount")}
              />
            </Grid>
            <Grid item xs={12}>
              <JoditEditor
                ref={editor}
                value={editorValue}
                onChange={(e) => setEditorValue(e)}
              />
            </Grid>
            <div></div>
          </Grid>
          <Box mt={3} alignSelf="end" onClick={handleSubmit(UpdateSession)}>
            <BlueButton text="Submit" size="lg" />
          </Box>
        </Stack>
      </Modal>
    </div>
  );
};

export default SheduleModels;
