import React from "react";
import { Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import { allImages } from "../../../images";

const MentorLinkFeatures = () => {
  const isSmallScreen = useMediaQuery("(max-width:600px)");

  return (
    <Stack
      style={{
        // backgroundColor: "#F7F7F7",
        padding: isSmallScreen ? "20px" : "30px",
        // height: "300px",
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "28px",
        marginBottom: "28px",
        // marginLeft: "58px",
        // marginRight: "58px",
      }}
    >
      <Stack my={2} style={{ alignSelf: "center", marginBottom: "48px" }}>
        <Typography
          variant="subtitle1"
          className="landing__heading banner__title"
          textAlign={"center"}
          lineHeight={"33px"}
          marginBottom={"8px"}
        >
          Elevate Your Experience with Mentor Link
        </Typography>
        <Typography
          variant="subtitle1"
          className="landing__content"
          alignSelf={"center"}
          textAlign={"center"}
          lineHeight={"28px"}
        >
          Unlock limitless growth opportunities, tailored guidance, and
          invaluable connections to propel your career forward
        </Typography>
      </Stack>
      <Grid container>
        <Grid
          item
          xs={12}
          lg={5}
          md={12}
          style={{
            backgroundColor: "#7FC2EB",
            padding: "36px",
            borderRadius: "30px",
          }}
        >
          <Stack>
            <Typography
              style={{ color: "#3E3E3E", fontWeight: "600", fontSize: "32px" }}
              alignSelf={"center"}
              lineHeight={"36px"}
            >
              Book Your Session At Your Convenience
            </Typography>
            <Typography
              variant="p"
              style={{ color: "#3E3E3E", fontWeight: "400" }}
              alignSelf={"center"}
              marginTop={"6px"}
              marginBottom={"24px"}
            >
              Select a time that fits your schedule and connect with the perfect
              mentor. Enjoy the flexibility to learn and grow at your
              convenience.
            </Typography>
            <Stack
              style={{ width: "320px", height: "320px", alignSelf: "center" }}
            >
              <img src={allImages.BookSessions} alt="" srcset="" />
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12} lg={7} md={12}>
          <Stack style={{ display: "flex", flexDirection: "row" }}>
            <Stack
              style={{
                backgroundColor: "#F6CFCF",
                margin: !isSmallScreen && "0px 16px 16px 16px",
                padding: "20px",
                borderRadius: "17px",
                // width:'400px',
                width: "100%",
                marginTop: isSmallScreen && "12px",
              }}
            >
              <Stack
                style={{
                  width: "100%",
                  height: "130px",
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "28px",
                }}
              >
                <div
                  style={{
                    backgroundColor: "#FFFFFF",
                    width: isSmallScreen ? "60px" : "100px",
                    height: isSmallScreen ? "60px" : "100px",
                    borderRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={allImages.gpayImg}
                    alt="gpay"
                    style={{
                      width: "60%",
                      height: "60%",
                      objectFit: "contain",
                    }}
                  />
                </div>
                <div
                  style={{
                    backgroundColor: "#FFFFFF",
                    width: isSmallScreen ? "60px" : "100px",
                    height: isSmallScreen ? "60px" : "100px",
                    borderRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginLeft: "12px",
                  }}
                >
                  <img
                    src={allImages.paytmImg}
                    alt=""
                    style={{
                      width: "60%",
                      height: "60%",
                      objectFit: "contain",
                    }}
                  />
                </div>
              </Stack>
              <Typography
                // variant="h4"
                style={{
                  color: "#3E3E3E",
                  fontWeight: "600",
                  fontSize: "32px",
                }}
                alignSelf={"start"}
              >
                Fast Payouts
              </Typography>
              <Typography
                variant="p"
                style={{ color: "#3E3E3E", fontWeight: "400" }}
                alignSelf={"start"}
              >
                Receive your earnings without delay.
              </Typography>
            </Stack>
            <Stack
              style={{
                backgroundColor: "#CFF1CE",
                margin: !isSmallScreen && "0px 16px 16px 0px",
                marginTop: isSmallScreen && "12px",
                marginLeft: isSmallScreen && "16px",
                marginRight: isSmallScreen && "12px",
                padding: "20px",
                borderRadius: "17px",
                // width:'400px',
                width: "100%",
                backgroundImage: `url(${allImages.AiIllus})`, // Corrected this line
                backgroundSize: "cover", // Optional: to ensure the background covers the whole area
                backgroundPosition: "center", // Optional: to center the background image
              }}
            >
              <Stack
                style={{
                  // width: "170px",
                  width: "100%",
                  height: "155px",
                  alignSelf: "center",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={allImages.AiWandImage}
                  style={{ height: "90px", width: "90px" }}
                />
              </Stack>
              <Typography
                style={{
                  color: "#3E3E3E",
                  fontWeight: "600",
                  fontSize: "32px",
                }}
                alignSelf={"start"}
              >
                AI Assist
              </Typography>
              <Typography
                variant="p"
                style={{ color: "#3E3E3E", fontWeight: "400" }}
                alignSelf={"start"}
              >
                Find your mentor easily
              </Typography>
            </Stack>
          </Stack>
          {/* ------------------------------ */}
          <Stack style={{ display: "flex", flexDirection: "row" }}>
            <Stack
              style={{
                backgroundColor: "#ECE3D0",
                margin: "0px 16px 0px 16px",
                padding: "20px",
                borderRadius: "17px",
                // width:'430px',
                width: "100%",
                marginLeft: isSmallScreen && "0px",
                marginTop: isSmallScreen && "12px",
              }}
            >
              <Stack
                style={{
                  width: "100%",
                  height: isSmallScreen ? "90px" : "165px",
                  alignSelf: "center",
                }}
              >
                <img
                  src={allImages.ExpertsImgs}
                  alt=""
                  srcset=""
                  style={{ objectFit: "contain", height: "100%" }}
                />
              </Stack>
              <Typography
                style={{
                  color: "#3E3E3E",
                  fontWeight: "600",
                  fontSize: "32px",
                  marginTop: "12px",
                }}
                alignSelf={"start"}
                lineHeight={"36px"}
              >
                Learn More From Experts
              </Typography>
              <Typography
                variant="p"
                style={{ color: "#3E3E3E", fontWeight: "400" }}
                alignSelf={"start"}
              >
                Expand Your Knowledge
              </Typography>
            </Stack>
            <Stack
              style={{
                // backgroundColor: "#DBD1FC",
                margin: "0px 16px 0px 0px",
                padding: "20px",
                borderRadius: "17px",
                marginTop: isSmallScreen && "12px",
                // backgroundImage:`${allImages.FlowerIllus}`
                backgroundImage: `url(${allImages.FlowerIllus})`, // Corrected this line
                backgroundSize: "cover", // Optional: to ensure the background covers the whole area
                backgroundPosition: "center", // Optional: to center the background image
                width: "90%",
              }}
            >
              <Stack
                style={{
                  // width: "170px",
                  height: isSmallScreen ? "90px" : "155px",
                  alignSelf: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  style={{
                    color: "#9F87EE",
                    fontWeight: "700",
                    fontSize: isSmallScreen ? "40px" : "86px",
                  }}
                  alignSelf={"center"}
                >
                  10K +
                </Typography>
              </Stack>
              <Typography
                style={{
                  color: "#3E3E3E",
                  fontWeight: "600",
                  fontSize: "32px",
                  marginTop: "16px",
                }}
                // alignSelf={"start"}
              >
                Empower Creators
              </Typography>
              <Typography
                variant="p"
                style={{ color: "#3E3E3E", fontWeight: "400" }}
                alignSelf={"start"}
              >
                Unlock Your Creative Potential
              </Typography>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default MentorLinkFeatures;
