import React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import Index from "../components/privateRoute/userRoute/settings/Index";

const SettingsPage = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const userData = useSelector((state) => state?.user?.profileData);
  const location = useLocation();

  useEffect(() => {
    document.title = `Mentorlink mentor settings`;
  }, []);

  if (!userData?.mentor) {
    return (
      <>
        <Navigate to="/home" state={{ from: location }} replace />
      </>
    );
  }
  if (pathname === "/settings") {
    return navigate("availability");
  }
  return (
    <div>
      <Index />
    </div>
  );
};

export default SettingsPage;
