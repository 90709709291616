import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { Suspense } from "react";
import Loader from "../Loader";
import MentorRatings from "./MentorRatings";
import UpcomingSession from "../Manage/UpcomingSession";
import { useState } from "react";
import { useEffect } from "react";
import ConfigAPIURL from "../../../../config/ConfigAPIURL";
import APIRequest from "../../../../utils/APIRequest";
import { useSelector } from "react-redux";
var CryptoJS = require("crypto-js");
const BookingOverview = React.lazy(() => import("./BookingOverview"));

const Bookings = () => {
  const [totalPage, setTotalPage] = useState(5);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState();
  const [paginatedData, setPaginatedData] = useState([]);
  const [isMentor, setIsMentor] = useState(false);
  const [reload, setReload] = useState(false);
  const [totalBookings, setTotalBookings] = useState([]);
  const [allFeedback, setAllFeedback] = useState([]);
  const [totalRating, setTotalRating] = useState();
  const userData = useSelector((state) => state.user.profileData);

  useEffect(() => {
    fetchUserInfo();
    fetchBookingOverview();
    fetchMentorLatestReview();
    fetchMentorTotalRating();
  }, []);
  useEffect(() => {
    fetchBookingHistory();
  }, [reload]);

  const fetchBookingOverview = async () => {
    try {
      const response = await APIRequest.request(
        "GET",
        ConfigAPIURL.bookingOverview,
        ""
      );
      if (response?.data?.responseCode === 109) {
        setTotalBookings(response?.data?.result);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const fetchBookingHistory = async () => {
    try {
      const response = await APIRequest.request(
        "GET",
        ConfigAPIURL.bookingHistory +
          "?" +
          "status=" +
          "enquiry,confirmed" +
          "&pageLength=" +
          totalPage +
          "&pageNo=" +
          0,
        ""
      );
      if (response?.data?.responseCode === 109) {
        if (response?.data?.pageCount === 1) {
          setLoading(false);
        }
        setPageCount(response?.data?.pageCount);
        setPaginatedData(response.data.result);
        // setLoading(!loading);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const fetchUserInfo = async () => {
    const response = await APIRequest.request(
      // get the users id from localstorage
      "GET",
      ConfigAPIURL.userProfile,
      ""
    );
    if (response?.data?.responseCode === 109) {
      //useradditional details is stored as state and displayed

      setIsMentor(response?.data?.profile?.mentor);
    }
  };
  const handleBookingUpdate = async (recordId, status) => {
    try {
      const response = await APIRequest.request(
        "PUT",
        ConfigAPIURL.updateBooking,
        JSON.stringify({
          recordId,
          status,
        })
      );
      if (response?.data?.responseCode === 109) {
        setReload(!reload);
      }
    } catch (err) {
      console.log(err);
    }
  };
  window.onscroll = function (ev) {
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
      if (pageCount !== 1) {
        setTotalPage(totalPage + 3);
        fetchBookingHistory();
        setLoading(true);
      }
      // filterData();
    }
  };

  const handleTwilio = (id, endingTime) => {
    let fullName = userData?.fname + " " + userData?.lname;
    //if full name is undefined or null or 0 then it will be string
    fullName = fullName ? fullName : " ";
    if (!id) return;
    const data = {
      name: fullName,
      roomName: id,
      endingTime,
    };
    //finally encrypt the data and send as payload parameter
    var ciphertext = CryptoJS.AES.encrypt(
      JSON.stringify(data),
      "my-secret-key@123"
    ).toString();
    //validate if the cipher text is found
    if (!ciphertext) return;
    window.open(ConfigAPIURL.twilioUrl + "/?payload=" + ciphertext, "_blank");
  };

  const fetchMentorLatestReview = async () => {
    try {
      const response = await APIRequest.request(
        "POST",
        ConfigAPIURL.mentorReviewFetch,
        JSON.stringify({
          mentorId: userData?._id,
        })
      );
      if (response?.data?.responseCode === 109) {
        setAllFeedback(response?.data?.result);
        // setTotalRating(response?.data?.result[0]);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const fetchMentorTotalRating = async () => {
    try {
      const response = await APIRequest.request(
        "POST",
        ConfigAPIURL.totalRatingReviewCount,
        JSON.stringify({
          mentorId: userData?._id,
        })
      );
      if (response?.data?.responseCode === 109) {
        setTotalRating(response?.data?.result[0]);
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <Stack gap={2}>
      <Suspense fallback={<Loader />}>
        <BookingOverview totalBookings={totalBookings} />
      </Suspense>
      <MentorRatings totalRating={totalRating} allFeedback={allFeedback} />
      <Stack>
        <Typography
          variant="h3"
          ml={1}
          sx={{ fontSize: "18px" }}
          fontWeight="700"
        >
          Bookings
        </Typography>
        <Stack>
          {paginatedData && paginatedData.length > 0 ? (
            paginatedData.map((value) => {
              return (
                <UpcomingSession
                  key={value?._id}
                  data={value}
                  isMentor={isMentor}
                  handleBookingUpdate={handleBookingUpdate}
                  handleTwilio={handleTwilio}
                />
              );
            })
          ) : (
            <Box width="350px" alignSelf="center">
              <img
                src={
                  "https://s3.ap-south-1.amazonaws.com/mentorlink/profile/1664432569933_Group%2034753.png"
                }
                alt="booking Not found"
              />
              <Typography variant="subtitle1" textAlign="center">
                No Bookings Found
              </Typography>
            </Box>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Bookings;
