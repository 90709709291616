import { Stack, Typography } from "@mui/material";
import React from "react";
import { Calendar, DateObject } from "react-multi-date-picker";
import moment from "moment/moment";

const BookingDates = ({ date, slots }) => {
  return (
    <Stack direction="row" justifyContent="space-between" px={0.5}>
      <Typography variant="subtitle1">{date}</Typography>
      <Typography variant="subtitle1" color="#FF9900">
        {slots} Slots
      </Typography>
    </Stack>
  );
};

const BookingCalendar = ({ weeklySlots, bookingDates }) => {
  const today = moment().startOf("day").toDate();

  const dayClassName = (date) => {
    const day = moment(date).startOf("day").toDate();
    if (day.getTime() === today.getTime()) {
      return "current-day";
    }
    return "";
  };
  return (
    <Stack className="rounded" py={1} px={2}>
      {bookingDates && (
        <>
          <Typography
            className="heading"
            variant="subtitle1"
            sx={{ fontSize: "17px" }}
            fontWeight="700"
            ml={0.3}
            mb={1}
          >
            Calendar Days
          </Typography>
          <Stack margin="auto">
            <Calendar
              minDate={moment().toDate()}
              value={weeklySlots}
              readOnly={true}
              className="custom-calendar"
              mapDays={({ date, today, currentMonth, isSameDate }) => {
                if (isSameDate(date, today)) {
                  return {
                    style: { color: "red", fontWeight: "bold" },
                    className: "highlight-today",
                  };
                }
              }}
              style={{ width: "256px", height: "256px", boxShadow: "none" }}
            />
          </Stack>
          <Stack>
            {bookingDates.length > 0 && (
              <Typography
                className="heading"
                variant="subtitle1"
                sx={{ fontSize: "17px" }}
                fontWeight="700"
                ml={0.3}
                mt={1}
              >
                Booked Dates
              </Typography>
            )}
          </Stack>
          <Stack gap={0.3}>
            {bookingDates.length > 0 &&
              bookingDates.map((value) => {
                const date = new Date(value?._id * 1000)
                  .toDateString()
                  .slice(4);
                return (
                  <BookingDates
                    key={value._id}
                    date={date}
                    slots={value?.count}
                  />
                );
              })}
          </Stack>
        </>
      )}
    </Stack>
  );
};

export default BookingCalendar;
