import { Box, Button, Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import TimeSlots from "./TimeSlots";
import "../BookingsPage/booking.scss";
import { Calendar, DateObject } from "react-multi-date-picker";
import { allImages } from "../../../../images";
import moment from "moment/moment";

const weekDays = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
var months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const DateSelection = ({ availabilityDates, setQuerys, querys }) => {
  const today = new Date().getDate();
  const [totalSlots, setTotalSlots] = useState([]);
  const [selectedComponent, setSelectedComponent] = useState();
  const [dates, setDates] = useState(null);
  const [open, setOpen] = useState(true);
  const [allHolidays, setAllHolidays] = useState();
  const isSmallScreen = useMediaQuery("(max-width:600px)");

  const dateFilter = (data) => {
    const datesArr = [];
    if (data.length === 0) return;
    data.forEach((val) => {
      datesArr.push(val?.date);
    });

    return datesArr;
  };

  const convertSecondsToDate = (data) => {
    // (data);
    const datesArr = [];
    if (!data || data.length === 0) return;
    data.forEach((value) => {
      const format = new Date(value).toDateString();
      const year = format.slice(11, 15);
      const day = format.slice(8, 10);
      const month =
        (new Date(value).getMonth() + 1).toString().length > 1
          ? new Date(value).getMonth() + 1
          : "0" + (new Date(value).getMonth() + 1);
      datesArr.push(year + "/" + month + "/" + day);
    });
    return datesArr;
  };

  useEffect(() => {
    //initial allslots and selected date will set whenever there is weeklyslots
    if (!availabilityDates?.weeklySlots) return;
    const date = new Date();
    const todaysDate = date.getDay();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    if (availabilityDates?.holidays) {
      setAllHolidays(dateFilter(availabilityDates?.holidays));
    }

    // setTotalSlots(availabilityDates?.weeklySlots[todaysDate]?.timeSlots);
    // setQuerys({
    //   ...querys,
    //   sessionDate: date / 1000,
    // });
    // // set Date as Today
    // setDates("Today");
  }, [availabilityDates]);
  if (!availabilityDates?.weeklySlots) return;
  // console.log("availabilityDates", availabilityDates);
  return (
    <Stack mt={2}>
      <Typography
        variant="subtitle1"
        sx={{ fontSize: "20px", fontWeight: "600" }}
      >
        {/* {"Select a date "} */}
      </Typography>
      <Grid
        container
        spacing={1.5}
        wrap="nowrap"
        overflow="auto"
        className="date__scroll"
      ></Grid>
      <Stack color="black">
        <Stack direction="row" justifyContent="space-between">
          <Stack>
            <Typography
              variant="subtitle1"
              sx={{ fontSize: { md: "20px", xs: "16px" }, fontWeight: "600" }}
            >
              {"Select a date "}
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ fontSize: "14px", fontWeight: "600" }}
            >
              {dates ? dates : null}
            </Typography>
          </Stack>

          <Button
            onClick={() => setOpen(!open)}
            disableRipple
            disableTouchRipple
            disableFocusRipple
          >
            <Box width="24px" height="24px">
              <img src={allImages.calendar} alt="" />
            </Box>
          </Button>
        </Stack>
        {open && (
          <Stack mt={2} alignItems="center">
            <div
              style={{
                border: "1px solid #E5E5E5",
                padding: !isSmallScreen ? "12px" : '8px',
                display: "flex",
                marginBottom: "8px",
                borderRadius: "8px",
                boxShadow: "0px 7px 17px 0px rgba(45, 81, 28, 0.10)",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "8px",
                  fontSize:!isSmallScreen ? "16px" : '12px'
                }}
              >
                <div
                  style={{
                    width: !isSmallScreen ? "15px" : '10px',
                    height: !isSmallScreen ? "15px" : '10px',
                    borderRadius: "50%",
                    backgroundColor: "#A8DF8E",
                    marginRight: "6px",
                  }}
                ></div>
                Available
              </div>
              {/* ------------------- */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "8px",
                  fontSize:!isSmallScreen ? "16px" : '12px'
                }}
              >
                <div
                  style={{
                    width: !isSmallScreen ? "15px" : '10px',
                    height: !isSmallScreen ? "15px" : '10px',
                    borderRadius: "50%",
                    backgroundColor: "red",
                    marginRight: "6px",
                  }}
                ></div>
                Unavailable
              </div>
              {/* ------------------ */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "8px",
                  fontSize:!isSmallScreen ? "16px" : '12px'
                }}
              >
                <div
                  style={{
                    width: !isSmallScreen ? "15px" : '10px',
                    height: !isSmallScreen ? "15px" : '10px',
                    borderRadius: "50%",
                    backgroundColor: "#900C3F",
                    marginRight: "6px",
                  }}
                ></div>
                Holiday
              </div>
              {/* --------------------------- */}
              <div style={{ display: "flex", alignItems: "center" , fontSize:!isSmallScreen ? "16px" : '12px'}}>
                <div
                  style={{
                    width: !isSmallScreen ? "15px" : '10px',
                    height: !isSmallScreen ? "15px" : '10px',
                    borderRadius: "50%",
                    backgroundColor: "#b3b3b3",
                    marginRight: "6px",
                  }}
                ></div>
                Selected
              </div>
            </div>
            <div
              style={{
                boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.18)",
                borderRadius: "10px", 
                padding: "0px", 
                overflow: "hidden",
              }}
            >
              <Calendar
                minDate={moment().toDate()}
                // mapDays={({ date }) => {
                //   const disablingDates = convertSecondsToDate(allHolidays);
                //   // (date.format());
                //   if (disablingDates) {
                //     var disableDays = disablingDates.includes(date.format());
                //   }
                //   // (disableDays);
                //   if (disableDays)
                //     return {
                //       disabled: true,
                //       style: { color: "#ccc" },
                //       // onClick: () => alert("weekends are disabled"),
                //     };

                //     // -----------------------------
                // }}
                mapDays={({ date }) => {
                  const formattedDate = date.format(); // Format the date to match your availabilityDates
                  // const dayInfo = availabilityDates.find(day => day.date === formattedDate);
                  const isPastDate = moment().isAfter(formattedDate, "day");

                  if (isPastDate) {
                    return {
                      style: { backgroundColor: "#fff", color: "#ccc" },
                    };
                  }
                  const disablingDates = convertSecondsToDate(allHolidays);
                  // (date.format());
                  if (disablingDates) {
                    var disableDays = disablingDates.includes(date.format());
                  }
                  if (disableDays)
                    return {
                      disabled: true,
                      style: { backgroundColor: "#900C3F", color: "#fff" },
                    };
                  for (const day of availabilityDates.weeklySlots) {
                    if (day.day === date.weekDay.index) {
                      if (day.isHoliday) {
                        return {
                          style: { backgroundColor: "red", color: "#fff" },
                        };
                      } else {
                        return {
                          style: { backgroundColor: "#A8DF8E", color: "#fff" },
                        };
                      }
                    }
                  }

                  // Default style
                  // return {
                  //   style: { backgroundColor: "green", color: "#fff" },
                  // };
                }}
                //   mapDays={({ date }) => {
                //     const disablingDates = convertSecondsToDate(allHolidays);

                //     if (disablingDates) {
                //       const disableDays = disablingDates.includes(date.format());

                //       if (disableDays) {
                //         return {
                //           disabled: true,
                //           style: { color: "#ccc" },
                //         };
                //       }
                //     }

                //     // const weekDayIndex = date.dateObject.day(); // Get the day of the week (0-6)
                //     // const weeklySlot = availabilityDates?.weeklySlots[weekDayIndex];

                //     // if (weeklySlot && weeklySlot.isHoliday) {
                //     //   return {
                //     //     style: { backgroundColor: "red", color: "white" },
                //     //   };
                //     // }
                // // if(availabilityDates?.weeklySlots[disablingDates].isHoliday){
                // //   return {
                // //     style: { backgroundColor: "red", color: "white" },
                // //   }
                // // }else{
                // //   return {
                // //     style: { backgroundColor: "green", color: "white" },
                // //   }
                // // }
                //   }}

                style={{ width: "256px", height: "256px", boxShadow: "none" }}
                value={" "}
                onChange={(e) => {
                  const day = e[e.length - 1] ? e[e.length - 1] : e;
                  // console.log(new Date(day.format()));
                  if (
                    !availabilityDates?.weeklySlots[day.weekDay.index].isHoliday
                  ) {
                    setTotalSlots(
                      availabilityDates?.weeklySlots[day.weekDay.index]
                        ?.timeSlots
                    );
                    setQuerys({
                      ...querys,
                      sessionDate: new Date(day.format()) / 1000,
                    });

                    setDates(
                      day.month.shortName + " " + day.day + " " + day.year
                    );
                  } else {
                    setDates(
                      day.month.shortName + " " + day.day + " " + day.year
                    );
                    setTotalSlots(null);
                  }
                }}
                disabledWeekDays={[0, 3]}
              />
            </div>
          </Stack>
        )}
      </Stack>
      {totalSlots ? (
        <TimeSlots
          totalSlots={totalSlots}
          setQuerys={setQuerys}
          querys={querys}
        />
      ) : (
        <>
          <Typography
            mt={2}
            fontSize="16px"
            variant="subtitle1"
            fontWeight="600"
            color="black"
          >
            Mentor is not available on {dates}
          </Typography>
        </>
      )}
    </Stack>
  );
};

export default DateSelection;
