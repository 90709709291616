import { Stack, TextField, Typography } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button } from "react-bootstrap";
import ConfigAPIURL from "../../../../config/ConfigAPIURL";
import APIRequest from "../../../../utils/APIRequest";
import BlueButton from "../BlueButton";
import "./modalSkills.scss";

const ExperienceSkills = ({ userSkills, onClose,setSkills }) => {

  const [tags, setTags] = useState(userSkills);
  const [terms, setTerms] = useState("");
  const [searchResults, setSearchResults] = useState(null);
  useEffect(() => {
    setSkills(tags)
  },[tags])
  useEffect(() => {
    fetchSkills();
  }, [terms]);

  const fetchSkills = async () => {
    if (terms === "") return;
    const response = await APIRequest.request(
      "POST",
      ConfigAPIURL.userAllSkills,
      JSON.stringify({
        Skill: terms,
      })
    );
    if (response.data.responseCode === 109) {
      setSearchResults(response.data.result);
    }
  };
  // removing the tags from state
  const removeTags = (indexToRemove) => {
    setTags([...tags.filter((_, index) => index !== indexToRemove)]);
  };
  //adding tags to state
  const addTags = (e) => {
    if (e.target.value !== "") {
      setTags([...tags, e.target.value]);
      e.target.value = "";
      setTerms("");
    }
  };
  // to update the current tags
  const UpdateTags = async () => {
    const response = await APIRequest.request(
      "PUT",
      ConfigAPIURL.userUpdateSkills,
      JSON.stringify({
        skills: tags,
      })
    );
    //closes the popup
    onClose();
  };
  return (
    <Stack alignItems="start" width="100%">
      <div className="tags-input" style={{ width: "100%" }}>
        <ul id="tags">
          {tags &&
            tags.map((tag, index) => (
              <li key={index} className="tag">
                <span className="tag-title">{tag}</span>
                <span
                  className="tag-close-icon"
                  onClick={() => removeTags(index)}
                >
                  x
                </span>
              </li>
            ))}
        </ul>
        <input
          style={{ width: "100%" }}
          type="text"
          onKeyUp={(event) => (event.key === "Enter" ? addTags(event) : null)}
          placeholder="Press enter to add skills"
          value={terms}
          onChange={(e) => setTerms(e.target.value)}
        />
      </div>
      {/* ui which renders the result */}
      {/* <Typography variant="h5" mt={4}>Suggested Skills</Typography> */}
      <Stack mt={1} direction="row">
        {searchResults &&
          searchResults.map((val) => {
            return (
              <Button
                sx={{}}
                variant="h5"
                key={val._id}
                onClick={() => setTerms(val.skill)}
              >
                {val.skill}
              </Button>
            );
          })}
      </Stack>
    </Stack>
  );
};

export default ExperienceSkills;
