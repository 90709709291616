import { allImages } from "../images";

export const mentorimgFakedata = [
    allImages.mentor1,
    allImages.mentor2,
    allImages.mentor3,
    allImages.mentor4,
    allImages.mentor5,
    allImages.mentor6,

]