class ConfigAPIURL {
  //Base URL For Live & Staging
  static baseUrl =
    process.env.REACT_APP_PayDefination_BaseUrl ||
    process.env.REACT_APP_Dev_PayDefination_BaseUrl;

  // static baseUrl = "https://dev.mentorlink.in";
  // static baseUrl = http://localhost:4000
  //   process.env.REACT_APP_PayDefination_BaseUrl ||
  //   process.env.REACT_APP_Dev_PayDefination_BaseUrl;
  static twilioUrl = "https://vc.mentorlink.in";

  //List of Pay Defination APIs and request method type
  static userIsLoggedin = this.baseUrl + "/user/islogin"; //get
  static userAccountlogin = this.baseUrl + "/user/accountLogin"; //post
  static mobilenoLogin = this.baseUrl + "/user/mobileno/login"; //post
  static userVerifyotp = this.baseUrl + "/user/verify/otp"; //post
  static userResendOtp = this.baseUrl + "/user/resend/otp"; //post
  static userAccountCreate = this.baseUrl + "/user/create"; //post
  static userExist = this.baseUrl + "/user/exist"; //post
  static emailPresent = this.baseUrl + "/user/email/exist"; //post
  static userUpdate = this.baseUrl + "/user/update"; //post
  static userDelete = this.baseUrl + "/user/delete"; //post
  static userLogout = this.baseUrl + "/user/logout"; //post
  static unsubscribeEmail = this.baseUrl + "/user/unsubscribe/:email"; //get
  static userForgotpassword = this.baseUrl + "/user/forgot/password"; //get
  static userDetail = this.baseUrl + "/user/userdetail"; //get
  static checkUserPresent = this.baseUrl + "/user/checkuserpresent"; //get
  static checkUserPresentVerifyOtp =
    this.baseUrl + "/user/checkuserpresentverifyotp"; //get
  static emailOtp = this.baseUrl + "/user/email/otp/send"; //post
  static emailVerifyOtp = this.baseUrl + "/user/email/otp/verify"; //post
  // New
  static forgotOTP = this.baseUrl + "/user/reset/otp/password"; //POST
  static verifyOTP = this.baseUrl + "/user/email/otp/verify"; //POST
  static resetPassword = this.baseUrl + "/user/reset/password"; // PUT

  static UserProfileUpdate = this.baseUrl + "/user/profile/update"; //put
  static userProfile = this.baseUrl + "/user/profile"; //get
  static userSearch = this.baseUrl + "/user/search";
  static bannerAdvertisement = this.baseUrl + "/user/advertisement/banner/all"; //post
  static userAdditionalInfo = this.baseUrl + "/user/profile/userInfo"; //post
  static userAllSkills = this.baseUrl + "/user/skills/all";
  static userUpdateAdditionalInfo =
    this.baseUrl + "/user/profile/userInfo/update"; //put
  static userAdvertisements = this.baseUrl + "/user/advertisement/mentor/all"; //get
  static fileUpload = this.baseUrl + "/user/upload/file";
  static PromotionalAdvertisement =
    this.baseUrl + "/user/advertisement/promotion/all"; //GET
  static UserCategoriesAds = this.baseUrl + "/user/category/all"; //GET
  static userAllOfferings = this.baseUrl + "/user/offerings/all"; //post
  static userCreateOfferings = this.baseUrl + "/user/offerings/create"; //post
  static userRemoveOffering = this.baseUrl + "/user/offerings/remove"; //post
  static userOfferingsUpdate = this.baseUrl + "/user/offerings/update"; //post
  static userProfileDataRemove = this.baseUrl + "/user/profile/userInfo/delete"; //post

  static userResults = this.baseUrl + "/user/global/mentor/search"; //Get
  static userProfileSingleUpdate =
    this.baseUrl + "/user/profile/userInfo/update/single"; //post
  static getMentors = this.baseUrl + "/user/category/filter/all"; // post
  static getCountry = this.baseUrl + "/user/country/list"; //get
  static getState = this.baseUrl + "/user/state/list"; //post
  static getCity = this.baseUrl + "/user/city/list"; //post search key is mandatory
  static subCategories = this.baseUrl + "/user/category/subcategories"; //post
  static technolgyVertical = this.baseUrl + "/user/technologyVertical/all"; //get
  static suggestedMentors = this.baseUrl + "/user/mentors/suggested"; //get
  static highestQualification = this.baseUrl + "/user/qualifications/all"; //get
  static getUserSlots = this.baseUrl + "/user/slots"; //get
  static userUpdateSlots = this.baseUrl + "/user/slots/update"; //post
  static getMentorBookingSlots = this.baseUrl + "/user/slots/mentor"; //get
  static bookingHistory = this.baseUrl + "/user/booking/history"; //get
  static confirmBooking = this.baseUrl + "/user/booking/create"; //POst
  static updateBooking = this.baseUrl + "/user/booking/update"; //PUT
  static bookingOverview = this.baseUrl + "/user/booking/overview"; //get
  static bookedDateSlots = this.baseUrl + "/user/booking/dates"; //get
  static userAllNotifications = this.baseUrl + "/user/notication/all"; //post
  static updateNotifications = this.baseUrl + "/user/notification/update"; //put
  static ratingSessions = this.baseUrl + "/user/booking/review"; //post whether rated or not
  static createBookings = this.baseUrl + "/user/booking/review/create"; //post
  static totalRatingReviewCount = this.baseUrl + "/user/mentor/review/count"; //post
  static mentorReviewFetch = this.baseUrl + "/user/mentor/review/all"; //post
  static followAndUnfollow = this.baseUrl + "/user/follow/update"; //PUT
  static followFollowingList = this.baseUrl + "/user/follow/list"; //post
  static userStatistics = this.baseUrl + "/user/follow/stats"; //get
  static applyCoupons = this.baseUrl + "/user/coupons/apply"; //get
  static allCouponsDetails = this.baseUrl + "/user/coupons/getcredits"; //get
  static getBookedSlots = this.baseUrl + "/user/booking/bookedslots"; //post
  static getCouponsRedeem = this.baseUrl + "/user/coupons/redeem/points"; //get
  static getLinkedinUserDetails = this.baseUrl + "/user/linkedin"; //get
  static getOrderDetails = this.baseUrl + "/user/order/details"; //post
  static getMentorInvoice = this.baseUrl + "/user/order/mentor/details"; //get
  static applyCoupon = this.baseUrl + "/user/discount/getdetails"; //post
  static getTime = this.baseUrl + "/user/getTime"; //get

  //Chat Bot
  static getChatQuestions = this.baseUrl + "/user/chatbot/questions"; //get
  static sendTypedMessage = this.baseUrl + "/user/semanticSearch"; //POST
  static sendChatData = this.baseUrl + "/user/chatbot/results"; //POST
  static submitContactUs = this.baseUrl + "/user/contactus"; //POST
  static getChatBotCategories = this.baseUrl + "/user/chatbot/users/categories"; //GET
  static getChatBotSkills = this.baseUrl + "/user/chatbot/category/skills"; //Post
  static getSearchedMentors = this.baseUrl + "/user/chatbot/mentor/skills"; //Post
  static getSearchedMentorsCategories =
    this.baseUrl + "/user/chatbot/mentor/category"; //Post

  //New Features
  static getAllTopRatedMentors = this.baseUrl + "/user/topMentors"; //GET

  /*
  // admin
  static sessionValidation = this.baseUrl + '/admin/islogin'; //get
  static accountLogin = this.baseUrl + '/admin/accountLogin';
  static verifyOtp = this.baseUrl + '/admin/verify/otp';
  static resendOtp = this.baseUrl + '/admin/resend/otp';
  static adminLogout = this.baseUrl + '/admin/logout';

  static adminGeneratePassword = this.baseUrl + '/admin/password/generate';

  static forgotPassword = this.baseUrl + '/admin/forgot/password';
  static resetPassword = this.baseUrl + '/admin/reset/password';
 
  //Admin APIS
  static roleTableURL = this.baseUrl + '/admin/roles/all'; //post
  static roleTitles = this.baseUrl + '/admin/roles/titles'; //post
  static roleDetails = this.baseUrl + '/admin/role/details'; //post
  static roleCreate = this.baseUrl + '/admin/role/create'; //post
  static roleUpdate = this.baseUrl + '/admin/role/permission/update'; //post
  static roleUnassigned = this.baseUrl + '/admin/role/permission/unassigned'; //post
  static roleGrant = this.baseUrl + '/admin/role/permission/grant'; //post

  //  User Table API
  static userTableURL = this.baseUrl + '/admin/user/all'; //post
  static userCreate = this.baseUrl + '/admin/user/add'; //post
  static userUpdate = this.baseUrl + '/admin/user/update'; //post
  static userDelete = this.baseUrl + '/admin/user/delete'; //post
  static userDetails = this.baseUrl + '/admin/user/details'; //post

  static resetPasswordAttempt =
    this.baseUrl + '/admin/user/resetpasswordattempt';
}
*/
}
export default ConfigAPIURL;
