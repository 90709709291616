import React, { useState, useEffect } from "react";
import APIRequest from "../utils/APIRequest";
import { useNavigate } from "react-router-dom";
import MenteeHomePage from "../components/privateRoute/userRoute/Home/Index";
import MentorHomePage from "../components/privateRoute/userRoute/MentorHome/Index";
import { useSelector } from "react-redux";
import ChatHomePage from "../components/general/ChatHomePage/Index";

const Home = () => {
  useEffect(() => {
    document.title = "Mentorlink home";
  }, []);
  const userDatas = useSelector((state) => state.user.profileData);

  if (userDatas?.mentor === true) {
    return <MentorHomePage />;
  }
  if (userDatas?.mentor === false) {
    // return <MenteeHomePage />;
    return <ChatHomePage />;
  }

  return <></>;
};

export default Home;
