import { Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import LocalStorage from "../../../config/LocalStorage";
import APIRequest from "../../../utils/APIRequest";
import ConfigAPIURL from "../../../config/ConfigAPIURL";
import { useNavigate } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import { encryptandDecrypt } from "../../../config/EncrytDecryptData";
import { allImages } from "../../../images";

const MentorsCard = (props) => {
    const {mentorData} = props;
    const[userLoggedin,setUserLoggedin] = useState(false)
    const[loading,setLoading] = useState(false)
    const navigate = useNavigate();

    //check whether the user is logged in or nt and route to private route
  const isLogin = async () => {
    setLoading(true)
    const response = await APIRequest.request(
      "GET",
      ConfigAPIURL.userIsLoggedin,
      ""
    );
    if (response?.data?.responseCode === 109) {
        navigate(
            `/mentors/id?payload=${encryptandDecrypt.encrypt(mentorData?._id)}`
          )
          
        setUserLoggedin(true);
        setLoading(false)
    } else{
        navigate("/login");
        setLoading(false)
    }
  };

  console.log("this is mentorData",mentorData);
  return (
    <Stack
      style={{
        // border: "1px solid #F1F2F4",
        border: '1px solid #B6B6B6',
        maxWidth: "314px",
        padding: "8px",
        borderRadius: "24px",
      }}
      className="mentor-card"
    >
      <Stack style={{borderRadius:'12px'}}>
        <img
          src={allImages.avatar}
        //   src="https://s3-alpha-sig.figma.com/img/915b/48d6/513297345fd381fd02573da5e62987f6?Expires=1717977600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=e8NzXPE~OQjUrG3nrALRFrQoJd3~ZaurrjamGgeuWrvA4nzjrIi1WQVTjqkIPqwARs~BqoZCQztYpQwSBLgKi~NCZJNvJX49lVKHS1pTPzLNHM0MzdyEhUGv34wtk6W8D7ZnMB8Dq90vgY3QLZKox88er9g4qG~dOg9aWJIrtquwz-9~zpEM3JofVURaTb7wg5WL7mCIVuOx5HoJJsnkJSvwo3hxECCxOatMJ2m3AbtXuU0fEK5tYLvxvdiJyXFsQS4OWKQw2kXg2cJNHtEvWQAibo-zUGz1XtWWeDjmwUZzWmsiyKQqcCoCRS3QlmYPtTvNDRp0MiQpL~KSIEl4xg__"
          style={{ maxHeight: "260px", maxWidth: "292px", borderRadius: "12px" }}
        />
      </Stack>
      <Stack style={{ marginTop: "16px" }}>
        <Typography variant="h5" color={"#000000"} fontSize={"20px"} textTransform={'capitalize'}>
          {mentorData?.fname} {mentorData?.lname}
        </Typography>
        {/* <Typography  color={"#687A87"} fontSize={"14px"} marginBottom={'4px'}>
         <img src={allImages.bagImage}style={{width:'20px',height:'20px',marginRight:'4px'}} /> {mentorData?.designation} at {mentorData?.organization}
        </Typography> */}
        <Typography
      color={"#687A87"}
      fontSize={"14px"}
      marginBottom={'4px'}
      sx={{
        display: 'inline-block',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '100%',  // Adjust as needed
        verticalAlign: 'middle'
      }}
      textTransform={'capitalize'}
    >
      <img
        src={allImages.bagImage}
        alt="bag"
        style={{ width: '20px', height: '20px', marginRight: '4px', verticalAlign: 'middle' }}
      /> 
      {mentorData?.designation} at {mentorData?.organization}
    </Typography>
        {/* <Typography color={"#687A87"} fontSize={"14px"}>
         <img src={allImages.chatImage} style={{width:'20px',height:'20px',marginRight:'4px'}} /> {mentorData?.sessionCount} sessions ({mentorData?.averageRating} reviews)
        </Typography> */}
      </Stack>
      <Stack
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems:'center',
          padding: "10px",
          borderRadius: "12px",
          backgroundColor: "#F6F6F6",
          marginTop:'8px',
          marginBottom:'4px',
        }}
      >
        <Stack>
          <Typography
            style={{
              // backgroundColor: "#FF5F73",
              padding: "6px",
              borderRadius: "6px",
              cursor:'pointer',
              marginRight:'4px',
              width:'120px',
              textAlign:'center',
              border: '1px solid #FF5F73'
            }}
            color={"#FF5F73"}
            fontSize={"16px"}
            onClick={isLogin}
          >
            View Details 
          </Typography>
          {/* {loading &&  <CircularProgress color="inherit"  size={16} />} */}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default MentorsCard;
