import styled from "@emotion/styled";
import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  Button,
  Stack,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import SendIcon from "@mui/icons-material/Send";
import APIRequest from "../../../utils/APIRequest";
import ConfigAPIURL from "../../../config/ConfigAPIURL";
import { useNavigate } from "react-router-dom";

const ChatBubble = styled(Box)(({ theme }) => ({
  display: "inline-block",
  padding: "10px 20px",
  borderRadius: "15px",
  backgroundColor: "#f1f1f1",
  maxWidth: "50%",
  position: "relative",
  margin: "10px 0",
  "&::after": {
    content: '""',
    position: "absolute",
    left: "10px",
    bottom: "-15px",
    borderWidth: "15px 15px 0 0",
    borderStyle: "solid",
    borderColor: "#f1f1f1 transparent transparent transparent",
    display: "block",
    width: 0,
  },
  display: "flex",
  flexDirection: "column",
  marginTop: "28px",
  marginBottom: "28px",
}));

// const ChatBubbleRight = styled(Box)(({ theme }) => ({
//   display: "inline-block",
//   padding: "10px 20px",
//   borderRadius: "15px",
//   backgroundColor: "#00275A",
//   color: "white",
//   maxWidth: "100%",
//   width: "50%",
//   position: "relative",
//   margin: "10px 0",
//   alignSelf: "flex-end",
//   "&::after": {
//     content: '""',
//     position: "absolute",
//     right: "10px",
//     bottom: "-15px",
//     borderWidth: "15px 0 0 15px",
//     borderStyle: "solid",
//     borderColor: "#00275A transparent transparent transparent",
//     display: "block",
//     width: 0,
//   },
//   display: "flex",
//   flexDirection: "column",
//   marginTop: "28px",
//   marginBottom: "28px",
// }));
const ChatBubbleRight = styled(Box)(({ theme }) => ({
    display: "inline-block",
    padding: "10px 20px",
    borderRadius: "15px",
    backgroundColor: "#00275A",
    color: "white",
    maxWidth: "60%",
    position: "relative",
    margin: "10px 0",
    alignSelf: "flex-end",
    "&::after": {
      content: '""',
      position: "absolute",
      right: "10px",
      bottom: "-15px",
      borderWidth: "15px 0 0 15px",
      borderStyle: "solid",
      borderColor: "#00275A transparent transparent transparent",
      display: "block",
      width: 0,
    },
  }));

const ChatInput = ({ typedMsg, setTypedMsg, sendTypedMessage }) => {
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      sendTypedMessage();
    }
  };
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        paddingTop: "10px",
        position: "sticky",
        bottom: 0,
        width: "100%",
        backgroundColor: "white",
      }}
    >
      <TextField
        fullWidth
        variant="outlined"
        placeholder="Type here..."
        value={typedMsg}
        onKeyDown={handleKeyPress}
        onChange={(e) => setTypedMsg(e.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                type="submit"
                color="primary"
                onClick={sendTypedMessage}
              >
                <SendIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        sx={{
          "& .MuiOutlinedInput-root": {
            borderRadius: "25px",
          },
        }}
      />
    </div>
  );
};

const ChatAreaLoggenInUser = ({ initialQuestions ,setReceivedData ,setOpen}) => {
  const [typedMsg, setTypedMsg] = useState("");
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [userLoggedin, setUserLoggedin] = useState(false);
  const [chatHistory, setChatHistory] = useState([]);
  const [chatData, setChatData] = useState([]);
  const [questions, setQuestions] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (initialQuestions && initialQuestions?.length > 0) {
      setQuestions(initialQuestions);
    }
  }, [initialQuestions]);
//   console.log("this is questions",chatData);

  useEffect(() => {
    isLogin();
  }, []);

  const isLogin = async () => {
    const response = await APIRequest.request(
      "GET",
      ConfigAPIURL.userIsLoggedin,
      ""
    );
    if (response?.data?.responseCode === 109) {
      setUserLoggedin(true);
    } else {
      setUserLoggedin(false);
    }
  };
  const handleOptionClick =async (option) => {
    const currentQ = questions?.[currentQuestion];
    setChatHistory([
      ...chatHistory,
      { question: currentQ?.text, answer: option.text },
    ]);
    // Create a new entry with dynamic key
  const newEntry = { [currentQ?.keyword]: option.text };
  // Update the chat data with the new entry
  setChatData((prevChatData) => [...prevChatData, newEntry]);

  const newChatData = [...chatData, newEntry];

    // setChatData([
    //   ...chatData,
    //   { currentQ.keyword : option.text },
    // ]);
  
    if (currentQ?.isDeviating && option.nextQuestionId) {
      const nextQuestion = questions?.find(
        (question) => question._id === option.nextQuestionId
      );
    //   console.log("Next question after deviating:", nextQuestion);
      if (nextQuestion) {
        setQuestions((prevQuestions) =>
          prevQuestions.filter(
            (q) =>
              q._id === nextQuestion._id ||
              !currentQ?.options.some(
                (opt) => opt.nextQuestionId === q._id && opt.text !== option.text
              )
          )
        );
        setCurrentQuestion(questions?.indexOf(nextQuestion));
      }
    } else {
      if (currentQuestion + 1 < questions?.length) {
        setCurrentQuestion(currentQuestion + 1);
    } else {
        setCurrentQuestion(null);
        await sendChatData(newChatData); // Call the API to send chat data
      }
    }
  };
  

//   const handleOptionClick = (option) => {
//     const currentQ = questions?.[currentQuestion];
//     setChatHistory([
//       ...chatHistory,
//       { question: currentQ?.text, answer: option.text },
//     ]);

//     if (currentQ?.isDeviating && option.nextQuestionId) {
//       const nextQuestion = questions?.find(
//         (question) => question._id === option.nextQuestionId
//       );
//       console.log("this is isdeveveve",nextQuestion);
//       if (nextQuestion) {
//         setQuestions((prevQuestions) =>
//           prevQuestions.filter(
//             (q) =>
//               !prevQuestions.some(
//                 (option) => option.nextQuestionId === q._id
//               ) || q._id === nextQuestion._id
//           )
//         );
//         setCurrentQuestion(questions?.indexOf(nextQuestion));
//       }
//     } else {
//       if (currentQuestion + 1 < questions?.length) {
//         setCurrentQuestion(currentQuestion + 1);
//       } else {
//         sendTypedMessage();
//       }
//     }
//   };

  const sendTypedMessage = async () => {
    const currentQ = questions?.[currentQuestion];
    setChatHistory([
      ...chatHistory,
      { question: currentQ?.text, answer: typedMsg },
    ]);
    
    // Create a new entry with dynamic key
  const newEntry = { [currentQ?.keyword]: typedMsg };
  // Update the chat data with the new entry
  setChatData((prevChatData) => [...prevChatData, newEntry]);
  setTypedMsg('')
  // Update the chat data with the new entry
  const newChatData = [...chatData, newEntry];

//   console.log("this is length",questions?.length);
    // try {
    //   const response = await APIRequest.request(
    //     "POST",
    //     ConfigAPIURL.sendTypedMessage,
    //     JSON.stringify({ semanticQuery: typedMsg })
    //   );
    //   if (response.data.responseCode === 109) {
    //     const receivedData = response?.data?.data;
    //     setTypedMsg("");
    //     navigate("/home/chat", { state: { receivedData } });
    //   }
    // } catch (err) {
    //   console.log(err);
    // }

    if (currentQuestion + 1 < questions?.length) {
      setCurrentQuestion(currentQuestion + 1);
    }else {
        setCurrentQuestion(null);
        await sendChatData(newChatData); // Call the API to send chat data
      }
  };

  const currentQ = questions?.[currentQuestion];

  const sendChatData = async (newChatData) => {
    try {
      const response = await APIRequest.request(
        "POST",
        ConfigAPIURL.sendChatData, // Assuming this is the endpoint to send the chat data
        JSON.stringify({keyword: newChatData})
      );
      if (response.data.responseCode === 109) {
        setReceivedData(response?.data?.data)
        setOpen(false)
        scrollToBottom()
        // console.log("Chat data sent successfully!");
      } else {
        console.log("Failed to send chat data");
      }
    } catch (err) {
      console.log("Error sending chat data:", err);
    }
  };

  const scrollToBottom = () => {
    const chatModal = document.getElementById('chatModal'); // Assuming your chat modal has an ID of 'chatModal'
    if (chatModal) {
      chatModal.scrollTop = chatModal.scrollHeight;
    }
  };
  // useEffect(() => {
  //   scrollToBottom();
  // }, [chatHistory]);
  

  return (
    <div id="chatModal">
      {chatHistory?.map((entry, index) => (
        <Stack key={index}>
          <ChatBubble>
            <Typography variant="body1">{entry.question}</Typography>
          </ChatBubble>
          <ChatBubbleRight>
            <Typography variant="body1">{entry.answer}</Typography>
          </ChatBubbleRight>
        </Stack>
      ))}
      {/* <ChatBubble>
        <Typography variant="body1">{currentQ?.text}</Typography>
        {currentQ?.options &&
          currentQ?.options.map((option, index) => (
            <Button
              key={index}
              onClick={() => handleOptionClick(option)}
              variant="contained"
              color="primary"
              style={{ marginTop: "10px" }}
            >
              {option.text}
            </Button>
          ))}
      </ChatBubble> */}
      {currentQuestion === null ? (
        <ChatBubble>
        <Typography variant="body1">Thanks for Response</Typography>
        </ChatBubble>
      ) : (
        <>
      <ChatBubble>
        <Typography variant="body1">{currentQ?.text}</Typography>
       </ChatBubble>
        {currentQ?.options &&
          currentQ?.options?.map((option, index) => (
            <Button
              key={index}
              onClick={() => handleOptionClick(option)}
            //   variant="contained"
            //   color="primary"
              style={{ margin: "10px",padding:'12px 24px 12px 24px',border: '1px solid #8E8E8E',backgroundColor:'#FFFFFF',color:'#8E8E8E',borderRadius:'26px' }}
            >
              {option.text}
            </Button>
          ))}
          </>
      )}
      {!currentQ?.options && currentQuestion !== null && (
        <ChatInput
          setTypedMsg={setTypedMsg}
          typedMsg={typedMsg}
          sendTypedMessage={sendTypedMessage}
        />
      )}
    </div>
  );
};

export default ChatAreaLoggenInUser;
