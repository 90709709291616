import { Label } from "@mui/icons-material";
import { Box, Checkbox, Divider, Stack, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { useState } from "react";
import { allImages } from "../../../../images";
import Timings from "./Timings";
import uuid from "react-uuid";

const weekDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];


const AvailableTime = ({
  data,
  addSlots,
  deleteSlots,
  day,
  updateSlots,
  setHolidays,
}) => {
  const isSmallScreen = useMediaQuery("(max-width:600px)");

  // (data);
  const [checked, setChecked] = useState(!data.isHoliday);
  return (
    <Stack
      justifyContent="space-between"
      mt={1}
      // px={1}
      sx={{
        flexDirection: { xs: "column", sm: "row" },
        alignItems: { xs: "start" },
      }}
    >
      <Stack
        // direction="row"
        sx={{
          flexDirection: {
            xs: "column",
            sm: "row",
          },
          gap: { xs: 0, md: "2rem" },
          // alignItems: { xs: "center", sm: "start" },
          // justifyContent: "center",
        }}
        // gap=".5rem"
      >
        <Stack direction="row" alignItems="center">
          <Checkbox
            checked={checked}
            onChange={(e) => {
              setHolidays({
                day,
                checked,
              });
              setChecked(e.target.checked);
            }}
          />
          <Typography
            className="heading"
            width="30px"
            over
            variant="subtitle1"
            fontWeight="600"
            fontSize="20px"
          >
            {weekDays[day]}
          </Typography>
        </Stack>

        <Stack>
          {!checked ? (
            <Typography
              className="heading"
              fontWeight="600"
              fontSize="20px"
              color="#727272"
            >
              Unavailable
            </Typography>
          ) : (
            <Stack gap={2}>
              {data &&
                data?.timeSlots &&
                data?.timeSlots?.map((value, ind) => {
                  return (
                    <Timings
                      day={day}
                      ind={ind}
                      selected={data?._id}
                      key={uuid()}
                      data={value}
                      deleteSlots={deleteSlots}
                      updateSlots={updateSlots}
                    />
                  );
                })}
            </Stack>
          )}
        </Stack>
      </Stack>

      <Stack alignItems="center">
        {checked && (
          <Box
            sx={{ width: "25px", height: "25px", cursor: "pointer" }}
            onClick={() => addSlots(data)}
          >
            <img src={allImages.plusIcon} alt="plus" />
          </Box>
        )}
      </Stack>
    </Stack>
  );
};

export default AvailableTime;
