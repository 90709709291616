import homeIcon from "../src/assets/icons.png";
import profile from "../src/assets/Ellipse 283.png";
import searchIcon from "../src/assets/search.png";
import groupIcon from "../src/assets/Group 22.png";
import bell from "../src/assets/bell 2.png";
import manageIcon from "../src/assets/Group 34358.png";
import officeImage from "../src/assets/office.png";
import mentors from "../src/assets/Discover Mentors.png";
import featuredmentors1 from "../src/assets/featuredmentor1.png";
import featuredmentors2 from "../src/assets/featuredmentor2.png";
import imageBanner from "../src/assets/banner.png";
import profilementor from "../src/assets/profilementor.png";
import arrowvector from "../src/assets/Vector.png";
import bookmarks from "../src/assets/bookmark.png";
import development from "../src/assets/Group 34386.png";
import development2 from "../src/assets/Group 34387.png";
import development3 from "../src/assets/Group 34388.png";
import menuicon from "../src/assets/menu-icon.png";
import searchicon2 from "../src/assets/mangnifyingglass.png";
import logoicon from "../src/assets/logo.png";
import googleIcon from "../src/assets/google (1).png";
import Aboutus1 from "../src/assets/Aboutus1.png";
import Aboutus2 from "../src/assets/Aboutus2.png";
import Aboutushead from "../src/assets/Aboutushead.png";
import linkedinIcon from "../src/assets/linkedn.png";
import mentor1 from "../src/assets/mentor1.png";
import mentor2 from "../src/assets/mentor2 (1).png";
import mentor3 from "../src/assets/mentor2 (2).png";
import mentor4 from "../src/assets/mentor2 (3).png";
import mentor5 from "../src/assets/mentor2 (4).png";
import mentor6 from "../src/assets/mentor2 (5).png";
import menuWhite from "../src/assets/menuWhite.png";
import mentorProfile from "../src/assets/profile.png";
import ShareIcon from "../src/assets/shareicon.png";
import workplace from "../src/assets/workplace.png";
import brain from "../src/assets/brain.png";
import calendar from "../src/assets/calendar.png";
import searchlandingpage from "../src/assets/searchlanding.png";
import bag from "../src/assets/bag.png";
import calendar2 from "../src/assets/calendar2.png";
import money from "../src/assets/money.png";
import time from "../src/assets/time.png";
import moreIcon from "../src/assets/moreIcon.png";
import notificationImg from "../src/assets/notificationImg.png";
import likeIcon from "../src/assets/thumbs-up.png";
import Share2 from "../src/assets/share-2.png";
import comment from "../src/assets/message-circle.png";
import companyProfile from "../src/assets/companyProfile.png";
import searchClose from "../src/assets/icons8-close-30.png";
import editPencil from "../src/assets/edit.png";
import closeIcon from "../src/assets/closeIcon.png";
import money2 from "../src/assets/money.png";
import deleteButton from "../src/assets/DeleteButton.png";
import plusIcon from "../src/assets/plusIcon.png";
import gMail from "../src/assets/gmail.png";
import faceBook from "../src/assets/facebook.png";
import addIcon from "../src/assets/plusIcon.png";
import tickIcon from "../src/assets/tickIcon.png";
import map from "../src/assets/map.png";
import filterIcon from "../src/assets/filter.png";
import cameraIcon from "../src/assets/camera.png";
import cameraIconTwo from "../src/assets/cameraIcon.svg";
import linkShareIcon from "../src/assets/linkShare.png";
import filter2 from "../src/assets/filter2.png";
import logout from "../src/assets/logout.png";
import categoriesIcon from "../src/assets/category.png";
import couponsIcon from "../src/assets/coupons.png";
import logoNameIcon from "../src/assets/mentor.png";
import landingPagebannner from "../src/assets/landingPagebannner.png";
import landingCalendar from "../src/assets/calenar__landing.png";
import searchLanding from "../src/assets/serachbanner.png";
import landingBrain from "../src/assets/headbanner.png";
import testimonial from "../src/assets/testimonial.png";
import testimonial2 from "../src/assets/testimonial2.png";
import testimonial3 from "../src/assets/testimonial3.png";
import skills1 from "../src/assets/skills1.png";
import skills2 from "../src/assets/skills2.png";
import skills3 from "../src/assets/skills3.png";
import ProfileComplete from "../src/assets/Mentorlink - image.png";
import sadImage from "../src/assets/sad_image.png";
import signUpimage from "../src/assets/signup__page.png";
import logoWhole from "../src/assets/logowhole.png";
import signupImageMock from "../src/assets/signup__pagemock.jpg";
import chatBg from "../src/assets/chatBackground.png";
import doodles from "../src/assets/doodles.jpg";
import logoutIllustration from "../src/assets/logout illustration.png";
import profileIcon from "../src/assets/profileIcon.png";
import settingsIcon from "../src/assets/settingicon.png";
import logoutNewIcon from "../src/assets/logoutnew.png";
import avatar from "../src/assets/avatar.png";
import underConstruction from "../src/assets/Under construction-pana 1.png";
import contactUs from "../src/assets/contactUs.jpg";
import findmentor from "../src/assets/findmentor.png";
import contionuslearn from "../src/assets/contionuslearn.png";
import CalenderTwo from "../src/assets/CalenderTwo.png";
import UserFriendly from "../src/assets/UserFriendly.png";
import IndustryExperts from "../src/assets/IndustryExperts.png";
import YearofExperince from "../src/assets/YearofExperince.png";
import landingPageBannerTwo from "../src/assets/landingPageBannerTwo.png";
import persona1 from "../src/assets/persona1.png";
import persona2 from "../src/assets/persona2.png";
import persona3 from "../src/assets/persona3.png";
import persona4 from "../src/assets/persona4.png";
import persona5 from "../src/assets/persona5.png";
import persona6 from "../src/assets/persona6.png";
import mentorlinkWorks1 from "../src/assets/mentorlinkWorks1.png";
import mentorlinkWorks2 from "../src/assets/mentorlinkWorks2.png";
import mentorlinkWorks3 from "../src/assets/mentorlinkWorks3.png";
import socialProofFrame from "../src/assets/socialProofFrame.png";
import BookSessions from "../src/assets/BookSessions.png";
import paymentsImages from "../src/assets/paymentsImages.png";
import AiWandImage from "../src/assets/AiWandImage.png";
import ExpertsImgs from "../src/assets/ExpertsImgs.png";
import InstagramIcon from "../src/assets/InstagramIcon.png";
import Linkedin from "../src/assets/LinkedinIcon.png";
import XIcon from "../src/assets/XIcon.png";
import YoutubeIcon from "../src/assets/YoutubeIcon.png";
import LoginImage from "../src/assets/LoginImage.png";
import aboutUsImageone from "../src/assets/aboutUsImageone.png";
import anoutUsOurMission from "../src/assets/anoutUsOurMission.png";
import ExpertMentorsAboutUS from "../src/assets/ExpertMentorsAboutUS.png";
import PersonalisedGuidanceAboutIUS from "../src/assets/PersonalisedGuidanceAboutIUS.png";
import FlexibleAboutUS from "../src/assets/FlexibleAboutUS.png";
import CallingAboutUS from "../src/assets/CallingAboutUS.png";
import JoinCommunityAboutUS from "../src/assets/JoinCommunityAboutUS.png";
import logoFooter from "../src/assets/logoFooter.png";
import GetMentor from "../src/assets/GetMentor.svg";
import gpayImg from "../src/assets/gpayImg.png";
import paytmImg from "../src/assets/paytmImg.png";
import FlowerIllus from "../src/assets/FlowerIllus.png";
import AiIllus from "../src/assets/AiIllus.png";
import certIllus from "../src/assets/certIllus.png";
import LoaderImages from "../src/assets/LoaderImages.png";
import chatImage from "../src/assets/chatImage.png";
import bagImage from "../src/assets/bagImage.png";
import chevronDown from "../src/assets/chevron-down.png";
import chevronUP from "../src/assets/chevron-up.png";
import loadingAnimation from "../src/assets/loadingAnimation.png";
import EducationIcon from "../src/assets/EducationIcon.png";
import AwardsIcon from "../src/assets/AwardsIcon.png";
import RoboCloseEye from "../src/assets/RoboCloseEye.png";
import RoboOpenEye from "../src/assets/RoboOpenEye.png";
import RoboGhostStraight from "../src/assets/RoboGhostStraight.png";
import RoboGhostRight from "../src/assets/RoboGhostRight.png";
import RoboGhostLeft from "../src/assets/RoboGhostLeft.png";
import logoNew from "../src/assets/logoNew.jpg";
import chatIcon from "../src/assets/chatIcon.svg";

const allImages = {
  Aboutus1,
  Aboutus2,
  chatIcon,
  logoNew,
  Aboutushead,
  homeIcon,
  profile,
  searchIcon,
  groupIcon,
  bell,
  manageIcon,
  officeImage,
  mentors,
  featuredmentors1,
  featuredmentors2,
  imageBanner,
  profilementor,
  arrowvector,
  bookmarks,
  development,
  development2,
  development3,
  menuicon,
  searchicon2,
  logoicon,
  googleIcon,
  linkedinIcon,
  mentor1,
  mentor2,
  mentor3,
  mentor4,
  mentor5,
  mentor6,
  menuWhite,
  mentorProfile,
  ShareIcon,
  workplace,
  bag,
  searchlandingpage,
  calendar,
  brain,
  calendar2,
  time,
  money,
  moreIcon,
  notificationImg,
  likeIcon,
  Share2,
  comment,
  companyProfile,
  searchClose,
  editPencil,
  closeIcon,
  avatar,
  deleteButton,
  money2,
  plusIcon,
  gMail,
  faceBook,
  addIcon,
  tickIcon,
  map,
  filterIcon,
  cameraIcon,
  linkShareIcon,
  filter2,
  logout,
  categoriesIcon,
  couponsIcon,
  logoNameIcon,
  landingPagebannner,
  landingCalendar,
  landingBrain,
  searchLanding,
  testimonial,
  testimonial2,
  testimonial3,
  skills1,
  skills2,
  skills3,
  ProfileComplete,
  sadImage,
  signUpimage,
  logoWhole,
  signupImageMock,
  chatBg,
  doodles,
  logoutIllustration,
  profileIcon,
  settingsIcon,
  logoutNewIcon,
  contactUs,
  underConstruction,
  findmentor,
  contionuslearn,
  CalenderTwo,
  UserFriendly,
  IndustryExperts,
  YearofExperince,
  landingPageBannerTwo,
  persona1,
  persona2,
  persona3,
  persona4,
  persona5,
  persona6,
  mentorlinkWorks1,
  mentorlinkWorks2,
  mentorlinkWorks3,
  socialProofFrame,
  BookSessions,
  paymentsImages,
  AiWandImage,
  ExpertsImgs,
  InstagramIcon,
  Linkedin,
  XIcon,
  YoutubeIcon,
  LoginImage,
  aboutUsImageone,
  anoutUsOurMission,
  ExpertMentorsAboutUS,
  PersonalisedGuidanceAboutIUS,
  FlexibleAboutUS,
  CallingAboutUS,
  JoinCommunityAboutUS,
  logoFooter,
  GetMentor,
  paytmImg,
  gpayImg,
  FlowerIllus,
  AiIllus,
  certIllus,
  LoaderImages,
  chatImage,
  bagImage,
  chevronDown,
  chevronUP,
  loadingAnimation,
  cameraIconTwo,
  EducationIcon,
  AwardsIcon,
  RoboCloseEye,
  RoboOpenEye,
  RoboGhostLeft,
  RoboGhostRight,
  RoboGhostStraight,
};
export { allImages };
