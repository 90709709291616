import {
  Snackbar,
  Alert,
  Button,
  Grid,
  InputAdornment,

  Stack,
  TextField,
  Typography,
} from "@mui/material";
import OtpInput from "react18-input-otp";
import { Box, Container } from "@mui/system";
import React, { useState, useEffect, Component } from "react";
import { allImages } from "../../images";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Link, useNavigate } from "react-router-dom";
import ConfigAPIURL from "../../config/ConfigAPIURL";
import APIRequest from "../../utils/APIRequest";
import Toaster from "../../utils/Toaster";
import { checksUserExist, signInwithGoogle } from "../../config/Firebase";
import LocalStorage from "../../config/LocalStorage";
import Spinner from "react-bootstrap/Spinner";

import ProfileUpdate from "../privateRoute/userRoute/ProfileUpdate";
import { toast } from "react-toastify";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useLocation } from "react-router-dom";
import { useLinkedIn } from "react-linkedin-login-oauth2";
// You can use provided image shipped by this package or using your own
import { LinkedIn } from "react-linkedin-login-oauth2";
import linkedin from "react-linkedin-login-oauth2/assets/linkedin.png";
import { linkedinAuth } from "../../utils/LinkedinAuth";
import "bootstrap/dist/css/bootstrap.min.css";
import BlueButton from "../privateRoute/userRoute/BlueButton";
import Signup from "./Signup";

const ForgotPassword = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [visibile, setVisible] = useState(false);
  const [eMail, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginForm, setLoginForm] = useState(false);
  const [spinner, setSpinner] = useState(true);
  const [showOTPField, setShowOTPField] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [otp, setOTP] = useState("");
  const [userId, setUserId] = useState("");
  const [passcheck, setPasscheck] = useState(false);
  const [snakbarValues, setSnakbarValues] = useState({
    status: false,
    severity: "",
    message: "",
  });
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  useEffect(() => {
    isLogin();
  }, []);

  const notificationClose = (event) => {
    setSnakbarValues({ status: false, severity: "", message: "" });
  };

  const isLogin = async () => {
    const response = await APIRequest.request(
      "GET",
      ConfigAPIURL.userIsLoggedin,
      ""
    );
    if (response?.data?.responseCode === 109) {
      if (response.data.userType !== null) {
        return navigate("/home");
      } else {
        setSnakbarValues({
          status: true,
          severity: "error",
          message: "User does not have permission to login",
        });
      }
    }
    if (response?.data?.responseCode === 108) {
    } else {
      setSnakbarValues({
        status: true,
        severity: "error",
        message: "Server Error, Please try after sometime",
      });
    }
  };

  const handleForgotPassword = async () => {
    if (eMail === "") {
      return setSnakbarValues({
        status: true,
        severity: "error",
        message: "Please enter the Email before Proceeding",
      });
    }

    const requestBody = JSON.stringify({
      email: eMail,
    });

    const response = await APIRequest.request(
      "POST",
      ConfigAPIURL.forgotOTP,
      requestBody
    );

    console.log("response", response);

    if (response?.data?.responseCode === 109) {
      setShowOTPField(true);
      setUserId(response?.data?.result?.userId);
      console.log("settting up userId", response?.data?.result?.userId);
      return setSnakbarValues({
        status: true,
        severity: "success",
        message:
          "OTP has been generated and successfully sent to your email. Please check your email!",
      });
    }
    if (response?.data?.responseCode === 103) {
      return setSnakbarValues({
        status: true,
        severity: "error",
        message: "Email id is not yet registered",
      });
    }
  };

  //OTP Check

  const handleCheckOtp = async () => {
    if (eMail === "") {
      return setSnakbarValues({
        status: true,
        severity: "error",
        message: "Please check the OTP",
      });
    }

    const requestBody = JSON.stringify({
      otp: otp,
    });

    const response = await APIRequest.request(
      "POST",
      ConfigAPIURL.verifyOTP,
      requestBody,
      {
        "Content-Type": "application/json",
      }
    );

    console.log("response", response);

    if (response?.data?.responseCode === 109) {
      setPasscheck(true);
      return setSnakbarValues({
        status: true,
        severity: "success",
        message: "OTP verified successfully !",
      });
    }
    if (response?.data?.responseCode === 103) {
      return setSnakbarValues({
        status: true,
        severity: "error",
        message: "Incorrect OTP",
      });
    }
  };

  //handaling ressetting password after succesfull OTP validation

  const handleResetPassword = async () => {
    if (newPassword === "") {
      return setSnakbarValues({
        status: true,
        severity: "error",
        message: "Please enter a new password before proceeding",
      });
    }

    const requestBody = JSON.stringify({
      userId: userId,
      password: newPassword,
    });

    const response = await APIRequest.request(
      "PUT",
      ConfigAPIURL.resetPassword,
      requestBody,
      {
        "Content-Type": "application/json",
      }
    );

    // Handle the response after resetting the password
    // ...

    if (response?.data?.responseCode === 109) {
      setPasscheck(true);
      setSnackbarOpen(true);
      setTimeout(() => {
        navigate("/login");
      }, 2000);
    } else {
      setSnakbarValues({
        status: true,
        severity: "error",
        message: "Failed to reset password. Please try again.",
      });
    }
  };

  return (
    <>
      {
        <Stack
          width="100%"
          sx={{
            background: "white",
            minHeight: "100vh",
            alignItems: { xs: "center", md: "start" },
          }}
          className="signin__form"
        >
          <Grid container height="100vh" style={{ position: "relative" }}>
            <Grid
              item
              lg={4.5}
              md={5}
              // className="Signin__img"
              sx={{ height: "100vh", display: { xs: "none", md: "block" } }}
            >
              <img
                style={{ objectFit: "cover" }}
                src="https://s3.ap-south-1.amazonaws.com/mentorlink/placeholder/1666162305789_Group%2034722%20(2).png"
                alt=""
              />
            </Grid>
            <Grid item lg={1.5} md={1}></Grid>
            <Grid
              item
              lg={3}
              md={5}
              sm={6}
              xs={12}
              sx={{
                alignSelf: "center",
                justifyContent: { xs: "center", md: "start" },
              }}
            >
              <Stack
                style={{
                  alignSelf: "end",
                  marginBottom: "90px",
                  flexDirection: "row",
                  alignItems: "center",
                  position: "absolute",
                  gap: 8,
                  top: 20,
                  right: 50,
                }}
              >
                <Typography
                  justifySelf="end"
                  variant="subtitle1"
                  textAlign="center"
                  sx={{
                    fontWeight: 400,
                    fontSize: { xs: "16px", md: "16px" },
                    lineHeight: "19.53px",
                    marginTop: "10px",
                  }}
                >
                  New to Mentorlink?{" "}
                </Typography>

                <BlueButton
                  style={{ textTransform: "capitalize", marginTop: 0 }}
                  text="Sign Up"
                  onClick={() => navigate("/signup")}
                />
                {/* </Link> */}
              </Stack>
              <>
                <Typography
                  mb={2}
                  textAlign="center"
                  sx={{
                    fontWeight: "700",
                    fontSize: { xs: "24px" },
                  }}
                >
                  {passcheck && showOTPField
                    ? "Enter new password"
                    : passcheck
                    ? "Reset password"
                    : showOTPField
                    ? "Enter OTP for password reset"
                    : "Reset password"}
                </Typography>

                <Box
                  px={3}
                  py={2}
                  sx={{
                    // border: { sm: "1px solid #EEEEEE" },
                    borderRadius: "15px",
                    overflow: "hidden",
                    background: "white",
                    mt: { lg: "1rem", xs: "2rem" },
                  }}
                >
                  <Typography
                    mb={2}
                    textAlign="start"
                    sx={{
                      fontWeight: "600",
                      fontSize: { xs: "24px", lg: "24px" },
                    }}
                  >
                    {/* Log in */}
                  </Typography>
                  <form>
                    <Stack>
                      <TextField
                        value={eMail}
                        onChange={(e) => setEmail(e.target.value)}
                        autoFocus
                        sx={{
                          borderRadius: "6px",
                          height: "40px",
                          outline: "none",
                          fontSize: "24px",
                          fontWeight: "400",
                        }}
                        style={{ display: showOTPField ? "none" : "" }}
                        variant="outlined"
                        type="text"
                        fullWidth
                        label="E-mail"
                        mb={3}
                        size="small"
                      />
                      {showOTPField && (
                        <>
                          <OtpInput
                            id="demo_input"
                            containerStyle={{
                              justifyContent: "center",

                              display: "flex",
                              width: "100%",
                              gap: 20,
                            }}
                            value={otp}
                            onChange={(value) => setOTP(value)} // Update the onChange event handler
                            numInputs={6}
                            separator={<span></span>}
                            inputStyle={{
                              width: "47px",
                              height: "49px",
                              justifyContent: "space-between",
                              border: "1px solid #E5E5E5",
                              display: passcheck ? "none" : "",
                            }}
                          />
                          <span
                            style={{
                              display: passcheck ? "none" : "",
                              justifyContent: "center",
                              marginTop: "8px",
                              color: "red",
                            }}
                          >
                            Enter your 6 digit OTP
                          </span>
                          <Button
                            type="button"
                            variant="contained"
                            className="submit__button"
                            sx={{
                              width: "100%",
                              background: "#2C72FF",
                              height: { xs: "50px", md: "42px" },
                              mt: "20px",
                              borderRadius: { xs: "10px", md: "10px" },
                              fontSize: { md: "20px", xs: "24px" },
                              fontWeight: 700,
                              textTransform: "capitalize",
                            }}
                            style={{ display: passcheck ? "none" : "" }}
                            onClick={handleCheckOtp}
                          >
                            Verify OTP
                          </Button>
                        </>
                      )}
                    </Stack>

                    <Button
                      type="button"
                      variant="contained"
                      className="submit__button"
                      sx={{
                        width: "100%",
                        background: "#2C72FF",
                        height: { xs: "50px", md: "42px" },
                        mt: "20px",
                        borderRadius: { xs: "10px", md: "10px" },
                        fontSize: { md: "20px", xs: "24px" },
                        fontWeight: 700,
                        textTransform: "capitalize",
                      }}
                      style={{
                        display: showOTPField ? "none" : "",
                      }}
                      onClick={handleForgotPassword}
                    >
                      {showOTPField ? "Reset Password" : "Get OTP"}
                    </Button>
                    {passcheck && (
                      <>
                        <TextField
                          value={newPassword}
                          onChange={(e) => setNewPassword(e.target.value)}
                          sx={{
                            borderRadius: "6px",
                            height: "40px",
                            outline: "none",
                            fontSize: "24px",
                            fontWeight: "400",
                          }}
                          type={visibile ? "text" : "password"}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment
                                position="end"
                                sx={{ cursor: "pointer" }}
                              >
                                {!visibile ? (
                                  <VisibilityOffIcon
                                    onClick={() => setVisible(!visibile)}
                                  />
                                ) : (
                                  <VisibilityIcon
                                    onClick={() => setVisible(!visibile)}
                                  />
                                )}
                              </InputAdornment>
                            ),
                          }}
                          variant="outlined"
                          fullWidth
                          label="New Password"
                          mb={3}
                          size="small"
                        />
                        <Button
                          type="button"
                          variant="contained"
                          className="submit__button"
                          sx={{
                            width: "100%",
                            background: "#2C72FF",
                            height: { xs: "50px", md: "42px" },
                            mt: "20px",
                            borderRadius: { xs: "10px", md: "10px" },
                            fontSize: { md: "20px", xs: "24px" },
                            fontWeight: 700,
                            textTransform: "capitalize",
                          }}
                          onClick={handleResetPassword}
                        >
                          Reset Password
                        </Button>
                        <Snackbar
                          open={snackbarOpen}
                          autoHideDuration={2000}
                          onClose={() => setSnackbarOpen(false)}
                          message="Password changed successfully. Redirecting to login page."
                        />
                      </>
                    )}
                  </form>
                </Box>
              </>
            </Grid>
            <Grid item md={2}></Grid>
          </Grid>

          <Snackbar
            open={snakbarValues.status}
            onClose={notificationClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            autoHideDuration={3000}
          >
            <Alert severity={snakbarValues.severity}>
              {snakbarValues.message}
            </Alert>
          </Snackbar>
        </Stack>
      }
    </>
  );
};

const buttonLink = {
  color: "black",
};

export default ForgotPassword;
