import React, { useState } from "react";
import moment from "moment";
import { useEffect } from "react";
import { Button, Grid, Stack, Typography } from "@mui/material";
import "./booking.css";
import { useRef } from "react";
import { v4 as uuid } from "uuid";
import "./booking.scss";
import APIRequest from "../../../../utils/APIRequest";
import ConfigAPIURL from "../../../../config/ConfigAPIURL";
import "./booking.scss";
import { convenience, defaultPrice } from "../../../../config/priceData";

function get24hTime(str) {
  str = String(str).toLowerCase().replace(/\s/g, "");
  var has_am = str.indexOf("am") >= 0;
  var has_pm = str.indexOf("pm") >= 0;
  // first strip off the am/pm, leave it either hour or hour:minute
  str = str.replace("am", "").replace("pm", "");
  // if hour, convert to hour:00
  if (str.indexOf(":") < 0) str = str + ":00";
  // now it's hour:minute
  // we add am/pm back if striped out before
  if (has_am) str += " am";
  if (has_pm) str += " pm";
  // now its either hour:minute, or hour:minute am/pm
  // put it in a date object, it will convert to 24 hours format for us
  var d = new Date("1/1/2011 " + str);
  // make hours and minutes double digits
  var doubleDigits = function (n) {
    return parseInt(n) < 10 ? "0" + n : String(n);
  };
  return doubleDigits(d.getHours()) + ":" + doubleDigits(d.getMinutes());
}

function tConvert(time) {
  // Check correct time format and split into components
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time,
  ];

  if (time.length > 1) {
    // If time format correct
    time = time.slice(1); // Remove full string match value
    time[5] = +time[0] < 12 ? "AM" : "PM"; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join(""); // return adjusted time or original string
}

const SelectedSlots = ({
  time,
  value,
  index,
  handleSelectedValues,
  selected,
  disabled,
}) => {
  // const [choosen, setChoosen] = useState(false);
  const choosenRef = useRef();

  // useEffect(() => {}, [selected]);
  const selectedValue = selected.some((val) => {
    return val?.id === value?.id;
  });

  return (
    <Stack
      component={Button}
      disabled={disabled}
      className="selectedtime__hover"
      variant="text"
      // disableFocusRipple="false"
      // disableRipple="false"
      // centerRipple="false"
      sx={{
        // border: "1px solid rgba(0, 0, 0, 0.38)",
        border: selectedValue ? "1px solid #00275A" : "1px solid #E0E0E0",
        padding: "12px",
        borderRadius: { xs: "8px", md: "8px" },
        justifyContent: "center",
        // background: selectedValue ? "#1C3464" : "white",
        background: selectedValue ? "#EAF1FF" : "white",
        // color: selectedValue ? "white" : "black",
        color: selectedValue ? "#00275A" : "black",
        width: { xs: "165px", sm: "170px", md: "200px" },
        cursor: "pointer",
        marginBottom: "8px",
        // "&.MuiButtonBase-root:hover": {
        //   bgcolor: selectedValue ? "#1C3464" : "white",
        //   color: selectedValue ? "white" : "black",
        // },
      }}
      ref={choosenRef}
      onClick={() => {
        // !choosen ? setChoosen(value?.id) : setChoosen(null);
        // setCurrentlySelected([...currentlySelected, value?.id]);
        handleSelectedValues(
          value?.id,
          value?.timings,
          time[index + 1].timings,
          index
        );
      }}
    >
      <Typography
        textAlign="center"
        sx={{
          fontSize: { xs: "12px", md: "15px" },
          overflow: "hidden",
          px: { xs: 0.1, md: 0.5 },
        }}
        fontWeight="600"
        variant="subtitle1"
      >
        {value.timings} - {time[index + 1].timings}
      </Typography>
    </Stack>
  );
};

const SlotsBooking = ({ splittedTimeSlot, setQuerys, querys }) => {
  let intime = splittedTimeSlot.slot.from;
  let outtime = splittedTimeSlot.slot.till;
  const [time, setTime] = useState([]);
  const result = [];
  const [selected, setSelected] = useState([]);
  const [currentlySelected, setCurrentlySelected] = useState([]);
  const [disablingDates, setDisablingDates] = useState([]);

  useEffect(() => {
    setSelected([]);
    fetchDisabledSlots(querys?.sessionDate, querys?.mentorId);
  }, [querys.sessionDate]);
  useEffect(() => {
    const mentorFee =
      ((querys?.price ? querys?.price : defaultPrice) / 2) * selected.length;
    // const convenience = 50;
    const tax = (convenience * 18) / 100;
    const totalFee = mentorFee + convenience + tax;
    setQuerys({
      ...querys,
      sessionTime: selected,
      appointmentFee: mentorFee,
      convenienceFee: mentorFee ? convenience : 0,
      tax: mentorFee ? tax : 0,
      totalPayment: mentorFee ? totalFee : 0,
    });
  }, [selected]);

  useEffect(() => {
    if (
      new Date(querys.sessionDate * 1000).toDateString() !==
      new Date().toDateString()
    )
      intervals(intime, outtime);
    setSelected([]);
    setQuerys({
      ...querys,
      appointmentFee: 0,
    });
  }, [splittedTimeSlot]);

  useEffect(() => {
    if (
      new Date(querys.sessionDate * 1000).toDateString() ===
      new Date().toDateString()
    ) {
      // converts to 24 hr format
      const timeConvertedToSeconds =
        new Date(querys.sessionDate * 1000).toDateString() +
        " " +
        get24hTime(splittedTimeSlot.slot.from);

      // if its greater than todays time then
      let addedTime = new Date(timeConvertedToSeconds) / 1000;
      if (new Date() / 1000 > addedTime) {
        const currentTime = tConvert(
          new Date().getHours() + ":" + new Date().getMinutes()
        ).toLowerCase();
        intime = currentTime;
        // console.log(intime);
        intervals(intime, outtime);
      }
    }
  }, [splittedTimeSlot]);

  // disabled slots fetching according to the dates
  const fetchDisabledSlots = async (date, recordId) => {
    console.log({ date, recordId });
    try {
      const response = await APIRequest.request(
        // date and recordId from querys
        "POST",
        ConfigAPIURL.getBookedSlots,
        JSON.stringify({
          date: date,
          recordId: recordId,
        })
      );
      if (response?.data?.responseCode === 109) {
        setDisablingDates(response?.data?.result);
      }
    } catch (err) {
      console.log(err);
    }
  };

  function intervals(startString, endString) {
    var start = moment(startString, "hh:mm a");
    var end = moment(endString, "hh:mm a");
    start.minutes(Math.ceil(start.minutes() / 15) * 15);

    var current = moment(start);

    while (current <= end) {
      const unique_id = uuid();

      if (result.includes(current.format("hh:mm a"))) {
        return null;
      } else {
        result.push({ timings: current.format("hh:mm a"), id: unique_id });
        current.add(30, "minutes");
        setTime(result);
      }
    }

    return time;
  }

  const handleSelectedValues = (unique_id, from, till, index) => {
    const allStots = [...selected];
    if (allStots && allStots?.length > 0) {
      var constantSlot = allStots?.some((val) => {
        return (
          val?.ind + 1 === index || val?.ind - 1 === index || val?.ind === index
        );
      });
    }
    //constant slot will be undefined first time and after it will show true or false and validate if its false
    if (constantSlot === false) {
      setSelected([]);
      return;
    }

    const isFound = allStots.find((value) => {
      return unique_id === value?.id;
    });
    if (!isFound) {
      if (
        //if less than time is found just add it to first of array
        allStots?.some((val) => {
          return val?.ind - 1 === index;
        })
      ) {
        //adding to first of index
        allStots.unshift({
          id: unique_id,
          from: from,
          till: till,
          ind: index,
        });
      } //adding to last of index
      else
        allStots.push({
          id: unique_id,
          from: from,
          till: till,
          ind: index,
        });
    }
    if (isFound) {
      // (allStots.findIndex((a) => a === isFound));
      allStots.splice(
        allStots.findIndex((a) => a === isFound),
        1
      );
    }
    // finally (allStots) will be added to the state and shown to summary;
    setSelected(allStots);
  };

  // console.log(disablingDates);

  return (
    <Stack mt={1.5}>
      {time && (
        <Typography
          variant="h5"
          sx={{ fontSize: "20px" }}
          fontWeight="600"
          mb={0.8}
        >
          Select a Time
        </Typography>
      )}
      <Grid
        // direction="column"
        // sx={{ flexDirection: { xs: "column", sm: "row" } }}
        container
        // sx={{ display: "grid", gridTemplateColumns: "repeat(2,1fr)" }}
        spacing={1}
        sx={{ flexWrap: { xs: "wrap", md: "nowrap" } }}
        overflow="auto"
        className="date__scroll"
      >
        {time && time.length > 0
          ? time?.map((value, index) => {
              let disabled = false;
              if (disablingDates[0]?.sessionTime) {
                disablingDates[0]?.sessionTime.forEach((data) => {
                  if (value?.timings === data?.from) disabled = true;
                });
              }
              if (!time[index + 1]) return;
              return (
                <Grid item xs={6} sm={3} md={4} key={index}>
                  <SelectedSlots
                    value={value}
                    index={index}
                    time={time}
                    handleSelectedValues={handleSelectedValues}
                    setCurrentlySelected={setCurrentlySelected}
                    currentlySelected={currentlySelected}
                    selected={selected}
                    disablingDates={disablingDates}
                    disabled={disabled}
                  />
                </Grid>
              );
            })
          : null}
      </Grid>
    </Stack>
  );
};

export default SlotsBooking;
