import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    bannerDatas: [],
    profileData : null
  },
  reducers: {
    update: (state, action) => {
      state.bannerDatas = action.payload;
    },
    userDetails : (state,action) =>{
      state.profileData = action.payload
    }
  },
});

export const { update,userDetails } = userSlice.actions;
export default userSlice.reducer