import { Stack, TextField, Typography } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button } from "react-bootstrap";
import ConfigAPIURL from "../../../../config/ConfigAPIURL";
import APIRequest from "../../../../utils/APIRequest";
import BlueButton from "../BlueButton";
import "./modalSkills.scss";

const ModalSkills = ({ userSkills, onClose }) => {
  const [tags, setTags] = useState(userSkills?.skills);
  const [terms, setTerms] = useState("");
  const [searchResults, setSearchResults] = useState(null);

  useEffect(() => {
    fetchSkills();
  }, [terms]);

  const fetchSkills = async () => {
    // if (terms === "") return;
    const response = await APIRequest.request(
      "POST",
      ConfigAPIURL.userAllSkills,
      JSON.stringify({
        Skill: terms,
      })
    );
    if (response?.data?.responseCode === 109) {
      setSearchResults(response.data.result);
      console.log(response.data.result);
    }
  };
  // removing the tags from state
  const removeTags = (indexToRemove) => {
    setTags([...tags.filter((_, index) => index !== indexToRemove)]);
  };
  //adding tags to state
  const addTags = (e) => {
    if (e.target.value !== "") {
      setTags([...tags, e.target.value]);
      e.target.value = "";
      setTerms("");
    }
  };
  // to update the current tags
  const UpdateTags = async () => {
    const response = await APIRequest.request(
      "PUT",
      ConfigAPIURL.UserProfileUpdate,
      JSON.stringify({
        skills: tags,
      })
    );
    //closes the popup
    onClose();
  };
  
  return (
    <Stack alignItems="start">
      <div className="tags-input">
        <ul id="tags">
          {tags &&
            tags?.map((tag, index) => (
              <li key={index} className="tag">
                <span className="tag-title">{tag}</span>
                <span
                  className="tag-close-icon"
                  onClick={() => removeTags(index)}
                >
                  x
                </span>
              </li>
            ))}
        </ul>
        <input
          type="text"
          onKeyUp={(event) => (event.key === "Enter" ? addTags(event) : null)}
          placeholder="Press enter to add skills"
          value={terms}
          onChange={(e) => setTerms(e.target.value)}
        />
      </div>
      {/* ui which renders the result */}
      <Typography
        variant="h5"
        sx={{ fontSize: { md: "20px", xs: "16px" } }}
        mt={4}
      >
        Suggested Skills
      </Typography>
      <Stack mt={2} direction="row" flexWrap={'wrap'}>
        {searchResults &&
          searchResults?.map((val) => {
            return (
              <Button
                sx={{}}
                variant="h5"
                key={val._id}
                onClick={() => setTerms(val.skill)}
              >
                {val.skill}
              </Button>
            );
          })}
      </Stack>
      <Stack alignSelf="flex-end" mt={5} onClick={UpdateTags} style={{position:'sticky',bottom:'5px',backgroundColor:'#fff'}}>
        <BlueButton text="Submit" size="lg" />
      </Stack>
    </Stack>
  );
};

export default ModalSkills;
