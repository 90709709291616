import {
  Alert,
  Backdrop,
  Box,
  Button,
  Fade,
  Modal,
  Rating,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { allImages } from "../../../../images";
import BlueButton from "../BlueButton";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { useRef } from "react";
import lottie from "lottie-web";
import { useNavigate } from "react-router-dom";
import APIRequest from "../../../../utils/APIRequest";
import ConfigAPIURL from "../../../../config/ConfigAPIURL";
import Lottie from "lottie-react";
import ratingIcon from "../../../../assets/rating-stars.json";
import DialogBox from "../../../general/Dialog/Index";
import CancelDialog from "./CancelDialog";
import InfoIcon from "@mui/icons-material/Info";
import PopoverComponent from "../../../popover/Index";
import MessageIcon from "@mui/icons-material/Message";
import { Link } from "react-router-dom";
import { encryptandDecrypt } from "../../../../config/EncrytDecryptData";

const cancelForm = {
  isOpen: false,
  reason: "",
  _id: null,
};

const UpcomingSession = ({
  data,
  isMentor,
  handleBookingUpdate,
  handleTwilio,
  setReload,
  reload,
  handleDownload,
  submitBillingAddtress,
  handleMentorGenerateInvoice,
  userForm,
}) => {
  console.log("this is data", data);
  const sendMessage = isMentor ? data?.userId : data?.mentorId;
  // console.log(data);
  const [enableButtons, setEnableButtons] = useState(false);
  const [change, setChange] = useState(false);
  const [isRated, setIsRated] = useState(false);
  // check whether to enable accept and cancel btn if its previous one
  const container = useRef();
  const ratingContainer = useRef();
  const navigate = useNavigate();
  const [feedback, setFeedback] = useState("");
  const [rating, setRating] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [change2, setChange2] = useState(false);

  const [isCancel, setIsCancel] = useState(cancelForm);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const resetForm = () => setIsCancel(cancelForm);

  const [snakbarValues, setSnakbarValues] = useState({
    status: false,
    severity: "",
    message: "",
  });

  const notificationClose = (event) => {
    setSnakbarValues({ status: false, severity: "", message: "" });
  };

  const handleOpen = () => {
    setOpen(true);
    setChange2(!change2);
  };
  const handleClose = () => setOpen(false);

  useEffect(() => {
    lottie.loadAnimation({
      container: container.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: require("../../../../assets/time_icon.json"),
    });

    return () => {
      lottie.destroy();
    };
  }, [change]);

  useEffect(() => {
    // console.log(1);
    lottie.loadAnimation({
      container: ratingContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: require("../../../../assets/time_icon.json"),
    });

    return () => {
      lottie.destroy();
    };
  }, [change2]);

  useEffect(() => {
    // validate
    if (data?.sessionDate && data?.sessionTime) {
      compareTime(data?.sessionDate, data?.sessionTime);
    }

    // check the session is completed or not if the status is confirmed
    if (data?.status === "confirmed") {
      const lastSessionsTime = data?.sessionTime.find((val) => {
        if (val === data?.sessionTime[data?.sessionTime.length - 1]) return val;
      });
      const endingTime =
        new Date(data?.sessionDate * 1000).toDateString() +
        " " +
        get24hTime(lastSessionsTime?.till);
      // if current date is greated than ending date and time then ===> session is completed
      if (new Date() / 1000 > new Date(endingTime) / 1000) {
        handleBookingUpdate(data?._id, "completed");
      }
    }

    //fetches the completed bookings whether rated or not
    if (data?.status === "completed") {
      fetchRatedOrNot();
    }
  }, [data]);

  const fetchRatedOrNot = async () => {
    try {
      const response = await APIRequest.request(
        "POST",
        ConfigAPIURL.ratingSessions,
        JSON.stringify({
          recordId: data?._id,
        })
      );
      if (response?.code === 100 && response?.data?.responseCode === 109) {
        // finally set the rating button should be shown or not
        setIsRated(response?.data?.result);
      }
    } catch (err) {
      console.log(err);
    }
  };

  function get24hTime(str) {
    str = String(str).toLowerCase().replace(/\s/g, "");
    var has_am = str.indexOf("am") >= 0;
    var has_pm = str.indexOf("pm") >= 0;
    // first strip off the am/pm, leave it either hour or hour:minute
    str = str.replace("am", "").replace("pm", "");
    // if hour, convert to hour:00
    if (str.indexOf(":") < 0) str = str + ":00";
    // now it's hour:minute
    // we add am/pm back if striped out before
    if (has_am) str += " am";
    if (has_pm) str += " pm";
    // now its either hour:minute, or hour:minute am/pm
    // put it in a date object, it will convert to 24 hours format for us
    var d = new Date("1/1/2011 " + str);
    // make hours and minutes double digits
    var doubleDigits = function (n) {
      return parseInt(n) < 10 ? "0" + n : String(n);
    };
    return doubleDigits(d.getHours()) + ":" + doubleDigits(d.getMinutes());
  }

  const compareTime = (date, time) => {
    //convert to 24hr format
    const timeString = get24hTime(time[0].from);
    const sessionDateTime =
      new Date(date * 1000).toDateString() + " " + timeString;
    setEnableButtons(new Date() / 1000 < new Date(sessionDateTime) / 1000);
  };

  const handleJoinMeeting = async (recordId, date, time, orderId) => {
    //validate if the time is exactly 15 mins before
    // convert to 24hrs format
    const timeString = get24hTime(time[0].from);
    const sessionDateTime =
      new Date(date * 1000).toDateString() + " " + timeString;
    // get the last session time end date its till value
    const lastSessionsTime = time.find((val) => {
      if (val === time[time.length - 1]) return val;
    });
    const endingTime =
      new Date(date * 1000).toDateString() +
      " " +
      get24hTime(lastSessionsTime?.till);
    // compare if the Session Date time is less than todays date Time validate
    //session will start 10 mins before
    if (new Date() / 1000 > new Date(sessionDateTime) / 1000 - 600) {
      // current time < end time then handle twilio validation
      if (new Date() / 1000 < new Date(endingTime) / 1000) {
        handleTwilio(orderId, endingTime);
      } else {
        setSnakbarValues({
          status: true,
          severity: "info",
          message: "Your Session has Ended",
        });
        handleBookingUpdate(recordId, "completed");
      }
    } else submitAlert();
  };

  const submitAlert = () => {
    //only just the alert shows its not the session time
    confirmAlert({
      customUI: ({ onClose }) => {
        // lottie.play();
        setChange(!change);
        return (
          <div
            className="upcoming__session--Alert"
            style={{ textAlign: "center" }}
          >
            <Stack alignItems="center">
              <Stack ref={container} width={200} height={200}></Stack>
            </Stack>
            <Typography variant="subtitle1" fontSize="16px">
              {" "}
              Your Session Has not yet started{" "}
            </Typography>
          </div>
        );
      },
    });
  };

  const SubmitCancelConfirm = (id) => {
    //if yes then session is cancelled
    confirmAlert({
      customUI: ({ onClose }) => {
        // lottie.play();
        return (
          <div
            className="upcoming__session--Alert"
            style={{ textAlign: "center" }}
          >
            <Typography variant="subtitle1" fontSize="16px">
              {" "}
              Are you sure you want to cancel the Session
            </Typography>
            <div style={style}>
              <button className="navbar__logout--button" onClick={onClose}>
                No
              </button>
              <button
                onClick={() => {
                  handleBookingUpdate(id, "cancelled");
                  onClose();
                }}
              >
                Yes
              </button>
            </div>{" "}
          </div>
        );
      },
    });
  };

  const createReviewAndRatings = async (
    recordId,
    mentorId,
    rating,
    feedback
  ) => {
    try {
      const response = await APIRequest.request(
        "POST",
        ConfigAPIURL.createBookings,
        JSON.stringify({
          recordId,
          mentorId,
          rating,
          feedback,
        })
      );

      if (response?.code === 100 && response?.data?.responseCode === 109) {
        setReload(!reload);
        handleClose();
      }
    } catch (err) {
      console.log(err);
    }
  };
  console.log(isCancel);
  return (
    <Stack
      sx={{ background: "white", }}
      border="1px solid #CECACA"
      mt={2}
      borderRadius="15px"
    >
      {isCancel?.isOpen && (
        <DialogBox isOpen={isCancel?.isOpen}>
          <CancelDialog
            userForm={userForm}
            data={isCancel}
            setIsCancel={setIsCancel}
            handleBookingUpdate={handleBookingUpdate}
            resetForm={resetForm}
            isMentor={isMentor}
          />
        </DialogBox>
      )}
      <Stack direction={"row"} justifyContent="space-between" px={2} pt={1}>
        <Stack direction={"row"} alignItems="center" gap={2} py={1}>
          {data?.status === "enquiry" || data?.status === "confirmed" ? (
            <>
              <Box
                width="16px"
                height="16px"
                sx={{
                  background:
                    data?.status === "enquiry" && !enableButtons
                      ? "red"
                      : "#23AD29",
                  borderRadius: "100%",
                }}
              ></Box>
              <Typography
                variant="h2"
                color="#2A4B95"
                sx={{ fontWeight: "600", fontSize: { md: "16px", xs: "19px" } }}
              >
                {data?.status === "enquiry" && !enableButtons
                  ? "Unconfirmed Sessions"
                  : "Upcoming session"}
              </Typography>
            </>
          ) : data?.status === "completed" ? (
            <>
              <Box
                width="16px"
                height="16px"
                sx={{ background: "#1C3464", borderRadius: "100%" }}
              ></Box>
              <Typography
                variant="h2"
                color="#2A4B95"
                sx={{
                  fontWeight: "600",
                  fontSize: { md: "16px", xs: "19px" },
                }}
              >
                Previous session
              </Typography>
            </>
          ) : (
            data?.status === "cancelled" && (
              <>
                <Box
                  width="20px"
                  height="20px"
                  sx={{ background: "#1C3464", borderRadius: "100%" }}
                ></Box>
                <Typography
                  variant="h2"
                  color="#2A4B95"
                  sx={{
                    fontWeight: "600",
                    fontSize: { md: "16px", xs: "19px" },
                  }}
                >
                  Cancelled session
                </Typography>
                {data?.reason && (
                  <>
                    <InfoIcon
                      onClick={(e) => setAnchorEl(e.currentTarget)}
                      style={{ color: "#1C3464", cursor: "pointer" }}
                    />
                    <PopoverComponent
                      anchorEl={anchorEl}
                      setAnchorEl={setAnchorEl}
                    >
                      {data?.reason}
                    </PopoverComponent>
                  </>
                )}
              </>
            )
          )}
        </Stack>
        {data?.status === "confirmed" && (
          <Button
            sx={{
              p: { xs: "7px 14px", md: "4px 14px" },
              borderRadius: "37px",
              fontSize: { xs: "10px", md: "14px" },
              fontWeight: "700",
            }}
            className="submit__button"
            variant="contained"
            onClick={() => {
              handleJoinMeeting(
                data?._id,
                data?.sessionDate,
                data?.sessionTime,
                data?.orderId
              );
              //
            }}
          >
            Join Meeting
          </Button>
        )}
        {data?.status !== "cancelled" && (
          <Link
            style={{ marginTop: 15 }}
            to={"/chats"}
            state={{ ...sendMessage }}
          >
            <MessageIcon
              sx={{ cursor: "pointer" }}
              className="heading__color"
            />
          </Link>
        )}
      </Stack>
      <hr style={{ marginTop: ".5rem" }} />

      <Stack
        direction="row"
        sx={{
          flexDirection: {
            xs: "column",
            md: "row",
            alignItems: { xs: "start", md: "flex-end" },
          },
        }}
        py={1.5}
        justifyContent="space-between"
        px={2}
      >
        <Stack gap={1}>
          <Stack direction="row" alignItems="center" gap={3}>
            <Box
              onClick={() => {
                !isMentor &&
                  navigate(
                    `/mentors/id?payload=${encryptandDecrypt.encrypt(
                      data?.mentorId?._id
                    )}`
                  );
              }}
              sx={{
                width: { md: "20px", xs: "20px" },
                height: { md: "20px", xs: "20px" },
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "100%",
                cursor: "pointer",
                overflow: "hidden",
              }}
            >
              {isMentor ? (
                <img
                  src={
                    data?.userId?.profileImage
                      ? data?.userId?.profileImage
                      : allImages.avatar
                  }
                  alt=""
                />
              ) : (
                <img
                  src={
                    data?.mentorId?.profileImage
                      ? data?.mentorId?.profileImage
                      : allImages.avatar
                  }
                  alt=""
                />
              )}
            </Box>
            <Typography
              fontWeight="600"
              sx={{ fontSize: { xs: "14px", md: "16px" } }}
              variant="subtitle1"
              textTransform="capitalize"
            >
              {isMentor
                ? data?.userId?.fname
                  ? data?.userId?.fname
                  : " " + " " + data?.userId?.lname
                  ? data?.userId?.lname
                  : ""
                : data?.mentorId?.fname + " " + data?.mentorId?.lname
                ? data?.mentorId?.fname + " " + data?.mentorId?.lname
                : ""}
            </Typography>
          </Stack>

          <Stack direction="row" alignItems="baseline" gap={3}>
            <Box
              sx={{
                width: { md: "18px", xs: "20px" },
                height: { md: "18px", xs: "20px" },
              }}
            >
              <img src={allImages.calendar2} alt="" />
            </Box>
            <Typography
              fontWeight="600"
              sx={{ fontSize: { xs: "14px", md: "16px" } }}
              variant="subtitle1"
            >
              {data?.sessionDate
                ? new Date(data?.sessionDate * 1000).toDateString()
                : ""}
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" gap={3}>
            <Box
              sx={{
                width: { md: "18px", xs: "20px" },
                height: { md: "18px", xs: "20px" },
              }}
            >
              <img src={allImages.time} alt="" />
            </Box>
            <Typography
              fontWeight="600"
              sx={{ fontSize: { xs: "14px", md: "16px" } }}
              variant="subtitle1"
              width="325px"
            >
              {data?.sessionTime &&
                data?.sessionTime.length > 0 &&
                data?.sessionTime.map((value, ind) => {
                  return (
                    <React.Fragment key={ind}>
                      {value?.from} to {value?.till},{" "}
                    </React.Fragment>
                  );
                })}
            </Typography>
            {/* {"Duration : 30 Minutes"} */}
            {/* {"12:00 - 2:00 pm (Duration : 2 hours)"} */}
          </Stack>
          <Stack direction="row" alignItems="center" gap={3}>
            <Box
              sx={{
                width: { md: "18px", xs: "20px" },
                height: { md: "18px", xs: "20px" },
              }}
            >
              <img src={allImages.money} alt="" />
            </Box>
            <Typography
              fontWeight="700"
              sx={{ fontSize: { xs: "14px", md: "16px" } }}
              variant="subtitle1"
            >
              ₹{data?.totalPayment ? data?.totalPayment : "000"}
            </Typography>
          </Stack>
        </Stack>

        <Stack
          direction={"row"}
          fontSize="10px"
          sx={{ mt: { xs: 3, md: 0 }, alignItems: { xs: "start", md: "end" } }}
          gap={1}
        >
          {data?.status === "enquiry" || data?.status === "confirmed" ? (
            <>
              {data?.status === "enquiry" && isMentor && enableButtons && (
                <Button
                  className="button__overlay"
                  sx={{
                    fontWeight: "700",
                    fontSize: { xs: "14px", md: "14px" },
                    // border: "2px solid #2A4B95",
                    borderRadius: "37px",
                    py: 0.5,
                    px: 2,
                  }}
                  onClick={() => handleBookingUpdate(data?._id, "confirmed")}
                >
                  Accept
                </Button>
              )}
              {enableButtons && (
                <Button
                  className="button__overlay"
                  sx={{
                    fontWeight: "700",
                    fontSize: { xs: "14px", md: "14px" },
                    // border: "2px solid #2A4B95",
                    borderRadius: "37px",
                    py: 0.5,
                    px: 2,
                  }}
                  onClick={() =>
                    setIsCancel((p) => {
                      return { ...p, _id: data?._id, isOpen: true };
                    })
                  }
                >
                  Cancel
                </Button>
              )}
            </>
          ) : (
            data?.status === "completed" && (
              <>
                {/* rating button */}
                {!isMentor && !isRated && (
                  <Button
                    className="button__overlay"
                    sx={{
                      fontWeight: "700",
                      fontSize: { xs: "14px", md: "14px" },
                      // border: "2px solid #2A4B95",
                      borderRadius: "37px",
                      py: 0.5,
                      px: 2,
                    }}
                    onClick={handleOpen}
                  >
                    Rate & Review
                  </Button>
                )}
                {
                  <Button
                    className="button__overlay"
                    onClick={() => {
                      if (isMentor) handleMentorGenerateInvoice(data?._id);
                      else submitBillingAddtress(data._id);
                    }}
                    sx={{
                      fontWeight: "700",
                      fontSize: { xs: "14px", md: "14px" },
                      // border: "2px solid #2A4B95",
                      borderRadius: "37px",
                      py: 0.5,
                      px: 2,
                    }}
                  >
                    Invoice
                  </Button>
                }
              </>
            )
          )}
        </Stack>
      </Stack>
      {/*  modal starting for the ratings*/}
      <Modal
        // sx={{ width: "500px" }}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={modalStyle}>
            <div
              className="upcoming__session--Alert"
              style={{ textAlign: "center" }}
            >
              <Stack height="100px" alignItems="center">
                <Lottie
                  style={{
                    // width: "300px",
                    height: "200px",
                    position: "absolute",
                    top: "-20px",
                  }}
                  animationData={ratingIcon}
                  loop={true}
                />
              </Stack>

              <Typography
                variant="subtitle1"
                fontSize="16px"
                fontWeight="600"
                lineHeight="27.8px"
              >
                {" "}
                How would you rate your experience with{" "}
                {data?.mentorId?.fname + " " + data?.mentorId?.lname}
              </Typography>
              <div style={style}>
                {/* ratings star */}
                <Rating
                  size="large"
                  name="simple-controlled"
                  value={rating}
                  onChange={(event, newValue) => {
                    setRating(newValue);
                  }}
                />
                {/* ratings text box */}
                <TextField
                  mt={6}
                  sx={{ mt: "1rem" }}
                  fullWidth
                  id="outlined-multiline-flexible"
                  label="Enter your feed back (Optional)"
                  // placeholder="Enter your feed back (Optional) "
                  multiline
                  maxRows={4}
                  value={feedback}
                  onChange={(e) => {
                    // console.log(e.target.value);
                    setFeedback(e.target.value);
                  }}
                />
                <Box
                  sx={{ mt: 2 }}
                  onClick={() =>
                    createReviewAndRatings(
                      data?._id,
                      data?.mentorId?._id,
                      rating,
                      feedback
                    )
                  }
                >
                  <BlueButton text="Submit" />
                </Box>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
      <Snackbar
        open={snakbarValues.status}
        onClose={notificationClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={3000}
      >
        <Alert severity={snakbarValues.severity}>{snakbarValues.message}</Alert>
      </Snackbar>
    </Stack>
  );
};

const style = {
  display: "flex",
  flexDirection: "column",
  textAlign: "center",
  alignItems: "center",
  // justifyContent: "center",
  marginTop: ".8rem",
};

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 520,
  bgcolor: "background.paper",
  borderRadius: "4px",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default UpcomingSession;
