import {
  Avatar,
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MentorProfileArea from "./MentorProfileArea";
import ConfigAPIURL from "../../../../config/ConfigAPIURL";
import APIRequest from "../../../../utils/APIRequest";
import { Stack } from "@mui/material";

import LocalStorage from "../../../../config/LocalStorage";
import MentorFollow from "../mentorprofilepage/MentorFollow";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { allImages } from "../../../../images";
import Demo from "./Demo";
import ProfilExperience from "../mentorprofilepage/ProfileExperience";
import MentorDetailsAccordion from "../mentorprofilepage/MentorDetailsAccordion";
import SheduleSession from "../mentorprofilepage/SheduleSession";
import UserAdditional from "./UserAdditional";
import Offerings from "./Offerings";
import SheduleModels from "./Models/SheduleModels";
import SocialMedia from "../mentorprofilepage/SocialMedia";
import SocialMediaModel from "./Models/SocialMediaModel";
import CompletnessRatio from "../MentorHome/CompletnessRatio";
import "./userprofile.scss";
import { useSelector } from "react-redux";
import BackdropComponent from "../../../general/backdrop/Index";
import Settings from "../MentorHome/Settings";
import CloseIcon from "@mui/icons-material/Close";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";

const Index = () => {
  const [userDetails, setUserDetails] = useState(null);
  const [changeData, setChangeData] = useState(true);
  const [totalRating, setTotalRating] = useState();
  const [userStatics, setUserStatics] = useState();
  const userData = useSelector((state) => state?.user?.profileData);
  const [isLoading, setIsLoading] = useState(false);
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const navigate = useNavigate();

  // the logged in users basic info is fetched
  const fetchUserInfo = async () => {
    setIsLoading(true);
    const response = await APIRequest.request(
      // get the users id from localstorage
      "GET",
      ConfigAPIURL.userProfile,
      ""
    );
    if (response?.data?.responseCode === 109) {
      //useradditional details is stored as state and displayed
      setUserDetails(response.data.profile);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    fetchMentorTotalRating();
    fetchUserStatistics();
  }, []);

  useEffect(() => {
    fetchUserInfo();
  }, [changeData]);

  const fetchMentorTotalRating = async () => {
    try {
      const response = await APIRequest.request(
        "POST",
        ConfigAPIURL.totalRatingReviewCount,
        JSON.stringify({
          mentorId: userData?._id,
        })
      );
      if (response?.data?.responseCode === 109) {
        setTotalRating(response?.data?.result[0]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  // users statistics data
  const fetchUserStatistics = async () => {
    try {
      const response = await APIRequest.request(
        "GET",
        ConfigAPIURL.userStatistics + "?currentUserId=" + userData?._id,
        ""
      );
      if (response?.code === 100 && response?.data?.responseCode === 109) {
        // setTotalReviews(response?.data?.result);
        setUserStatics(response?.data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const localdata = JSON.parse(localStorage.getItem("userDetails"));
  // console.log("this is data", localdata);
  const location = useLocation();

  // console.log("userDetailsuserDetails", userDetails);

  return (
    <Container maxWidth="lg" style={{ marginBottom: "1rem" }}>
      <BackdropComponent isOpen={isLoading} />
      {/* <Grid item xs={12} md={1}></Grid> */}
      {userDetails && localdata?.mentor === true && (
        <Grid container sx={{}} columnSpacing={1}>
          <Grid item xs={12} md={3.2}>
            <Stack
              className="sticky__div"
              // mt={3}
              px={2}
              sx={{
                background: "white",
                border: { md: "0.742701px solid #D2D2D2" },
                borderRadius: "15px",
                mt: { xs: "3rem", md: 3 },
                py: { md: 3, xs: 0 },
              }}
            >
              {isSmallScreen && (
                <CloseIcon
                  style={{ cursor: "pointer", alignSelf: "end", margin: "8px" }}
                  onClick={() => navigate("/home")}
                />
              )}

              <MentorFollow
                userStatics={userStatics}
                userDetails={userDetails}
              ></MentorFollow>

              <MentorProfileArea
                userStatics={userStatics}
                data={userDetails}
                totalRating={totalRating}
              />
              {localdata?.mentor === true && (
                <SocialMedia
                  data={userDetails}
                  changeData={changeData}
                  setChangeData={setChangeData}
                />
              )}
              {/* {localdata?.associatedOrganization?.name !== "" ? (
                <div>
                  <p>
                    Your Associated Organization is{" "}
                    {localdata?.associatedOrganization?.name}{" "}
                  </p>
                </div>
              ) : (
                <div>
                  <p>You are not assigned for any organization</p>
                </div>
              )} */}
            </Stack>
          </Grid>
          <Grid
            item
            xs={12}
            md={6.2}
            justifySelf="center"
            style={{ marginTop: "28px" }}
          >
            {localdata?.mentor === true && (
              <>
                <SheduleSession data={userDetails}>
                  <SheduleModels
                    data={userDetails}
                    fetchUserInfo={fetchUserInfo}
                  />
                </SheduleSession>
                {/* <Offerings data={userDetails} fetchUserInfo={fetchUserInfo} /> */}
                <UserAdditional data={userDetails} />
              </>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            md={2.6}
            sx={{ mt: 3, display: { xs: "none", md: "block" } }}
          >
            <Stack className="sticky__div" style={{ width: "100%" }}>
              {localdata?.mentor === true && (
                <>
                  <CompletnessRatio />
                  <Settings
                    title="Settings and Availability"
                    content="Set your available Date and Time"
                    navigateLink="/settings/availability"
                    btnTitle="Schedule Now"
                  />
                </>
              )}
            </Stack>
          </Grid>
        </Grid>
      )}
      {userDetails && localdata?.mentor === false && (
        <Grid
          container
          spacing={2}
          sx={{
            mt: 3,
            background: "white",
            borderRadius: "15px",
            padding: "10px",
          }}
        >
          <Grid item xs={12} md={12} justifySelf="center">
            <Stack
              // mt={3}
              px={2}
              sx={{
                background: "white",
                // border: { md: "0.742701px solid #D2D2D2" },
                borderRadius: "15px",
                mt: { xs: "3rem", md: 3 },
                py: { md: 3, xs: 0 },
              }}
            >
              {isSmallScreen && (
                <CloseIcon
                  style={{ cursor: "pointer", alignSelf: "end", margin: "8px" }}
                  onClick={() => navigate("/home")}
                />
              )}
              <Stack direction="column" alignItems="center" spacing={1}>
                <Box sx={{ position: "relative", display: "inline-block" }}>
                  <Avatar
                    alt="Profile Picture"
                    src={
                      userDetails?.profileImage
                        ? userDetails?.profileImage
                        : allImages?.avatar
                    }
                    sx={{ width: 110, height: 110 }}
                  />
                  {/* <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="label"
                    sx={{
                      position: "absolute",
                      bottom: 0,
                      right: 0,
                      backgroundColor: "#fff",
                    }}
                  >
                    <PhotoCameraIcon
                      style={{ color: "#000" }}
                      onClick={() => navigate("/update")}
                    />
                  </IconButton> */}
                </Box>
              </Stack>

              {/* <Stack
                width="100%"
                height={!userDetails?.profileImage ? "160px" : "370px"}
                alignSelf="center"
                sx={{
                  cursor: "pointer",
                  borderRadius: "50%", // Circular shape
                  overflow: "hidden", // To ensure the image stays within the circle
                  marginTop: "-7px",
                  position: "relative", // For positioning the camera icon
                }}
              >
                <img
                  src={
                    userDetails?.profileImage
                      ? userDetails?.profileImage
                      : allImages?.avatar
                  }
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                  alt="profile image"
                />
                {location.pathname === "/profile" && (
                  <Box
                    position="absolute"
                    width="30px"
                    height="30px"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                      background: "white",
                      borderRadius: "50%",
                      bottom: "10px", // Adjust as needed to position the icon
                      right: "10px", // Adjust as needed to position the icon
                    }}
                    onClick={() => navigate("/update")}
                  >
                    <img
                      style={{ width: "20px", height: "20px" }}
                      src={allImages.editPencil}
                      alt="edit"
                    />
                  </Box>
                )}
              </Stack> */}
            </Stack>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <label htmlFor="name">Name</label>
            <TextField
              name="name"
              value={`${userDetails?.fname} ${userDetails?.lname} `}
              disabled
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <label htmlFor="designation">Designation</label>
            <TextField
              name="designation"
              value={`${userDetails?.designation}`}
              disabled
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <label htmlFor="name">Gender</label>
            <TextField
              name="gender"
              value={`${userDetails?.gender}`}
              disabled
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            style={{ display: "flex", flexDirection: "column" }}
          >
            {/* <label htmlFor="name">Gender</label>
            <TextField
              name="gender"
              value={`${userDetails?.gender}`}
              disabled
            /> */}
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <label htmlFor="number">Phone Number</label>
            <TextField
              name="number"
              value={`${userDetails?.mobileNo}`}
              disabled
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <label htmlFor="Email">Email</label>
            <TextField name="Email" value={`${userDetails?.email}`} disabled />
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              margin: "auto",
            }}
          >
            <Typography
              style={{
                backgroundColor: "#00275A",
                color: "#fff",
                width: "180px",
                // textAlign: "center",
                fontWeight: "600",
                borderRadius: "8px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                padding: "12px",
              }}
              onClick={() => navigate("/update")}
            >
              Edit
            </Typography>
          </Grid>
        </Grid>
      )}
    </Container>
  );
};

export default Index;
