import { Grid, Stack } from "@mui/material";
import { Container } from "@mui/system";
import React, { useEffect, useState } from "react";
import Homehero from "./Home/HomeheroBanner";
import { mentorimgFakedata } from "../../../fakedata/mentorimagefakedata";
import APIRequest from "../../../utils/APIRequest";
import ConfigAPIURL from "../../../config/ConfigAPIURL";
import { useNavigate } from "react-router-dom";
import { allImages } from "../../../images";

const Mentorspage = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [endSlicing, setEndSlicing] = useState(9);

  useEffect(() => {
    fetchCategories();
  }, [endSlicing]);

  const fetchCategories = async () => {
    try {
      const response = await APIRequest.request(
        "GET",
        ConfigAPIURL.UserCategoriesAds,
        ""
      );
      if (response?.data?.responseCode === 109) {
        // setData(response?.data?.categories.slice(0, endSlicing));
        setData(response?.data?.categories);
      } else return;
    } catch {}
  };

  window.onscroll = function (ev) {
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
      setEndSlicing(endSlicing * 2);
      // filterData();
    }
  };
  return (
    <Stack>
      <Homehero />
      <Container maxWidth={"md"} sx={{ mt: "2.2rem", mb: ".5rem" }}>
        <Grid container spacing={1}>
          {data &&
            data?.map((val) => {
              return (
                <Grid
                  item
                  xs={6}
                  sm={4}
                  key={val?._id}
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate(`/categories/${val?.title}`);
                  }}
                >
                  <img
                    src={val?.logo ? val?.logo : allImages.development}
                    alt={val}
                  />
                </Grid>
              );
            })}
        </Grid>
      </Container>
    </Stack>
  );
};

export default Mentorspage;
