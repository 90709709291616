import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const MentorDetailsAccordion = ({ title, data, children }) => {
  const [expanded, setExpanded] = React.useState("panel1");
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  return (
    // <Accordion
    //   expanded={expanded === "panel1"}
    //   onChange={handleChange("panel1")}
    //   sx={{ borderRadius: "15px" }}
    // >
    //   <AccordionSummary
    //     expandIcon={<ExpandMoreIcon />}
    //     aria-controls="panel1a-content"
    //     id="panel1a-header"
    //   >
    //     <Stack direction="row" gap={2} alignItems="center">
    //       <Typography
    //         className="heading"
    //         sx={{ fontWeight: "600", fontSize: "20px" }}
    //       >
    //         {title}
    //       </Typography>
    //       {children}
    //     </Stack>
    //   </AccordionSummary>
    //   <Divider variant="middle" />
    //   <AccordionDetails>
    //     <Typography
    //       style={{
    //         wordBreak: "break-all",
    //         lineBreak: "auto",
    //         whiteSpace: "pre-wrap",
    //       }}
    //       className="content"
    //       fontWeight="400"
    //       textAlign="justify"
    //       fontSize="14px"
    //     >
    //       {data?.about}
    //     </Typography>
    //   </AccordionDetails>
    // </Accordion>
    <Stack style={{marginTop:'10px',}}>
      <Stack direction="row" gap={2} alignItems="center">
      <Typography
            // className="heading"
            sx={{ fontWeight: "600", fontSize: "28px",color:'#343434' }}
          >
            {title}
          </Typography>
          {children}
          </Stack>
          <Typography
          style={{
            wordBreak: "break-all",
            lineBreak: "auto",
            whiteSpace: "pre-wrap",
            backgroundColor:'#FFFFFF',borderRadius:'20px',padding:'28px',marginTop:'10px'
          }}
          // className="content"
          fontWeight="400"
          textAlign="justify"
          fontSize="20px"
          color={'#373737'}
          marginBottom={'20px'}
        >
          {data?.about}
        </Typography>
    </Stack>
  );
};

export default MentorDetailsAccordion;
