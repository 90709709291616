import { Box, Slide, Snackbar, Stack, Typography } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import ConfigAPIURL from "../../../../config/ConfigAPIURL";
import APIRequest from "../../../../utils/APIRequest";
import BlueButton from "../BlueButton";

function TransitionUp(props) {
  return <Slide {...props} direction="up" />;
}

const InputBar = ({ text, state, setState, p, type }) => {
  return (
    <input
      type={type || "text"}
      value={state}
      onChange={(e) => setState(e.target.value)}
      placeholder={text}
      style={{
        borderRadius: "10px",
        border: "1px solid rgba(0, 0, 0, 0.1)",
        outline: "none",
        padding: p ? p : ".6rem .4rem",
      }}
    />
  );
};

const BankDetails = () => {
  const [holderName, setHolderName] = useState("");
  const [accNumber, setAccNumber] = useState("");
  const [reVerifyaccNumber, setreVerifyAccNumber] = useState("");

  const [ifscCode, setIfscCode] = useState("");
  const [bankName, setBankName] = useState("");
  const [snackBar, setSnackBar] = useState({
    message: "",
  });
  const [open, setOpen] = React.useState(false);
  const [transition, setTransition] = React.useState(undefined);
  const [isFilled, setIsFilled] = React.useState(false);

  const [isSame, setIsSame] = React.useState(false);
  const [serverData, setServerData] = React.useState("");

  useEffect(() => {
    const fileds = {
      accountNumber: accNumber,
      bankName: bankName,
      holderName: holderName,
      ifscCode: ifscCode,
    };
    console.log(JSON.stringify(fileds), "sep", JSON.stringify(serverData));
    if (JSON.stringify(fileds) === JSON.stringify(serverData)) {
      setIsSame(true);
    } else {
      setIsSame(false);
    }
  }, [{ holderName, accNumber, ifscCode, bankName, serverData }]);

  useEffect(() => {
    fetchUserInfo();
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = (Transition) => () => {
    setTransition(() => Transition);
    if (reVerifyaccNumber !== accNumber) {
      setSnackBar({
        message:
          "Account Number you have provided is not matching please check again",
      });
      setOpen(true);

      return;
    }
    handleBankDetailsSubmit().then((value) => {
      if (value?.data?.responseCode === 109) {
        setSnackBar({
          message: "Updated Succesfully ",
        });
      } else
        setSnackBar({
          message: "Cannot update try again later ",
        });
    });
    setOpen(true);
  };

  const fetchUserInfo = async () => {
    const response = await APIRequest.request(
      // get the users id from localstorage
      "GET",
      ConfigAPIURL.userProfile,
      ""
    );
    if (response?.data?.responseCode === 109) {
      //useradditional details is stored as state and displayed
      // setUserDetails(response?.data?.profile?.bankDetails);
      let userDetails = response?.data?.profile?.bankDetails;
      if (userDetails) setIsFilled(true);
      setServerData({
        accountNumber: userDetails?.accountNumber || "",
        bankName: userDetails?.bankName || "",
        holderName: userDetails?.holderName || "",
        ifscCode: userDetails?.ifscCode || "",
      });
      setAccNumber(userDetails?.accountNumber || "");
      setBankName(userDetails?.bankName || "");
      setHolderName(userDetails?.holderName || "");
      setIfscCode(userDetails?.ifscCode || "");
      setreVerifyAccNumber(userDetails?.accountNumber);
      console.log(userDetails);
    }
  };
  const handleBankDetailsSubmit = async () => {
    try {
      const response = await APIRequest.request(
        "PUT",
        ConfigAPIURL.UserProfileUpdate,
        JSON.stringify({
          bankDetails: {
            bankName: bankName,
            ifscCode: ifscCode,
            holderName: holderName,
            accountNumber: accNumber,
          },
        })
      );

      if (response?.data?.responseCode === 109) {
        // setChange(!change);
        fetchUserInfo();
        return response;
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <Stack className="stack__round" sx={{ py: 3, px: { xs: 0, sm: 2 } }}>
      <Typography
        component="h2"
        fontWeight="600"
        fontSize="20px"
        lineHeight="32px"
      >
        Bank Details{" "}
      </Typography>
      <Stack gap={1.5} mt={1} mx={0.5}>
        <InputBar
          text="Account Holder Name"
          state={holderName}
          setState={setHolderName}
        />
        <InputBar
          type="text"
          text="Account Number"
          state={accNumber}
          setState={setAccNumber}
        />
        <InputBar
          type="password"
          text="Confirm Account Number"
          state={reVerifyaccNumber}
          setState={setreVerifyAccNumber}
        />
        <InputBar text="IFSC Code" state={ifscCode} setState={setIfscCode} />
        <InputBar text="Bank Name" state={bankName} setState={setBankName} />
      </Stack>
      <Box alignSelf="center" mt={1.5} onClick={handleClick(TransitionUp)}>
        {/* {!isSame && ( */}
          <BlueButton
            text={isFilled ? "Update" : "Submit"}
            transform="Capitalize"
          />
        {/* // )} */}
      </Box>

      <Snackbar
        open={open}
        onClose={handleClose}
        TransitionComponent={transition}
        message={snackBar.message}
        key={transition ? transition.name : ""}
      />
    </Stack>
  );
};

export default BankDetails;
