import styled from "@emotion/styled";
import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import SendIcon from "@mui/icons-material/Send";
import APIRequest from "../../../utils/APIRequest";
import ConfigAPIURL from "../../../config/ConfigAPIURL";
import { useNavigate } from "react-router-dom";

const ChatBubble = styled(Box)(({ theme }) => ({
  display: "inline-block",
  padding: "10px 20px",
  borderRadius: "15px",
  backgroundColor: "#f1f1f1",
  maxWidth: "50%",
  position: "relative",
  margin: "10px 0",
  "&::after": {
    content: '""',
    position: "absolute",
    left: "10px",
    bottom: "-15px",
    borderWidth: "15px 15px 0 0",
    borderStyle: "solid",
    borderColor: "#f1f1f1 transparent transparent transparent",
    display: "block",
    width: 0,
  },
  display: "flex",
  flexDirection: "column",
  marginTop: "28px",
  marginBottom: "28px",
}));

// Right side chat bubble
const ChatBubbleRight = styled(Box)(({ theme }) => ({
    display: "inline-block",
    padding: "10px 20px",
    borderRadius: "15px",
    backgroundColor: "#00275A",
    color: "white",
    maxWidth: "100%",
    width: "50%",
    position: "relative",
    margin: "10px 0",
    alignSelf: "flex-end",
    "&::after": {
      content: '""',
      position: "absolute",
      right: "10px",
      bottom: "-15px",
      borderWidth: "15px 0 0 15px",
      borderStyle: "solid",
      borderColor: "#00275A transparent transparent transparent",
      display: "block",
      width: 0,
    },
    display: "flex",
    flexDirection: "column",
    marginTop: "28px",
    marginBottom: "28px",
  }));

const ChatInput = ({ typedMsg, setTypedMsg, sendTypedMessage , userLoggedin }) => {
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      sendTypedMessage();
    }
  };
  return (
    <div
      //   onSubmit={handleSendMessage}
      style={{
        display: "flex",
        alignItems: "center",
        paddingTop: "10px",
        position: "sticky",
        bottom: 0,
        width: "100%",
        backgroundColor: "white",
        // borderTop: "1px solid #e0e0e0",
      }}
    >
      <TextField
        fullWidth
        variant="outlined"
        placeholder="Type here..."
        value={typedMsg}
        onChange={(e) => setTypedMsg(e.target.value)}
        onKeyDown={handleKeyPress}

        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton type="submit" color="primary" onClick={userLoggedin === false ? sendTypedMessage : ''}>
                <SendIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        sx={{
          "& .MuiOutlinedInput-root": {
            borderRadius: "25px",
          },
        }}
      />
    </div>
  );
};
const ChatArea = ({ message }) => {
  const [typedMsg, setTypedMsg] = useState("");
  const [userLoggedin, setUserLoggedin] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    isLogin();
  }, []);

  //check whether the user is logged in or nt and route to private route
  const isLogin = async () => {
    const response = await APIRequest.request(
      "GET",
      ConfigAPIURL.userIsLoggedin,
      ""
    );
    if (response?.data?.responseCode === 109) {
    //   if (response.data.userType !== null) {
    //     return navigate("/home");
    //   } else {
        setUserLoggedin(true);
        console.log("user is logged in");
    //   }
    }
    if (response?.data?.responseCode === 108) {
    } else {
      setUserLoggedin(false);
      console.log("user is Not logged in");
    }
  };

  const sendTypedMessage = async () => {
    try {
      const response = await APIRequest.request(
        "POST",
        ConfigAPIURL.sendTypedMessage,
        JSON.stringify({semanticQuery : typedMsg})
      );
      if (response.data.responseCode === 109) {
        const receivedData = response?.data?.data; // Assuming this is the data you want to pass
        setTypedMsg("");
        navigate("/home/chat", { state: { receivedData } });

      }
    } catch (err) {
      console.log(err);
    }
  };

//   console.log("this is msg", typedMsg);

  return (
    <div>
      {userLoggedin === false ? (
        <>
        <ChatBubble>
          <Typography variant="body1">
            {
              "Hi User, Please key in the challenge you are facing and i will try to search mentors for your Problem. "
            }
          </Typography>
        </ChatBubble>
        {/* {typedMsg && (
            <ChatBubbleRight>
          <Typography variant="body1">
            {typedMsg}
          </Typography>
        </ChatBubbleRight>
            )} */}
        </>
      ) : (
        message?.map((msg, index) => (
          <ChatBubble key={index}>
            <Typography variant="body1">{msg}</Typography>
          </ChatBubble>
        ))
      )}
      <ChatInput setTypedMsg={setTypedMsg} typedMsg={typedMsg} userLoggedin={userLoggedin} sendTypedMessage={sendTypedMessage} />
    </div>
  );
};

export default ChatArea;
