import { allImages } from "../../../../images";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React, { Children } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ModelExperience from "../UserProfile/ModelExperience";
import EducationModel from "../UserProfile/Models/EducationModel";
import AchieventModel from "../UserProfile/Models/AchieventModel";
import CertificationModels from "../UserProfile/Models/CertificationModels";
import ConfigAPIURL from "../../../../config/ConfigAPIURL";
import APIRequest from "../../../../utils/APIRequest";
import { useLocation } from "react-router-dom";

const CertificationAccordion = ({
  title,
  data,
  children,
  fetchUserAdditionalInfo,
}) => {
  const location = useLocation();
  const [expanded, setExpanded] = React.useState(
    data?.experience.length > 0 ? "panel1" : false
  );
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleRemoveCertification = async (id) => {
    try {
      const response = await APIRequest.request(
        "POST",
        ConfigAPIURL.userProfileDataRemove,
        JSON.stringify({
          recordId: id,
          heading: "certificates",
        })
      );
    } catch {
    } finally {
      fetchUserAdditionalInfo();
    }
  };
  return (
    <Stack mt={1}>
      {/* <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
        sx={{ borderRadius: "15px" }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Stack direction="row" gap={1} alignItems="center">
            <Typography
              textTransform="capitalize"
              className="heading"
              sx={{ fontWeight: "600", fontSize: "20px" }}
            >
              {title}
            </Typography>
            {location.pathname === "/profile" ? (
              <CertificationModels
                userData={data.certificates}
                fetchUserAdditionalInfo={fetchUserAdditionalInfo}
              />
            ) : null}
          </Stack>
        </AccordionSummary>
        <Divider variant="middle" />
        <AccordionDetails>
          <Stack ml={6}></Stack>
          {data?.certificates.length > 0 &&
            data.certificates.map((value) => {
              return (
                <Stack
                  sx={{ flexDirection: { xs: "column", md: "row" } }}
                  justifyContent="space-between"
                  alignItems="center"
                  key={value._id}
                  mx={2}
                  mt={2}
                  gap={1}
                >
                  <Stack width="100%">
                    <Stack
                      flexDirection="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography
                        color="#1C3464"
                        variant="subtitle1"
                        fontSize="20px "
                        fontWeight="700"
                        className="heading"
                      >
                        {value?.title}
                      </Typography>
                      {location.pathname === "/profile" ? (
                        <>
                          <Stack direction="row" gap={3}>
                            <CertificationModels
                              userData={value}
                              id={value._id}
                              fetchUserAdditionalInfo={fetchUserAdditionalInfo}
                            />
                            <Box
                              display="flex"
                              className="mainicon"
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                handleRemoveCertification(value._id)
                              }
                            >
                              <img src={allImages.deleteButton} alt="delete" />
                            </Box>
                          </Stack>
                        </>
                      ) : null}
                    </Stack>

                    <Typography
                      variant="subtitle1"
                      fontWeight="400"
                      fontSize="14px"
                      className="content wrap"
                    >
                      {value?.description}
                    </Typography>
                  </Stack>
                </Stack>
              );
            })}
        </AccordionDetails>
      </Accordion> */}
      <Typography
        textTransform="capitalize"
        sx={{
          fontWeight: "600",
          fontSize: "28px",
          color: "#343434",
          marginBottom: "12px",
        }}
      >
        {title}
      </Typography>
      <Stack
        style={{
          backgroundColor: "#FEFEFE",
          borderRadius: "18px",
          padding: "20px",
        }}
      >
        {location.pathname === "/profile" ? (
          <CertificationModels
            userData={data.certificates}
            fetchUserAdditionalInfo={fetchUserAdditionalInfo}
          />
        ) : null}
        {data?.certificates.length > 0 &&
          data.certificates.map((value) => {
            return (
              <Stack
                sx={{ flexDirection: { xs: "column", md: "row" } }}
                justifyContent="space-between"
                alignItems="center"
                key={value._id}
                mx={2}
                // mt={2}
                gap={1}
              >
                <Stack
                  style={{
                    backgroundColor: "#5CAFFD1A",
                    borderRadius: "12px",
                    padding: "20px",
                    marginRight: "8px",
                    width: "80px",
                    height: "80px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={allImages.certIllus}
                    alt=""
                    style={{ width: "40px", height: "40px" }}
                  />
                </Stack>
                <Stack width="100%">
                  <Stack
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography
                      color="#1c3464"
                      // variant="subtitle1"
                      fontSize="24px "
                      fontWeight="600"
                      // className="heading"
                    >
                      {value?.title}
                    </Typography>
                    {location.pathname === "/profile" ? (
                        <Stack direction="row" gap={3}style={{display:'flex',flexDirection:'row',justifyContent:'end',alignItems:'center',}}>
                          <CertificationModels
                            userData={value}
                            id={value._id}
                            fetchUserAdditionalInfo={fetchUserAdditionalInfo}
                            text={'edit'}
                          />
                          <Box
                            display="flex"
                            className="mainicon"
                            style={{ cursor: "pointer" }}
                            onClick={() => handleRemoveCertification(value._id)}
                          >
                            <img src={allImages.deleteButton} alt="delete" />
                          </Box>
                        </Stack>
                    ) : null}
                  </Stack>

                  <Typography
                    // variant="subtitle1"
                    fontWeight="400"
                    fontSize="18px"
                    // className="content wrap"
                    color={" #626266"}
                  >
                    {value?.description}
                  </Typography>
                </Stack>
              </Stack>
            );
          })}
      </Stack>
    </Stack>
  );
};

export default CertificationAccordion;
