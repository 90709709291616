import React, { useState, useEffect } from "react";
import {
  Document,
  Page,
  Text,
  Font,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";

import OpenSansBold from "./OpenSans-Bold.ttf";
import OpenSansRegular from "./OpenSans-Regular.ttf";
import styled from "@react-pdf/styled-components";
import { Typography } from "@mui/material";

// Create styles
Font.register({
  family: "Opensans",
  fonts: [
    { src: OpenSansRegular }, // font-style: normal, font-weight: normal
    { src: OpenSansBold, fontWeight: "bold" },
  ],
});
const styles = StyleSheet.create({
  page: {
    paddingTop: 35,
    paddingBottom: 45,
    paddingHorizontal: 35,
    backgroundColor: "#fff",
  },
  section: {
    marginTop: 50,
    textAlign: "center",
    fontSize: 24,
    fontWeight: "bold",
  },
  imageCover: {
    position: "absolute",
    minWidth: "100%",
    minHeight: "100%",
    display: "block",
    height: "100%",
    width: "100%",
  },
  view: {
    width: "100%",
    height: "100%",
    padding: 0,
    backgroundColor: "white",
  },
  section1: {
    marginTop: 60,
    textAlign: "center",
    fontSize: 34,
    fontWeight: "bold",
  },
  image: {
    objectFit: "cover",
  },
});

// Create Document Component
const Invoice = ({ data, isMentor }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page} wrap>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            borderBottomColor: "#000",
            borderBottomWidth: 1,
            alignItems: "center",
          }}
        >
          <View style={{ justifyContent: "center", alignItems: "center" }}>
            <Image
              src={{
                uri: "https://s3.ap-south-1.amazonaws.com/mentorlink/profile/1687513533882_LOGO_final%20(2).jpg",
              }}
              style={{
                width: "200px",
                height: "150px",
                objectFit: "contain",
                // resizeMode: "contain",
              }}
            />
          </View>
          <View>
            <Text style={{ fontSize: "12px", fontFamily: "Opensans" }}>
              Original for Customer
            </Text>
            <Text style={{ fontSize: "18px", fontFamily: "Opensans" }}>
              TAX INVOICE
            </Text>
            <Text style={{ fontSize: "10px", fontFamily: "Opensans" }}>
              Invoice #: MLINKA{new Date().getFullYear()}
              {new Date().getMonth()}
              {data?.orderId?.charAt(data?.orderId.length - 1)}
            </Text>
            <Text style={{ fontSize: "10px", fontFamily: "Opensans" }}>
              Invoice date: {new Date().toLocaleDateString("en-IN")}
            </Text>
          </View>
        </View>

        <View
          style={{
            // border: "2px solid black",
            flexDirection: "row",
            borderBottomColor: "#000",
            // borderBottomWidth: 1,
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "1rem",
          }}
        >
          <View
            style={{
              width: "100%",
              padding: 5,
            }}
          >
            <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
              <Text
                style={{
                  textAlign: "left",
                  fontSize: 12,
                  fontWeight: "bold",
                  paddingLeft: 10,
                  fontFamily: "Opensans",
                }}
              >
                {isMentor ? "Details of Supplier" : "Customer Details"} :{" "}
              </Text>
            </View>
            <View style={{ width: "100%" }}>
              <Text
                style={{
                  textAlign: "left",
                  fontSize: 9,
                  paddingLeft: 10,
                  fontWeight: "bold",
                  fontFamily: "Opensans",
                }}
              >
                {isMentor ? data?.mentorId?.fname : data?.fname}
              </Text>

              <Text
                style={{
                  textAlign: "left",
                  fontSize: 9,
                  paddingLeft: 10,
                  fontWeight: "bold",
                  fontFamily: "Opensans",
                }}
              >
                {isMentor
                  ? data?.mentorId?.billingAddress
                  : data?.userId?.billingAddress}
              </Text>
              <Text
                style={{
                  textAlign: "left",
                  fontSize: 9,
                  paddingLeft: 10,
                  fontWeight: "bold",
                  fontFamily: "Opensans",
                }}
              >
                {/* {"LandMark"} : {data.address.landMark} */}
              </Text>
              <Text
                style={{
                  textAlign: "left",
                  fontSize: 9,
                  paddingLeft: 10,
                  fontWeight: "bold",
                  fontFamily: "Opensans",
                }}
              >
                {"State Name"} :{" "}
                {isMentor
                  ? data?.mentorId?.address?.state
                  : data?.userId?.address?.state}
              </Text>
              <Text
                style={{
                  textAlign: "left",
                  fontSize: 9,
                  fontWeight: "bold",
                  paddingLeft: 10,
                  fontFamily: "Opensans",
                }}
              >
                {"City"} :{" "}
                {isMentor
                  ? data?.mentorId?.address?.city
                  : data?.userId?.address?.city}
              </Text>
              <Text
                style={{
                  textAlign: "left",
                  fontSize: 9,
                  fontWeight: "bold",
                  paddingLeft: 10,
                  fontFamily: "Opensans",
                }}
              >
                {"Contact "} :{" "}
                {isMentor
                  ? "+91 " + data?.mentorId?.mobileNo
                  : "+91 " + data?.userId?.mobileNo}
              </Text>
            </View>
          </View>
          <View
            style={{
              justifyContent: "center",
              width: "100%",
              padding: 5,
            }}
          >
            <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
              <Text
                style={{
                  width: "100%",
                  textAlign: "right",
                  fontSize: 12,

                  fontWeight: "bold",
                  paddingLeft: 90,
                  fontFamily: "Opensans",
                }}
              >
                {isMentor ? "Customer Details" : "Details of Supplier"} :{" "}
              </Text>
            </View>
            <View style={{ width: "100%" }}>
              <Text
                style={{
                  textAlign: "right",
                  fontSize: 9,
                  paddingLeft: 100,
                  fontWeight: "bold",
                  fontFamily: "Opensans",
                }}
              >
                {"Innocatlyst Advisors Pvt LTD"}
              </Text>

              <Text
                style={{
                  textAlign: "right",
                  fontSize: 9,
                  paddingLeft: 100,
                  fontWeight: "bold",
                  fontFamily: "Opensans",
                }}
              >
                {`#122, Pipeline Road, Malleshwaram10th Cross,
                Karnataka,India.`}
                {/* {data.address.fragmentedAddress} */}
              </Text>
              <Text
                style={{
                  textAlign: "right",
                  fontSize: 9,
                  paddingLeft: 100,
                  fontWeight: "bold",
                  fontFamily: "Opensans",
                }}
              >
                {/* {"invoicePDF.landMark"} : {data.address.landMark} */}
              </Text>
              <Text
                style={{
                  textAlign: "right",
                  fontSize: 9,
                  paddingLeft: 100,
                  fontWeight: "bold",
                  fontFamily: "Opensans",
                }}
              >
                {/* {"invoicePDF.township"} : {data.address.township} */}
              </Text>
              <Text
                style={{
                  textAlign: "right",
                  fontSize: 9,
                  fontWeight: "bold",
                  paddingLeft: 100,
                  fontFamily: "Opensans",
                }}
              >
                {/* {"invoicePDF.city"} : {data.address.city} */}
              </Text>
              <Text
                style={{
                  textAlign: "right",
                  fontSize: 9,
                  fontWeight: "bold",
                  paddingLeft: 100,
                  fontFamily: "Opensans",
                }}
              >
                {"+91 8095355599"}
                {/* {"invoicePDF.mobile"} : {data.mobileNo} */}
              </Text>
            </View>
          </View>
        </View>
        <View
          style={{
            flexDirection: "row",
            borderBottomColor: "#000",
            padding: 8,
            marginTop: "1rem",
            backgroundColor: "#D0CECE",
          }}
        >
          <View style={{ justifyContent: "center", width: "5%" }}>
            <Text
              style={{
                fontSize: 8,
                textAlign: "center",
                fontFamily: "Opensans",
                fontWeight: "bold",
              }}
            ></Text>
          </View>
          <View style={{ justifyContent: "center", width: "5%" }}>
            <Text
              style={{
                fontSize: 8,
                textAlign: "left",
                fontFamily: "Opensans",
                fontWeight: "bold",
              }}
            ></Text>
          </View>
          <View style={{ justifyContent: "center", width: "30%" }}>
            <Text
              style={{
                fontSize: 8,
                textAlign: "center",
                fontFamily: "Opensans",
                fontWeight: "bold",
                color: "white",
              }}
            >
              Description
            </Text>
          </View>
          <View style={{ justifyContent: "center", width: "15%" }}>
            <Text
              style={{
                fontSize: 8,
                textAlign: "center",
                fontFamily: "Opensans",
                fontWeight: "bold",
              }}
            >
              {/* {"invoicePDF.price"} */}
            </Text>
          </View>
          <View style={{ justifyContent: "center", width: "25%" }}>
            <Text
              style={{
                fontSize: 8,
                textAlign: "center",
                fontFamily: "Opensans",
                fontWeight: "bold",
                color: "white",
              }}
            >
              {/* {"invoicePDF.discount"} */}
              {"Appointment Fee"}
            </Text>
          </View>
          {!isMentor && (
            <View style={{ justifyContent: "center", width: "25%" }}>
              <Text
                style={{
                  fontSize: 8,
                  textAlign: "center",
                  fontFamily: "Opensans",
                  fontWeight: "bold",
                  color: "white",
                }}
              >
                {"Convenience Fee"}

                {/* {"invoicePDF.tax"} */}
              </Text>
            </View>
          )}

          <View style={{ justifyContent: "center", width: "25%" }}>
            <Text
              style={{
                fontSize: 8,
                textAlign: "right",
                fontFamily: "Opensans",
                fontWeight: "bold",
                color: "white",
              }}
            >
              {"GST (18%)"}

              {/* {"invoicePDF.billAmount"} */}
            </Text>
          </View>
          <View style={{ justifyContent: "center", width: "25%" }}>
            <Text
              style={{
                fontSize: 8,
                textAlign: "right",
                fontFamily: "Opensans",
                fontWeight: "bold",
                color: "white",
              }}
            >
              {"IGST (18%)"}

              {/* {"invoicePDF.billAmount"} */}
            </Text>
          </View>
        </View>
        <View
          style={{
            padding: 4,
            borderBottomColor: "#000",
            borderBottomWidth: 0.5,
            borderLeftWidth: 0.5,
            borderRightWidth: 0.5,
          }}
        >
          {/* {data &&
            data?.itemList &&
            data?.itemList?.map((items, index) => ( */}
          <View style={{ flexDirection: "row", marginBottom: 3 }}>
            <View style={{ justifyContent: "center", width: "5%" }}>
              <Text
                style={{
                  fontSize: 8,
                  textAlign: "center",
                  fontFamily: "Opensans",
                }}
              >
                {/* {index + 1} */}
              </Text>
            </View>
            <View style={{ justifyContent: "center", width: "5%" }}>
              <Text
                style={{
                  fontSize: 8,
                  textAlign: "left",
                  fontFamily: "Opensans",
                }}
              >
                {/* {items.itemName} */}
              </Text>
            </View>
            <View style={{ justifyContent: "center", width: "30%" }}>
              <Text
                style={{
                  fontSize: 8,
                  textAlign: "center",
                  fontFamily: "Opensans",
                }}
              >
                Mentoring Session with Mr.{data?.mentorId?.fname}
                {""}
                {data?.mentorId?.lname} on{" "}
                {new Date(data.createdAt * 1000).getDate() +
                  "/" +
                  (new Date(data.createdAt * 1000).getMonth() + 1) +
                  "/" +
                  new Date(data.createdAt * 1000).getFullYear()}
                {/* {items.itemCount} */}
              </Text>
            </View>
            <View style={{ justifyContent: "center", width: "15%" }}>
              <Text
                style={{
                  fontSize: 8,
                  textAlign: "center",
                  fontFamily: "Opensans",
                }}
              >
                {/* {items.price} */}
              </Text>
            </View>
            <View style={{ justifyContent: "center", width: "25%" }}>
              <Text
                style={{
                  fontSize: 8,
                  textAlign: "center",
                  fontFamily: "Opensans",
                }}
              >
                Rs {data?.appointmentFee}
              </Text>
            </View>
            {!isMentor && (
              <View style={{ justifyContent: "center", width: "25%" }}>
                <Text
                  style={{
                    fontSize: 8,
                    textAlign: "center",
                    fontFamily: "Opensans",
                  }}
                >
                  {"Rs " + data?.convenienceFee}
                </Text>
              </View>
            )}
            <View style={{ justifyContent: "center", width: "25%" }}>
              <Text
                style={{
                  fontSize: 8,
                  textAlign: "right",
                  fontFamily: "Opensans",
                }}
              >
                Rs{" "}
                {isMentor || data?.userId?.address?.country !== "India"
                  ? "0"
                  : data?.userId?.address?.state === "Karnataka"
                  ? data?.tax
                  : "0"}
              </Text>
            </View>
            {console.log(data)}
            <View style={{ justifyContent: "center", width: "25%" }}>
              <Text
                style={{
                  fontSize: 8,
                  textAlign: "right",
                  fontFamily: "Opensans",
                }}
              >
                Rs{" "}
                {isMentor || data?.userId?.address?.country !== "India"
                  ? "0"
                  : data?.userId?.address?.state !== "Karnataka"
                  ? data?.tax
                  : "0"}{" "}
              </Text>
            </View>
          </View>
          ))
        </View>
        <View
          style={{
            paddingTop: 4,
            paddingBottom: 4,
            borderBottomColor: "#000",
            borderBottomWidth: 0.5,
            borderLeftWidth: 0.5,
            borderRightWidth: 0.5,
          }}
        >
          <View style={{ flexDirection: "row" }}>
            <View style={{ width: "30%" }}>
              <View style={{ flexDirection: "row" }}>
                <Text
                  style={{
                    fontSize: 9,
                    fontFamily: "Opensans",
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>
            </View>
            <View style={{ width: "30%" }}>
              <View style={{ flexDirection: "row" }}>
                <Text
                  style={{
                    fontSize: 9,
                    fontFamily: "Opensans",
                    fontWeight: "bold",
                  }}
                >
                  {/* {"invoicePDF.totalQuantity"} :{" "} */}
                </Text>
                <Text style={{ fontSize: 9, fontFamily: "Opensans" }}>
                  {/* {data &&
                    data?.itemList &&
                    data?.itemList
                      .map((o) => Number(o.itemCount))
                      .reduce((a, c) => {
                        return a + c;
                      })} */}
                </Text>
              </View>
            </View>
            <View style={{ justifyContent: "center", width: "40%" }}>
              <View
                style={{
                  flexDirection: "row",
                  marginBottom: 5,
                  padding: "0 6px",
                }}
              >
                <View style={{ justifyContent: "center", width: "50%" }}>
                  <Text
                    style={{
                      fontSize: 9,
                      fontFamily: "Opensans",
                      fontWeight: "bold",
                    }}
                  >
                    {"Order Total"}
                  </Text>
                </View>
                <View style={{ justifyContent: "center", width: "10%" }}>
                  <Text
                    style={{
                      fontSize: 9,
                      textAlign: "center",
                      fontFamily: "Opensans",
                      fontWeight: "bold",
                    }}
                  >
                    :
                  </Text>
                </View>
                <View style={{ justifyContent: "center", width: "40%" }}>
                  <Text
                    style={{
                      fontSize: 9,
                      textAlign: "right",
                      fontFamily: "Opensans",
                    }}
                  >
                    Rs{" "}
                    {isMentor || data?.userId?.address?.country !== "India"
                      ? isMentor
                        ? data?.totalPayment - data?.tax - data?.convenienceFee
                        : data?.totalPayment - data?.tax
                      : data?.totalPayment}
                    {/* {data.itemList
                      .map((o) => Number(o.itemCount) * Number(o.price))
                      .reduce((a, c) => {
                        return a + c;
                      })} */}
                  </Text>
                </View>
              </View>

              <View style={{ flexDirection: "row", marginBottom: 5 }}>
                <View style={{ justifyContent: "center", width: "50%" }}>
                  <Text
                    style={{
                      fontSize: 9,
                      fontFamily: "Opensans",
                      fontWeight: "bold",
                    }}
                  >
                    {/* {"invoicePDF.deliveryCharges"}{" "} */}
                  </Text>
                </View>
                <View style={{ justifyContent: "center", width: "10%" }}>
                  <Text
                    style={{
                      fontSize: 9,
                      textAlign: "center",
                      fontFamily: "Opensans",
                      fontWeight: "bold",
                    }}
                  ></Text>
                </View>
                <View style={{ justifyContent: "center", width: "40%" }}>
                  <Text
                    style={{
                      fontSize: 9,
                      textAlign: "right",
                      fontFamily: "Opensans",
                    }}
                  >
                    {/* {data.deliveryCharge} */}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>

        <View
          style={{
            paddingTop: 5,
            paddingBottom: 5,
            borderBottomColor: "#000",
            borderBottomWidth: 0.5,
            borderLeftWidth: 0.5,
            borderRightWidth: 0.5,
          }}
        >
          <View style={{ flexDirection: "row" }}>
            <View style={{ width: "30%" }}></View>
            <View style={{ width: "30%" }}></View>
            <View style={{ justifyContent: "center", width: "40%" }}>
              <View style={{ flexDirection: "row", padding: "0 6px" }}>
                <View style={{ justifyContent: "center", width: "50%" }}>
                  <Text
                    style={{
                      fontSize: 9,
                      fontFamily: "Opensans",
                      fontWeight: "bold",
                    }}
                  >
                    {"Grand Total"}{" "}
                  </Text>
                </View>
                <View style={{ justifyContent: "center", width: "10%" }}>
                  <Text
                    style={{
                      fontSize: 9,
                      textAlign: "center",
                      fontFamily: "Opensans",
                      fontWeight: "bold",
                    }}
                  ></Text>
                </View>
                <View style={{ justifyContent: "center", width: "40%" }}>
                  <Text
                    style={{
                      fontSize: 9,
                      textAlign: "right",
                      fontWeight: "bold",
                      fontFamily: "Opensans",
                    }}
                  >
                    Rs{" "}
                    {isMentor || data?.userId?.address?.country !== "India"
                      ? isMentor
                        ? data?.totalPayment - data?.tax - data?.convenienceFee
                        : data?.totalPayment - data?.tax
                      : data?.totalPayment}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>

        <View
          style={{
            paddingTop: 10,
            paddingBottom: 10,
            borderBottomColor: "#000",
            // borderBottomWidth: 1,
            // borderBottomStyle: "dashed",
          }}
        >
          <Text
            style={{
              fontSize: 9,
              fontFamily: "Opensans",
              fontWeight: "bold",
            }}
          >
            Declaration:
          </Text>
          <Text style={{ fontSize: 7, fontFamily: "Opensans" }}>
            We Declare that this invoice shows the actual price of the Services
            described and that all particulars are true and correct
          </Text>
        </View>

        <View style={{ position: "absolute", bottom: 0 }}>
          <Text
            style={{
              paddingTop: 5,
              paddingBottom: 10,
              color: "grey",
              fontSize: 10,
              textAlign: "center",
              width: "100%",
              fontStyle: "italic",
            }}
          >
            This is a system generated invoice and does not require a digital
            signature or a signature
          </Text>
        </View>
      </Page>
    </Document>
  );
};

export default Invoice;
