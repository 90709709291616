import { allImages } from "../../../../images";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  appBarClasses,
  Box,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React, { Children } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ModelExperience from "../UserProfile/ModelExperience";
import APIRequest from "../../../../utils/APIRequest";
import ConfigAPIURL from "../../../../config/ConfigAPIURL";
import { useLocation } from "react-router-dom";
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
const ProfileExperience = ({
  title,
  data,
  children,
  setState,
  fetchUserAdditionalInfo,
}) => {
  const location = useLocation();
  const [expanded, setExpanded] = React.useState(
    data?.experience.length > 0 ? "panel1" : false
  );
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleDeleteExperience = async (id) => {
    try {
      const response = await APIRequest.request(
        "POST",
        ConfigAPIURL.userProfileDataRemove,
        JSON.stringify({
          recordId: id,
          heading: "experience",
        })
      );
      console.log(response);
      if (response.data.responseCode === 109) {
        setState(true);
        fetchUserAdditionalInfo();
      }
    } catch (err) {
    } finally {
      fetchUserAdditionalInfo();
    }
  };
  return (
    <Stack >
      {/* <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
        sx={{ borderRadius: "15px" }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Stack direction="row" gap={1} alignItems="center">
            <Typography
              className="heading"
              sx={{ fontWeight: "600", fontSize: "20px" }}
            >
              {title}
            </Typography>
            {location.pathname === "/profile" ? (
              <ModelExperience
                userData={data.experience}
                setState={setState}
                fetchUserAdditionalInfo={fetchUserAdditionalInfo}
              />
            ) : null}
          </Stack>
        </AccordionSummary>
        <Divider variant="middle" />
        <AccordionDetails>
          <Stack ml={6}></Stack>
          {data?.experience?.length > 0 &&
            data?.experience?.map((value) => {
              return (
                <Stack
                  sx={{ flexDirection: { xs: "column", md: "row" } }}
                  justifyContent="space-between"
                  alignItems="center"
                  key={value._id}
                  mx={2}
                  mt={1}
                  gap={1}
                >
                  <Stack width="100%">
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Stack direction="row" gap={3} alignItems="center">
                        <Typography
                          variant="subtitle1"
                          color="#2A4B95"
                          fontSize="20px"
                          fontWeight="600"
                          className="heading"
                          textTransform="capitalize"
                        >
                          {value?.company}
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          fontSize="16px"
                          fontWeight="600"
                          className="sub__heading"
                          textTransform="capitalize"
                        >
                          {value?.position}
                        </Typography>
                      </Stack>
                      {location.pathname === "/profile" ? (
                        <Stack direction="row" alignItems="center" gap={3}>
                          <ModelExperience
                            userData={value}
                            id={value._id}
                            fetchUserAdditionalInfo={fetchUserAdditionalInfo}
                          />
                          <Box
                            display="flex"
                            className="mainicon"
                            onClick={() => handleDeleteExperience(value._id)}
                          >
                            <img
                              style={{ cursor: "pointer" }}
                              src={allImages.deleteButton}
                              alt="delete"
                            />
                          </Box>
                        </Stack>
                      ) : null}
                    </Stack>
                    <Typography
                      variant="subtitle1"
                      fontWeight="400"
                      fontSize="14px"
                      color="#00000099"
                      className="sub__heading"
                    >
                      From
                      {value?.fromDate
                        ? new Date(parseInt(value?.fromDate, 10))
                            .toString("MM/dd/yy HH:mm:ss")
                            .slice(3, 15)
                        : null}{" "}
                      To
                      {value?.tillDate
                        ? new Date(parseInt(value?.tillDate, 10))
                            .toString("MM/dd/yy HH:mm:ss")
                            .slice(3, 15)
                        : " Current Date"}
                    </Typography>
                    <Typography variant="subtitle1" className="sub__heading">
                      {value?.location}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      fontSize="14px"
                      className="content wrap"
                    >
                      {value?.description}
                    </Typography>
                  </Stack>
                </Stack>
              );
            })}
        </AccordionDetails>
      </Accordion> */}
       <Typography
          sx={{ fontWeight: "600", fontSize: "28px", color: "#343434",marginBottom:'12px' }}
        >
          {title}
        </Typography>
      <Stack style={{backgroundColor:'#FEFEFE',borderRadius:'18px',padding:'20px'}}>
      <Stack>
        {location.pathname === "/profile" ? (
          <ModelExperience
            userData={data.experience}
            setState={setState}
            fetchUserAdditionalInfo={fetchUserAdditionalInfo}
          />
        ) : null}
      </Stack>
      <Stack>
        {data?.experience?.length > 0 &&
          data?.experience?.map((value) => {
            return (
              <Stack
                sx={{ flexDirection: { xs: "column", md: "row" } }}
                justifyContent="space-between"
                alignItems="center"
                key={value._id}
                mx={2}
                mt={1}
                gap={1}
              >
                <Stack width="100%" style={{borderBottom:'1px solid #E3E3E3'}}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Stack direction="row"
                    // justifyContent="space-between"
                    alignItems="center">
                    <Stack style={{backgroundColor:'#E2E8F47A',borderRadius:'12px',padding:"18px",marginRight:'8px',height:'80px',width:'80px',display:'flex',justifyContent:'center',alignItems:'center'}}>
                      <WorkOutlineIcon style={{color:'#00275A',fontSize:'40px'}} />
                    </Stack>
                    
                    <Stack direction="column" gap={0} >
                      <Typography
                        // variant="subtitle1"
                        fontSize="24px"
                        fontWeight="500"
                        // className="sub__heading"
                        textTransform="capitalize"
                        color={'#29292B'}
                      >
                        {value?.position}
                      </Typography>
                      <Typography
                        // variant="subtitle1"
                        color="#525252"
                        fontSize="16px"
                        fontWeight="500"
                        // className="heading"
                        textTransform="capitalize"
                      >
                        {value?.company}
                      </Typography>
                    </Stack>
                    </Stack>
                    {location.pathname === "/profile" ? (
                      <Stack style={{display:'flex',flexDirection:'row',justifyContent:'end',alignItems:'center',}} gap={3}>
                        <ModelExperience
                          userData={value}
                          id={value._id}
                          fetchUserAdditionalInfo={fetchUserAdditionalInfo}
                          text={'edit'}
                        />
                        <Box
                          display="flex"
                          className="mainicon"
                          onClick={() => handleDeleteExperience(value._id)}
                        >
                          <img
                            style={{ cursor: "pointer" }}
                            src={allImages.deleteButton}
                            alt="delete"
                          />
                        </Box>
                      </Stack>
                    ) : null}
                  </Stack>
                  <Typography
                    variant="subtitle1"
                    fontWeight="500"
                    fontSize="14px"
                    color="#676768"
                    // className="sub__heading"
                    alignSelf={'end'}
                  >
                    {/* From */}
                    {value?.fromDate
                      ? new Date(parseInt(value?.fromDate, 10))
                          .toString("MM/dd/yy HH:mm:ss")
                          .slice(3, 15)
                      : null}{" "}
                    {/* To */}-
                    {value?.tillDate
                      ? new Date(parseInt(value?.tillDate, 10))
                          .toString("MM/dd/yy HH:mm:ss")
                          .slice(3, 15)
                      : " Current Date"}
                  </Typography>
                  {/* <Typography variant="subtitle1" className="sub__heading">
                    {value?.location}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    fontSize="14px"
                    className="content wrap"
                  >
                    {value?.description}
                  </Typography> */}
                </Stack>
              </Stack>
            );
          })}

      </Stack>
      </Stack>
    </Stack>
  );
};

export default ProfileExperience;
