// import React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  DialogActions,
  DialogContentText,
  Button,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import { Slide } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ChatBotModal = (props) => {
  const isSmallScreen = useMediaQuery("(max-width:600px)");

  return (
    <Dialog
      fullWidth={props.fullWidth}
      maxWidth={props.maxWidth}
      open={props.slideInDialog}
      TransitionComponent={Transition}
      keepMounted
      // onClose={() => props.setSlideInDialog(false)}
      PaperProps={{
        sx: {
          position: "absolute",
          bottom:isSmallScreen ? 100 : 80, // Adjust to position the modal above the button
          right:isSmallScreen ? 14 : 90, // Adjust to position the modal just to the right of the button
          margin: 0,
          bgcolor: "background.paper",
          boxShadow: 24,
          borderRadius: 2,
          // p: 2,
          maxWidth: 800,
          maxHeight:500,
          padding:'0px'
        },
      }}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      {props.title !== undefined && props.title !== null ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            alignContent: "center",
            marginTop: "0",
            paddingTop: "0",
          }}
        >
          <DialogTitle id="alert-dialog-slide-title">{props.title}</DialogTitle>
          <div
            aria-label="close"
            onClick={props.setSlideInDialog}
            style={{
              cursor: "pointer",
            }}
          >
            <CloseIcon />
          </div>
        </div>
      ) : (
        <></>
      )}
      <DialogContent style={{padding:'0px'}}>
        <DialogContentText id="alert-dialog-slide-description">
          {props.contentComponent}
        </DialogContentText>
      </DialogContent>
    </Dialog>
    // </div>
  );
};

export default ChatBotModal;
