import {
  Alert,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputAdornment,
  InputLabel,
  makeStyles,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Snackbar,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { Box, Container } from "@mui/system";
import React, { useState, useEffect } from "react";
import { allImages } from "../../images";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ConfigAPIURL from "../../config/ConfigAPIURL";
import APIRequest from "../../utils/APIRequest";
import Toaster from "../../utils/Toaster";
import { signInwithGoogle } from "../../config/Firebase";
import LocalStorage from "../../config/LocalStorage";
import { Toast } from "bootstrap";
import VisibilityIcon from "@mui/icons-material/Visibility";
import uuid from "react-uuid";
import OtpInput from "react18-input-otp";
import BlueButton from "../privateRoute/userRoute/BlueButton";
import { LazyLoadImage } from "react-lazy-load-image-component";
const Signup = () => {
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (location.state) {
      if (location.state.skill) {
        return;
      }
      setIsVerified(true);
      setOtpAuth(false);
    }
  }, []);
  //should be done according to state
  const [visibile, setVisible] = useState(false);
  const [eMail, setEmail] = useState(
    location?.state?.email
      ? location?.state?.email
      : location?.state?.data?.results?.userEmail
      ? location?.state?.data?.results?.userEmail
      : ""
  );
  const [password, setPassword] = useState(
    location?.state?.uid
      ? location?.state?.uid
      : location?.state?.data?.results?.userProfile?.uid
      ? location?.state?.data?.results?.userProfile?.uid
      : ""
  );
  const [name, setName] = useState({
    firstName: location?.state?.name?.split(" ")[0]
      ? location?.state?.name?.split(" ")[0]
      : location?.state?.data?.results?.userProfile?.firstName
      ? location?.state?.data?.results?.userProfile?.firstName
      : "",
    lastName: location?.state?.name?.split(" ")[1]
      ? location?.state?.name?.split(" ")[1]
      : location?.state?.data?.results?.userProfile?.lastName
      ? location?.state?.data?.results?.userProfile?.lastName
      : "",
  });
  const [userName, setUserName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [designation, setDesignation] = useState("");
  const [organisation, setOrganisation] = useState("");
  const [isMentor, setIsMentor] = useState(false);
  const [allCountriesCode, setAllCountrysCode] = useState([]);
  const [countryCode, setCountryCode] = useState("+91");
  const [snakbarValues, setSnakbarValues] = useState({
    status: false,
    severity: "",
    message: "",
  });

  const [isVerified, setIsVerified] = useState(false);
  const [otpAuth, setOtpAuth] = useState(true);
  const [isError, setIsError] = useState(false);
  const [otp, setOtp] = React.useState("");

  const { state } = location;

  console.log("this is state from signup", location.state);

  // console.log("this is otp", otp);

  const handleChange = (newValue) => {
    setOtp(newValue);
  };
  useEffect(() => {
    fetchCountry();
    isLogin();
  }, []);
  //google sign in
  const notificationClose = (event) => {
    setSnakbarValues({ status: false, severity: "", message: "" });
  };

  const fetchCountry = async () => {
    try {
      const response = await APIRequest.request(
        "GET",
        ConfigAPIURL.getCountry,
        ""
      );
      if (response.data.responseCode === 109) {
        const countryData = response.data.countries;
        const allCountryData = [];
        countryData.forEach((val) => {
          allCountryData.push(val.phoneCode);
        });
        setAllCountrysCode(allCountryData);
        setCountryCode(
          allCountryData.find((val) => {
            return val === 91;
          })
        );
      }
    } catch (err) {
      console.log(err);
    }
  };
  const checkUserExist = async () => {
    if (eMail === "") {
      return;
    }
    try {
      const response = await APIRequest.request(
        "POST",
        ConfigAPIURL.emailPresent,
        JSON.stringify({ email: eMail })
      );
      console.log(response);
      if (response?.data?.responseCode === 101) {
        setIsError(true);
      }
      if (response?.data?.responseCode === 103) {
        setIsError(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  //check whether the user is logged in or nt and route to private route
  const isLogin = async () => {
    const response = await APIRequest.request(
      "GET",
      ConfigAPIURL.userIsLoggedin,
      ""
    );
    if (response?.data?.responseCode === 109) {
      if (response.data.userType !== null && !state?.skill) {
        return navigate("/home");
      } else if (state?.skill) {
        return navigate("/home/mentors/list", {
          state: { skill: state.skill },
        });
      } else {
        setSnakbarValues({
          status: true,
          severity: "error",
          message: "User does not have permission to login",
        });
      }
    }
    if (response?.data?.responseCode === 108) {
    } else {
      setSnakbarValues({
        status: true,
        severity: "error",
        message: "Server Error, Please try after sometime",
      });
    }
  };

  //  creation of  the account
  const UserSignUp = () => {
    return APIRequest.request(
      "POST",
      ConfigAPIURL.userAccountCreate,
      JSON.stringify({
        fname: name.firstName,
        lname: name.lastName,
        user_id: userName,
        mobileNo: phoneNumber,
        designation: designation,
        organization: organisation,
        email: eMail?.toLowerCase(),
        password: password,
        mentor: isMentor,
        signUpId: location?.state?.uid
          ? location?.state?.uid
          : location?.state?.data?.results?.userProfile?.uid
          ? location?.state?.data?.results?.userProfile?.uid
          : "",
        // profileImage: location?.state?.profileImage,
      })
    );
  };
  //after signUp the user get logged in
  const userLoggsIn = () => {
    return APIRequest.request(
      "POST",
      ConfigAPIURL.userAccountlogin,
      JSON.stringify({
        email: eMail?.toLowerCase(),
        password: password,
      })
    );
  };

  const handleTriggerOtp = async (emailId) => {
    try {
      const response = await APIRequest.request(
        "POST",
        ConfigAPIURL.emailOtp,
        JSON.stringify({ email: emailId?.toLowerCase() })
      );

      console.log(response);
      if (response?.data?.responseCode === 109) return true;
      return false;
    } catch (err) {
      console.log(err);
    }
  };
  const handleCheckOtp = async () => {
    try {
      const response = await APIRequest.request(
        "POST",
        ConfigAPIURL.emailVerifyOtp,
        JSON.stringify({ otp: otp })
      );

      if (response?.data?.responseCode === 109) return true;
      return false;
    } catch (err) {
      console.log(err);
    }
  };

  //submitting data and creates account after validation
  const handleSubmit = async () => {
    if (otpAuth) {
      // if the email id already exist return if not trigger otp
      const response = await handleTriggerOtp(eMail);
      if (!response)
        return setSnakbarValues({
          status: true,
          severity: "error",
          message: "Email id already exist",
        });
      setSnakbarValues({
        status: true,
        severity: "success",
        message: "OTP has been successfully sent.",
      });
      return setOtpAuth(false);
    }
    if (!isVerified) {
      // verify the otp and if the response code is 109 then make the verified else show the popup otp doesnt match
      const response = await handleCheckOtp();
      if (!response) {
        return setSnakbarValues({
          status: true,
          severity: "error",
          message: "OTP doesn't match",
        });
      }
      return setIsVerified(true);
    }
    if (eMail === "" || password === "" || userName === "") {
      setSnakbarValues({
        status: true,
        severity: "error",
        message: "Email id ,UserName and Password cannot be empty",
      });
    }

    // const mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    //email validation if its true data is passed to server else error throws
    // if (eMail.match(mailFormat)) {
    //validation success
    const response = await UserSignUp();
    if (response?.data?.responseCode === 109) {
      setSnakbarValues({
        status: true,
        severity: "success",
        message: "Account created succesfully",
      });
      const response = await userLoggsIn();
      if (response?.data?.responseCode === 105) {
        // navigates to the update page
        // navigate("/update");
        if (state?.skill) {
          navigate("/update", {
            state: { skill: state.skill },
          });
        } else {
          navigate("/update");
        }
      }
    }
    if (response?.data?.responseCode === 114) {
      return setSnakbarValues({
        status: true,
        severity: "error",
        message: "User Name or Email  already exists try Logging In",
      });
    } else {
      setSnakbarValues({
        status: true,
        severity: "error",
        message: "Server Error, Please try after sometime",
      });
    }
    // } else
    //   setSnakbarValues({
    //     status: true,
    //     severity: "error",
    //     message: "Invalid email format",
    //   });
  };

  const resendOtp = async () => {
    const response = await handleTriggerOtp(eMail);
    if (!response)
      return setSnakbarValues({
        status: true,
        severity: "error",
        message: "failed to sent the OTP.",
      });
    return setSnakbarValues({
      status: true,
      severity: "success",
      message: "OTP has been successfully sent.",
    });
  };

  return (
    <>
      {
        <Stack
          // border="2px solid black"
          direction="row"
          justifyContent="center"
          px={1}
          py={2}
          position="relative"
          width="100%"
          sx={{
            background: "white",
            minHeight: "100vh",
            alignItems: { xs: "center", md: "start" },
          }}
        >
          <Stack width="100%">
            {/* <Box mt={2} width="100%">

            </Box> */}
            {/* <Container maxWidth={"xl"}> */}
            <Grid
              container
              // spacing={10}
              sx={{
                minHeight: "100vh",
                justifyContent: { xs: "center", md: "start" },
                position: "relative",
              }}
            >
              <Grid
                item
                lg={4.5}
                md={4}
                className="sticky__div"
                sx={{
                  minHeight: "100vh",
                  display: { xs: "none", md: "block" },
                }}
              >
                <LazyLoadImage
                  style={{ objectFit: "cover" }}
                  src={allImages.signUpimage}
                  width={300}
                  // height="100vh"
                  alt="Image Alt"
                  PlaceholderSrc={allImages.signupImageMock}
                />
                {/* <img
                  src={allImages.signUpimage}
                  style={{ objectFit: "cover" }}
                /> */}
              </Grid>
              <Grid item lg={7} md={8} sm={12} xs={12} sx={{}}>
                <Stack
                  sx={{
                    height: "100%",
                    justifyContent: "center",
                    width: { md: "100%" },
                    // px: "50px",
                    alignItems: "center",
                  }}
                >
                  <Stack
                    style={{
                      alignSelf: "end",
                      marginBottom: "90px",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 8,
                      top: 0,
                      position: "absolute",
                    }}
                    sx={{ mt: "38px" }}
                  >
                    <Typography
                      justifySelf="end"
                      variant="subtitle1"
                      textAlign="center"
                      sx={{
                        fontWeight: 400,
                        fontSize: { xs: "16px", md: "16px" },
                        lineHeight: "19.53px",
                        marginTop: "10px",
                        marginBottom: "20px",
                      }}
                    >
                      Already on Mentorlink?
                    </Typography>
                    {/* <Link
                      to="/login"
                      style={{
                        fontSize: "700",
                        color: "#1C3464",
                        fontSize: "18px",
                      }}
                    > */}{" "}
                    <BlueButton
                      style={{
                        textTransform: "capitalize",
                        marginTop: 0,
                        marginBottom: "20px",
                      }}
                      text="Sign In"
                      onClick={() => navigate("/login")}
                    />
                    {/* </Link> */}
                  </Stack>
                  <Grid container style={{ justifyContent: "center" }}>
                    <Grid
                      item
                      xs={12}
                      lg={7}
                      style={{ paddingLeft: "10px", paddingRight: "10px" }}
                    >
                      {/* <Stack
                      sx={{
                        width: "58px",
                        height: "58px",
                        borderRadius: "100%",
                      }}
                    >
                      <img
                        style={{
                          width: "54px",
                          height: "54px",
                          objectFit: "cover",
                        }}
                        src={allImages.logoWhole}
                        alt="logo"
                      />
                    </Stack> */}
                      <Typography
                        className=""
                        sx={{
                          fontSize: { md: "24px", xs: "16px" },
                          fontWeight: 600,
                          fontFamily: "Figtree",
                          lineHeight: "33px",
                          textAlign: "center",
                          width: "100%",
                        }}
                      >
                        Sign Up for an account{" "}
                      </Typography>
                      <Typography
                        className="heading"
                        style={{
                          color: "#868686",
                          fontWeight: 400,
                          lineHeight: "22px",
                          textAlign: "center",
                          fontSize: "16px",
                        }}
                      >
                        Get started by entering few details{" "}
                      </Typography>
                      <Box
                        // px={3}
                        py={2}
                        sx={{
                          background: "white",
                          width: "100%",

                          // mt: { lg: "1rem", sm: "1.5rem", xs: ".5rem" },
                          // py: "24px",

                          // mx: 4,
                        }}
                      >
                        <form
                          onSubmit={(e) => {
                            e.preventDefault();
                            handleSubmit();
                          }}
                        >
                          {/* newly edited */}

                          <Stack mt={2} gap={3}>
                            {!isVerified && (
                              <Stack direction="row" gap={1}>
                                <TextField
                                  variant="outlined"
                                  required
                                  fullWidth
                                  autoFocus
                                  label="First Name"
                                  size="small"
                                  disabled={location?.state?.name}
                                  value={name.firstName}
                                  onChange={(e) =>
                                    setName({
                                      ...name,
                                      firstName: e.target.value,
                                    })
                                  }
                                  // InputProps={{ style: { fontSize: 14 } }}
                                  InputLabelProps={{ style: { fontSize: 15 } }}
                                />
                                <TextField
                                  required
                                  fullWidth
                                  variant="outlined"
                                  label="Last Name"
                                  size="small"
                                  disabled={location?.state?.name}
                                  value={name.lastName}
                                  onChange={(e) =>
                                    setName({
                                      ...name,
                                      lastName: e.target.value,
                                    })
                                  }
                                  InputLabelProps={{ style: { fontSize: 15 } }}
                                />
                              </Stack>
                            )}
                            {!isVerified && (
                              <Grid item xs={12}>
                                <TextField
                                  onBlur={() => checkUserExist()}
                                  disabled={isVerified}
                                  required
                                  error={isError && true}
                                  helperText={
                                    isError && "Email id already exists."
                                  }
                                  value={eMail}
                                  type="email"
                                  // disabled={location?.state?.email}
                                  onChange={(e) => setEmail(e.target.value)}
                                  sx={{
                                    borderRadius: "6px",
                                    outline: "none",
                                    fontSize: "24px",
                                    fontWeight: "400",
                                  }}
                                  variant="outlined"
                                  fullWidth
                                  label="E-mail "
                                  size="small"
                                  InputLabelProps={{ style: { fontSize: 15 } }}
                                />
                              </Grid>
                            )}
                            {isVerified && (
                              <Grid item xs={12}>
                                {isVerified && (
                                  <TextField
                                    required
                                    value={userName}
                                    type="text"
                                    onChange={(e) =>
                                      setUserName(e.target.value)
                                    }
                                    sx={{
                                      borderRadius: "6px",
                                      outline: "none",
                                      fontSize: "24px",
                                      fontWeight: "400",
                                    }}
                                    variant="outlined"
                                    fullWidth
                                    label="User Name"
                                    size="small"
                                    InputLabelProps={{
                                      style: { fontSize: 15 },
                                    }}
                                  />
                                )}
                              </Grid>
                            )}
                            {isVerified && (
                              <FormControl>
                                <Grid container spacing={1}>
                                  <Grid item xs={2} overflow="hidden">
                                    <InputLabel
                                      sx={{ fontSize: "15px" }}
                                      id="demo-simple-select-autowidth-label"
                                    >
                                      Code
                                    </InputLabel>
                                    <Select
                                      sx={{
                                        maxHeight: 100,
                                        overflow: "hidden",
                                      }}
                                      labelId="demo-simple-select-autowidth-label"
                                      id="demo-simple-select-autowidth"
                                      MenuProps={{
                                        PaperProps: { sx: { maxHeight: 300 } },
                                      }}
                                      value={countryCode}
                                      onChange={(e) =>
                                        setCountryCode(e.target.value)
                                      }
                                      autoWidth
                                      label="Industry"
                                      fullWidth
                                      size="small"
                                    >
                                      {allCountriesCode?.length > 0 &&
                                        allCountriesCode.map((value, ind) => {
                                          return (
                                            <MenuItem key={ind} value={value}>
                                              {value}
                                            </MenuItem>
                                          );
                                        })}
                                    </Select>
                                  </Grid>
                                  <Grid item xs={9} lg={10} md={10}>
                                    <TextField
                                      required
                                      value={phoneNumber}
                                      type="number"
                                      // onChange={(e) =>
                                      //   setPhoneNumber(e.target.value)
                                      // }
                                      onChange={(e) => {
                                        const input = e.target.value;
                                        const numericInput = input?.replace(
                                          /[^0-9]/g,
                                          ""
                                        ); // Remove non-numeric characters
                                        const limitedInput = numericInput.slice(
                                          0,
                                          10
                                        ); // Limit to 10 digits
                                        setPhoneNumber(limitedInput);
                                      }}
                                      sx={{
                                        borderRadius: "6px",
                                        outline: "none",
                                        fontSize: "24px",
                                        fontWeight: "400",
                                      }}
                                      variant="outlined"
                                      fullWidth
                                      label="Phone Number"
                                      size="small"
                                      InputLabelProps={{
                                        style: { fontSize: 15 },
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              </FormControl>
                            )}

                            {location.state && !location?.state?.skill
                              ? null
                              : !isVerified && (
                                  <Grid item xs={12}>
                                    <TextField
                                      value={password}
                                      required
                                      onChange={(e) =>
                                        setPassword(e.target.value)
                                      }
                                      fontSize="24px"
                                      variant="outlined"
                                      label="Password"
                                      fullWidth
                                      size="small"
                                      type={visibile ? "text" : "password"}
                                      InputLabelProps={{
                                        style: { fontSize: 15 },
                                      }}
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment
                                            position="end"
                                            sx={{ cursor: "pointer" }}
                                          >
                                            {!visibile ? (
                                              <VisibilityOffIcon
                                                onClick={() =>
                                                  setVisible(!visibile)
                                                }
                                              />
                                            ) : (
                                              <VisibilityIcon
                                                onClick={() =>
                                                  setVisible(!visibile)
                                                }
                                              />
                                            )}
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                                  </Grid>
                                )}
                            {!otpAuth && !isVerified && (
                              <Stack gap={1}>
                                <Typography
                                  sx={{
                                    fontSize: "12px",
                                    margin: 0,
                                    padding: 0,
                                  }}
                                >
                                  6 Digit code has been sent to the mail
                                </Typography>

                                <Stack
                                  flexDirection="row"
                                  alignItems="start"
                                  justifyContent="space-between"
                                >
                                  <OtpInput
                                    id="demo_input"
                                    containerStyle={{
                                      justifyContent: "center",
                                      display: "flex",
                                      width: "100%",
                                      gap: 16,
                                    }}
                                    value={otp}
                                    onChange={handleChange}
                                    numInputs={6}
                                    separator={<span></span>}
                                    inputStyle={{
                                      width: "47px",
                                      height: "49px",
                                      justifyContent: "space-between",
                                      border: "1px solid #E5E5E5",
                                    }}
                                  />
                                </Stack>
                                <Typography
                                  sx={{
                                    fontSize: "12px",
                                    margin: 0,
                                    textAlign: "center",
                                  }}
                                >
                                  Havent recieved the verification code?
                                </Typography>
                                <Typography
                                  onClick={resendOtp}
                                  sx={{ cursor: "pointer" }}
                                  variant="medium"
                                  textAlign="center"
                                  className="heading__color"
                                >
                                  Resend
                                </Typography>
                              </Stack>
                            )}
                            {isVerified && (
                              <Stack direction="row" gap={1}>
                                <TextField
                                  required
                                  variant="outlined"
                                  label="Designation"
                                  InputLabelProps={{ style: { fontSize: 15 } }}
                                  size="small"
                                  value={designation}
                                  onChange={(e) =>
                                    setDesignation(e.target.value)
                                  }
                                />
                                <TextField
                                  required
                                  variant="outlined"
                                  label="Organisation"
                                  InputLabelProps={{ style: { fontSize: 15 } }}
                                  size="small"
                                  value={organisation}
                                  onChange={(e) =>
                                    setOrganisation(e.target.value)
                                  }
                                />
                              </Stack>
                            )}
                            {isVerified && (
                              <Stack alignItems="start">
                                <Typography fontSize="14px" variant="subtitle1">
                                  Select your Role ?
                                </Typography>
                                <FormControl>
                                  <RadioGroup
                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                    name="controlled-radio-buttons-group"
                                    value={isMentor}
                                    onChange={(e) =>
                                      setIsMentor(e.target.value)
                                    }
                                    size={"small"}
                                  >
                                    <FormControlLabel
                                      value={true}
                                      control={<Radio />}
                                      label="Mentor"
                                    />
                                    <FormControlLabel
                                      size={"small"}
                                      value={false}
                                      control={<Radio />}
                                      label="Mentee"
                                    />
                                  </RadioGroup>
                                </FormControl>
                              </Stack>
                            )}
                          </Stack>
                          <Stack alignItems="center" mt={4}>
                            <Button
                              textAlign="center"
                              type="submit"
                              variant="contained"
                              className="submit__button"
                              sx={{
                                width: "50%",
                                background: "#2C72FF",
                                height: { xs: "50px", md: "42px" },
                                mt: "36px",
                                borderRadius: { xs: "6px", md: "6px" },
                                fontSize: { md: "20px", xs: "18px" },
                                fontWeight: 600,
                                textTransform: "capitalize",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {otpAuth
                                ? "Verify"
                                : !isVerified
                                ? "Verify Otp"
                                : "Sign up"}
                            </Button>
                          </Stack>
                        </form>
                      </Box>
                    </Grid>
                  </Grid>
                </Stack>
              </Grid>
            </Grid>

            {/* </Container> */}
            <Snackbar
              open={snakbarValues.status}
              onClose={notificationClose}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              autoHideDuration={3000}
            >
              <Alert severity={snakbarValues.severity}>
                {snakbarValues.message}
              </Alert>
            </Snackbar>
          </Stack>
        </Stack>
      }
    </>
  );
};

const buttonLink = {
  color: "black",
};

export default Signup;
