// import { Grid, Stack, Typography } from "@mui/material";
// import React from "react";
// import { allImages } from "../../../images";
// import TestimonialCard from "./TestimonialCard";

// const data = [
//   {
//     img: allImages.testimonial,
//     text: "“One of the best platforms for learning and growing at an affordable price. I highly recommend everyone to use this platform.”",
//     title: "UX designer ",
//     subhead: "Siemens",
//   },
//   {
//     img: allImages.testimonial2,
//     text: "“One of the best platforms for learning and growing at an affordable price. I highly recommend everyone to use this platform.”",
//     title: "Lead developer",
//     subhead: "Atkins",
//   },
//   {
//     img: allImages.testimonial3,
//     text: "“One of the best platforms for learning and growing at an affordable price. I highly recommend everyone to use this platform.”",
//     title: "Business Analyst",
//     subhead: "Opentext",
//   },
// ];
// const Testimonials = () => {
//   return (
//     <>
//       <Stack id="purpose" textAlign="center" my={2}>
//         <Stack my={2}>
//           <Typography
//             variant="subtitle1"
//             className="landing__heading banner__title"
//           >
//             Testimonials
//           </Typography>
//           <Typography variant="subtitle1" className="landing__content">
//             Here’s what our user’s are saying about our platform.
//           </Typography>
//         </Stack>
//         <Grid
//           container
//           sx={{ mt: 3.2, justifyContent: "center", gap: 4, height: "100%" }}
//         >
//           {data.map((val, ind) => {
//             return (
//               <Grid item md={3} xs={12}>
//                 <TestimonialCard {...val} ind={ind} />
//               </Grid>
//             );
//           })}
//         </Grid>
//       </Stack>
//     </>
//   );
// };

// export default Testimonials;
import Slider from "react-slick";
import { Box, Typography, Avatar, Stack, useMediaQuery } from "@mui/material";
import { Card, CardContent } from "@mui/material";

import { allImages } from "../../../images";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React from "react";

const TestimonialCard = ({ img, text, title, subhead }) => {
  return (
    <Stack
      sx={{
        borderRadius: "46px",
        boxShadow: 3,
        textAlign: "center",
        backgroundColor: "#fff",
        display: "flex",
        flexDirection: "row",
        padding: "28px",
      }}
    >
      <Stack style={{ marginRight: "8px", }}>
        <Avatar
          src={img}
          alt={title}
          sx={{ width: 92, height: 92, margin: "auto" }}
        />
      </Stack>
      <Stack>
        <Typography  fontWeight={"400"} fontSize={'14px'}>
          {text}
        </Typography>
        {/* <Typography
          sx={{
            fontWeight: "500",
            fontSize: "14px",
            color: "#00275A",
            alignSelf: "end",
            marginTop: "14px",
          }}
        >
          -{subhead}
        </Typography> */}
        <Typography
          sx={{
            fontWeight: "500",
            fontSize: "14px",
            color: "#00275A",
            alignSelf: "end",
            marginTop: "14px",
          }}
        >
          -{title}, {subhead}
        </Typography>
      </Stack>
    </Stack>
  );
};

const data = [
  {
    img: allImages.testimonial4,
    text: "Thank you for guiding us through the intricacies of software architecture and coding best practices. Your practical insights have truly elevated our team's development skills.",
    title: "Rahul Varma",
    subhead: "Technology Mentor",
  },
  {
    img: allImages.testimonial4,
    text: "Your strategic advice on market analysis and growth strategies has been invaluable. We appreciate your mentorship in navigating the complexities of launching a startup.",
    title: "Vikram Singh",
    subhead: "Business Mentor",
  },
  {
    img: allImages.testimonial4,
    text: "Your creative vision and attention to user experience have greatly enhanced our product's usability. Your feedback on design principles has been instrumental in refining our interfaces.",
    title: "Swapna",
    subhead: "Design Mentor",
  },
  {
    img: allImages.testimonial4,
    text: "We've seen a significant improvement in our online presence, thanks to your expertise in digital marketing strategies. Your guidance on SEO and content strategy has been spot on!",
    title: "Lokesh",
    subhead: "Marketing Mentor",
  },
 
];
const dataTwo = [
  {
    img: allImages.testimonial4,
    text: "Your financial guidance has helped us better understand budgeting and financial projections. Your insights into investment strategies have been crucial in shaping our financial planning.",
    title: "Swathi",
    subhead: "Finance Mentor",
  },
  {
    img: allImages.testimonial4,
    text: "Your deep knowledge of medical research methodologies has guided our team, and your feedback on design and analysis has strengthened our studies.",
    title: "Nikhil",
    subhead: "Healthcare Mentor",
  },
  {
    img: allImages.testimonial4,
    text: "Thank you for your legal expertise in navigating complex regulatory issues. Your advice on contract negotiations and compliance has been crucial in protecting our business interests.",
    title: "Rudra Singh",
    subhead: "Legal Mentor",
  },
  {
    img: allImages.testimonial4,
    text: "Your insights have greatly enhanced our curriculum development and improved our teaching strategies, leading to better student engagement and success.",
    title: "Ujjwal",
    subhead: "Education Mentor",
  },
];

const Testimonials = () => {
  const isSmallScreen = useMediaQuery("(max-width:600px)");

  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 10000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: "linear",
    pauseOnHover: true,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 0.8,
        },
      },
    ],
  };
  const settingsTwo = {
    dots: false,
    infinite: true,
    speed: 8000,
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: "linear",
    pauseOnHover: true,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 0.8,
        },
      },
    ],
  };

  return (
    <Box
      id="purpose"
      textAlign="center"
      my={2}
      style={{
        backgroundColor: "#00275A",
        height: "100%",
        paddingTop: "20px",
        paddingBottom: "20px",
      }}
    >
      <Typography variant="h1" color={"#446086"} marginBottom={"20px"} fontWeight={'600'} style={{fontSize : isSmallScreen &&'48px'}}>
        People talk
      </Typography>
      <Slider {...settings}>
        {data.map((val, ind) => (
          <Box key={ind} px={2}>
            <TestimonialCard {...val} />
          </Box>
        ))}
      </Slider>
      <Stack style={{ marginTop: "18px" }}>
        <Slider {...settingsTwo}>
          {dataTwo?.map((val, ind) => (
            <Box key={ind} px={2}>
              <TestimonialCard {...val} />
            </Box>
          ))}
        </Slider>
      </Stack>
    </Box>
  );
};

export default Testimonials;
