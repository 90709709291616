import React, { useEffect } from 'react'
import Index from '../components/privateRoute/userRoute/UserProfile/Index'

const Profile = () => {
  useEffect(() => {
    document.title = `Mentorlink user profile`;
  }, []);
  return (
    <div>
        <Index/>
    </div>
  )
}

export default Profile