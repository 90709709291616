import React from "react";
import { useEffect } from "react";
import Index from "../components/privateRoute/userRoute/Notification/Index";

const Notification = () => {
  useEffect(() => {
    document.title = `Mentorlink notification`;
  }, []);
  return (
    <div>
      <Index />
    </div>
  );
};

export default Notification;
