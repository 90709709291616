import {
  Alert,
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import ConfigAPIURL from "../../../../config/ConfigAPIURL";
import { allImages } from "../../../../images";
import APIRequest from "../../../../utils/APIRequest";
import zIndex from "@mui/material/styles/zIndex";

const rollingImg =
  "https://s3.ap-south-1.amazonaws.com/mentorlink/profile/1665204038760_Rolling-1s-57px%20(1).gif";

const ProfileDetails = ({
  userProfile,
  register,
  userDetails,
  setUserDetails,
  localdata,
}) => {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState(false);

  // if userprofile is null just returns and cptures all input fields
  if (!userProfile) return;
  console.log();

  const uploadImage = async (e) => {
    const formdata = new FormData();
    const file = e.target.files[0];
    if (file.size > 3000000) {
      setSnackbar(true);
      return setTimeout(() => {
        setSnackbar(false);
      }, 3000);
    }

    formdata.append("attachment", file);
    formdata.append("bucketName", "profile");
    const response = await APIRequest.multipartForDataRequest(
      "PUT",
      ConfigAPIURL.fileUpload,
      formdata
    );
    if (response.data.responseCode === 109) {
      setLoading(true);
      setTimeout(() => {
        setFile(response.data.attachmentUrl[0]);
        setUserDetails({
          ...userDetails,
          profileImg: response.data.attachmentUrl[0],
        });
        setLoading(false);
      }, 1500);
    }
  };
  return (
    <>
      <Stack>
        <form style={{ display: "flex", flexDirection: "column", gap: 24 }}>
          <Typography
            style={{ fontWeight: 600, fontSize: "22px", alignSelf: "start" }}
          >
            Upload Profile
          </Typography>
          <label style={{ alignSelf: "center" }}>
            <Box
              width="100px"
              height="100px"
              borderRadius="100%"
              // overflow="hidden"
              sx={{ cursor: "pointer" }}
              position="relative"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <img
                style={{
                  width: loading ? "40px" : "100%",
                  height: loading ? "40px" : "100%",
                  borderRadius: "50%",
                }}
                src={
                  loading
                    ? rollingImg
                    : file
                    ? file
                    : userDetails?.profileImg
                    ? userDetails.profileImg
                    : allImages.avatar
                }
                alt="profile"
              />
              <Box
                position="absolute"
                top={59}
                left={65}
                style={{
                  backgroundColor: "#F1F6FF",
                  padding: "5px",
                  borderRadius: "50%",
                  zIndex: 20,
                }}
              >
                <img
                  src={allImages.cameraIconTwo}
                  alt="camera"
                  style={{ width: "22px", height: "22px", objectFit: "cover" }}
                />
              </Box>
            </Box>
            <input
              type="file"
              accept="image/png, image/gif, image/jpeg"
              onChange={uploadImage}
            />
          </label>
          <Typography
            style={{ fontWeight: 600, fontSize: "22px", alignSelf: "start",marginBottom:'0px' }}
          >
            Personal Details
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} md={8} lg={6}>
              <TextField
                label="First Name"
                InputLabelProps={{ style: { fontSize: 15 } }}
                size="small"
                fullWidth
                defaultValue={userProfile.fname ? userProfile.fname : ""}
                {...register("fname")}
              />
            </Grid>
            <Grid item xs={12} md={8} lg={6}>
              <TextField
                label="Last Name"
                InputLabelProps={{ style: { fontSize: 15 } }}
                size="small"
                fullWidth
                defaultValue={userProfile?.lname ? userProfile?.lname : ""}
                {...register("lname")}
              />
            </Grid>
            <Grid item xs={12} md={8} lg={6}>
              <FormControl style={{width:'100%'}}>
                <InputLabel
                  sx={{ fontSize: "15px",lineHeight:'12px' }}
                  id="demo-simple-select-autowidth-label"
                >
                  Gender
                </InputLabel>
                <Select
                  labelId="demo-simple-select-autowidth-label"
                  id="demo-simple-select-autowidth"
                  // value={userDetails.gender}
                  // onChange={handleChange}
                  defaultValue={userProfile?.gender ? userProfile?.gender : ""}
                  {...register("gender")}
                  autoWidth
                  label="Gender"
                  fullWidth
                  size="small"
                  style={{ minWidth: "260px" }}
                >
                  <MenuItem value={"male"}>Male</MenuItem>
                  <MenuItem value={"female"}>Female</MenuItem>
                  <MenuItem value={"other"}>Prefer not to say</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={8} lg={6}>
              <TextField
                label="Designation"
                size="small"
                InputLabelProps={{ style: { fontSize: 15 } }}
                defaultValue={
                  userProfile?.designation ? userProfile?.designation : ""
                }
                fullWidth
                {...register("designation")}
              />
            </Grid>
            <Grid item xs={12} md={8} lg={6}>
              <TextField
                label="Organisation"
                defaultValue={
                  localdata?.associatedOrganization?.name
                    ? localdata?.associatedOrganization?.name
                    : userProfile?.organization
                }
                size="small"
                fullWidth
                InputLabelProps={{ style: { fontSize: 15 } }}
                {...register("organization")}
                disabled={
                  localdata?.associatedOrganization?.name ? true : false
                }
              />
            </Grid>
          </Grid>
        </form>
      </Stack>
      <Stack position="fixed" sx={{ bottom: "50px" }}>
        {snackbar && (
          <Alert severity="warning">
            You cannot Upload more than 3Mb files!
          </Alert>
        )}
      </Stack>
    </>
  );
};

export default ProfileDetails;
