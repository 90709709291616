import {
  Alert,
  Box,
  Button,
  Checkbox,
  Divider,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import ConfigAPIURL from "../../../../config/ConfigAPIURL";
import APIRequest from "../../../../utils/APIRequest";
import BlueButton from "../BlueButton";
import { allImages } from "../../../../images";

const Summary = ({
  querys,
  setQuerys,
  lottie,
  setIndexValue,
  snackBarMessage,
  setSnackBarMessage,
}) => {
  const [checked, setChecked] = React.useState(false);
  const [snakbarValues, setSnakbarValues] = useState({
    status: false,
    severity: "",
    message: "",
  });

  const notificationClose = (event) => {
    setSnakbarValues({ status: false, severity: "", message: "" });
    setSnackBarMessage({ status: false, severity: "", message: "" });
  };
  const checkWalletAvailable = async (event) => {
    if (!checked) {
      isRedeemableCoupons();
    } else setChecked(false);
  };

  const isRedeemableCoupons = async () => {
    //   //then only redeem will happen and returns whether to redeem by response 1 or not by response 0
    const isRedeemable = await getRedeemPoints();
    console.log(isRedeemable);
    if (isRedeemable === 1) {
      setSnakbarValues({
        status: true,
        severity: "success",
        message: "Coupons Applied Successfully",
      });
      setChecked(true);
    } else {
      setSnakbarValues({
        status: true,
        severity: "error",
        message: "You dont have enough wallet balance",
      });
      setChecked(false);
    }
  };

  useEffect(() => {
    //if the check box is on then check on the wallet its available or not
    if (checked) isRedeemableCoupons();
  }, [querys?.totalPayment]);
  //razor pay
  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        console.log("success");
        resolve(true);
      };
      script.onerror = (err) => {
        console.log(err);
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }
  // when user books goes to razorpay and returns back the payment id
  async function displayRazorpay() {
    const amount = querys?.appointmentFee;
    if (!amount) return;
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    const totalAmount = querys?.couponDetails?.amount
      ? querys?.totalPayment - querys?.couponDetails?.amount > 0
        ? querys?.totalPayment - querys?.couponDetails?.amount
        : 1
      : querys?.totalPayment;
    // const totalAmount = 1; //added for temp period for testing

    const options = {
      key: "rzp_live_V5IFJZygzacCdA", // Enter the Key ID generated from the Dashboard
      amount: Math.floor(totalAmount * 100),
      // amount: Math.floor(1 * 100),

      name: "Mentor Link.",
      description: "Test Transaction",
      handler: async function (response) {
        console.log("inside handler", response);
        const data = {
          razorpayPaymentId: response.razorpay_payment_id,
        };
        console.log(data);
        if (data?.razorpayPaymentId)
          handleServerSidebooking(
            data.razorpayPaymentId,
            querys?.couponDetails?.amount && "discount"
          );
        return data;
      },
      image: allImages.logoWhole,
      prefill: {
        name: "MentorLink",
        email: "mentorlink@gmail.com",
        contact: "9544614796",
      },
      notes: {
        address: "Soumya Dey Corporate Office",
      },
      theme: {
        color: "#2c72ff",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }

  //finished
  const handleConfirmBooking = async () => {
    displayRazorpay();
  };

  const handleSuccessAndRejection = async () => {
    setIndexValue(true);

    lottie.play();
  };
  const handleServerSidebooking = async (id, paymentType) => {
    let datas = {
      mentorId: querys?.mentorId,
      sessionTime: querys.sessionTime,
      appointmentFee: querys?.appointmentFee,
      convenienceFee: querys?.convenienceFee,
      tax: querys?.tax,
      totalPayment: querys?.totalPayment,
      sessionDate: querys?.sessionDate,
    };
    if (id) {
      datas = {
        ...datas,
        paymentRefId: id,
      };
    }
    if (paymentType === "coupon") {
      datas = {
        ...datas,
        paymentType: "coupon",
      };
    }
    if (paymentType === "discount") {
      datas = {
        ...datas,
        paymentType: "coupon",
        couponId: querys?.couponDetails?._id,
      };
    }

    try {
      const response = await APIRequest.request(
        // get the users id from localstorage
        "POST",
        ConfigAPIURL.confirmBooking,
        JSON.stringify(datas)
      );
      console.log(response);
      if (response?.data?.responseCode === 109) {
        handleSuccessAndRejection();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getRedeemPoints = async () => {
    try {
      const response = await APIRequest.request(
        // get the users id from localstorage
        "GET",
        ConfigAPIURL.getCouponsRedeem + "?amount=" + querys?.totalPayment,
        ""
      );
      console.log(
        ConfigAPIURL.getCouponsRedeem + "?amount=" + querys?.totalPayment
      );
      if (response?.data?.responseCode === 109) {
        return response?.data?.result;
        // handleSuccessAndRejection();
      }
    } catch (err) {
      console.log(err);
    }
  };
  const couponsConfirmBooking = async () => {
    handleServerSidebooking(null, "coupon");
  };
  const discountConfirmBooking = async () => {
    handleServerSidebooking(null, "discount");
  };

  console.log(
    querys?.totalPayment - querys?.couponDetails?.amount > 0,
    "this is coupon details"
  );
  return (
    <Stack
      border="0.742701px solid #D2D2D2"
      borderRadius="4.4562px"
      sx={{ background: " #FEFEFE", py: 1, px: 4,borderRadius:'16px',padding:'24px' }}
    >
      <Typography
        variant="subtitle1"
        fontWeight="600"
        sx={{ fontSize: "20px" }}
      >
        {"Summary"}
      </Typography>
      {/* pay amout */}
      <Stack direction="row" alignItems="center">
        {/* coupons check or not */}
        <Checkbox
          checked={checked}
          onChange={checkWalletAvailable}
          inputProps={{ "aria-label": "controlled" }}
        />
        <Typography variant="subtitle1" color="black" sx={{ font: "16px" }}>
          Choose wallet Amount
        </Typography>
      </Stack>
      <Stack
        // borer="0.742701px solid #D2D2D2"
        border="0.74px solid #D2D2D2"
        borderRadius="4.4562px"
        sx={{ background: "white" }}
      >
        <Typography
          variant="subtitle1"
          fontWeight="600"
          // sx={{ fontSize: "16px" }}
          p={1.5}
          borderBottom="2px solid #E0E0E0"
          color='#1C3464'
          fontSize={'20px'}
        >
          {"Amount to pay"}
        </Typography>
        <Stack p={1.5} borderBottom="2px solid #E0E0E0">
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="subtitle1" color="#797979" sx={{ font: "16px" }} fontWeight={'500'}>
              {"Consultation Fee"}
            </Typography>
            <Typography variant="subtitle1" fontSize="16px" fontWeight={'500'} color="#797979">
              + ₹ {querys?.appointmentFee}
            </Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="subtitle1"  sx={{ font: "16px" }}  fontWeight={'500'} color="#797979">
              {"Convenience "}
            </Typography>
            <Typography variant="subtitle1"  fontWeight={'500'} color="#797979" fontSize="16px">
              + ₹ {querys?.convenienceFee}
            </Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography
              variant="subtitle1"
              sx={{ font: "16px"}}
              fontWeight={'500'} color="#797979"
            >
              {"Tax"}
            </Typography>
            <Typography variant="subtitle1" fontWeight={'500'} color="#797979" fontSize="16px">
              + ₹ {querys?.tax}
            </Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography
              // color="#22D61E"
              variant="subtitle1"
              // fontWeight="600"
              sx={{ font: "16px" }}
              fontWeight={'500'} color="#797979"
            >
              {"Applied Coupon"}
            </Typography>
            <Typography
              // color="#22D61E"
              variant="subtitle1"
              // fontWeight="700"
              fontSize="16px"
              fontWeight={'500'} color="#797979"
            >
              - ₹{" "}
              {checked
                ? querys?.totalPayment
                : querys?.couponDetails?.amount || 0}
              {/* - ₹{" "} */}
              {/* {querys?.coupons || 0} */}
            </Typography>
          </Stack>
        </Stack>
        <Stack p={1.5}>
          <Stack direction="row" justifyContent="space-between">
            <Typography
              variant="subtitle1"
              fontWeight="600"
              sx={{color: "#1C3464", fontSize: "20px" }}
            >
              {"Total amount"}
            </Typography>
            <Typography
              variant="subtitle1"
              color="#1C3464"
              fontWeight="700"
              sx={{ fontSize: "20px" }}
            >
              ₹
              {checked
                ? 0
                : querys?.couponDetails?.amount
                ? querys?.totalPayment - querys?.couponDetails?.amount > 0
                  ? querys?.totalPayment - querys?.couponDetails?.amount
                  : 0
                : querys?.totalPayment}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <Box
        alignSelf="end"
        sx={{ cursor: "pointer",marginTop:'16px' }}
        onClick={() => {
          if (querys?.totalPayment - querys?.couponDetails?.amount < 0) {
            discountConfirmBooking();
            return;
          }
          checked ? couponsConfirmBooking() : handleConfirmBooking();
        }}
      >
        <Button
          variant="contained"
          sx={{
            background: "#FF5F73",
            borderRadius: "24px",
            color: "white",
            fontWeight: "700",
            fontSize: "14px",
            // py: ".5rem",
            cursor: "pointer",
            padding:'14px 22px'
          }}
          disabled={!querys?.appointmentFee ? true : false}
        >
          {"Confirm Booking"}
        </Button>
      </Box>
      <Snackbar
        open={snakbarValues?.status || snackBarMessage?.status}
        onClose={notificationClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={3000}
      >
        <Alert severity={snakbarValues.severity || snackBarMessage?.severity}>
          {snakbarValues?.message || snackBarMessage?.message}
        </Alert>
      </Snackbar>
    </Stack>
  );
};

export default Summary;
