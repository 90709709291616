import { Box, Button, Stack, Typography } from "@mui/material";
import { fontFamily } from "@mui/system";
import React from "react";
import { useNavigate } from "react-router-dom";
import { encryptandDecrypt } from "../../../../config/EncrytDecryptData";
import { allImages } from "../../../../images";
import BlueButton from "../BlueButton";

const MentorCard2 = (props) => {
  const navigate = useNavigate();
  return (
    <Stack
      direction="row"
      mt={3}
      justifyContent={"space-between"}
      alignItems="center"
    >
      <Stack direction="row" gap={1} alignItems="center">
        <Box sx={{ width: "60px", height: "60px" }}>
          <img
            src={
              props.details.profileImage && props.details.profileImage !== ""
                ? props.details.profileImage
                : allImages.profilementor
            }
            alt="profileImg"
          />
        </Box>
        <Box>
          <Typography
            variant="subtitle1"
            fontWeight={"700"}
            fontSize="12px"
            width="100px"
          >
            {props.details.fname}
          </Typography>
          <Typography
            width="100px"
            variant="subtitle1"
            fontWeight={"600"}
            fontSize="11px"
            color="#727272"
            sx={{ textTransform: "capitalize" }}
          >
            {props.details.designation}
          </Typography>
        </Box>
      </Stack>
      {!props?.isMentor ? (
        <Box>
          <Button
            onClick={() =>
              navigate(
                `/booking/id?payload=${encryptandDecrypt.encrypt(
                  props?.details?._id
                )}`
              )
            }
            variant="contained"
            className="submit__button"
            sx={{
              // background: "#1C3464",
              borderRadius: "37.135px",
              // color: "white",
              fontWeight: "700",
              fontSize: "10px",
              fontFamily: "nunito",
              cursor: "pointer",
            }}
          >
            Book Now
          </Button>
        </Box>
      ) : (
        <Box>
          <Button
            onClick={() => navigate(`/mentors/${props.details._id}`)}
            variant="contained"
            sx={{
              background: "#1C3464",
              borderRadius: "37.135px",
              color: "white",
              fontWeight: "700",
              fontSize: "10px",
              fontFamily: "nunito",
              cursor: "pointer",
            }}
          >
            {" "}
            See More
          </Button>
        </Box>
      )}
    </Stack>
  );
};

export default MentorCard2;
