import React, { useState, useEffect } from "react";

import "./waveAnimation.css";
// import styles from './waveAnimation.css';

function WaveAnimation(props) {
  return (
//     <div className={'waveContainer'}>
//     <svg className={'waves'} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
//       viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
//       <defs>
//         <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
//       </defs>
//       <g className={'parallax'}>
//         <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(74, 144, 226, 0.7)" />
//         <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(80, 227, 194, 0.5)" />
//         <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(74, 144, 226, 0.3)" />
//         <use xlinkHref="#gentle-wave" x="48" y="7" fill="rgba(80, 227, 194, 0.1)" />
//       </g>
//     </svg>
//   </div>
<div className="waveContainer">
      <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 1440 320" preserveAspectRatio="none">
        <path className="wave wave1" d="M0,160L48,176C96,192,192,224,288,240C384,256,480,256,576,234.7C672,213,768,171,864,149.3C960,128,1056,128,1152,149.3C1248,171,1344,213,1392,234.7L1440,256L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
        <path className="wave wave2" d="M0,256L48,261.3C96,267,192,277,288,261.3C384,245,480,203,576,197.3C672,192,768,224,864,234.7C960,245,1056,235,1152,224C1248,213,1344,203,1392,197.3L1440,192L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
      </svg>
      {/* <svg className="waves" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
      <path className="wave wave1" fill="#00275A" fill-opacity="1" d="M0,256L17.1,240C34.3,224,69,192,103,202.7C137.1,213,171,267,206,261.3C240,256,274,192,309,186.7C342.9,181,377,235,411,224C445.7,213,480,139,514,122.7C548.6,107,583,149,617,170.7C651.4,192,686,192,720,192C754.3,192,789,192,823,176C857.1,160,891,128,926,138.7C960,149,994,203,1029,202.7C1062.9,203,1097,149,1131,160C1165.7,171,1200,245,1234,240C1268.6,235,1303,149,1337,117.3C1371.4,85,1406,107,1423,117.3L1440,128L1440,320L1422.9,320C1405.7,320,1371,320,1337,320C1302.9,320,1269,320,1234,320C1200,320,1166,320,1131,320C1097.1,320,1063,320,1029,320C994.3,320,960,320,926,320C891.4,320,857,320,823,320C788.6,320,754,320,720,320C685.7,320,651,320,617,320C582.9,320,549,320,514,320C480,320,446,320,411,320C377.1,320,343,320,309,320C274.3,320,240,320,206,320C171.4,320,137,320,103,320C68.6,320,34,320,17,320L0,320Z"></path>
      <path className="wave wave2" fill="#00275A" fill-opacity="1" d="M0,256L17.1,240C34.3,224,69,192,103,202.7C137.1,213,171,267,206,261.3C240,256,274,192,309,186.7C342.9,181,377,235,411,224C445.7,213,480,139,514,122.7C548.6,107,583,149,617,170.7C651.4,192,686,192,720,192C754.3,192,789,192,823,176C857.1,160,891,128,926,138.7C960,149,994,203,1029,202.7C1062.9,203,1097,149,1131,160C1165.7,171,1200,245,1234,240C1268.6,235,1303,149,1337,117.3C1371.4,85,1406,107,1423,117.3L1440,128L1440,320L1422.9,320C1405.7,320,1371,320,1337,320C1302.9,320,1269,320,1234,320C1200,320,1166,320,1131,320C1097.1,320,1063,320,1029,320C994.3,320,960,320,926,320C891.4,320,857,320,823,320C788.6,320,754,320,720,320C685.7,320,651,320,617,320C582.9,320,549,320,514,320C480,320,446,320,411,320C377.1,320,343,320,309,320C274.3,320,240,320,206,320C171.4,320,137,320,103,320C68.6,320,34,320,17,320L0,320Z"></path>
      </svg> */}
    </div>
  );
}

export default WaveAnimation;