import { Button, Container, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { faker } from "@faker-js/faker";
import "../Home/Home.scss";
import { allImages } from "../../../../images";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import APIRequest from "../../../../utils/APIRequest";
import ConfigAPIURL from "../../../../config/ConfigAPIURL";
import { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import { useSelector, useDispatch } from "react-redux";
import { update } from "../Redux/UserSlice";

const Homehero = () => {
  const bannerDatas = useSelector((state) => state.user.bannerDatas);
  const dispatch = useDispatch();
  const [bannerData, setBannerData] = useState([]);

  const navigate = useNavigate();

  console.log(navigate);
  useEffect(() => {
    fetchBannerAds();
  }, []);

  const fetchBannerAds = () => {
    APIRequest.request(
      "POST",
      ConfigAPIURL.bannerAdvertisement,
      JSON.stringify({
        pageSize: 2,
        page: 0,
      })
    ).then((response) => {
      if (response?.data?.responseCode === 109) {
        console.log("this is response data", response?.data);
        if (response?.data?.rows?.[0] < 1) return;
        dispatch(update(response?.data?.rows?.[0]?.bannerList));
      }
    });
  };
  return (
    <Container
      maxWidth={"md"}
      sx={{
        borderRadius: "15px",
        mt: { md: "1.5rem", xs: "3.2rem" },
      }}
    >
      <Carousel controls={false} indicators={false}>
        {bannerDatas?.length > 0 &&
          bannerDatas?.map((val) => {
            return (
              <Carousel.Item key={val?._id}>
                <Link to={val?.siteLink}>
                  <img
                    // onClick={() => navigate(val?.siteLink, { replace: false })}
                    className="d-block w-100 "
                    src={val.image}
                    alt="First slide"
                  />
                </Link>
              </Carousel.Item>
            );
          })}
      </Carousel>
    </Container>
  );
};

export default Homehero;
