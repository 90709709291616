import { Container, Grid, Stack } from "@mui/material";
import React from "react";
import PopularMentors from "../categoriespage/PopularMentors";
import Posts from "./Posts";
import Profile from "./Profile";
import Notifications from "./Notifications";
import Promotions from "./Promotions";

const Index = () => {
  return (
    <>
      <Container maxWidth="lg" sx={{ minHeight: "100vh" }}>
        {/* if no notification */}

        {/* if notification exist */}
        <Grid container mt={5} spacing={1.5}>
          <Grid
            item
            sx={{ display: { xs: "none", md: "block" } }}
            xs={12}
            md={3}
          >
            <Profile />
          </Grid>
          <Grid item xs={12} md={6}>
            <Notifications />
          </Grid>
          <Grid
            item
            sx={{ display: { xs: "none", md: "block" } }}
            xs={12}
            md={3}
          >
            {/* <Promotions /> */}
            <PopularMentors />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Index;
